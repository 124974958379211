import React, { ReactElement } from 'react'
import styles from './DarkBox.module.scss'

interface Props {
	label: string, 
	content: string, 
	editable: boolean, 
	onClick: () => void, 
	labelDetail: string,
};

const DarkBox: React.FC<Props> = ({ 
	label, 
	content, 
	editable = true, 
	onClick, 
	labelDetail 
}): ReactElement => {
	return (
		<div className={ styles.container }>
			<div className={ styles.labelContainer }>
				{ label && 
					<div className={ styles.label }>
						{ label }
					</div> 
				}
				{ labelDetail && 
					<div className={ styles.labelDetail }>
						{ labelDetail }
					</div>
				}
			</div>
			<div className={ styles.contentContainer }>
				<div className={ styles.content }>
					{ content }
				</div>
				{ !!editable && 
					<span className={ styles.rightText } onClick={ () => onClick() }>
						Edit
					</span>
				}
			</div>
		</div>
  	);
}

export default DarkBox;
