// React
import { PureComponent, FC, ReactElement } from "react";
// Lodash
import { get } from "lodash";
// Redux
import { connect } from "react-redux";
// Actions
import { setLoading } from "../../../actions";
// Sagas
import { getRouteData } from "../../../sagas/navigation/bhaNavigation";
// Components
import { stepList, stepListIndexType } from "./stepList";
// Constant
import { Images } from "../../../constants";
// Scss
import styles from "./Stepper.module.scss";
import {
  getRoute,
  getRouteData as getRouteDataCompany,
} from "../../../sagas/navigation/companyNavigation";

// Type props
type Props = {
  steps: Array<any>;
  activeStep: string | number;
  stepList: stepListIndexType;
  setLoading: (e: any) => void;
  isVertical: boolean | string;
  isInline: boolean | string;
  stepIndicator: any;
  context?: string;
  businessOperatedName?: string;
  stepClickEvent?: any;
  onStepChange?: () => void;
};

// Type state
type State = {
  mysteps: Array<any>;
  activeStep: string | number;
  context?: string;
  businessOperatedName?: string;
  stepClickEvent?: any;
  onStepChange?: () => void;
};
// Interface
interface IStep {
  key: string | number;
  active?: string | boolean;
  completed?: string | Boolean;
  navigationAllowed: any;
  indicator: any;
  isInline: string | boolean;
  children?: JSX.Element | JSX.Element[];
  handleStepChange: () => void;
  isDisabled: string | boolean;
  title: string;
}

/**
 * Step
 * @param {IStep} props
 * @return {*}  {ReactElement}
 */
const Step: FC<IStep> = (props: IStep): ReactElement => {
  const active: string = props.active ? "active" : "";
  const completed: string = props.completed ? "completed" : "";
  const context: string = get(props, "context", "");
  // const stepClickEvent: any = get(props, 'stepClickEvent', () => {});
  // const onStepChange: any = get(props, "onStepChange", () => {});

  return (
    <div
      className={`${styles.stepper__step} ${
        props.completed
          ? `${styles.completedSibling} ${
              props.navigationAllowed && styles.cursorPointer
            }`
          : ""
      }`}
      onClick={() => {
        if (!props.isDisabled) {
          props.handleStepChange();
        }
      }}
      style={{ cursor: "pointer" }}
    >
      <div className={`${styles.stepper__indicator}`}>
        <span className={`${styles.stepper__info} ${styles[active]}`}>
          {props.completed ? (
            <span
              style={{
                backgroundImage: `url(${Images.checkMark})`,
                backgroundSize: `15px 13px`,
                backgroundPosition: `50% 50%`,
                backgroundRepeat: `no-repeat`,
              }}
              className={styles.completed}
            >
              {" "}
              0
            </span>
          ) : (
            props.indicator
          )}
        </span>
      </div>
      <div
        className={`${styles.stepper__label} ${
          props.active ? `${styles.activeLabel}` : ""
        }`}
      >
        {props.title}
      </div>
      {props.isInline ? (
        ""
      ) : (
        <div className={styles.stepper__panel}>{props.children}</div>
      )}
    </div>
  );
};

// overlay
/**
 * @class MainStepper
 * @extends {PureComponent<Props, State>}
 */
class MainStepper extends PureComponent<Props, State> {
  /**
   * @static
   * @type {object}
   * @memberof MainStepper
   */
  static propTypes: object = {};

  /**
   * Creates an instance of MainStepper.
   * @param {Props} props
   * @memberof MainStepper
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      mysteps: this.props.steps || [],
      activeStep: this.props.activeStep,
      context: this.props.context ?? "",
      businessOperatedName: this.props.businessOperatedName ?? "",
      stepClickEvent: this.props.stepClickEvent,
      // onStepChange: this.props.onStepChange,
    };
  }

  componentDidMount(): void {
    if (this.props.stepList && this.props.stepList !== undefined) {
      this.setState({ mysteps: stepList[this.props.stepList] });
    }
  }

  handleStepChange(nextStep: any): void {
    if (this.props.onStepChange) {
      this.props.onStepChange();
    }
    if (nextStep) {
      const enrolementType: stepListIndexType =
        nextStep && nextStep.split("-")[0].toLowerCase();
      const desiredStep: any = enrolementType && nextStep.split("-")[1];
      let stepObj: any[] = [];
      if (this.props.context === "company-full") {
        // alert("Company Full");
        stepObj =
          this.props.steps &&
          this.props.steps.filter(
            (item: any): Array<any> => item.step.includes(desiredStep)
          );
        let data = {
          inviteState: {
            step: nextStep,
          },
          business: {
            businessOperatedName: this.props.businessOperatedName,
          },
        };

        if (this.props.stepClickEvent) this.props.stepClickEvent(nextStep);

        let routeData = getRouteDataCompany(data);
        this.props.setLoading({ loading: false, ...routeData });
      } else {
        stepObj =
          stepList &&
          stepList[enrolementType].filter(
            (item: any): Array<any> => item.step.includes(desiredStep)
          );
        if (get(stepObj, "[0].indicator") <= get(this.props, "stepIndicator")) {
          let routeData = getRouteData(undefined, nextStep);
          this.props.setLoading({ ...routeData });
        }
      }
    }
  }

  render(): ReactElement {
    const { mysteps }: State = this.state;
    return (
      <div
        className={`
					${styles.stepper} 
					${this.props.isVertical ? `${styles.stepper__vertical}` : ""} 
					${this.props.isInline ? `${styles.stepper__inline} ` : ""}
				`}
      >
        {mysteps.map((step) => {
          if (this.props.context === "company-full" && step.indicator === 5) {
            return (
              <Step
                key={step.indicator}
                indicator={step.indicator}
                title={step.title}
                active={this.props.activeStep === 5}
                isInline={this.props.isInline}
                handleStepChange={() => this.handleStepChange(step.step)}
                isDisabled={false}
                navigationAllowed={true}
                completed={true}
              />
            );
          }
          if (
            this.props.context === "company-full" &&
            this.props.activeStep === step.indicator
          ) {
            return (
              <Step
                key={step.indicator}
                indicator={step.indicator}
                title={step.title}
                active={true}
                isInline={this.props.isInline}
                handleStepChange={() => this.handleStepChange(step.step)}
                isDisabled={false}
                navigationAllowed={true}
                completed={step.indicator === 5 ? true : false}
              />
            );
          }
          if (this.props.activeStep === step.indicator) {
            return (
              <Step
                key={step.indicator}
                indicator={step.indicator}
                title={step.title}
                active={true}
                isInline={this.props.isInline}
                handleStepChange={() => this.handleStepChange(step.step)}
                isDisabled={step.isDisabled}
                navigationAllowed={
                  step.indicator <= get(this.props, "stepIndicator")
                    ? true
                    : false
                }
              />
            );
          }
          if (this.props.activeStep > step.indicator) {
            return (
              <Step
                key={step.indicator}
                indicator={step.indicator}
                title={step.title}
                completed={this.props.stepIndicator > step.indicator && true}
                isInline={this.props.isInline}
                handleStepChange={() => this.handleStepChange(step.step)}
                isDisabled={step.isDisabled}
                navigationAllowed={
                  step.indicator <= get(this.props, "stepIndicator")
                    ? true
                    : false
                }
              />
            );
          }
          return (
            <Step
              key={step.indicator}
              indicator={step.indicator}
              title={step.title}
              isInline={this.props.isInline}
              handleStepChange={() => this.handleStepChange(step.step)}
              isDisabled={step.isDisabled}
              navigationAllowed={
                step.indicator <= get(this.props, "stepIndicator")
                  ? true
                  : false
              }
            />
          );
        })}
      </div>
    );
  }
}

// Redux mapper
const mapStateToProps = (state: any) => {
  const { stepIndicator } = state.generalReducer;
  const { data } = state.brokerageHouseReducer;

  return {
    stepIndicator,
    brokerageHouseData: data,
  };
};

// Connect with redux store
const Stepper = connect(mapStateToProps, { setLoading })(MainStepper);

// Export
export { Step, Stepper };
