import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  arrayPush,
  arrayRemoveAll,
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { Popup, Icon, Message } from "semantic-ui-react";
import {
  // fetchAgreements,
  // fetchBanks,
  updateCompany,
  saveEnrolmentCompanyData,
} from "../../../../../actions";
import { Images } from "../../../../../constants";
import {
  FULL__STEP_FIVE__COMPANY,
  FULL__STEP_SIX__COMPANY,
} from "../../../../../sagas/navigation/stepTypes";
import { email, required } from "../../../../../utilities/validationRules";
import Button from "../../../../shared/button";
import Checkbox from "../../../../shared/checkbox";
import Divider from "../../../../shared/divider";
import { ConnectedInput } from "../../../../shared/input/ConnectedInput";
import { FormattedInput } from "../../../../shared/input/FormattedInput";
import { RadioGroup } from "../../../../shared/radio";
import { Stepper } from "../../../../shared/stepper";
// import { steps } from "./sampleConstants";
import {
  BankPaymentMethods,
  steps,
} from "../../../../shared/company-enrolment/sampleData";
import {
  addToMetaDataPayloadString,
  resetPayload,
} from "../../../../shared/company-enrolment/company-helpers";
import GradientButton from "../../../../auth/login/components/GradientButton";

export class BankAccount extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.bottomRef = React.createRef();
    this.state = {
      submission: false,
      terms: false,
      institutionNumber: "",
      bankPaymentMethod: this.props.bankPaymentMethod,
      payloadString: this.props.payloadString,
      bankNumber: this.props.bankNumber,
      bankBranchNumber: this.props.bankBranchNumber,
      accountNumber: this.props.accountNumber,
      authorizedSigners: this.props.authorizedSigners,
      payloadString: this.props.payloadString,
      companyEnrolment: this.props.companyEnrolment ?? {},
      prepaySectionNumber: 13,
      enrolledByEmail: this.props.enrolledByEmail,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    setTimeout(() => {
      const { selectedCompanyGuid, email } = this.props.companyEnrolment ?? {};
      this.setState({
        selectedCompanyGuid,
        email,
      });

      if (this.state.programPaymentType !== "postpayment") {
        this.props.dispatch(
          arrayPush("CompanyBankAccount", "authorizedSigners", {})
        );
      }
    });
  }
  switchAccountType = (id) => {
    this.setState({ accountTypeId: id });
  };

  toggleSubmission = () => {
    this.setState({ submission: !this.state.submission });
  };
  toggleTerms = () => {
    this.setState({ terms: !this.state.terms });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleGeneric = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  chosePaymentType = (value) => {
    this.setState({ bankPaymentMethod: value }, () => {
      // if (
      //   this.state.bankPaymentMethod === "pad" &&
      //   this.props.authorizedSigners.length < 1
      // ) {
      //   this.props.dispatch(
      //     arrayRemoveAll("CompanyBankAccount", "authorizedSigners")
      //   );
      //   this.props.dispatch(
      //     arrayPush("CompanyBankAccount", "authorizedSigners", {})
      //   );
      // }
      // if (
      //   this.state.bankPaymentMethod !== "pad" &&
      //   this.props.authorizedSigners.length > 0
      // ) {
      //   this.props.dispatch(
      //     arrayRemoveAll("CompanyBankAccount", "authorizedSigners")
      //   );
      // }
    });
  };

  scrollToBottom = () => {
    if (this.bottomRef.current) {
      this.bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    const { styles, handleChange, updateView } = this.props;
    const { bankPaymentMethod } = this.state;
    return (
      <>
        <div
          className={styles.msgContainer}
          style={{
            width: "600px",
            fontFamily: "Montserrat",
            textAlign: "center",
          }}
        >
          Reminder: Click "
          <a
            onClick={this.scrollToBottom}
            style={{
              cursor: "pointer",
            }}
          >
            Save and Continue
          </a>
          " button to keep your progress!
        </div>
        <div className={styles.contentContainerX}>
          <div className={styles.description}>
            <Stepper
              steps={steps}
              activeStep={4}
              context={"company-full"}
              businessOperatedName={this.props.businessOperatedName}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.subHeaderLabels}>
              Section{" "}
              {this.state.programPaymentType !== "postpayment" ? 14 : 11}:
              Invoicing Info
            </div>
            <div className={styles.standaloneLabel}>
              How do you plan on paying your invoices?
            </div>
            <div className={styles.sectionContent}>
              <RadioGroup
                items={BankPaymentMethods}
                value={this.state.bankPaymentMethod}
                name="opt-group"
                onUpdate={(value) => this.chosePaymentType(value)}
              />
              {bankPaymentMethod === "pad" && (
                <div className={styles.msgContainer}>
                  Funds will be automatically withdrawn from the company's
                  account and transferred to us via EFT (Electronic Funds
                  Transfer). Please note that there will be a 10-day hold on the
                  funds to clear with no additional fees.
                </div>
              )}
              {bankPaymentMethod === "wire" && (
                <div className={styles.msgContainer}>
                  Aya will issue an invoice, and the company must manually
                  transfer the funds to pay it.
                </div>
              )}
            </div>
          </div>
          {bankPaymentMethod === "pad" && (
            <div className={styles.section}>
              <div className={styles.superSubHeader}>Pre-Authorized Debit</div>
              <div className={styles.sectionContent}>
                <Popup
                  content={
                    <React.Fragment>
                      <React.Fragment>
                        <img
                          className={styles.tooltipImage}
                          src={Images.institutionNumber}
                        />
                      </React.Fragment>
                      {
                        "you can find this 3 digit number on the bottom of a cheque or on your online banking"
                      }
                    </React.Fragment>
                  }
                  trigger={
                    <div className={styles.standaloneLabel}>
                      Financial Institution Number{" "}
                      <img src={Images.question} className={styles.tooltip} />{" "}
                    </div>
                  }
                />
                <Field
                  component={ConnectedInput}
                  label=""
                  name="bankNumber"
                  type="text"
                  max={3}
                  extrastyle="semi-small"
                  placeholder="---"
                />

                <Popup
                  content={
                    <React.Fragment>
                      <React.Fragment>
                        <img
                          className={styles.tooltipImage}
                          src={Images.branchNumber}
                        />
                      </React.Fragment>
                      {
                        "you can find this 5 digit number on the bottom of a cheque or on your online banking"
                      }
                    </React.Fragment>
                  }
                  trigger={
                    <div className={styles.standaloneLabel}>
                      Branch Transit Number{" "}
                      <img src={Images.question} className={styles.tooltip} />{" "}
                    </div>
                  }
                />
                <Field
                  component={ConnectedInput}
                  label=""
                  name="bankBranchNumber"
                  type="text"
                  max={5}
                  extrastyle="semi-small"
                  placeholder="-----"
                />

                <Popup
                  content={
                    <React.Fragment>
                      <React.Fragment>
                        <img
                          className={styles.tooltipImage}
                          src={Images.accountNumber}
                        />
                      </React.Fragment>
                      {
                        "you can find this number on the bottom of a cheque or on your online banking"
                      }
                    </React.Fragment>
                  }
                  trigger={
                    <div className={styles.standaloneLabel}>
                      Account Number{" "}
                      <img src={Images.question} className={styles.tooltip} />{" "}
                    </div>
                  }
                />
                <Field
                  component={ConnectedInput}
                  label=""
                  name="accountNumber"
                  type="text"
                  extrastyle="large"
                  placeholder="xxxxxxxxxx"
                />
              </div>
            </div>
          )}
          {this.props.programPaymentType !== "postpayment" && (
            <React.Fragment>
              <div className={styles.section}>
                <div className={styles.section}>
                  <div className={styles.subHeaderLabels}>
                    Section 15: Authorized Signer
                  </div>
                  <div className={styles.msgContainer}>
                    An authorized signer is legally empowered to manage the
                    company's Aya account, including signing and conducting
                    transactions. Aya, as a registered Money Services Business
                    (MSB) under FINTRAC regulations, requires authorized signers
                    to provide two forms of ID to enroll a business and activate
                    an account.
                  </div>
                </div>
                <div
                  className={styles.description}
                  style={{ marginBottom: "20px" }}
                >
                  Please provide the details of up to 3 authorized signers (each
                  authorized signer will be required to undergo ID Verification)
                </div>
                <FieldArray
                  name="authorizedSigners"
                  component={this.renderAuthorizedSigners}
                  styles={styles}
                  mystate={this.state}
                />
              </div>
            </React.Fragment>
          )}

          <div className={styles.buttonAreaContainer}>
            <GradientButton
              handleClick={() => {
                // e.preventDefault();
                this.updateBankAccount(FULL__STEP_SIX__COMPANY);
              }}
              style={{
                marginTop: "20px",
              }}
              label="Save And Continue"
              width="100%"
            ></GradientButton>

            {/* Bottom element to scroll to */}
            <div ref={this.bottomRef}></div>
          </div>
        </div>
      </>
    );
  }

  renderAuthorizedSigners = ({ styles, handleChange, fields, mystate }) => (
    <div>
      {fields.map((authorizedSigner, index) => (
        <div
          key={index}
          className={`${styles.sectionContent} ${styles.directorWrapper}`}
        >
          <div className={`${styles.blueContainer} ${styles.checkboxActive}`}>
            <div className={styles.sectionTitle}>
              Authorized Signer {index + 1}
            </div>
            <div className={styles.inputContainer}>
              <Field
                component={ConnectedInput}
                label="First Name"
                name={`${authorizedSigner}.firstName`}
                type="text"
                validate={[required]}
                placeholder="Enter First Name"
                extrastyle="medium"
              />
              <div style={{ paddingRight: 20, marginRight: 10 }} />
              <Field
                component={ConnectedInput}
                label="Last Name"
                name={`${authorizedSigner}.lastName`}
                type="text"
                validate={[required]}
                extrastyle="medium"
                placeholder="Enter Last Name"
              />
            </div>
            <div>
              <Field
                component={ConnectedInput}
                label="Email"
                name={`${authorizedSigner}.email`}
                type="email"
                validate={[required, email]}
                placeholder="Enter Email"
              />
              <div
                className={styles.msgContainer}
                color="green"
                style={{
                  marginBottom: 10,
                }}
              >
                <Icon name="info circle" />
                <span>
                  The Authorized Signer Email Address must be unique and should
                  be used only as the Authorized Signer.
                </span>
              </div>
            </div>
            <br />
            <Field
              component={FormattedInput}
              label="Mobile Number"
              name={`${authorizedSigner}.mobileNumber`}
              type="tel"
              labelDetail="optional"
              myFormat="+1 (###) ###-####"
              placeholder="Enter Mobile Number"
            />
            <div className={styles.multiInputContainer}>
              <Field
                component={FormattedInput}
                label="Work Number"
                name={`${authorizedSigner}.workNumber`}
                type="tel"
                extrastyle="large"
                labelDetail="optional"
                myFormat="+1 (###) ###-####"
                placeholder="Enter Work Number"
              />
              <Field
                component={ConnectedInput}
                label="Extension"
                name={`${authorizedSigner}.workNumberExt`}
                type="text"
                extrastyle="tiny"
                placeholder="0"
              />
            </div>
            <div>
              {_.size(fields) > 1 && (
                <div className={styles.removeAdministratorWrapper}>
                  <a
                    href={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      fields.remove(index);
                    }}
                    className={styles.removeAdministrator}
                  >
                    - Remove Authorized Signer
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      {_.size(fields) < 3 && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            fields.push();
          }}
          className={styles.addAdministrator}
        >
          + Add Authorized Signer
        </a>
      )}
    </div>
  );

  updateBankAccount = async (nextStep) => {
    const {
      bankName,
      bankBranchNumber,
      bankNumber,
      accountNumber,
      authorizedSigners,
    } = this.props;
    const { selectedBankName, institutionNumber, bankPaymentMethod } =
      this.state;

    let payloadData = this.props.company;

    //modify contacts
    let { contacts = [] } = payloadData;

    //Remove authorizedSigners
    if (
      contacts &&
      typeof contacts !== null &&
      typeof contacts.forEach === "function"
    ) {
      var i = contacts.length;
      while (i--) {
        if (contacts[i].userRole.name === "planSponsorAuthorizedSigner") {
          contacts.splice(i, 1);
        }
      }
    }

    const params = {
      method: "put",
      path: `invites/${payloadData.inviteGuid}`,
      sendNotification: false,
      data: {
        ...payloadData,
        dirty: true,
        inviteState: {
          dirty: true,
          step: nextStep,
          status: "active",
        },
        person: {
          // ...payloadData.person,
          dirty: false,
          countryCode: "CA",
          languageCode: "en",
          programDefinedVariables: {},
        },
        contacts: contacts || [],
        business: {
          ...payloadData.business,
          dirty: true,
          businessPreferredLanguage: "en",
          country: "CA",
          mailingCountry: "CA",
          institutionType: "planSponsor",
          defaultPaymentMethod: bankPaymentMethod,
          metaInformation: {
            ...payloadData.business.metaInformation,
            newPlanAdminGuid: this.props.newPlanAdmin
              ? this.props.newPlanAdmin.inviteGuid
              : "",
            dirty: true,
          },
        },
        bankAccountDetails: {
          dirty: true,
          bankName:
            institutionNumber === "0" ? bankName : selectedBankName || "",
          bankBranchNumber: bankBranchNumber,
          bankNumber: bankNumber,
          bankAccountNumber: accountNumber,
          bankAccountType: "business",
        },
        accessToken: "",
      },
    };
    if (authorizedSigners && authorizedSigners.length > 0) {
      for (const authorizedSigner of authorizedSigners) {
        if (authorizedSigner.email) {
          let item = {
            firstName: authorizedSigner.firstName,
            lastName: authorizedSigner.lastName,
            email: authorizedSigner.email
              ? authorizedSigner.email.toLowerCase()
              : undefined,
            userRole: {
              label: "PlanSponsor Company Authorized Signer",
              name: "planSponsorAuthorizedSigner",
            },
            countryCode: "CA",
            languageCode: "en",
            mobileNumber: authorizedSigner.mobileNumber,
            workNumber: authorizedSigner.workNumber,
            workNumberExt: authorizedSigner.workNumberExt,
            dirty: true,
          };
          params.data.contacts.push(item);
        }
      }
    }

    //Payload string
    const modifiedPayloadString = addToMetaDataPayloadString(
      this.state.payloadString,
      params.data
    );
    let newParams = resetPayload(params);
    newParams.data.business.metaInformation["payloadString"] =
      modifiedPayloadString;

    try {
      // let response = this.props.updateCompany(newParams);
      let response = await this.props.saveEnrolmentCompanyData({
        companyData: modifiedPayloadString,
        enrolledByEmail: this.state.enrolledByEmail,
        guid: this.state.selectedCompanyGuid,
      });
    } catch (ex) {}
  };
}

const validate = (formValues) => {
  const {
    bankName,
    accountNumber,
    bankBranchNumber,
    bankNumber,
    bankPaymentMethod,
  } = formValues;
  const errors = {};

  if (bankBranchNumber) {
    const trimmedValue = bankBranchNumber.replace(/\s/g, "");

    // Check for numeric only
    if (!/^\d+$/.test(trimmedValue)) {
      errors.bankBranchNumber = "Must contain only numbers";
    }
  } else if (!bankBranchNumber && bankPaymentMethod === "pad") {
    errors.bankBranchNumber = "Branch Transit Number is required";
  }

  if (accountNumber) {
    const trimmedValue = accountNumber.replace(/\s/g, "");

    // Check for numeric only
    if (!/^\d+$/.test(trimmedValue)) {
      errors.accountNumber = "Must contain only numbers";
    }
  } else if (!accountNumber && bankPaymentMethod === "pad") {
    errors.accountNumber = "Account Number is required";
  }

  if (bankNumber) {
    const trimmedValue = bankNumber.replace(/\s/g, "");

    // Check for numeric only
    if (!/^\d+$/.test(trimmedValue)) {
      errors.bankNumber = "Must contain only numbers";
    }
  } else if (!bankNumber && bankPaymentMethod === "pad") {
    errors.bankNumber = "Bank Number is required";
  }

  return errors;
};

const WrappedBankAccount = reduxForm({
  form: "CompanyBankAccount",
  validate,
  enableReinitialize: true,
})(BankAccount);

const mapStateToProps = (state) => {
  const selector = formValueSelector("CompanyBankAccount");

  const { newPlanAdmin } = state.companyReducer;

  const data = state.companyReducer?.companyEnrolment?.companyData ?? {};
  let payloadString =
    JSON.stringify(state.companyReducer.companyEnrolment?.companyData) ?? "";
  const companyEnrolment = state.companyReducer.companyEnrolment ?? {};

  const enrolledByEmail = state.authReducer?.preAuthDetails?.email ?? "";

  const businessOperatedName =
    _.get(data, "business.businessOperatedName") ?? "";

  let bankPaymentMethod = _.get(data, "business.defaultPaymentMethod") ?? "";
  let bankNumber = _.get(data, "bankAccountDetails.bankNumber") ?? "";
  let bankBranchNumber =
    _.get(data, "bankAccountDetails.bankBranchNumber") ?? "";
  let accountNumber = _.get(data, "bankAccountDetails.bankAccountNumber") ?? "";
  let contacts = _.get(data, "contacts") ?? [];

  let authorizedSigners = [];
  if (contacts?.length > 0 && typeof contacts?.forEach === "function") {
    for (let item of contacts) {
      if (item.userRole.name === "planSponsorAuthorizedSigner") {
        authorizedSigners.push(item);
      }
    }
  }

  //get programDefinedVariables's
  //business pdv's
  let programDefinedVariables = {};
  if (
    _.get(data, "business.programDefinedVariables") &&
    typeof data.business.programDefinedVariables !== null &&
    typeof data.business.programDefinedVariables.forEach === "function"
  ) {
    data.business.programDefinedVariables.forEach((item) => {
      if (
        item.productVariableDefinition.variableName &&
        item.variableValue.length > 0
      ) {
        programDefinedVariables[item.productVariableDefinition.variableName] =
          item.variableValue;
      }
    });
  } else if (_.get(data, "business.programDefinedVariables")) {
    for (let item in data.business.programDefinedVariables) {
      if (
        _.get(
          data,
          `business.programDefinedVariables[${item}].productVariableDefinition.variableName`
        )
      ) {
        programDefinedVariables[
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableName
        ] =
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableValue;
      }
      programDefinedVariables[item] =
        data.business.programDefinedVariables[item];
    }
  }

  return {
    newPlanAdmin,
    bankName: selector(state, "bankName"),
    bankBranchNumber: selector(state, "bankBranchNumber"),
    bankNumber: selector(state, "bankNumber"),
    accountNumber: selector(state, "accountNumber"),
    authorizedSigners: selector(state, "authorizedSigners"),
    company: data,
    programPaymentType:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.programPaymentType"
      ) || _.get(programDefinedVariables, "programPaymentType"),
    bankPaymentMethod: bankPaymentMethod,
    payloadString: payloadString,
    businessOperatedName: selector(state, "businessOperatedName"),
    payloadString: payloadString,
    companyEnrolment: selector(state, "companyEnrolment"),
    enrolledByEmail: enrolledByEmail,

    initialValues: {
      bankPaymentMethod: bankPaymentMethod,
      payloadString: payloadString,
      bankNumber: bankNumber,
      bankBranchNumber: bankBranchNumber,
      accountNumber: accountNumber,
      authorizedSigners: authorizedSigners,
      businessOperatedName: businessOperatedName,
      payloadString: payloadString ?? "",
      companyEnrolment: companyEnrolment,
      enrolledByEmail: enrolledByEmail,
    },
  };
};

export default connect(mapStateToProps, {
  updateCompany,
  saveEnrolmentCompanyData,
})(WrappedBankAccount);
