// React
import { ReactElement, ChangeEvent, PureComponent } from "react";
// React google
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import styled from "styled-components";
import GradientLine from "../GradientLine/GradientLine";
import GradientText from "../GradientText/GradientText";

// Scss
import styles from "./Places.module.scss";

// API Key
const API_KEY: string = "AIzaSyBgFcUp0U28tV4BDZP6iuPyQcDKJiNan58";

// Props type
type Props = {
  googleMaps: object;
  myValue: string;
  label: any;
  name: string;
  type: any;
  leftIcon: any;
  placeholder: string;
  extrastyle: string;
  coreInputStylesContainer: any;
  coreInputStyles: any;
  setAddress: (name: string, search: string) => void;
  gradientInput: boolean;
  gradientLabel: string;
};

// State type
type State = {
  value: string;
  search: string;
};

/**
 * @class GoogleSuggest
 * @extends {PureComponent<Props, State>}
 */
class GoogleSuggest extends PureComponent<Props, State> {
  /**
   * Creates an instance of GoogleSuggest.
   * @param {Props} props
   * @memberof GoogleSuggest
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      search: "",
      value:
        this.props.myValue !== undefined &&
        this.props.myValue !== null &&
        this.props.myValue.trim().length > 0
          ? this.props.myValue
          : "",
    };
  }

  /**
   * @param {*} prevProps
   * @memberof GoogleSuggest
   */
  componentDidUpdate(prevProps: any): void {
    if (this.props.myValue !== prevProps.myValue) {
      this.setState({
        value:
          this.props.myValue !== undefined &&
          this.props.myValue !== null &&
          this.props.myValue.trim().length > 0
            ? this.props.myValue
            : "",
      });
    }
  }

  /**
   * @param {ChangeEvent<HTMLInputElement>} e
   * @memberof GoogleSuggest
   */
  handleInputChange(e: ChangeEvent<HTMLInputElement>): void {
    e.stopPropagation();
    this.setState({ search: e.target.value, value: e.target.value }, () => {
      this.props.setAddress(this.props.name, this.state.search);
    });
  }

  /**
   * @param {*} suggest
   * @memberof GoogleSuggest
   */
  handleSelectSuggest(suggest: any): void {
    this.setState({ search: "", value: suggest.formatted_address }, () => {
      this.props.setAddress(this.props.name, suggest);
    });
  }

  /**
   * @return {*}  {ReactElement}
   * @memberof GoogleSuggest
   */
  render(): ReactElement {
    const { search, value }: State = this.state;
    const {
      label,
      name,
      type,
      leftIcon,
      placeholder,
      extrastyle,
      gradientInput,
      gradientLabel,
    }: Props = this.props;

    return (
      <ReactGoogleMapLoader
        params={{
          key: API_KEY,
          libraries: "places,geocode",
        }}
        render={(googleMaps: any): ReactElement | null =>
          googleMaps && (
            <div
              className={`${styles.inputContainer} ${styles[extrastyle]} ${
                styles[this.props.coreInputStylesContainer]
              }`}              
            >
              <ReactGooglePlacesSuggest
                autocompletionRequest={{ input: search }}
                googleMaps={googleMaps}
                onSelectSuggest={this.handleSelectSuggest.bind(this)}                
              >
                {gradientInput ? (
                  <StyledGradientWrapper>
                    {label && <StyledLabel>{label}</StyledLabel>}
                    {gradientLabel && (
                      <StyledLabelContainer>
                        <GradientText
                          string={gradientLabel}
                          fontSize={"1.75rem"}
                        />
                      </StyledLabelContainer>
                    )}
                    <StyledGradientTextInput
                      type="text"
                      value={value}
                      onKeyPress={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      placeholder={
                        placeholder ? placeholder : "Search a location"
                      }
                      onChange={this.handleInputChange.bind(this)}
                    />
                    <GradientLine />
                  </StyledGradientWrapper>
                ) : (
                  <input                    
                    className={`${styles.inputField} ${
                      styles[this.props.coreInputStyles]
                    }`}
                    type="text"
                    value={value}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    placeholder={
                      placeholder ? placeholder : "Search a location"
                    }
                    onChange={this.handleInputChange.bind(this)}
                  />
                )}
              </ReactGooglePlacesSuggest>
            </div>
          )
        }
      />
    );
  }
}

// Export
export default GoogleSuggest;

interface ICSProps {
  primaryColor: string;
  secondaryColor: string;
}

const StyledGradientWrapper = styled.div<ICSProps>`
  margin: 1.5rem 0;
  position: relative;
  width: 100%;
`;

const StyledLabel = styled.h2<ICSProps>`
  font-size: 1.25rem;
  font-family: Montserrat;
  margin-top: 1rem;
  color: #6673a5;
  font-weight: 400;
`;

const StyledGradientTextInput = styled.input`
  border: 0;
  border-bottom: 1px solid transparent;
  padding-bottom: 0.75rem;
  width: 100%;
  color: #6673a5;
  font-size: 1.5rem;
  font-family: Montserrat;
  letter-spacing: 1.25px;

  &:focus {
    outline: none;
  }
`;

const StyledLabelContainer = styled.div`
  margin-bottom: 1rem;
`;
