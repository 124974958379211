import _, { set } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  clearCompany,
  clearGeneral,
  createCompany,
  fetchBhaBrokers,
  fetchBrokerageHouses,
  fetchIndustries,
  fetchPcoTpa,
  fetchProductCodes,
  fetchAyaSalesReps,
  createCompanyEnrolment,
  clearBrokerageHouse,
  clearAllBrokers,
} from "../../../../../actions";
import { Icon, Message } from "semantic-ui-react";
import { GlobalCountryList } from "../../../../../constants";
import userRoleMapper from "../../../../../containers/dashboard/userRoleMapper";
import { FULL__STEP_TWO__COMPANY } from "../../../../../sagas/navigation/stepTypes";
import {
  postalCodeCA,
  postalCodeUS,
  required,
} from "../../../../../utilities/validationRules";
import Button from "../../../../shared/button";
import Checkbox from "../../../../shared/checkbox";
import Divider from "../../../../shared/divider";
import GoogleSuggest from "../../../../shared/googlePlaces";
import Input from "../../../../shared/input";
import { ConnectedInput } from "../../../../shared/input/ConnectedInput";
import { FormattedInput } from "../../../../shared/input/FormattedInput";
import CustomSelect from "../../../../shared/select";
import { languageList } from "../../../../shared/company-enrolment/sampleData";
import { getStaticGuids } from "../../../../shared/company-enrolment/company-helpers";
import GradientButton from "../../../../auth/login/components/GradientButton";
import { person } from "../../../selfEnrolment/sampleData";
import broker from "../../../../../reducers/broker";

// const {
//   BHA_GUID,
//   BHA_NAME,
//   BROKER_GUID,
//   BROKER_NAME,
//   AYA_SALES_REP_GUID,
//   AYA_SALES_REP_NAME,
//   TPA_GUID,
//   TPA_NAME,
// } = getStaticGuids();

export class Corporate extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      view: "STEP_ONE",
      languageId: 1,
      companyIncorperated: true,
      selectedFile: "",
    };
  }

  componentDidMount() {
    this.props.clearBrokerageHouse();
    this.props.clearAllBrokers();

    const { email } = this.props.companyEnrolment;
    this.setState({ email });
  }

  componentDidUpdate(prevProps) {}

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setAddress = (name, address) => {
    let { address_components, formatted_address } = address;
    let data = {};
    if (address_components) {
      for (let item of address_components) {
        switch (item.types[0]) {
          case "country":
            data.country = item.short_name;
            break;
          case "postal_code":
            data.postalCode = item.long_name;
            break;
          case "administrative_area_level_1":
            data.province = item.long_name;
            break;
          case "political":
            data.city = item.long_name;
            break;
          case "locality":
            !_.has(data, "city")
              ? (data.city = item.long_name)
              : (data.city = `${data.city}`);
            break;
          case "street_number":
            data.streetAddress = item.long_name;
            break;
          case "route":
            data.streetAddress = `${
              data.streetAddress ? `${data.streetAddress}` : ""
            } ${item.long_name}`;
            break;
          default:
            break;
        }
      }
    }

    if (address_components && name === "businessRegisteredAddress") {
      this.setState({
        bankAddress: formatted_address,
        streetOne: data.streetAddress,
        city: data.city,
        province: data.province,
        country: data.country,
        postalCode: data.postalCode,
      });
    } else {
      this.setState({ [name]: address });
    }
  };

  changeReferingEntity = (value) => {
    this.setState({ referingEntity: value });
  };

  updateView = (view) => {
    this.setState({ view });
  };

  switchLanguage = (id) => {
    for (let language of languageList) {
      if (language.id === id && language.disabled !== true) {
        this.setState({ languageId: id });
      }
    }
  };

  someFunction = (val) => {};

  onGenericChange = (value, name) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleGeneric = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  changeAddressSelect = (value, label, name) => {
    this.setState({ [name]: value });
  };

  render() {
    const { styles, userRole } = this.props;
    const checkboxDropDownActive = this.state.companyIncorperated
      ? "checkboxDropDownActive"
      : "";
    return (
      <div className={styles.contentContainerX}>
        <div className={styles.msgContainer}>
          Welcome! To get started, please provide some basic info about the
          company you want to enrol with Aya.
        </div>
        <div className={styles.section}>
          <div className={styles.subHeaderLabels}>Corporate Information</div>
          <div className={styles.sectionContent}>
            <Field
              component={ConnectedInput}
              label="Company Name (Legal)"
              name="businessLegalName"
              validate={[required]}
              type="text"
              placeholder="New Company LLC"
            />
            <Field
              component={ConnectedInput}
              label="Company Name (Doing Business As)"
              name="businessOperatedName"
              type="text"
              max={23}
              validate={[required]}
              placeholder="New Company LLC"
            />
            <div
              className={styles.superSubHeader}
              style={{
                marginTop: "30px",
                marginBottom: "20px",
              }}
            >
              Corporate Contact (Legal):
            </div>
            <div className={styles.inputContainer}>
              <Field
                component={ConnectedInput}
                label="First Name"
                name="firstName"
                type="text"
                validate={[required]}
                placeholder="Enter First Name"
                extrastyle="medium"
              />
              <div style={{ paddingRight: 20, marginRight: 10 }} />
              <Field
                component={ConnectedInput}
                label="Last Name"
                name="lastName"
                type="text"
                validate={[required]}
                extrastyle="medium"
                placeholder="Enter Last Name"
              />
            </div>
            <div>
              <Field
                component={ConnectedInput}
                label="Email Address"
                name="email"
                type="email"
                validate={[required]}
                placeholder="Enter Email"
              />
            </div>
            <br />
            <Field
              component={FormattedInput}
              label="Mobile Number"
              name="mobileNumber"
              type="tel"
              labelDetail="optional, if providing Work Number"
              myFormat="+1 (###) ###-####"
              placeholder="Enter Mobile Number"
              validate={!this.props.workNumber && [required]}
            />
            <div className={styles.multiInputContainer}>
              <Field
                component={FormattedInput}
                label="Work Number"
                name="workNumber"
                type="tel"
                labelDetail="optional, if providing Mobile Number"
                extrastyle="large"
                myFormat="+1 (###) ###-####"
                placeholder="Enter Work Number"
                validate={!this.props.mobileNumber && [required]}
              />
              <Field
                component={ConnectedInput}
                label="Extension"
                name="workNumberExt"
                type="text"
                extrastyle="tiny"
                placeholder="0"
              />
            </div>
          </div>
          <div
            className={styles.standaloneLabel}
            style={{
              fontWeight: "bold",
              color: "#7d8d91",
              fontSize: "12px",
            }}
          >
            Corporate Legal Address
          </div>
          <GoogleSuggest
            name="businessRegisteredAddress"
            onKeyUp={this.handleChange}
            setAddress={this.setAddress}
            labelDetail="As found on Utility Bill, no P.O. Box"
          />
          {(this.state.streetOne || this.state.city) && (
            <React.Fragment>
              <Input
                label="Address 1"
                name="streetOne"
                type="text"
                placeholder="Address 1"
                onKeyUp={this.handleChange}
                myValue={this.state.streetOne}
              />
              <Input
                label="Address 2 (e.g., Apt Number, Suite Number…etc)"
                name="streetTwo"
                type="text"
                placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
                onKeyUp={this.handleChange}
                myValue={this.state.streetTwo}
              />
              <div className={styles.inputContainer}>
                <Input
                  label="City"
                  name="city"
                  extrastyle="semi-medium"
                  type="text"
                  placeholder="City"
                  onKeyUp={this.handleChange}
                  myValue={this.state.city}
                />
                <Input
                  label="Province/State"
                  name="province"
                  type="text"
                  extrastyle="medium-with-margin"
                  placeholder="State/Province"
                  onKeyUp={this.handleChange}
                  myValue={this.state.province}
                />
                <CustomSelect
                  label="Country"
                  name="country"
                  extrastyle="medium-with-margin"
                  onChange={(value, label, name) =>
                    this.changeAddressSelect(value, label, name)
                  }
                  options={GlobalCountryList}
                  value={this.state.country}
                />
              </div>
              <Input
                label="Postal Code/ZIP"
                name="postalCode"
                type="text"
                error={
                  (this.state.postalCode &&
                    this.state.country === "US" &&
                    postalCodeUS(this.state.postalCode)) ||
                  (this.state.country === "CA" &&
                    postalCodeCA(this.state.postalCode)) ||
                  (this.state.detailsEnteredlookGood &&
                    !this.state.postalCode &&
                    "Postal Code/Zip is required")
                }
                extrastyle="semi-medium"
                placeholder="Zip/Postal Code"
                onKeyUp={this.handleChange}
                myValue={this.state.postalCode}
              />
            </React.Fragment>
          )}
        </div>
        <div
          className={styles.msgContainer}
          style={{
            marginBottom: "20px",
          }}
        >
          This info will remain locked throughout your application, please
          review
        </div>
        <Checkbox
          checked={this.state.detailsEnteredlookGood}
          onClick={() => this.toggleGeneric("detailsEnteredlookGood")}
          darkBox={"darkBox"}
          label={"Information entered above looks good, lets continue..."}
        />
        <div className={styles.msgContainer}>
          <p>
            You’re now entering our quick and paperless application! Be sure to
            click "Save and Continue" as you progress to ensure your work is
            saved. If you need to pause, you can return later via our website to
            pick up where you left off.
          </p>
          <p>
            Note: Some fields are mandatory, so fill out as much as possible. If
            you can't submit at the end, we'll let you know what's missing—rest
            assured, everything you've completed is saved and logged!
          </p>
        </div>
        <div className={styles.msgContainer}>
          <p>
            If you have any questions, feel free to email us at
            support@ayacare.com or call 1-888-427-6682
          </p>
        </div>
        <div className={styles.buttonAreaContainer}>
          <GradientButton
            handleClick={() => {
              this.createCompany();
            }}
            style={{
              marginTop: "20px",
            }}
            disabled={
              !this.state.detailsEnteredlookGood ||
              !this.state.postalCode ||
              this.props.prestine ||
              this.props.invalid ||
              this.props.submitting
            }
            label="Save And Continue"
            width="100%"
          ></GradientButton>
        </div>
      </div>
    );
  }

  createCompany = async () => {
    const {
      firstName,
      lastName,
      email,
      mobileNumber,
      workNumber,
      workNumberExt,
      businessLegalName,
      businessOperatedName,
      craBusinessNumber,
      businessGenericEmail,
      brokerName,
      bhaName,
      brokerGuid,
      bhaGuid,
      salesRepGuid,
    } = this.props;

    const {
      businessAddress,
      streetOne,
      streetTwo,
      city,
      province,
      postalCode,
      country,
      businessIndustry,
    } = this.state;

    const {
      BHA_GUID,
      BHA_NAME,
      BROKER_GUID,
      BROKER_NAME,
      AYA_SALES_REP_GUID,
      AYA_SALES_REP_NAME,
      TPA_GUID,
      TPA_NAME,
    } = getStaticGuids();

    const params = {
      method: "post",
      path: `invites`,
      data: {
        dirty: true,
        inviteState: {
          dirty: true,
          step: FULL__STEP_TWO__COMPANY,
          status: "active",
          inviteStatus: "BA00S",
        },
        sendNotification: false,
        requestedForBusinessId: "",
        requestedPersonParentId: "",
        person: {
          dirty: true,
          personStatus: "PIDV01",
          firstName: firstName,
          lastName: lastName,
          mobileNumber: mobileNumber,
          workNumber: workNumber,
          workNumberExt: workNumberExt,
          email: email ? email.toLowerCase() : undefined,
          userRole: {
            dirty: true,
            label: "PlanSponsor Company Contact",
            name: "planSponsorContact",
          },
          countryCode: "CA",
          languageCode: "en",
        },
        business: {
          dirty: true,
          businessStatus: "BA00S",
          businessLegalName: businessLegalName,
          businessOperatedName: businessOperatedName,
          businessShortName: "",
          businessDbaLicNumber: craBusinessNumber,
          businessGenericEmail: businessGenericEmail,
          institutionType: "planSponsor",
          businessPreferredLanguage: "en",
          addressBook: [
            {
              dirty: true,
              isPrimary: true,
              addressClass: "business",
              city: city,
              province: province,
              postalCode: postalCode,
              streetOne: streetOne,
              streetTwo: streetTwo,
              country: country,
            },
          ],
          metaInformation: {
            dirty: true,
            // companyWebsite: 'n/a',
            assocBrokerageHouseName: bhaName,
            assocBrokerName: brokerName,
            assocThirdPartyAdministratorName: TPA_NAME,
            requestedByPersonGuid: brokerGuid,
            ayaSalesRepName: brokerName,
          },
          enrolledByBusinessID: bhaGuid, //brokerageHOuse GUid
          // requestedByPersonGuid: BROKER_GUID, //broker
          // requestedForBusinessAssocId: TPA_GUID, //tpa GUid
          // requestedForBusinessAssocBusinessType: "planSponsor",
        },
        salesPersonGuid: salesRepGuid ?? "",
        accessToken: "",
      },
    };

    //Payload string
    params.data.business.metaInformation["payloadString"] = JSON.stringify(
      params.data
    );

    try {
      // let response = this.props.createCompany(params);
      let response = await this.props.createCompanyEnrolment({
        companyData: JSON.stringify(params.data),
        enrolledByEmail: this.props.enrolledByEmail,

        // businessName: businessLegalName,
      });
    } catch (ex) {}
  };
}

const validate = (formValues) => {
  const errors = {};
  return errors;
};

const WrappedCorporate = reduxForm({
  form: "Corporate",
  validate,
  enableReinitialize: true,
})(Corporate);

const mapStateToProps = (state) => {
  const selector = formValueSelector("Corporate");
  const { companyEnrolment } = state.companyReducer ?? {};
  const data = companyEnrolment?.companyData ?? {};
  const {
    // accessToken,
    userClaims: {
      businessOperatedName: bhaName,
      userRole,
      personGuid,
      businessGuid,
      firstName,
      lastName,
    },
  } = state.authReducer ?? {};
  const enrolledByEmail = state.authReducer?.preAuthDetails?.email ?? "";
  const brokerName = firstName + " " + lastName;
  const brokerGuid = personGuid;
  const bhaGuid = businessGuid;
  const salesRepGuid = personGuid;

  return {
    firstName: selector(state, "firstName"),
    lastName: selector(state, "lastName"),
    email: selector(state, "email"),
    mobileNumber: selector(state, "mobileNumber"),
    workNumber: selector(state, "workNumber"),
    workNumberExt: selector(state, "workNumberExt"),
    businessLegalName: selector(state, "businessLegalName"),
    businessOperatedName: selector(state, "businessOperatedName"),
    businessGenericEmail: selector(state, "businessGenericEmail"),
    craBusinessNumber: selector(state, "craBusinessNumber"),
    company: data,
    companyEnrolment: companyEnrolment,
    enrolledByEmail: enrolledByEmail,
    brokerName: brokerName,
    bhaName: bhaName,
    brokerGuid: brokerGuid,
    bhaGuid: bhaGuid,
    salesRepGuid: salesRepGuid,
  };
};

export default connect(mapStateToProps, {
  clearCompany,
  clearGeneral,
  createCompanyEnrolment,
  clearBrokerageHouse,
  clearAllBrokers,
})(WrappedCorporate);
