// React
import { PureComponent } from 'react';
// Lodash
import _ from 'lodash';
// Redux
import { connect } from 'react-redux';
// React select box
import Select from 'react-select';
// Scss
import styles from './Select.module.scss';
import { ReactElement } from 'react';

/** @type {object} */
const customStyles: object = {
  control: (provided: any, state: any): object => ({
    ...provided,
    height: 48,
    backgroundColor: state.isDisabled ? '#f7f8f8' : 'white',
    borderColor: state.isDisabled ? '#f7f8f8' : '#e0e0e0',
  }),
  option: (
    styles: any,
    { data, isDisabled, isFocused, isSelected }: any
  ): object => {
    return {
      ...styles,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'white'
          ? 'white'
          : '#627d98'
        : '#627d98',
    };
  },
};

/** @type {object} */
const customStylesCoreSmall: object = {
  control: (provided: any, state: any): object => ({
    ...provided,
    height: 33,
  }),
};

// Type props
type Props = {
  value: string;
  selectLabel: string | boolean;
  label: string;
  name: string;
  onChange: (value: string, label: string, name: string) => void;
  loading: boolean | string;
  myloading: boolean | string;
  options: Array<Options>;
  coreInputStylesContainer: string;
  coreInputStyles: string;
  extrastyle: string;
  isSearchable: boolean | string;
  placeholder: string;
  disabled: boolean | string;
  testid: string;
  isClearable?: boolean | string;
};

// Type state
type State = {
  selectedOption: any;
  selectLabel: any;
};

// Type options
type Options = {
  [index: string]: any;
};

/**
 * @export
 * @class CustomSelect
 * @extends {PureComponent<Props, State>}
 */
export class CustomSelect extends PureComponent<Props, State> {
  /**
   * Creates an instance of CustomSelect.
   * @param {Props} props
   * @memberof CustomSelect
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: this.props.value || null,
      selectLabel: _.has(this.props, 'label')
        ? this.props.selectLabel || this.props.label
        : 'Select',
    };
  }

  /**
   * @param {*} selectedOption
   * @memberof CustomSelect
   */
  handleChange = (selectedOption: any): void => {
    this.setState({ selectedOption });
    if (selectedOption !== null) {
      this.props.onChange(
        selectedOption.value,
        selectedOption.label,
        this.props.name
      );
    }
  };

  /**
   * @return {*}  {ReactElement}
   * @memberof CustomSelect
   */
  render(): ReactElement {
    const { selectedOption, selectLabel }: State = this.state;
    let { options, loading }: Props = this.props;
    options =
      options !== undefined && options !== null && options.length > 0
        ? options
        : [];

    return (
      <div
        className={`${styles.selectContainer} ${
          styles[this.props.extrastyle]
        } ${styles[this.props.coreInputStylesContainer]} ${
          this.props.coreInputStyles ? `${styles.customSelectThin}` : ``
        }`}
      >
        {selectLabel && <div className={styles.selectLabel}>{selectLabel}</div>}
        <div
          className={`${styles.customSelect}`}
          data-testid={this.props.testid}
        >
          <Select
            id="myselect"
            containerClassName={`${styles[this.props.coreInputStyles]}`}
            styles={
              this.props.coreInputStyles ? customStylesCoreSmall : customStyles
            }
            menuColor="#627d98"
            theme={(theme: { [index: string]: any }): object => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral90: '#627d98',
                neutral80: '#627d98',
                neutral70: '#627d98',
                neutral60: '#627d98',
                neutral50: '#627d98',
                neutral40: '#627d98',
              },
            })}
            value={
              _.has(this.props, 'value')
                ? options.filter((option: Options): any => {
                    return (
                      (option.label === this.props.value && this.props.value) ||
                      (option.value === this.props.value && this.props.value)
                    );
                  })
                : selectedOption
            }
            isSearchable={
              this.props.isSearchable ? this.props.isSearchable : true
            }
            isLoading={this.props.myloading ? this.props.myloading : loading}
            onChange={this.handleChange}
            options={options}
            isClearable={
              this.props.isClearable !== undefined
                ? this.props.isClearable
                : true
            }
            placeholder={this.props.placeholder}
            isDisabled={this.props.disabled}
          />
          {/* <Form.Select
						containerClassName={`${styles[this.props.coreInputStyles]}`}
						value={
							_.has(this.props, 'value')
								? options.filter(option => {
										return (
											(option.label === this.props.value && this.props.value) ||
											(option.value === this.props.value && this.props.value)
										);
									})
								: selectedOption
						}
						isSearchable={this.props.isSearchable ? this.props.isSearchable : true}
						isLoading={this.props.myloading ? this.props.myloading : loading}
						onChange={this.handleChange}
						options={options}
						isClearable={true}
						placeholder={this.props.placeholder}
						isDisabled={this.props.disabled}
					/> */}
        </div>
      </div>
    );
  }
}

// Redux mapper
const mapStateToProps = (state: any, ownProps: any) => ({
  loading: ownProps.loading || state.generalReducer.loading,
});

// Export
export default connect(mapStateToProps, null)(CustomSelect);
