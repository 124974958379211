// React
import { ChangeEvent, ReactElement, FC, memo } from "react";
// React widget
import DropdownList from "react-widgets/DropdownList";
// Components
import { Tooltip } from "../tooltip";
// Constant
import { Images } from "../../../constants";
// Css
import "react-widgets/styles.css";
// Scss
import styles from "./Input.module.scss";

// Interface
interface IProps {
  input: any;
  data: any[];
  valueField: string;
  textField: string;
  myValue: string;
  disabled: boolean;
  coreInputStylesContainer: string;
  coreInputStyles: string;
  notEditableStyles: string;
  leftIconReferenceStyles: string;
  extrastyle: string;
  placeholder: string;
  label: string;
  secondaryLinkOnClick: (args: any) => void;
  secondaryLink: (args: any) => void;
  meta: {
    error: any;
    touched: any;
  };
  labelDetail: string;
  tooltip: string;
  tooltipMessage: string;
  tooltipImage: string;
  onChange: (args: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (args: ChangeEvent<HTMLInputElement>) => void;
  filter: boolean;
}

const RDL: FC<IProps> = ({
  input,
  data,
  valueField,
  textField,
  disabled,
  coreInputStylesContainer,
  coreInputStyles,
  notEditableStyles,
  leftIconReferenceStyles,
  extrastyle,
  placeholder,
  label,
  secondaryLinkOnClick,
  secondaryLink,
  meta: { error, touched },
  labelDetail,
  tooltip,
  tooltipMessage,
  tooltipImage,
  onChange,
  onBlur,
  filter = true,
}: IProps): ReactElement => (
  <div
    className={`${styles.inputContainer} ${styles[extrastyle]} ${styles[coreInputStylesContainer]}`}
  >
    {label && (
      <div className={styles.labelContainer}>
        <div className={styles.label}>
          {label} <span className={styles.labelDetail}>{labelDetail}</span>
          {(tooltipMessage || tooltipImage) && (
            <Tooltip
              message={tooltipMessage}
              render={() => (
                <img
                  className={styles.tooltipImage}
                  src={Images[tooltipImage]}
                />
              )}
              position={"top"}
            >
              <img src={Images.question} className={styles.tooltip} />
            </Tooltip>
          )}
        </div>
        <div className={styles.secondaryLink} onClick={secondaryLinkOnClick}>
          {secondaryLink}
        </div>
      </div>
    )}
    <div className={styles.widgetWrapper}>
      <DropdownList
        containerClassName={`${styles.widgetFieldDropDown} ${styles[notEditableStyles]} ${styles[coreInputStyles]}`}
        {...input}
        data={data}
        valueField={valueField}
        textField={textField}
        value={input.value}
        defaultValue={input.value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          input.onChange(e);
          onChange && onChange(e);
        }}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={(_e: ChangeEvent<HTMLInputElement>) => {
          input.onBlur(input.value);
        }}
        filter={filter}
      />
    </div>
    {touched && error && <span className={styles.error}>{error}</span>}
  </div>
);

// Export
export const RenderDropdownList = memo(RDL);
