// React
import { FC, ReactElement, memo } from "react";
// Lodash
import _ from "lodash";
// Scss
import styles from "./TextCard.module.scss";

// Interfaces
interface ITextCard {
  key?: string | number;
  id: string | number;
  label: string;
  subLabel?: string;
  active: string;
  onChange: (id: string | number, label: string) => void;
  small: boolean | string;
  disabled: boolean | string;
  noShadow: boolean | string;
  typeOne: boolean | string;
}
interface ITextCardList {
  selectedId: any;
  items: IItems;
  onChange: (id: string | number, label: string) => void;
  small: boolean | string;
  noShadow: boolean | string;
  typeOne: boolean | string;
  multiple: boolean | string;
  listDisabled: boolean;
}
interface IItems {
  [key: string]: any;
}

/**
 * @param {ITextCard} { id, label, subLabel, active, onChange, small, disabled, noShadow, typeOne }
 * @return {*}  {ReactElement}
 */
export const TextCard: FC<ITextCard> = ({
  id,
  label,
  subLabel,
  active,
  onChange,
  small,
  disabled,
  noShadow,
  typeOne,
}: ITextCard): ReactElement => (
  <div
    key={id}
    className={`
      ${typeOne ? `${styles.cardOne}` : `${styles.card}`}
      ${styles[active]} 
      ${small ? `${styles.smallFixed}` : ""} 
      ${noShadow ? `${styles.noShadow}` : ""} 
      ${disabled ? `${styles.disabled}` : ""}
			${disabled ? `${styles.notEditableStyles}` : ""}
			`}
    onClick={() => onChange(id, label)}
  >
    <div className={`${typeOne ? `${styles.labelOne}` : `${styles.label}`}`}>
      {label}
    </div>
    <div
      className={`${typeOne ? `${styles.subLabelOne}` : `${styles.subLabel}`}`}
    >
      {subLabel}
    </div>
  </div>
);

/**
 * @param {ITextCardList} {
 * 	selectedId,
 * 	items,
 * 	onChange,
 * 	small,
 * 	noShadow,
 * 	typeOne,
 * 	multiple,
 * 	listDisabled = false
 * }
 * @return {*}  {ReactElement}
 */
export const TextCardList: FC<ITextCardList> = ({
  selectedId,
  items,
  onChange,
  small,
  noShadow,
  typeOne,
  multiple,
  listDisabled = false,
}: ITextCardList): ReactElement => (
  <div
    className={`${small ? `${styles.cardListLeft}` : `${styles.cardList}`}  `}
  >
    {items &&
      items.map(
        (item: IItems): ReactElement => (
          <TextCard
            {...item}
            id={item.id}
            label={item.label}
            small={small ? true : false}
            noShadow={noShadow ? true : false}
            typeOne={typeOne ? true : false}
            key={item.id || item.label}
            disabled={item.disabled ? true : listDisabled ? true : false}
            onChange={(id, label) => {
              if (!listDisabled) {
                onChange(id, label);
              }
            }}
            active={
              multiple
                ? _.has(selectedId, item.label)
                  ? "active"
                  : ""
                : selectedId == item.id
                ? "active"
                : ""
            }
          />
        )
      )}
  </div>
);

// Export
export default memo(TextCardList);
