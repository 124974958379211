// React
import { PureComponent, ReactElement } from "react";
// Scss
import styles from "./Checkbox.module.scss";

// Props type
type Props = {
  darkBox: string;
  nowrap: string;
  label: string;
  input: any;
  myValue: any;
  children: JSX.Element | JSX.Element[];
};

// State type
type State = {};

/**
 * @export
 * @class ConnectedCheckbox
 * @extends {PureComponent<Props, State>}
 */
export default class ConnectedCheckbox extends PureComponent<Props, State> {
  /**
   * Creates an instance of ConnectedCheckbox.
   * @param {Props} props
   * @memberof ConnectedCheckbox
   */
  constructor(props: Props) {
    super(props);
  }

  /**
   * @return {*}  {ReactElement}
   * @memberof ConnectedCheckbox
   */
  render(): ReactElement {
    // Getting keys from props
    const { input, myValue }: Props = this.props;
    return (
      <div
        className={`${styles.connectedContainer} ${
          styles[this.props.darkBox]
        } ${this.props.nowrap ? `${styles.nowrap}` : ""}`}
      >
        <input
          {...input}
          className={styles.connectedInput}
          type="checkbox"
          checked={input.checked || myValue}
        />
        <label htmlFor="checkbox" />
        <div className={styles.labelStyle}>
          {this.props.label || this.props.children}
        </div>
      </div>
    );
  }
}
