// React
import { FC, ReactElement, memo } from "react";
// Semantic  UI
import { Form, TextArea } from "semantic-ui-react";

// Interface
interface IProps {
  name: string;
  placeholder: string;
  label: string;
  input: any;
  meta: { error: any; touched: any };
}

/**
 * @param {IProps} {
 *   name,
 *   placeholder,
 *   label,
 *   input,
 *   meta: { error, touched },
 * }
 * @return {*}  {ReactElement}
 */
const CTA: FC<IProps> = ({
  name,
  placeholder,
  label,
  input,
  meta: { error, touched },
}: IProps): ReactElement => (
  <Form.Field
    {...input}
    control={TextArea}
    label={label}
    name={name}
    placeholder={placeholder}
    style={{
      width: "100%",
      minHeight: "5rem",
      border: "solid 1px #e0e0e0",
      borderRadius: "5px",
      padding: "1rem",
    }}
  />
);

export const ConnectedTextArea = memo(CTA);
