// Types
import { Action } from "../actions/types";

// Type state
type State = {
  brokers: Array<any>;
  brokersFull: Array<any>;
  data: any;
};

// Initial state
const INITIAL_STATE: State = {
  brokers: [],
  brokersFull: [],
  data: {
    inviteState: {
      step: "PRELIMINARY-STEP_TWO-BROKERS",
      status: "",
    },
    sendNotification: false,
    requestedForBusinessId: "",
    requestedPersonParentId: "",
    person: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      smsNumber: "",
      homeNumber: "",
      workNumber: "",
      workNumberExt: "",
      faxNumber: "",
      faxNumberExt: "",
      email: "",
      postalCode: "",
      countryCode: "CA",
      languageCode: "en",
      governmentBirthID: "",
      personStatus: "PA00S",
      title: "",
      address: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      mailingAddress: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      userRole: {
        label: "broker",
        name: "broker",
      },
      verification: {
        documentType: "",
        documentNumber: "",
        expiryDate: "",
        jurisdictionOfInsurance: "",
      },
      metaInformation: {
        dateOfBirth: "",
        familyStatus: "",
        gender: "",
        diagnosedPermanentImpairment: "",
        hsaClassType: "",
        hsaStartDate: "",
        officeLocation: "",
        officeCostCenter: "",
        termsConditions: "",
        dependents: [
          {
            dependentType: "",
            provincialHealthInfo: {
              coveredProvincial: false,
              coreHealthInsuranceCoverage: "",
              type: "",
              insuranceCompany: {
                name: "",
                otherName: "",
                policyNumber: "",
              },
              primaryCertificateFirstName: "",
              primaryCertificateLastName: "",
            },
          },
          {
            dependentType: "",
            provincialHealthInfo: {
              coveredProvincial: false,
              coreHealthInsuranceCoverage: "",
              type: "",
              insuranceCompany: {
                name: "",
                otherName: "",
                policyNumber: "",
              },
              primaryCertificateFirstName: "",
              primaryCertificateLastName: "",
            },
          },
        ],
        provincialHealthInfo: {
          isCovered: "false",
          coreHealthInsuranceCoverage: "",
          insuranceCompany: {
            name: "",
            policyNumber: "",
          },
        },
        studentEligibility: {
          schoolAttendanceName: "",
          schoolStartDate: "",
          schoolEndDate: "",
        },
      },
      programDefinedVariables: {
        employeeSetup: {
          employeeClassType: "",
          employeeManualFunding: "",
          startDate: "",
          endDate: "",
        },
      },
    },
    business: {
      businessLegalName: "",
      businessOperatedName: "",
      businessShortName: "",
      businessDbaLicNumber: "",
      isAddressSameAsMailing: false,
      businessGenericEmail: "",
      businessTelephoneNumber: "",
      businessTelephoneNumberExtension: "",
      businessPreferredLanguage: "en",
      businessLogoURL: "",
      institutionType: "brokerageHouse",
      address: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      mailingAddress: {
        city: "",
        province: "",
        postalCode: "",
        streetOne: "",
        streetTwo: "",
        country: "CA",
      },
      tpaInformation: {
        type: [""],
        tpaBusinessId: "",
      },
      metaInformation: {
        companyWebsite: "",
        corporateNumber: "",
        businessNumber: "",
        businessType: "",
        numberOfBrokers: "",
        numberOfEmployees: "",
        estimatedAnnualCompanyHsaAccount: "",
        jurisdictionOfInsurance: "",
        isCompanyPubliclyTraded: "false",
        trading: {
          stockSymbol: "",
          stockExchangeName: "",
        },
        industry: {
          industryType: "",
          industryClassificationType: "",
        },
        dateOfRegistration: "",
        sourceDocument: {
          name: "",
          number: "",
        },
      },
      programDefinedVariables: {
        contractTerm: {
          term: 1,
          startDate: "",
          endDate: "",
          revenueShareAdminFee: "0.0",
          revenueShareInterchangeFee: 0,
          revenueShareProgramFeesSetup: 0,
        },
        statementDelivery: {
          paymentTerm: "M",
        },
        distributorForecast: {
          brokerageCommitmentNumberOfBusiness: "",
          brokerageCommitmentAdoptionYr1: 0,
          brokerageCommitmentAdoptionYr2: 0,
          brokerageCommitmentAdoptionYr3: 0,
          brokerageCommitmentAdoptionYr4: 0,
          brokerageCommitmentAdoptionYr5: 0,
          averageNumberOfEmployeesPerBusiness: 0,
        },
        chargeVolumeForecast: {
          averageSpendPerEmployeePerYear: 0,
          averageNumberOfTransactionsPerEmployeePerMonth: 0,
          numberOfHsaTransactionsYear: "",
          hsaSpend: "",
        },
      },
    },
    bankAccountDetails: {
      bankName: "",
      bankBranchCity: "",
      bankBranchProvince: "",
      bankBranchPostalCode: "",
      bankBranchStreetOne: "",
      bankBranchStreetTwo: "",
      country: "CA",
      bankAccountDesignation: "checking",
      bankBranchNumber: "",
      bankNumber: "",
      bankAccountNumber: "",
      bankAccountType: "business",
      submissionPreAuthorizedDebitForm: "false",
      agreeTermsConditions: "false",
    },
    referral: {
      id: "",
      type: "business",
    },
    accessToken: "",
  },
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "EDIT_BROKER":
      return { ...state, data: { ...state.data, ...action.data } };
    case "UPDATE_BHA_BROKERS":
      return { ...state, brokers: { ...action.data } };
    case "UPDATE_FULL_BROKERS":
      return { ...state, brokersFull: { ...action.data } };
    case "CLEAR_BROKER":
      return { ...state, data: INITIAL_STATE.data };
    case "CLEAR_ALL_BROKERS":
      return { ...state, brokers: [], brokersFull: [] };
    default:
      return state;
  }
};
