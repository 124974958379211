import React, { ReactElement } from "react";
import { Popup, List } from "semantic-ui-react";
import styles from "./Company.module.css";
import { Images } from "../../../constants";

type IErrorProps = {
  errors: { [key: string]: string };
};

export const ErrorPopupComponent: React.FC<IErrorProps> = ({
  errors,
}): ReactElement => {
  return (
    <Popup
      content={
        <List divided relaxed>
          {Object.entries(errors).map(([key, errorMessage]) => (
            <List.Item key={key}>
              <List.Content>
                <List.Header>{key}</List.Header>
                {errorMessage}
                {/* <List.Description>{errorMessage}</List.Description> */}
              </List.Content>
            </List.Item>
          ))}
        </List>
      }
      trigger={
        <div className={styles.infoLogoContainer}>
          <div className={styles.styledInfoIcon}>
            <img src={Images.alertCircle} />
          </div>
        </div>
      }
      on="click"
      position="top center"
    />
  );
};

// export default ErrorPopupComponent;
