// React
import { FC, ReactElement, useEffect } from "react";
// Router
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
// Redux
import { connect } from "react-redux";
// Semantic UI
import { Divider } from "semantic-ui-react";
// Lodash
import _ from "lodash";
// Actions
import { fetchClaims, clearClaimList } from "../../../actions";
import { versionNumber } from "../../../constants";
// Helper
import menuItems from "./sampleUserData";
import userRoleMapper from "./userRoleMapper";
// Scss
import styles from "./MenuBar.module.scss";

let isClaimsLististClearedByMenuComponent = false;

/**
 * @param {*} props
 * @return {*}  {ReactElement}
 */
const MenuBar: FC<any> = (props: any): ReactElement => {
  // use effect

  useEffect(() => {
    if (!isClaimsLististClearedByMenuComponent) {
      props.clearClaimList();
      isClaimsLististClearedByMenuComponent = true;
    }
  }, []);

  useEffect(() => {
    if (props.stats.claimsCount === 0) {
      props.fetchClaims({
        mode: "newest2000",
      });
    }
  }, []);

  // user role
  const userRole: string = userRoleMapper[props.userRole];

  // element
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.headerSection}>
          <div className={styles.headerContent}>
            <NavLink to="#">
              {props.businessOperatedName &&
              props.businessOperatedName === "Aya Payments"
                ? "Aya Care"
                : props.businessOperatedName?.toString()}
            </NavLink>
          </div>
        </div>
        <Divider />
        <div className={styles.section}>
          <div className={styles.sectionContent}>
            <NavLink exact to="/dashboard" activeClassName={styles.active}>
              Dashboard
            </NavLink>
          </div>
          {userRole && menuItems(props, styles)[userRole]["BROKERAGE"]}
        </div>
        <div className={styles.section}>
          {userRole && menuItems(props, styles)[userRole]["CLIENTS"]}
        </div>
        <div className={styles.section}>
          {userRole && menuItems(props, styles)[userRole]["ADDITIONAL"]}
        </div>
      </div>
      <div className={styles.footerSection}>
        <div className={styles.footerContent}>{versionNumber}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { data }: any = state.statsReducer;
  const { claims, count }: any = state.claimsReducer;
  const { companyStats, companiesFull, selfEnrolment, companyEnrolment }: any =
    state.companyReducer;
  const { employeesFull }: any = state.employeeReducer;
  const { tpas } = state.tpaReducer;
  const { brokersFull } = state.brokerReducer;
  const formattedStats: Array<any> = [];
  let usersCount: number = 0;

  if (data.data) {
    if (_.has(data, "data") && data.data.length > 0) {
      let parsedData = JSON.parse(data.data);
      parsedData.forEach((item: any) => {
        if (
          item.itemtype === "superAdmin" ||
          item.itemtype === "claimEditor" ||
          item.itemtype === "finance" ||
          item.itemtype === "tpaAdministrator" ||
          item.itemtype === "tpaServiceRepresentative" ||
          item.itemtype === "broker"
        ) {
          usersCount += parseInt(item.itemvalue);
        }

        formattedStats[item.itemtype] = {
          value: item.itemvalue,
          recordType: item.recordtype,
        };
      });
    }
  }

  return {
    stats: {
      ...formattedStats,
      companiesCount: companiesFull.count,
      employeesCount: employeesFull.count,
      tpaCount: tpas.count,
      usersCount: usersCount,
      claimsCount: count,
      brokers: brokersFull?.count,
      selfEnroledCompanies: selfEnrolment?.selfEnroledCompanies?.length,
      enroledCompanies: companyEnrolment?.enroledCompanies?.length,
    },
  };
};

// Export
export default connect(mapStateToProps, { fetchClaims, clearClaimList })(
  withRouter(MenuBar)
);
