// React
import { PureComponent, ReactElement } from "react";
// Scss
import styles from "./Checkbox.module.scss";

// Props type
type Props = {
  darkBox: string;
  nowrap?: string;
  disabled?: boolean;
  onClick: () => void;
  checked: any;
  extraStyles?: string;
  label: string;
};
// State type
type State = {};

/**
 * @export
 * @class Checkbox
 * @extends {PureComponent<Props, State>}
 */
export default class Checkbox extends PureComponent<Props, State> {
  /**
   * Creates an instance of Checkbox.
   * @param {*} props
   * @memberof Checkbox
   */
  constructor(props: Props) {
    super(props);
  }

  /**
   * @return {*}
   * @memberof Checkbox
   */
  render(): ReactElement {
    return (
      <div
        className={`${styles.container} ${styles[this.props.darkBox]} ${
          this.props.nowrap ? `${styles.nowrap}` : ""
        }`}
        onClick={() => {
          if (!this.props.disabled) {
            this.props.onClick();
          }
        }}
      >
        <div className={styles.round}>
          <input
            type="checkbox"
            checked={this.props.checked || ""}
            onChange={() => {
              if (!this.props.disabled) {
                this.props.onClick();
              }
            }}
          />
          <label htmlFor="checkbox" />
        </div>
        <div className={`${styles.labelStyle} ${this.props.extraStyles}`}>
          {this.props.label || this.props.children}
        </div>
      </div>
    );
  }
}
