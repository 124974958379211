import React, { useEffect } from "react";
import Confetti from "js-confetti";
import { COLOR_SCHEMES } from "../../../styles/colors/colors";

const ConfettiComponent: React.FC = () => {
  useEffect(() => {
    const confetti = new Confetti();

    confetti.addConfetti({
      confettiColors: [
        COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE,
        COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE_SECONDARY,
        COLOR_SCHEMES.PRIMARY_GRADIENT.BLUE,
      ], // Array of colors for confetti
      confettiRadius: 5,
      confettiNumber: 500,
    });

    // Clean up function
    return () => {
      confetti.clearCanvas();
    };
  }, []);

  return null; // No need to render anything, confetti is rendered directly onto the DOM
};

export default ConfettiComponent;
