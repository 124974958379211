// React
import { Fragment, PureComponent } from "react";
import { ReactElement } from "react";
// Semantic UI
import { Popup } from "semantic-ui-react";
// Constant
import { Images } from "../../../constants";
// Scss
import styles from "./Radio.module.scss";

// Type props
type Props = {
  value: string;
  onUpdate: (e: string) => void;
  labelField: string;
  valueField: string;
  horizontal: string | number;
  thinVerticalSpacing: string | number;
  items: Array<Items>;
  name: string;
  extraStyle: any;
  noBackground: any;
  disabled: boolean | string;
};
// Type state
type State = {
  value: string;
};
// Type items
type Items = {
  [index: string]: any;
};

/**
 * @class RadioGroup
 * @extends {PureComponent<Props, State>}
 */
class RadioGroup extends PureComponent<Props, State> {
  /**
   * Creates an instance of RadioGroup.
   * @param {Props} props
   * @memberof RadioGroup
   */
  constructor(props: Props) {
    super(props);
    this.state = { value: this.props.value };
  }

  /**
   * @param {Props} prevProps
   * @memberof RadioGroup
   */
  componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value }, () =>
        typeof this.props.onUpdate === "function"
          ? this.props.onUpdate(this.props.value)
          : null
      );
    }
  }

  onChange(value: string): void {
    this.setState({ value: value }, () =>
      typeof this.props.onUpdate === "function"
        ? this.props.onUpdate(value)
        : null
    );
  }

  get value(): string {
    return this.state.value;
  }

  render(): ReactElement {
    const { labelField, valueField }: Props = this.props;
    return (
      <div
        className={`${styles.radioGroup} 
				${this.props.horizontal ? `${styles.horizontal}` : ""}
				${this.props.thinVerticalSpacing ? `${styles.thinVerticalSpacing}` : ""}				
				`}
        items={this.props.items}
        name={this.props.name}
      >
        {/* TODO: REFACTOR RADIO BUTTON, CHANGE PAYLOAD STRUCTURE TO INCLUDE REFERENCE KEYS (END THE USE OF ID'S e.g 1,2,3) FOR EASY READABILITY.
        Implement getter utility methods to retrieve values with the aid of the REFERENCE KEYS,
        CREATE STRUCTURE THAT CONSIST OF (VALUE,LABEL,REFERENCE_KEY) */}
        {this.props.items.map((item: Items) => {
          const { tooltipMessage, tooltipImage }: Items = item;
          const checkedClass =
            this.state.value === item.value ? styles.selectedContainer : "";
          return (
            <div
              key={item.value}
              className={
                this.props.extraStyle
                  ? styles[this.props.extraStyle]
                  : `${styles.container} ${
                      !this.props.noBackground
                        ? `${checkedClass}`
                        : `${styles.noPaddingMarginContainer}`
                    } ${this.props.horizontal ? `${styles.padRight}` : ""} `
              }
              onClick={() => {
                if (!this.props.disabled) {
                  this.onChange(item.value);
                }
              }}
            >
              <div className={styles.radioButtonArea}>
                <label key={item.value}>
                  <input
                    type="radio"
                    checked={
                      this.state.value ===
                      (valueField ? item[valueField] : item.value)
                    }
                    disabled={item.disabled}
                    value={valueField ? item[valueField] : item.value}
                    name={this.props.name}
                    onChange={() => {
                      if (!this.props.disabled) {
                        this.onChange(
                          valueField ? item[valueField] : item.value
                        );
                      }
                    }}
                  />
                  <span />
                </label>
              </div>
              <div className={styles.labelArea}>
                {(labelField ? item[labelField] : item.label) && (
                  <div className={styles.labelStyle}>
                    {labelField ? item[labelField] : item.label}{" "}
                    {(tooltipMessage || tooltipImage) && (
                      <Popup
                        content={
                          <Fragment>
                            {tooltipImage && (
                              <Fragment>
                                <img
                                  className={styles.tooltipImage}
                                  src={Images[tooltipImage]}
                                />
                              </Fragment>
                            )}
                            {tooltipMessage}
                          </Fragment>
                        }
                        position="top center"
                        trigger={
                          <img
                            src={Images.question}
                            className={styles.tooltip}
                          />
                        }
                      />
                    )}
                  </div>
                )}
                {item.subLabel && (
                  <div className={styles.subLabelStyle}>{item.subLabel}</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

// Export
export { RadioGroup };
