import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  arrayPush,
  arrayRemoveAll,
  change,
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { Popup } from "semantic-ui-react";
import {
  updateCompany,
  saveEnrolmentCompanyData,
} from "../../../../../actions";
import { GlobalCountryList, Images } from "../../../../../constants";
import userRoleMapper from "../../../../../containers/dashboard/userRoleMapper";
import { FULL__STEP_FOUR__COMPANY } from "../../../../../sagas/navigation/stepTypes";
import { isEmpty } from "../../../../../utilities/customFunctions";
import { countryNonCA } from "../../../../../utilities/validationRules";
import Checkbox from "../../../../shared/checkbox";
import DarkBox from "../../../../shared/darkBox";
import Divider from "../../../../shared/divider";
import GoogleSuggest from "../../../../shared/googlePlaces";
import Input from "../../../../shared/input";
import { ConnectedInput } from "../../../../shared/input/ConnectedInput";
import { RenderDropdownList } from "../../../../shared/input/WidgetInput";
import { RadioGroup } from "../../../../shared/radio";
import CustomSelect from "../../../../shared/select";
import { Stepper } from "../../../../shared/stepper";
import {
  BusinessOperatedInCAList,
  CompanyTypeList,
  CorporateNumberTypeList,
  DefaultGuid,
  EmployeeDropdownList,
  ExchangeDropdownList,
  FATCAList,
  languageList,
  ProvinceList,
  steps,
} from "../../../../shared/company-enrolment/sampleData";
import {
  addToMetaDataPayloadString,
  resetPayload,
} from "../../../../shared/company-enrolment/company-helpers";
import { INDUSTRIES } from "../../../../shared/company-enrolment/industryConstants";
import GradientButton from "../../../../auth/login/components/GradientButton";

export class CorporateFull extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.bottomRef = React.createRef();
    this.state = {
      updated: 0,
      fatca: this.props.fatca,
      bhaGuid: this.props.bhaGuid,
      brokerGuid: this.props.brokerGuid,
      tpaGuid: this.props.tpaGuid,
      ayaSalesRepGuid: this.props.ayaSalesRepGuid,
      bhaLabel: this.props.bhaName,
      brokerLabel: this.props.brokerName,
      tpaLabel: this.props.tpaName,
      ayaSalesRepLabel: this.props.ayaSalesRepName,
      businessType: this.props.businessType,
      industryClassificationList: this.props.industryClassificationList,
      industryList: this.props.industryList,
      estimatedNumberOfEmployees: this.props.estimatedNumberOfEmployees,
      exchange: this.props.exchange,
      corporateNumberType: this.props.corporateNumberType,
      corporateNumber: this.props.corporateNumber,
      provinceOfRegisteredOffice: this.props.provinceOfRegisteredOffice,
      dateOfRegistration: moment.utc(this.props.dateOfRegistration).toDate(),
      sourceDocument: this.props.sourceDocument,
      streetOne: this.props.streetOne,
      streetTwo: this.props.streetTwo,
      city: this.props.city,
      province: this.props.province,
      country: this.props.country,
      postalCode: this.props.postalCode,
      mailingStreetOne: this.props.mailingStreetOne,
      mailingStreetTwo: this.props.mailingStreetTwo,
      mailingCity: this.props.mailingCity,
      mailingProvince: this.props.mailingProvince,
      mailingCountry: this.props.mailingCountry,
      mailingPostalCode: this.props.mailingPostalCode,
      operatingInCanada: this.props.operatingInCanada,
      craBusinessNumber: this.props.craBusinessNumber,
      peoName: this.props.peoName,
      peoContactName: this.props.peoContactName,
      peoContactEmail: this.props.peoContactEmail,
      website: this.props.website,
      websiteExists: this.props.websiteExists,
      businessIndustry: this.props.businessIndustry,
      industryClassificationType: this.props.industryClassificationType,
      notSameAddress: this.props.notSameAddress,
      payloadString: this.props.payloadString,
      companyEnrolment: this.props.companyEnrolment ?? {},
      enrolledByEmail: this.props.enrolledByEmail,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (
      this.props.streetOne ||
      this.props.streetTwo ||
      this.props.city ||
      this.props.province ||
      this.props.country ||
      this.props.postalCode
    ) {
      this.setState({
        streetOne: this.props.streetOne,
        streetTwo: this.props.streetTwo,
        city: this.props.city,
        province: this.props.province,
        country: this.props.country,
        postalCode: this.props.postalCode,
      });
    }

    setTimeout(() => {
      const { selectedCompanyGuid, email } = this.props.companyEnrolment ?? {};
      this.setState({
        selectedCompanyGuid,
        email,
      });
      if (this.props?.otherAddresses?.length > 0) {
        this.setState({
          otherAddresses: this.props.otherAddresses,
        });
      }
      if (this.props?.stockSymbol) {
        this.setState({
          publiclyTraded: this.props.stockSymbol ? true : false,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {}

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setAddress = (name, address) => {
    let { address_components, formatted_address } = address;
    let data = {};
    if (address_components) {
      for (let item of address_components) {
        switch (item.types[0]) {
          case "country":
            data.country = item.short_name;
            break;
          case "postal_code":
            data.postalCode = item.long_name;
            break;
          case "administrative_area_level_1":
            data.province = item.long_name;
            break;
          case "political":
            data.city = item.long_name;
            break;
          case "locality":
            !_.has(data, "city")
              ? (data.city = item.long_name)
              : (data.city = `${data.city}`);
            break;
          case "street_number":
            data.streetAddress = item.long_name;
            break;
          case "route":
            data.streetAddress = `${
              data.streetAddress ? `${data.streetAddress}` : ""
            } ${item.long_name}`;
            break;
          default:
            break;
        }
      }
    }

    if (address_components && name === "businessAddress") {
      this.setState({
        businessAddress: formatted_address,
        streetOne: data.streetAddress,
        city: data.city,
        province: data.province,
        country: data.country,
        postalCode: data.postalCode,
      });
    } else if (address_components && name === "mailingAddress") {
      this.setState({
        mailingAddress: formatted_address,
        mailingStreetOne: data.streetAddress,
        mailingCity: data.city,
        mailingProvince: data.province,
        mailingCountry: data.country,
        mailingPostalCode: data.postalCode,
      });
    } else if (_.size(name.split(".")[1]) > 0) {
      this.props.dispatch(
        change(
          "CorporateFull",
          `${name.split(".")[0]}.streetOne`,
          data.streetAddress
        )
      );
      this.props.dispatch(
        change("CorporateFull", `${name.split(".")[0]}.city`, data.city)
      );
      this.props.dispatch(
        change("CorporateFull", `${name.split(".")[0]}.province`, data.province)
      );
      this.props.dispatch(
        change("CorporateFull", `${name.split(".")[0]}.country`, data.country)
      );
      this.props.dispatch(
        change(
          "CorporateFull",
          `${name.split(".")[0]}.postalCode`,
          data.postalCode
        )
      );
      this.setState({ updated: this.state.updated + 1 });
    } else {
      this.setState({ [name]: address });
    }
  };

  filterIndustries = (industries) => {
    if (industries && industries.length > 0) {
      let desiredIndustries = industries.filter(
        (item) =>
          item.parentClassificationGuid ===
          "00000000-0000-0000-0000-000000000000"
      );

      return desiredIndustries;
    } else {
      return [];
    }
  };

  filterClassifications = (industries, newClassificationFilter) => {
    let defaultFilter = "";
    if (this.state.businessIndustry && !newClassificationFilter) {
      defaultFilter = this.getDefaultClassificationGuid(
        this.state.businessIndustry,
        industries
      );
    }

    if (
      this.state.classificationFilter !== "" ||
      (this.state.businessIndustry && !newClassificationFilter)
    ) {
      return industries.filter(
        (item) =>
          item.parentClassificationGuid ===
          (newClassificationFilter || defaultFilter)
      );
    } else {
      return [];
    }
  };

  changeReferingEntity = (value) => {
    this.setState({ referingEntity: value });
  };

  updateView = (view) => {
    this.setState({ view });
  };

  switchLanguage = (id) => {
    for (let language of languageList) {
      if (language.id === id && language.disabled !== true) {
        this.setState({ languageId: id });
      }
    }
  };

  someFunction = (val) => {};

  onGenericChange = (value, name) => {
    this.setState({ [name]: value });
  };

  toggleCompanyIncorperated = () => {
    this.setState({ companyIncorperated: !this.state.companyIncorperated });
  };
  fileChangedHandler = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  changeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  changeAddressSelect = (value, label, name) => {
    this.setState({ [name]: value });
  };

  changeSelectIndustry = (name, value) => {
    let classificationFilter = "";
    this.props.industries.forEach((industry) => {
      if (industry.label === value) {
        classificationFilter = industry.value;
      }
    });
    this.setState(
      {
        [name]: value,
        classificationFilter: classificationFilter,
        industryClassificationType: "",
      },
      () => {
        this.filterClassifications(this.props.industries, classificationFilter);
      }
    );
  };

  getDefaultClassificationGuid = (value, industries) => {
    let classificationFilter = "";
    industries.forEach((industry) => {
      if (industry.label === value) {
        classificationFilter = industry.value;
      }
    });
    return classificationFilter;
  };

  onChangeGenericCheckBox = (name) => {
    this.setState({ [name]: !this.state[name] }, () => {
      if (
        name === "otherAddresses" &&
        this.state.otherAddresses &&
        _.has(this.props, "otherAddresses") &&
        _.size(this.props.otherAddresses) < 1
      ) {
        this.props.dispatch(arrayPush("CorporateFull", "otherAddresses", {}));
      }
      if (name === "otherAddresses" && !this.state.otherAddresses) {
        this.props.dispatch(arrayRemoveAll("CorporateFull", "otherAddresses"));
      }
      if (name === "stockSymbol" && !this.state.publiclyTraded) {
        this.props.dispatch(arrayRemoveAll("CorporateFull", "stockSymbol"));
        this.setState({ exchange: "" });
      }
      if (name === "website" && !this.state.websiteExists) {
        this.props.dispatch(change("CorporateFull", "website", ""));
      }
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChange = (date, context) => {
    this.setState({
      [context]: date,
    });
  };

  onChangeGenericRadio = (value, name) => {
    if (name === "corporateNumberType" && value === "Federal") {
      this.setState({ provinceOfRegisteredOffice: "" });
    }
    this.setState({ [name]: value });
  };

  toggleGeneric = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  toggleMailingAddress = () => {
    this.setState({ notSameAddress: !this.state.notSameAddress }, () => {
      if (!this.state.notSameAddress) {
        this.setState({
          mailingAddress: "",
          mailingStreetOne: "",
          mailingStreetTwo: "",
          mailingCity: "",
          mailingProvince: "",
          mailingCountry: "",
          mailingPostalCode: "",
        });
      }
    });
  };

  scrollToBottom = () => {
    if (this.bottomRef.current) {
      this.bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    const { styles, userRole } = this.props;
    const {
      businessType,
      bhaLabel,
      brokerLabel,
      tpaLabel,
      bhaGuid,
      brokerGuid,
      tpaGuid,
      operatingInCanada,
      ayaSalesRepLabel,
    } = this.state;
    const checkboxDropDownActive = this.state.companyIncorperated
      ? "checkboxDropDownActive"
      : "";
    return (
      <>
        <div
          className={styles.msgContainer}
          style={{
            width: "600px",
            fontFamily: "Montserrat",
            textAlign: "center",
          }}
        >
          Reminder: Click "
          <a
            onClick={this.scrollToBottom}
            style={{
              cursor: "pointer",
            }}
          >
            Save and Continue
          </a>
          " button to keep your progress!
        </div>
        <div className={styles.contentContainerX}>
          <div className={styles.description}>
            <Stepper
              steps={steps}
              activeStep={2}
              context={"company-full"}
              businessOperatedName={this.props.businessOperatedName}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.subHeaderLabels}>
              Section 7: Company Information
            </div>
            <div className={styles.sectionContent}>
              <Field
                component={ConnectedInput}
                label="Legal Business Name"
                name="businessLegalName"
                type="text"
                disabled={true}
                placeholder="New Company LLC"
              />
              <Field
                component={ConnectedInput}
                label="Company Name (Doing Business As) - As appears on card - 23 Characters Max"
                name="businessOperatedName"
                type="text"
                max={23}
                disabled={true}
                placeholder="New Company LLC"
              />
              <CustomSelect
                label="Is the Business Operating in Canada?"
                name="operatingInCanada"
                placeholder="Select"
                extrastyle="fullWidth"
                onChange={(value) =>
                  this.changeSelect("operatingInCanada", value)
                }
                options={BusinessOperatedInCAList}
                value={operatingInCanada}
              />
              {operatingInCanada === "Yes, Via PEO (or equivalent)" && (
                <React.Fragment>
                  <div className={styles.leftIndentContainer}>
                    <Field
                      component={ConnectedInput}
                      label="Name of PEO"
                      name="peoName"
                      type="text"
                      placeholder="Name of PEO"
                    />
                    <Field
                      component={ConnectedInput}
                      label="PEO Contact Name"
                      labelDetail="Optional"
                      name="peoContactName"
                      type="text"
                      placeholder="PEO Contact Name"
                    />
                    <Field
                      component={ConnectedInput}
                      label="PEO Contact Email"
                      labelDetail="Optional"
                      name="peoContactEmail"
                      type="email"
                      placeholder="PEO Contact Email"
                    />
                  </div>
                </React.Fragment>
              )}
              <CustomSelect
                label="Company Type"
                name="businessType"
                onChange={(value) => this.changeSelect("businessType", value)}
                options={CompanyTypeList}
                value={businessType}
              />
              {
                //When Business is US registered and a corporation
                operatingInCanada === "No, USA Registered" && (
                  <React.Fragment>
                    <div className={styles.leftIndentContainer}>
                      <Field
                        component={ConnectedInput}
                        label="TAX ID Number / EIN"
                        name="taxIdOrEinNumber"
                        type="text"
                        placeholder="0000000000"
                      />
                    </div>
                  </React.Fragment>
                )
              }
              {
                //When Business is US registered and a corporation
                operatingInCanada === "No, International (Non-USA)" && (
                  <React.Fragment>
                    <div className={styles.leftIndentContainer}>
                      <Field
                        component={ConnectedInput}
                        label="TAX ID Number / TIN"
                        name="taxIdOrTinNumber"
                        type="text"
                        placeholder="0000000000"
                      />
                    </div>
                  </React.Fragment>
                )
              }
              {(operatingInCanada === "Yes, Registered" ||
                operatingInCanada === "Yes, Via PEO (or equivalent)") &&
                businessType && (
                  // businessType.toLowerCase() !== "corporation" &&
                  // this.props.programPaymentType !== "postpayment" && (
                  <React.Fragment>
                    <div className={styles.leftIndentContainer}>
                      {/* <div className={styles.standaloneLabel}>
                      9 Digit CRA Business Registered Tax Number
                    </div> */}
                      <Field
                        component={ConnectedInput}
                        label="Business Number (9 Digit CRA Business Registered Tax Number)"
                        name="craBusinessNumber"
                        type="text"
                        placeholder="000000000"
                        extrastyle="medium"
                        tooltipMessage={
                          <div className={styles.popupBNCN}>
                            <span>
                              <p>
                                The Business Number (BN) is a part of a unique
                                federal government numbering system that
                                identifies a business and the accounts it
                                maintains with the Canada Revenue Agency (CRA).
                              </p>
                              <p>
                                The BN for a corporation is different from the
                                corporation's corporation number. It is composed
                                of a 9-digit registration number that identifies
                                the business.
                              </p>
                              <p>
                                Where can I find the business number? <br />
                                <div style={{ marginTop: 5 }}>
                                  <a
                                    href="https://beta.canadasbusinessregistries.ca/search"
                                    target="_blank"
                                    className={styles.popupLink}
                                  >
                                    Corporation Canada online database.
                                  </a>
                                </div>
                              </p>
                            </span>
                          </div>
                        }
                        hoverableTooltip={true}
                      />
                    </div>
                  </React.Fragment>
                )}
              {(operatingInCanada === "Yes, Registered" ||
                operatingInCanada === "Yes, Via PEO (or equivalent)") &&
                businessType === "Corporation" &&
                this.props.programPaymentType !== "postpayment" && (
                  <React.Fragment>
                    <div className={styles.leftIndentContainer}>
                      <Field
                        component={ConnectedInput}
                        label="Corporate Number"
                        name="corporateNumber"
                        type="text"
                        placeholder="0000000000"
                        tooltipMessage={
                          <div className={styles.popupBNCN}>
                            <span>
                              <p>
                                A corporation number is the number assigned to a
                                corporation by Corporations Canada.It is usually
                                a 7 to 10 digit number.
                              </p>{" "}
                              <p>
                                Where can I find the corporation number? <br />
                                <div style={{ marginTop: 5 }}>
                                  <a
                                    href="https://beta.canadasbusinessregistries.ca/search"
                                    target="_blank"
                                    className={styles.popupLink}
                                  >
                                    Corporation Canada online database.
                                  </a>
                                </div>
                              </p>
                            </span>
                          </div>
                        }
                        hoverableTooltip={true}
                      />
                      <div className={styles.standaloneLabel}>
                        Place of Corp. Registration
                      </div>
                      <RadioGroup
                        items={CorporateNumberTypeList}
                        value={this.state.corporateNumberType}
                        noBackground={true}
                        name="corporateNumberType"
                        onUpdate={(value) =>
                          this.onChangeGenericRadio(
                            value,
                            "corporateNumberType"
                          )
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              {this.state.corporateNumberType === "Provincial" &&
                businessType &&
                businessType.toLowerCase() === "corporation" && (
                  <CustomSelect
                    label="Province of Registration"
                    name="provinceOfRegisteredOffice"
                    value={this.state.provinceOfRegisteredOffice}
                    onChange={(value) =>
                      this.changeSelect("provinceOfRegisteredOffice", value)
                    }
                    options={ProvinceList}
                  />
                )}
              <br />
              <br />
            </div>
            {this.state.businessAddressEditMode ? (
              <React.Fragment>
                <div className={styles.standaloneLabel}>
                  Corporate Legal Address{" "}
                  <span className={styles.light}>
                    - As found on Utility Bill, no PO Box
                  </span>
                </div>
                <GoogleSuggest
                  name="businessAddress"
                  onKeyUp={this.handleChange}
                  setAddress={this.setAddress}
                  labelDetail="P. O. Box Not Accepted"
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <DarkBox
                  label="Corporate Legal Address - As found on Utility Bill, no PO Box"
                  content={`
										${this.state.streetTwo ? `${this.state.streetTwo},` : ""}
										${this.state.streetOne ? `${this.state.streetOne},` : ""}
										${this.state.city ? `${this.state.city},` : ""}
										${this.state.province ? `${this.state.province}` : ""}
										${this.state.country ? `${this.state.country},` : ""}
										${this.state.postalCode ? `${this.state.postalCode}` : ""}
									`}
                  onClick={() => this.toggleGeneric("businessAddressEditMode")}
                />
              </React.Fragment>
            )}
            {(this.state.streetOne || this.state.city) && (
              <React.Fragment>
                <Input
                  label="Address 1"
                  name="streetOne"
                  type="text"
                  placeholder="Address 1"
                  onKeyUp={this.handleChange}
                  myValue={this.state.streetOne}
                />
                <Input
                  label="Address 2 (e.g., Apt Number, Suite Number…etc)"
                  name="streetTwo"
                  type="text"
                  placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
                  onKeyUp={this.handleChange}
                  myValue={this.state.streetTwo}
                />
                <div className={styles.inputContainer}>
                  <Input
                    label="City"
                    name="city"
                    extrastyle="semi-medium"
                    type="text"
                    placeholder="City"
                    onKeyUp={this.handleChange}
                    myValue={this.state.city}
                  />
                  <Input
                    label="Province/State"
                    name="province"
                    type="text"
                    extrastyle="medium-with-margin"
                    placeholder="State/Province"
                    onKeyUp={this.handleChange}
                    myValue={this.state.province}
                  />
                  <CustomSelect
                    label="Country"
                    name="country"
                    extrastyle="medium-with-margin"
                    onChange={(value, label, name) =>
                      this.changeAddressSelect(value, label, name)
                    }
                    options={GlobalCountryList}
                    value={this.state.country}
                  />
                </div>
                <Input
                  label="Postal Code/ZIP"
                  name="postalCode"
                  type="text"
                  extrastyle="semi-medium"
                  placeholder="Zip/Postal Code"
                  onKeyUp={this.handleChange}
                  myValue={this.state.postalCode}
                />
              </React.Fragment>
            )}
            <Checkbox
              checked={this.state.notSameAddress}
              label={
                "Mailing Address is different than Corporate Legal Address"
              }
              name="notSameAddress"
              onClick={() => this.toggleMailingAddress()}
            />
            {this.state.notSameAddress && (
              <React.Fragment>
                <div className={styles.standaloneLabel}>Mailing Address</div>
                <GoogleSuggest
                  name="mailingAddress"
                  labelDetail="P. O. Box Not Accepted"
                  setAddress={this.setAddress}
                  extrastyle={"withCheckBox"}
                  onKeyUp={this.handleChange}
                  myValue={
                    this.state.sameAddress
                      ? this.state.businessAddress
                      : this.state.mailingAddress
                  }
                />
                <br />
              </React.Fragment>
            )}
            {this.state.notSameAddress &&
              (this.state.mailingStreetOne || this.state.mailingCity) && (
                <React.Fragment>
                  <Input
                    label="Address 1"
                    name="mailingStreetOne"
                    type="text"
                    placeholder="Address 1"
                    onKeyUp={this.handleChange}
                    myValue={
                      this.state.sameAddress
                        ? this.state.streetOne
                        : this.state.mailingStreetOne
                    }
                  />
                  <Input
                    label="Address 2 (e.g., Apt Number, Suite Number…etc)"
                    name="mailingStreetTwo"
                    type="text"
                    placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
                    onKeyUp={this.handleChange}
                    myValue={
                      this.state.sameAddress
                        ? this.state.streetTwo
                        : this.state.mailingStreetTwo
                    }
                  />
                  <div className={styles.inputContainer}>
                    <Input
                      label="City"
                      name="mailingCity"
                      extrastyle="semi-medium"
                      type="text"
                      placeholder="City"
                      onKeyUp={this.handleChange}
                      myValue={
                        this.state.sameAddress
                          ? this.state.city
                          : this.state.mailingCity
                      }
                    />

                    <Input
                      label="Province/State"
                      name="mailingProvince"
                      type="text"
                      extrastyle="medium-with-margin"
                      placeholder="State/Province"
                      onKeyUp={this.handleChange}
                      myValue={
                        this.state.sameAddress
                          ? this.state.province
                          : this.state.mailingProvince
                      }
                    />
                    <CustomSelect
                      label="Country"
                      name="mailingCountry"
                      extrastyle="medium-with-margin"
                      onChange={(value, label, name) =>
                        this.changeAddressSelect(value, label, name)
                      }
                      options={GlobalCountryList}
                      value={
                        this.state.sameAddress
                          ? this.state.country
                          : this.state.mailingCountry
                      }
                    />
                  </div>
                  <Input
                    label="Postal Code/ZIP"
                    name="mailingPostalCode"
                    type="text"
                    extrastyle="semi-medium"
                    placeholder="Zip/Postal Code"
                    onKeyUp={this.handleChange}
                    myValue={
                      this.state.sameAddress
                        ? this.state.postalCode
                        : this.state.mailingPostalCode
                    }
                  />
                </React.Fragment>
              )}
            <br />
            {this.state.websiteExists && (
              <Field
                component={ConnectedInput}
                label="Company Website"
                name="website"
                labelDetail={"optional"}
                type="text"
                placeholder="http://www.example.com"
              />
            )}
            <Checkbox
              checked={!this.state.websiteExists}
              label={"The company does not have a website"}
              name="websiteCheckbox"
              onClick={() => this.onChangeGenericCheckBox("websiteExists")}
            />
            <br />
            {this.props.programPaymentType !== "postpayment" && (
              <React.Fragment>
                <CustomSelect
                  label="What is your business's primary field or industry?"
                  name="businessIndustry"
                  onChange={(value, label) =>
                    this.changeSelectIndustry("businessIndustry", label)
                  }
                  value={this.state.businessIndustry}
                  options={this.filterIndustries(this.props.industries)}
                />
                <CustomSelect
                  label="Describe the Nature of Your Business:"
                  name="industryClassificationType"
                  onChange={(value, label) =>
                    this.changeSelect("industryClassificationType", label)
                  }
                  value={this.state.industryClassificationType}
                  options={this.filterClassifications(
                    this.props.industries,
                    this.state.classificationFilter
                  )}
                />
              </React.Fragment>
            )}
            <CustomSelect
              label="Number of T4 & T4A Employees"
              name="estimatedNumberOfEmployees"
              onChange={(value) =>
                this.changeSelect("estimatedNumberOfEmployees", value)
              }
              value={this.state.estimatedNumberOfEmployees}
              options={EmployeeDropdownList}
            />
            {this.props.programPaymentType !== "postpayment" && (
              <React.Fragment>
                <React.Fragment>
                  <div className={styles.inputContainer}>
                    <div
                      className={`${styles.checkboxDropdownContainer} ${
                        this.state.otherAddresses
                          ? `${styles.checkboxActive}`
                          : ""
                      }`}
                    >
                      <Checkbox
                        checked={this.state.otherAddresses}
                        onClick={() =>
                          this.onChangeGenericCheckBox("otherAddresses")
                        }
                        label={"The company has offices outside of Canada"}
                      />
                      {this.state.otherAddresses && (
                        <div className={styles.leftPaddedInputContainer}>
                          <div className={styles.standaloneLabel}>Address</div>
                          <FieldArray
                            name="otherAddresses"
                            component={this.renderOtherAddresses}
                            styles={styles}
                            mystate={this.state}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
                <div className={styles.inputContainer}>
                  <div
                    className={`${styles.checkboxDropdownContainer} ${
                      this.state.publiclyTraded
                        ? `${styles.checkboxActive}`
                        : ""
                    }`}
                  >
                    <Checkbox
                      checked={this.state.publiclyTraded}
                      onClick={() =>
                        this.onChangeGenericCheckBox("publiclyTraded")
                      }
                      label={"The company is a publicly traded company"}
                    />
                    {this.state.publiclyTraded && (
                      <div className={styles.leftPaddedInputContainer}>
                        <div className={styles.multiInputContainer}>
                          <Field
                            component={ConnectedInput}
                            label="Stock Symbol"
                            name="stockSymbol"
                            type="text"
                            extrastyle="small"
                            placeholder="Symbol"
                          />
                          <div style={{ paddingRight: 20, marginRight: 10 }} />
                          <CustomSelect
                            selectLabel={"Exchange"}
                            name="exchange"
                            extrastyle="largeWithoutMarginTop"
                            onChange={(value) =>
                              this.changeSelect("exchange", value)
                            }
                            value={this.state.exchange}
                            options={ExchangeDropdownList}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
            <br />
          </div>
          {this.props.programPaymentType !== "postpayment" && (
            <React.Fragment>
              {/* <Divider /> */}
              <div className={styles.section}>
                {/* <div className={styles.sectionTitle}>FATCA CRS Declaration</div> */}
                <div className={styles.subHeaderLabels}>
                  Section 8: FATCA CRS Declaration
                </div>
                <div className={styles.description}>
                  Aya Payments, through its third-party agents, collects Know
                  Your Client (KYC) information as mandated under the Proceeds
                  of Crime (Money Laundering) and Terrorist Financing Act
                  (PCMLTFA) for individuals and corporate entities. <br />
                  Additionally, for compliance purposes, Aya Payments is
                  required to collect the Declaration of Tax Residence for
                  Entities (RC519E).
                  <a
                    href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/rc519.html"
                    target="_blank"
                  >
                    {" "}
                    Learn More{" "}
                  </a>
                </div>
                <div className={styles.sectionContent}>
                  <div className={styles.standaloneLabel}>
                    Does your business have tax obligations in any other country
                    other than Canada?
                  </div>
                  <RadioGroup
                    items={FATCAList}
                    value={this.state.fatca || this.props.fatca}
                    noBackground={true}
                    name="fatca"
                    onUpdate={(value) =>
                      this.onChangeGenericRadio(value, "fatca")
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          <div className={styles.buttonAreaContainer}>
            <GradientButton
              handleClick={() => {
                // e.preventDefault();
                this.updateCompany(FULL__STEP_FOUR__COMPANY);
              }}
              style={{
                marginTop: "20px",
              }}
              label="Save And Continue"
              width="100%"
            ></GradientButton>
          </div>

          {/* Bottom element to scroll to */}
          <div ref={this.bottomRef}></div>
        </div>
      </>
    );
  }

  renderOtherAddresses = ({ styles, handleChange, fields }) => (
    <div>
      <React.Fragment>
        {fields.map((otherAddress, index) => {
          let currentField = fields.get(index);
          let { streetOne, city, country } = currentField || {};
          return (
            <div key={index}>
              <React.Fragment>
                <div className={styles.sectionTitle}>Address {index + 1}</div>
                <div>
                  <GoogleSuggest
                    name={`${otherAddress}.otherAddresses`}
                    onKeyUp={this.handleChange}
                    setAddress={this.setAddress}
                    labelDetail="P. O. Box Not Accepted"
                  />
                </div>
                {(streetOne || city) && (
                  <React.Fragment>
                    <Field
                      component={ConnectedInput}
                      label="Address 1"
                      name={`${otherAddress}.streetOne`}
                      type="text"
                      placeholder="Address 1"
                      onKeyUp={this.handleChange}
                    />
                    <Field
                      component={ConnectedInput}
                      label="Address 2 (e.g., Apt Number, Suite Number…etc)"
                      name={`${otherAddress}.streetTwo`}
                      type="text"
                      placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
                      onKeyUp={this.handleChange}
                    />
                    <div className={styles.inputContainer}>
                      <Field
                        component={ConnectedInput}
                        label="City"
                        name={`${otherAddress}.city`}
                        extrastyle="semi-medium"
                        type="text"
                        placeholder="City"
                        onKeyUp={this.handleChange}
                      />
                      <Field
                        component={ConnectedInput}
                        label="Province/State"
                        name={`${otherAddress}.province`}
                        type="text"
                        extrastyle="medium-with-margin"
                        placeholder="State/Province"
                        onKeyUp={this.handleChange}
                      />
                      <Field
                        component={RenderDropdownList}
                        label="Country"
                        name={`${otherAddress}.country`}
                        extrastyle="medium-with-margin"
                        data={GlobalCountryList}
                        validate={[countryNonCA]}
                        valueField="value"
                        textField="label"
                        placeholder="country"
                      />
                    </div>
                    <Field
                      component={ConnectedInput}
                      label="Postal / Zip Code"
                      name={`${otherAddress}.postalCode`}
                      type="text"
                      extrastyle="semi-medium"
                      placeholder="Zip/Postal Code"
                      onKeyUp={this.handleChange}
                    />
                  </React.Fragment>
                )}
                {country &&
                (country === "CA" || _.get(country, "value") === "CA") ? (
                  <div className={styles.error}>
                    Your Non-Canadian office address must not be a Canadian
                    address
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}
                <div className={styles.removeAdministratorWrapper}>
                  <a
                    href={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      fields.remove(index);
                      this.onRemoveAddress("otherAddresses");
                    }}
                    className={styles.removeAdministrator}
                  >
                    - Remove this Address
                  </a>
                </div>
                <br />
                <Divider />
                <br />
              </React.Fragment>
            </div>
          );
        })}
        <div>
          <br />
          <br />
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              fields.push();
            }}
            className={styles.addAdministrator}
          >
            {" "}
            + Add another address
          </a>
        </div>
      </React.Fragment>
    </div>
  );

  onRemoveAddress = (name) => {
    if (this.props[name]?.length === 1) {
      this.setState({ [name]: false });
    }
  };

  updateCompany = async (nextStep) => {
    const {
      businessOperatedName,
      businessLegalName,
      craBusinessNumber,
      businessShortName,
      numberOfBrokers,
      corporateNumber,
      website,
      stockSymbol,
      stockExchangeName,
      // canadaAddresses,
      otherAddresses,
      peoName,
      peoContactName,
      peoContactEmail,
      taxIdOrEinNumber,
      taxIdOrTinNumber,
    } = this.props;

    const {
      provinceOfRegisteredOffice,
      businessAddress,
      exchange,
      streetOne,
      streetTwo,
      city,
      province,
      postalCode,
      country,
      publiclyTraded,
      mailingAddress,
      mailingStreetOne,
      mailingStreetTwo,
      mailingCity,
      mailingProvince,
      mailingPostalCode,
      mailingCountry,
      businessIndustry,
      industryClassificationType,
      sourceDocument,
      dateOfRegistration,
      estimatedNumberOfEmployees,
      corporateNumberType,
      bhaLabel,
      brokerLabel,
      tpaLabel,
      bhaGuid,
      tpaGuid,
      brokerGuid,
      operatingInCanada,
      businessType,
      notSameAddress,
      fatca,
      ayaSalesRepLabel,
      ayaSalesRepGuid,
      websiteExists,
    } = this.state;

    let payloadData = this.props.company;
    // if (!payloadData.inviteGuid) {
    //   payloadData = this.props.companyAllInfo;
    // }

    const params = {
      method: "put",
      // path: `invites/${payloadData.inviteGuid}`,
      path: `invites`,
      // save: _.get(options, 'save'),
      data: {
        ...payloadData,
        dirty: true,
        requestedForBusinessId: "",
        requestedPersonParentId: "",
        inviteState: {
          dirty: true,
          step: nextStep,
          status: "active",
          inviteStatus: "BA01S",
        },
        sendNotification: false,
        person: {
          // ...payloadData.person,
          dirty: false,
          personStatus: "PIDV01",
          countryCode: "CA",
          languageCode: "en",
          programDefinedVariables: {},
          metaInformation: {},
        },
        business: {
          ...payloadData.business,
          dirty: true,
          businessStatus: "BA01S",
          businessLegalName: businessLegalName,
          businessOperatedName: businessOperatedName,
          businessShortName: businessOperatedName
            ? businessOperatedName.substring(0, 5)
            : "SHORTNAME",
          businessDbaLicNumber: craBusinessNumber,
          isAddressSameAsMailing: !notSameAddress,
          addressBook: [
            {
              dirty: true,
              isPrimary: true,
              addressClass: "business",
              city: city,
              province: province,
              postalCode: postalCode,
              streetOne: streetOne,
              streetTwo: streetTwo,
              country: country,
            },
            {
              dirty: true,
              isPrimary: false,
              addressClass: "mailing",
              city: notSameAddress ? mailingCity : city,
              province: notSameAddress ? mailingProvince : province,
              postalCode: notSameAddress ? mailingPostalCode : postalCode,
              streetOne: notSameAddress ? mailingStreetOne : streetOne,
              streetTwo: notSameAddress ? mailingStreetTwo : streetTwo,
              country: notSameAddress ? mailingCountry : country,
            },
          ],
          businessPreferredLanguage: "en",
          logo: "",
          institutionType: "planSponsor",
          metaInformation: {
            dirty: true,
            companyWebsite: websiteExists ? website : "n/a",
            assocBrokerageHouseName: bhaLabel,
            assocBrokerName: brokerLabel,
            assocThirdPartyAdministratorName: tpaLabel,
            requestedByPersonGuid: brokerGuid,
            ayaSalesRepName: ayaSalesRepLabel ?? "",
            operatingInCanada: operatingInCanada,
            peo: {
              dirty: true,
              peoName: peoName,
              peoContactName: peoContactName,
              peoContactEmail: peoContactEmail,
            },
            taxIdOrEinNumber: taxIdOrEinNumber,
            taxIdOrTinNumber: taxIdOrTinNumber,
            corporateNumber: corporateNumber,
            corporateNumberType: corporateNumberType,
            businessType: businessType,
            numberOfBrokers: "",
            numberOfEmployees: estimatedNumberOfEmployees,
            jurisdictionOfInsurance: provinceOfRegisteredOffice,
            isCompanyPubliclyTraded: publiclyTraded ? "true" : "false",
            trading: {
              dirty: true,
              stockSymbol: stockSymbol,
              stockExchangeName: exchange,
            },
            industry: {
              dirty: true,
              industryType: businessIndustry,
              industryClassificationType: industryClassificationType,
            },
            dateOfRegistration: dateOfRegistration,
            sourceDocument: {
              dirty: true,
              name: sourceDocument,
              number: "",
            },
            fatca: fatca ? "true" : "false",
          },
          enrolledByBusinessID: this.props.bhaGuid, //brokerageHOuse GUid
          newEnrolledByBusinessID:
            bhaGuid !== this.props.bhaGuid ? bhaGuid : undefined, //only send if bhaGuid is different
          // requestedByPersonGuid: this.props.brokerGuid, //broker
          // newRequestedByPersonGuid:
          //   brokerGuid !== this.props.brokerGuid ? brokerGuid : undefined, //only send if brokerGuid is different
          // requestedForBusinessAssocId: this.props.tpaGuid, //tpa GUid
          // newRequestedForBusinessAssocId:
          //   tpaGuid !== this.props.tpaGuid ? tpaGuid : undefined, //only send if tpaGuid is different
          // requestedForBusinessAssocBusinessType: "planSponsor",
        },
        salesPersonGuid: ayaSalesRepGuid ?? "",
        accessToken: this.props.accessToken,
      },
    };

    if (this.state.otherAddresses && otherAddresses.length > 0) {
      for (const [index, address] of otherAddresses.entries()) {
        let item = {
          dirty: true,
          isPrimary: false,
          addressClass: `otherAddresses.${index}`,
          city: address.city,
          province: address.province,
          postalCode: address.postalCode,
          streetOne: address.streetOne,
          streetTwo: address.streetTwo,
          country: address.country,
        };
        params.data.business.addressBook.push(item);
      }
    }

    //Payload string
    const modifiedPayloadString = addToMetaDataPayloadString(
      this.state.payloadString,
      params.data
    );
    let newParams = resetPayload(params);
    newParams.data.business.metaInformation["payloadString"] =
      modifiedPayloadString;

    try {
      // let response = this.props.updateCompany(newParams);
      let response = await this.props.saveEnrolmentCompanyData({
        companyData: modifiedPayloadString,
        enrolledByEmail: this.state.enrolledByEmail,
        guid: this.state.selectedCompanyGuid,
        // businessName: this.state.businessLegalName,
      });
    } catch (ex) {}
  };
}

const validate = (formValues) => {
  const {
    businessOperatedName,
    businessLegalName,
    craBusinessNumber,
    businessGenericEmail,
  } = formValues;
  const errors = {};

  if (isEmpty(businessOperatedName))
    errors.businessOperatedName = "Business Operated Name cannot be left blank";
  if (isEmpty(businessLegalName))
    errors.businessLegalName = "Business Legal Name cannot be left blank";

  return errors;
};

const WrappedCorporateFull = reduxForm({
  form: "CorporateFull",
  validate,
  enableReinitialize: true,
})(CorporateFull);

const mapStateToProps = (state) => {
  const selector = formValueSelector("CorporateFull");

  const industries = {
    data: INDUSTRIES || [],
  };

  //From payload string
  // let data = state.companyReducer.companyAllInfo;
  // const companyAllInfo = state.companyReducer.companyAllInfo;
  // let payloadString =
  //   state.companyReducer.companyAllInfo?.business?.metaInformation
  //     ?.payloadString ?? "";
  // if (payloadString !== "") data = JSON.parse(payloadString);
  const data = state.companyReducer?.companyEnrolment?.companyData ?? {};
  let payloadString =
    JSON.stringify(state.companyReducer.companyEnrolment?.companyData) ?? "";
  const companyEnrolment = state.companyReducer.companyEnrolment ?? {};

  const enrolledByEmail = state.authReducer?.preAuthDetails?.email ?? "";

  const {
    accessToken,
    userClaims: { businessOperatedName: userRole, personGuid },
  } = state.authReducer;
  let properUserRole = userRoleMapper[userRole];

  let allIndustries = [];
  if (_.has(industries, "data")) {
    let feed = industries.data;
    feed.forEach((data) => {
      allIndustries.push({
        label: data.classificationname,
        value: data.classificationguid,
        parentClassificationGuid: data.parentclassificationguid,
      });
    });
  }

  //retrieve meta data
  let myMetaData = {};
  if (payloadString !== "") {
    myMetaData = {
      ...data.business?.metaInformation,
      ...data.person?.metaInformation,
    };
  } else {
    if (
      _.has(data, "business.metaInformation") &&
      typeof data.business?.metaInformation !== "undefined" &&
      typeof data.business?.metaInformation.forEach === "function"
    ) {
      data.business.metaInformation.forEach((item) => {
        if (item.metaType === "string") {
          myMetaData[item.metaKey] = item.metaValue;
        }
      });
    }
    if (
      _.has(data, "person.metaInformation") &&
      typeof data.person?.metaInformation !== "undefined" &&
      typeof data.person?.metaInformation?.forEach === "function"
    ) {
      data.person?.metaInformation?.forEach((item) => {
        if (item.metaType === "string") {
          myMetaData[item.metaKey] = item.metaValue;
        }
      });
    }
  }

  //select prechosen industry
  let industryList = [];
  if (allIndustries.length > 0) {
    industryList = allIndustries.filter(
      (item) => item.parentClassificationGuid === DefaultGuid
    );
  }

  let industryClassificationList = [];
  if (
    // _.get(data, 'business.metaInformation.industry.industryType') ||
    _.get(myMetaData, "industry.industryType") &&
    industryList.length > 0
  ) {
    let classificationFilter = "";
    industryList.forEach((industry) => {
      if (industry.label === _.get(myMetaData, "industry.industryType")) {
        classificationFilter = industry.value;
      }
    });
    industryClassificationList = allIndustries.filter(
      (item) => item.parentClassificationGuid === classificationFilter
    );
  }

  // get fatca
  let fatca = _.get(myMetaData, "fatca") === "true" ? true : false;

  //get programDefinedVariables's
  //business pdv's
  let programDefinedVariables = {};
  if (
    _.get(data, "business.programDefinedVariables") &&
    typeof data.business.programDefinedVariables !== null &&
    typeof data.business.programDefinedVariables.forEach === "function"
  ) {
    data.business.programDefinedVariables.forEach((item) => {
      if (
        item.productVariableDefinition.variableName &&
        item.variableValue.length > 0
      ) {
        programDefinedVariables[item.productVariableDefinition.variableName] =
          item.variableValue;
      }
    });
  } else if (_.get(data, "business.programDefinedVariables")) {
    for (let item in data.business.programDefinedVariables) {
      if (
        _.get(
          data,
          `business.programDefinedVariables[${item}].productVariableDefinition.variableName`
        )
      ) {
        programDefinedVariables[
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableName
        ] =
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableValue;
      }
      programDefinedVariables[item] =
        data.business.programDefinedVariables[item];
    }
  }

  //get businessAddress
  let streetOne;
  let streetTwo;
  let city;
  let province;
  let postalCode;
  let country;
  let mailingStreetOne;
  let mailingStreetTwo;
  let mailingCity;
  let mailingProvince;
  let mailingPostalCode;
  let mailingCountry;
  // let canadaAddresses = [];
  let otherAddresses = [];
  let businessAddress = "";
  let mailingAddress = "";
  if (
    _.get(data, "business.addressBook") &&
    typeof data.business.addressBook !== "undefined" &&
    typeof data.business.addressBook.forEach === "function"
  ) {
    data.business.addressBook.forEach((item) => {
      if (item.addressClass === "business") {
        streetOne = item.streetOne;
        streetTwo = item.streetTwo;
        city = item.city;
        province = item.province;
        postalCode = item.postalCode;
        country = item.country;
        businessAddress = JSON.stringify(item);
      }
      if (item.addressClass === "mailing") {
        mailingStreetOne = item.streetOne;
        mailingStreetTwo = item.streetTwo;
        mailingCity = item.city;
        mailingProvince = item.province;
        mailingPostalCode = item.postalCode;
        mailingCountry = item.country;
        mailingAddress = JSON.stringify(item);
      }
      // if (item.addressClass.split(".")[0] === "canadaAddresses") {
      //   canadaAddresses.push(item);
      // }
      if (item.addressClass.split(".")[0] === "otherAddresses") {
        otherAddresses.push(item);
      }
    });
  }
  let notSameAddress =
    (mailingStreetOne && streetOne !== mailingStreetOne) ||
    (mailingStreetTwo && streetTwo !== mailingStreetTwo) ||
    (mailingPostalCode && postalCode !== mailingPostalCode)
      ? true
      : false;

  let businessRelation = {};
  businessRelation["bhaGuid"] =
    _.get(data, "business.enrolledByBusinessID") || "";
  businessRelation["tpaGuid"] =
    _.get(data, "business.requestedByPersonGuid") || "";
  businessRelation["brokerGuid"] = _.get(
    data,
    "business.requestedByPersonGuid"
  );
  businessRelation["ayaSalesRepGuid"] = _.get(data, "salesPersonGuid");

  let websiteExists =
    _.get(myMetaData, "companyWebsite") === undefined ||
    (_.get(myMetaData, "companyWebsite") &&
      _.get(myMetaData, "companyWebsite") !== "n/a");
  let website = websiteExists ? _.get(myMetaData, "companyWebsite") : "";

  return {
    // productCodes: newProductCodes,
    businessOperatedName: selector(state, "businessOperatedName"),
    businessLegalName: selector(state, "businessLegalName"),
    corporateNumber: selector(state, "corporateNumber"),
    craBusinessNumber: selector(state, "craBusinessNumber"),
    businessShortName: selector(state, "businessShortName"),
    peoName: selector(state, "peoName"),
    peoContactName: selector(state, "peoContactName"),
    peoContactEmail: selector(state, "peoContactEmail"),
    taxIdOrEinNumber: selector(state, "taxIdOrEinNumber"),
    taxIdOrTinNumber: selector(state, "taxIdOrTinNumber"),
    companyEnrolment: selector(state, "companyEnrolment"),
    enrolledByEmail: enrolledByEmail,

    website: selector(state, "website"),
    websiteExists: websiteExists,
    stockSymbol: selector(state, "stockSymbol"),
    sourceDocument: selector(state, "sourceDocument"),
    businessIndustry: selector(state, "businessIndustry"),
    industryClassificationType: selector(state, "industryClassificationType"),
    // canadaAddresses: selector(state, "canadaAddresses"),
    otherAddresses: selector(state, "otherAddresses"),
    company: data,
    // companyAllInfo: companyAllInfo,
    myMetaData,
    accessToken,
    bhaName:
      _.get(data, "business.metaInformation.assocBrokerageHouseName") ||
      _.get(myMetaData, "assocBrokerageHouseName"),
    brokerName:
      _.get(data, "business.metaInformation.assocBrokerName") ||
      _.get(myMetaData, "assocBrokerName"),
    tpaName:
      _.get(
        data,
        "business.metaInformation.assocThirdPartyAdministratorName"
      ) || _.get(myMetaData, "assocThirdPartyAdministratorName"),
    ayaSalesRepName:
      _.get(data, "business.metaInformation.ayaSalesRepName") ||
      _.get(myMetaData, "ayaSalesRepName"),
    bhaGuid: _.get(businessRelation, "bhaGuid"),
    brokerGuid:
      _.get(data, "business.metaInformation.requestedByPersonGuid") ||
      _.get(myMetaData, "requestedByPersonGuid"),
    tpaGuid:
      _.get(businessRelation, "tpaGuid") || _.get(businessRelation, "bhaGuid"),
    ayaSalesRepGuid: _.get(data, "salesPersonGuid"),
    streetOne: streetOne,
    streetTwo: streetTwo,
    city: city,
    province: province,
    country: country,
    postalCode: postalCode,
    industries: allIndustries,
    industryList: industryList,
    industryClassificationList: industryClassificationList,
    businessIndustry:
      _.get(data, "business.metaInformation.industry.industryType") ||
      _.get(myMetaData, "industryType"),
    industryClassificationType:
      _.get(
        data,
        "business.metaInformation.industry.industryClassificationType"
      ) || _.get(myMetaData, "industryClassificationType"),
    estimatedNumberOfEmployees:
      _.get(data, "business.metaInformation.numberOfEmployees") ||
      _.get(myMetaData, "numberOfEmployees"),
    exchange:
      _.get(data, "business.metaInformation.trading.stockExchangeName") ||
      _.get(myMetaData, "stockExchangeName"),
    operatingInCanada:
      _.get(data, "business.metaInformation.operatingInCanada") ||
      _.get(myMetaData, "operatingInCanada"),
    businessType:
      _.get(data, "business.metaInformation.businessType") ||
      _.get(myMetaData, "businessType"),
    corporateNumberType:
      _.get(data, "business.metaInformation.corporateNumberType") ||
      _.get(myMetaData, "corporateNumberType"),
    provinceOfRegisteredOffice:
      _.get(data, "business.metaInformation.jurisdictionOfInsurance") ||
      _.get(myMetaData, "jurisdictionOfInsurance"),
    dateOfRegistration:
      _.get(data, "business.metaInformation.dateOfRegistration") ||
      _.get(myMetaData, "dateOfRegistration"),
    sourceDocument:
      _.get(data, "business.metaInformation.sourceDocument.name") ||
      _.get(myMetaData, "name"),
    notSameAddress: notSameAddress,

    //Business Registered Address
    streetOne: streetOne,
    streetTwo: streetTwo,
    city: city,
    province: province,
    country: country,
    postalCode: postalCode,
    //Business Mailing Address
    mailingStreetOne: mailingStreetOne,
    mailingStreetTwo: mailingStreetTwo,
    mailingCity: mailingCity,
    mailingProvince: mailingProvince,
    mailingCountry: mailingCountry,
    mailingPostalCode: mailingPostalCode,
    //Addresses and Fatca
    fatca: fatca,
    programPaymentType:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.programPaymentType"
      ) || _.get(programDefinedVariables, "programPaymentType"),
    payloadString: payloadString,

    initialValues: {
      businessOperatedName: _.has(data.business, "businessOperatedName")
        ? data.business.businessOperatedName
        : "",
      businessLegalName: _.has(data.business, "businessLegalName")
        ? data.business.businessLegalName
        : "",
      craBusinessNumber: _.has(data.business, "businessDbaLicNumber")
        ? data.business.businessDbaLicNumber
        : "",
      notSameAddress: notSameAddress,
      peoName:
        _.get(data, "business.metaInformation.peo.peoName") ||
        _.get(myMetaData, "peoName"),
      peoContactName:
        _.get(data, "business.metaInformation.peo.peoContactName") ||
        _.get(myMetaData, "peoContactName"),
      peoContactEmail:
        _.get(data, "business.metaInformation.peo.peoContactEmail") ||
        _.get(myMetaData, "peoContactEmail"),
      taxIdOrEinNumber:
        _.get(data, "business.metaInformation.taxIdOrEinNumber") ||
        _.get(myMetaData, "taxIdOrEinNumber"),
      taxIdOrTinNumber:
        _.get(data, "business.metaInformation.taxIdOrTinNumber") ||
        _.get(myMetaData, "taxIdOrTinNumber"),
      website: website,
      websiteExists: websiteExists,
      stockSymbol:
        _.get(data, "business.metaInformation.trading.stockSymbol") ||
        _.get(myMetaData, "stockSymbol"),
      corporateNumber:
        _.get(data, "business.metaInformation.corporateNumber") ||
        _.get(myMetaData, "corporateNumber"),
      corporateNumberType:
        _.get(data, "business.metaInformation.corporateNumberType") ||
        _.get(myMetaData, "corporateNumberType"),
      operatingInCanada:
        _.get(data, "business.metaInformation.operatingInCanada") ||
        _.get(myMetaData, "operatingInCanada"),
      businessType:
        _.get(data, "business.metaInformation.businessType") ||
        _.get(myMetaData, "businessType"),
      estimatedNumberOfEmployees:
        _.get(data, "business.metaInformation.numberOfEmployees") ||
        _.get(myMetaData, "numberOfEmployees"),
      industryClassificationType:
        _.get(
          data,
          "business.metaInformation.industry.industryClassificationType"
        ) || _.get(myMetaData, "industryClassificationType"),
      // canadaAddresses: canadaAddresses,
      otherAddresses: otherAddresses,
      ayaSalesRepGuid: _.get(data, "salesPersonGuid") || "",
      payloadString: payloadString ?? "",
      companyEnrolment: companyEnrolment,
      enrolledByEmail: enrolledByEmail,
    },
  };
};

export default connect(mapStateToProps, {
  updateCompany,
  saveEnrolmentCompanyData,
})(WrappedCorporateFull);
