import _, { size, get } from "lodash";
import {
  buildPDVArray,
  restorePercentageValue,
  isEmailValid,
} from "../../../utilities/customFunctions";
import moment from "moment";

/**
 * Parse Persons
 */
export const parsePersonList = (list: any) => {
  let parsedList: { label: string; value: string }[] = [];
  if (size(list) > 0) {
    Object.values(list).forEach((data: any) => {
      parsedList.push({
        label: `${data.firstName} ${data.lastName}`,
        value: data.personGuid,
      });
    });
  }
  return parsedList;
};

export const getCompanyPDVInfo = (company: any) => {
  const data = company.companyAllInfo;

  //get programDefinedVariables
  //business pdv's
  let programDefinedVariables: any = {};
  if (
    _.get(data, "business.programDefinedVariables") &&
    typeof data.business.programDefinedVariables !== null &&
    typeof data.business.programDefinedVariables.forEach === "function"
  ) {
    data.business.programDefinedVariables.forEach((item: any) => {
      if (
        item.productVariableDefinition.variableName &&
        item.variableValue.length > 0
      ) {
        programDefinedVariables[item.productVariableDefinition.variableName] =
          item.variableValue;
      }
    });
  } else if (_.get(data, "business.programDefinedVariables")) {
    for (let item in data.business.programDefinedVariables) {
      if (
        _.get(
          data,
          `business.programDefinedVariables[${item}].productVariableDefinition.variableName`
        )
      ) {
        programDefinedVariables[
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableName
        ] =
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableValue;
      }
      programDefinedVariables[item] =
        data.business.programDefinedVariables[item];
    }
  }

  //get employeeClass
  let employeeClass = [];
  if (_.size(programDefinedVariables) > 0) {
    employeeClass = _.get(programDefinedVariables, "contractTerm")
      ? buildPDVArray(
          programDefinedVariables.contractTerm,
          programDefinedVariables.contractTerm.endDateYear1,
          programDefinedVariables.contractTerm.startDateYear1,
          programDefinedVariables.contractTerm.prorateYear1,
          programDefinedVariables.contractTerm.paymentSchedule
        )
      : buildPDVArray(
          programDefinedVariables,
          programDefinedVariables.endDateYear1,
          programDefinedVariables.startDateYear1,
          programDefinedVariables.prorateYear1,
          programDefinedVariables.paymentSchedule
        );
  } else {
    employeeClass = buildPDVArray(
      _.get(data, "business.programDefinedVariables.contractTerm"),
      _.get(data, "business.programDefinedVariables.contractTerm,endDateYear1"),
      _.get(
        data,
        "business.programDefinedVariables.contractTerm,startDateYear1"
      ),
      _.get(data, "business.programDefinedVariables.contractTerm,prorateYear1"),
      _.get(
        data,
        "business.programDefinedVariables.contractTerm,paymentSchedule"
      )
    );
  }

  return {
    companyGuid: get(data, "business.businessGUID"),
    programDefinedVariables,
    // userName: `${userClaims.firstName}  ${userClaims.lastName}`,
    businessName: _.get(data, "business.businessOperatedName"),
    year1StartDate: _.get(programDefinedVariables, "year1StartDate"),
    year1TermDate: _.get(programDefinedVariables, "year1endDate"),
    planType: _.get(programDefinedVariables, "programType"),
    purseType: _.get(programDefinedVariables, "purseType"),
    paymentType:
      _.get(programDefinedVariables, "programPaymentType") ===
      "prepaymentmastercard"
        ? "PrePay"
        : "PostPay",
    paymentSchedule: get(programDefinedVariables, "paymentSchedule"),
    postpaymentFundsPullingRate: _.get(
      programDefinedVariables,
      "postpaymentFundsPullingRate"
    ),
    dependentsEligible:
      _.get(programDefinedVariables, "allow") === "true" ? "Yes" : "No",
    lastDayToSubmitPrevYear: _.get(
      programDefinedVariables,
      "daysToSubmitClaimPreviousYear"
    ),
    lastDayToSubmitTermination: _.get(
      programDefinedVariables,
      "daysToSubmitClaimTermination"
    ),

    administrationFee: restorePercentageValue(
      _.get(programDefinedVariables, "administrationFee") || 0
    ),
    claimProcessingFee: restorePercentageValue(
      _.get(programDefinedVariables, "claimProcessingFee") || 0
    ),
    monthlyEmployeeFeeTier1:
      _.get(programDefinedVariables, "monthlyEmployeeFeeTier1") || 0,
    monthlyEmployeeFeeTier2:
      _.get(programDefinedVariables, "monthlyEmployeeFeeTier2") || 0,
    setupFee: _.get(programDefinedVariables, "setupFee") || 0,
    employeeSetupFee: _.get(programDefinedVariables, "employeeSetupFee") || 0,
    cardIssuanceFee: _.get(programDefinedVariables, "cardIssuanceFee") || 0,
    employeeMonthlyFee:
      _.get(programDefinedVariables, "monthlyEmployeeFee") || 0,
    earlyTerminationFee:
      _.get(programDefinedVariables, "earlyTerminationFee") || 0,
    // year1StartDate: _.get(programDefinedVariables, 'startDateYear1'),
    defaultPaymentMethod: get(data, "business.defaultPaymentMethod"),
    financialInstitution: get(data, "business.bankAccount.bankName") || "-",
    accountNumber: get(data, "business.bankAccount.bankAccountNumber"),
    classes: employeeClass,
  };
};

export const addToMetaDataPayloadString = (
  metadataPayloadString: string,
  payload: any
) => {
  let metadataParsed = {
    person: {
      metaInformation: {},
    },
    business: {
      metaInformation: {},
    },
    bankAccountDetails: {},
  };
  if (metadataPayloadString) {
    metadataParsed = JSON.parse(metadataPayloadString);
  }

  const newMetadata = {
    ...metadataParsed,
    ...payload,
    person: {
      ...metadataParsed?.person,
      ...payload.person,
      metaInformation: {
        ...metadataParsed?.person?.metaInformation,
        ...payload?.person?.metaInformation,
      },
    },
    contacts: payload.contacts || [],
    business: {
      ...metadataParsed?.business,
      ...payload?.business,
      metaInformation: {
        ...metadataParsed?.business?.metaInformation,
        ...payload?.business?.metaInformation,
      },
    },
    bankAccountDetails: {
      ...metadataParsed?.bankAccountDetails,
      ...payload?.bankAccountDetails,
    },
  };

  if (!payload?.person?.additionalRole && newMetadata?.person?.additionalRole)
    delete newMetadata.person.additionalRole;

  if (newMetadata.business?.metaInformation?.payloadString)
    delete newMetadata.business.metaInformation?.payloadString;

  return JSON.stringify(newMetadata);
};

export const resetPayload = (payload: any) => {
  const newPayload = {
    ...payload,
    data: {
      // ...payload.data,
      // dirty: true,
      inviteState: {
        dirty: true,
        step: payload.data.inviteState.step,
        status: payload.data.inviteState.status,
      },
      business: {
        //all business data
        dirty: true,
        businessGUID: payload.data.business.businessGUID,
        businessLegalName: payload.data.business.businessLegalName,
        businessOperatedName: payload.data.business.businessOperatedName,
        businessShortName: payload.data.business.businessShortName,
        BusinessDbaLicNumber: payload.data.business.BusinessDbaLicNumber,
        BusinessGenericEmail: payload.data.business.BusinessGenericEmail,
        BusinessTelephoneNumber: payload.data.business.BusinessTelephoneNumber,
        BusinessTelephoneExtension:
          payload.data.business.BusinessTelephoneExtension,
        BusinessLogoURL: payload.data.business.BusinessLogoURL,
        StreetOne: payload.data.business.StreetOne,
        StreetTwo: payload.data.business.StreetTwo,
        City: payload.data.business.City,
        Province: payload.data.business.Province,
        PostalCode: payload.data.business.PostalCode,
        MailingStreetOne: payload.data.business.MailingStreetOne,
        MailingStreetTwo: payload.data.business.MailingStreetTwo,
        MailingCity: payload.data.business.MailingCity,
        MailingProvince: payload.data.business.MailingProvince,
        MailingPostalCode: payload.data.business.MailingPostalCode,
        BusinessStatus: payload.data.business.BusinessStatus,
        IsIncorporated: payload.data.business.IsIncorporated,
        businessPreferredLanguage: "en",
        country: "CA",
        mailingCountry: "CA",
        metaInformation: {
          dirty: true,
          payloadString: "",
        },
      },
      accessToken: payload.data.accessToken,
    },
  };

  return newPayload;
};

export const getValidationErrors = (state: any, props: any) => {
  const {
    businessOperatedName,
    businessLegalName,
    craBusinessNumber,
    businessShortName,
    businessGenericEmail,
    registeredBusinessAddress,
    registeredMailingAddress,
    firstName,
    lastName,
    email,
    bankName,
    bankNumber,
    bankBranchNumber,
    accountNumber,
    employeeClass,
    claimProcessingFee,
    authorizedSigners,
    documentNumber,
    planAdministratorSameWithCoperateContact,
    planAdminEmail,
    planAdminFirstName,
    planAdminLastName,
    planSponsorBeneficiarys,
    planSponsorDirectors,
  } = props;

  const {
    streetOne,
    mailingStreetOne,
    bankPaymentMethod,
    programPaymentTypeId,
    expiryDate,
    actionAfterMastercardClaimDeclineId,
  } = state;
  let errors: any = {};

  //company
  if (!businessLegalName)
    errors["Legal Business Name"] =
      "Business Legal Name cannot be blank (Page 2: Corporate)";
  if (!businessOperatedName)
    errors["Company Name"] =
      "Business Operated Name cannot be left blank (Page 2: Corporate)";

  // if (!streetOne) errors.registeredBusinessAddress = 'Address cannot be blank';

  // if (!claimProcessingFee)
  //   errors['Claim Processing Fee'] = 'Claim Processing Fee cannot be blank';

  //administrator
  // if (!mailingStreetOne)
  //   errors.registeredMailingAddress = 'Address cannot be blank';

  if (!firstName)
    errors["First Name"] =
      "You must enter a first name for Corporate Contact (Page 3: Contacts)";
  if (!lastName)
    errors["Last Name"] =
      "You must enter a last name for Corporate Contact (Page 3: Contacts)";

  if (!email) errors.email = "You must enter an administrator email";
  else if (!isEmailValid(email))
    errors.email = "Invalid administrator email (Page 3: Contacts)";

  if (moment.utc(state.startDate).isAfter(moment.utc(Date.now()), "day"))
    errors["Start Date"] = "Start date cannot be in the future (Page 1: Plan)";

  if (moment.utc(state.endDate).isBefore(state.startDate, "day"))
    errors["End Date"] = "End date cannot be before start date (Page 1: Plan)";

  if (
    moment
      .utc(moment.utc(state.effectiveTerminationDate))
      .isBefore(moment.utc(state.startDate), "day")
  )
    errors["Effective Termination Date"] =
      "Effective termination date cannot be before start date (Page 1: Plan)";

  if (moment.utc(state.effectiveTerminationDate).isAfter(state.endDate, "day"))
    errors["Effective Termination Date"] =
      "Effective termination date cannot be after end date (Page 1: Plan)";

  if (moment.utc(state.endDateYear1).isBefore(state.startDateYear1, "day"))
    errors["Year 1 End Date"] =
      "End date cannot be before start date (Page 1: Plan)";

  if (
    moment
      .utc(state.endDateYear1)
      .isAfter(
        moment.utc(
          moment.utc(state.startDateYear1).add(1, "years").subtract(1, "days")
        ),
        "day"
      )
  )
    errors["Year 1 End Date"] =
      "End date cannot be after start date + 1 year (Excluding the end day) (Page 1: Plan)";

  if (moment.utc(state.dateOfBirth).isAfter(Date.now(), "day"))
    errors["Date Of Birth"] =
      "Date of birth cannot be in the future (Page 3: Contacts)";

  if (!bankPaymentMethod)
    errors["Bank Payment Method"] =
      "You must select a payment method (Page 4: Banking)";

  // if (
  //   bankPaymentMethod === 'pad' &&
  //   (!accountNumber || !bankNumber || !bankBranchNumber)
  // )
  //   errors['Bank Account Details'] = 'You must enter bank account details';

  if (
    // bankPaymentMethod === "pad" &&
    (!authorizedSigners || authorizedSigners.length === 0) &&
    programPaymentTypeId !== "postpayment"
  )
    errors["Authorized Signers"] =
      "You must add at least one authorized signer (Page 4: Banking)";

  if (!documentNumber && programPaymentTypeId !== "postpayment")
    errors["Government Photo ID Number"] =
      "Document Number cannot be blank (Page 3: Contacts)";

  if (bankBranchNumber) {
    const trimmedValue = bankBranchNumber.replace(/\s/g, "");

    // Check for numeric only
    if (!/^\d+$/.test(trimmedValue)) {
      errors["Bank Branch Number"] = "Must contain only numbers";
    }
  } else if (!bankBranchNumber && bankPaymentMethod === "pad") {
    errors["Bank Branch Number"] = "Required field";
  }

  if (bankNumber) {
    const trimmedValue = bankNumber.replace(/\s/g, "");

    // Check for numeric only
    if (!/^\d+$/.test(trimmedValue)) {
      errors["Financial Institution Number"] = "Must contain only numbers";
    }
  } else if (!bankNumber && bankPaymentMethod === "pad") {
    errors["Financial Institution Number"] = "Required field";
  }

  if (accountNumber) {
    const trimmedValue = accountNumber.replace(/\s/g, "");

    // Check for numeric only
    if (!/^\d+$/.test(trimmedValue)) {
      errors["Account Number"] = "Must contain only numbers";
    }
  } else if (!accountNumber && bankPaymentMethod === "pad") {
    errors["Account Number"] = "Required field";
  }

  if (
    expiryDate &&
    programPaymentTypeId !== "postpayment" &&
    moment.utc(expiryDate).isBefore(Date.now(), "day")
  ) {
    errors["Government photo ID Expiry Date"] =
      "Expiry date cannot be in the past (Page 3: Contacts)";
  }

  // if (programPaymentTypeId === "postpayment" && !claimProcessingFee)
  //   errors["Claim Processing Fee"] =
  //     "Claim Processing Fee cannot be blank (Page 1: Plan)";

  // if (
  //   programPaymentTypeId === "prepaymentmastercard" &&
  //   !actionAfterMastercardClaimDeclineId
  // ) {
  //   errors["Action After Card Claim Decline"] =
  //     "Action After Card Claim Decline cannot be blank (Page 1: Plan)";
  // }

  if (props.employeeClass && props.employeeClass.length === 0) {
    errors["Employee Class"] = "Employee Class cannot be blank (Page 1: Plan)";
  }

  if (props.employeeClass && props.employeeClass.length > 0) {
    props.employeeClass.forEach((employeeClass: any, index: number) => {
      index = index + 1;
      if (!employeeClass.className || employeeClass.className === "") {
        errors["Employee Class " + index + ": Name"] =
          "Employee Class Name cannot be blank (Page 1: Plan)";
      }
      if (!employeeClass.note || employeeClass.note === "") {
        errors["Employee Class " + index + ": Description"] =
          "Description cannot be blank (Page 1: Plan)";
      }
      if (!employeeClass.type || employeeClass.type === "") {
        errors["Employee Class " + index + ": Plan Type"] =
          "Plan Type cannot be blank (Page 1: Plan)";
      }
      if (employeeClass?.allocation) {
        ["HSA", "WSA", "Flex"].forEach((type) => {
          if (employeeClass.allocation[type]) {
            [
              "allocationSingle",
              "allocationSinglePlusOne",
              "allocationSinglePlusTwo",
            ].forEach((subtype) => {
              if (
                employeeClass.allocation[type][subtype] === "" ||
                employeeClass.allocation[type][subtype] === "0.00" ||
                employeeClass.allocation[type][subtype] === "0"
              ) {
                employeeClass.allocation[type][subtype] = 0;
              }
            });
          }
        });
      }
      if (
        employeeClass &&
        (!employeeClass.allocation ||
          (employeeClass?.allocation["HSA"]?.allocationSingle === 0 &&
            employeeClass?.allocation["WSA"]?.allocationSingle === 0 &&
            employeeClass?.allocation["Flex"]?.allocationSingle === 0))
      ) {
        errors["Employee Class " + index + ": Single"] =
          "Employee Class Single cannot be blank";
      }
      if (
        employeeClass &&
        employeeClass.allocation &&
        (employeeClass?.allocation["HSA"]?.allocationSingle > 0 ||
          employeeClass?.allocation["WSA"]?.allocationSingle > 0) &&
        employeeClass?.allocation["Flex"]?.allocationSingle > 0
      ) {
        errors["Employee Class " + index + ": Single"] =
          "Employee Class cannot have both HSA/WSA and Flex allocations";
      }
      if (
        employeeClass &&
        employeeClass.allocation &&
        (employeeClass?.allocation["HSA"]?.allocationSinglePlusOne > 0 ||
          employeeClass?.allocation["WSA"]?.allocationSinglePlusOne > 0) &&
        employeeClass?.allocation["Flex"]?.allocationSinglePlusOne > 0
      ) {
        errors["Employee Class " + index + ": Single+1"] =
          "Employee Class cannot have both HSA/WSA and Flex allocations";
      }
      if (
        employeeClass &&
        employeeClass.allocation &&
        (employeeClass?.allocation["HSA"]?.allocationSinglePlusTwo > 0 ||
          employeeClass?.allocation["WSA"]?.allocationSinglePlusTwo > 0) &&
        employeeClass?.allocation["Flex"]?.allocationSinglePlusTwo > 0
      ) {
        errors["Employee Class " + index + ": Single+2"] =
          "Employee Class cannot have both HSA/WSA and Flex allocations";
      }

      const hasHSAWSA =
        employeeClass?.allocation["HSA"]?.allocationSingle > 0 ||
        employeeClass?.allocation["WSA"]?.allocationSingle > 0 ||
        employeeClass?.allocation["HSA"]?.allocationSinglePlusOne > 0 ||
        employeeClass?.allocation["WSA"]?.allocationSinglePlusOne > 0 ||
        employeeClass?.allocation["HSA"]?.allocationSinglePlusTwo > 0 ||
        employeeClass?.allocation["WSA"]?.allocationSinglePlusTwo > 0;
      const hasFlex =
        employeeClass?.allocation["Flex"]?.allocationSingle > 0 ||
        employeeClass?.allocation["Flex"]?.allocationSinglePlusOne > 0 ||
        employeeClass?.allocation["Flex"]?.allocationSinglePlusTwo > 0;

      if (hasHSAWSA && hasFlex) {
        errors["Employee Class " + index] =
          "Employee Class cannot have both HSA/WSA and Flex allocations in the same class";
      }
    });
  }

  if ((!planAdminFirstName || !planAdminLastName) && planAdminEmail) {
    if (!planAdminFirstName)
      errors["First Name"] =
        "You must enter a first name for Plan Administrator (Page 3: Contacts)";

    if (!planAdminLastName)
      errors["Last Name"] =
        "You must enter a last name for Plan Administrator (Page 3: Contacts)";
  }
  if ((!planAdminEmail || !planAdminLastName) && planAdminFirstName) {
    if (!planAdminEmail)
      errors["Email"] =
        "You must enter a plan administrator email (Page 3: Contacts)";

    if (!planAdminLastName)
      errors["Last Name"] =
        "You must enter a last name for Plan Administrator (Page 3: Contacts)";
  }
  if ((!planAdminEmail || !planAdminFirstName) && planAdminLastName) {
    if (!planAdminEmail)
      errors["Email"] =
        "You must enter a plan administrator email (Page 3: Contacts)";

    if (!planAdminFirstName)
      errors["First Name"] =
        "You must enter a first name for Plan Administrator (Page 3: Contacts)";
  }

  if (planSponsorBeneficiarys && planSponsorBeneficiarys.length > 0) {
    //Foreach loop for plan sponsor beneficiary array and check if firstName field is filled and show error
    planSponsorBeneficiarys.forEach((beneficiary: any, index: number) => {
      index = index + 1;
      if (!beneficiary.firstName || beneficiary.firstName === "") {
        errors[
          "Beneficial Ownership Information (" + index + ") : First Name"
        ] =
          "Plan Sponsor Beneficiary First Name cannot be blank (Page 3: Contacts)";
      }
      if (!beneficiary.lastName || beneficiary.lastName === "") {
        errors["Beneficial Ownership Information (" + index + ") : Last Name"] =
          "Plan Sponsor Beneficiary Last Name cannot be blank (Page 3: Contacts)";
      }
      if (!beneficiary.email || beneficiary.email === "") {
        errors["Beneficial Ownership Information (" + index + ") : Email"] =
          "Plan Sponsor Beneficiary Email cannot be blank (Page 3: Contacts)";
      }
      if (
        !beneficiary.amountOfOwnership ||
        beneficiary.amountOfOwnership === ""
      ) {
        errors[
          "Beneficial Ownership Information (" +
            index +
            ") : Amount of Ownership"
        ] =
          "Plan Sponsor Beneficiary Amount of Ownership cannot be blank (Page 3: Contacts)";
      }
    });
  }

  if (planSponsorDirectors && planSponsorDirectors.length > 0) {
    planSponsorDirectors.forEach((director: any, index: number) => {
      index = index + 1;
      if (!director.firstName || director.firstName === "") {
        errors["Director Information (" + index + ") : First Name"] =
          "Plan Sponsor Director First Name cannot be blank (Page 3: Contacts)";
      }
      if (!director.lastName || director.lastName === "") {
        errors["Director Information (" + index + ") : Last Name"] =
          "Plan Sponsor Director Last Name cannot be blank (Page 3: Contacts)";
      }
      if (!director.email || director.email === "") {
        errors["Director Information (" + index + ") : Email"] =
          "Plan Sponsor Director Email cannot be blank (Page 3: Contacts)";
      }
    });
  }

  return errors;
};

export const getStaticGuids = () => {
  let environment = "phat-dev";
  if (
    location.host.includes("apistaging") ||
    `${process.env.REACT_APP_API_URL}`.includes("apistaging")
  ) {
    environment = "staging";
  } else if (location.host.includes("app.ayacare")) {
    environment = "prod";
  }

  switch (environment) {
    case "phat-dev":
      return {
        BHA_GUID: "41015bd8-74b1-ea11-aa56-b5eebf073bbc",
        BHA_NAME: "Test BHA",
        BROKER_GUID: "a0ee0acd-40b2-ea11-aa56-b5eebf073bbc",
        BROKER_NAME: "TestBHA",
        AYA_SALES_REP_GUID: "",
        AYA_SALES_REP_NAME: "",
        TPA_GUID: "41015bd8-74b1-ea11-aa56-b5eebf073bbc",
        TPA_NAME: "Test BHA",
      };
    case "staging":
      return {
        BHA_GUID: "ca0d0543-76b1-ea11-aa56-85bb0075ad95",
        BHA_NAME: "AYA_BHA",
        BROKER_GUID: "b1178dbd-afb4-ea11-aa56-85bb0075ad95",
        BROKER_NAME: "Aya broker",
        AYA_SALES_REP_GUID: "",
        AYA_SALES_REP_NAME: "",
        TPA_GUID: "ca0d0543-76b1-ea11-aa56-85bb0075ad95",
        TPA_NAME: "AYA_BHA",
      };
    case "prod":
      return {
        BHA_GUID: "5c89ac97-be8c-eb11-aa56-bc7afbf6c942",
        BHA_NAME: "Aya Payments, Inc. BHA",
        BROKER_GUID: "40891af0-c45b-41d0-a1f5-5a454a8c0ba2",
        BROKER_NAME: "Chanddeep Madaan",
        AYA_SALES_REP_GUID: "",
        AYA_SALES_REP_NAME: "",
        TPA_GUID: "5c89ac97-be8c-eb11-aa56-bc7afbf6c942",
        TPA_NAME: "Aya Payments, Inc. TPA",
      };
  }
};
