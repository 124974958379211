// React
import { FC, ReactElement, memo, Fragment, ChangeEvent } from "react";
// Semantic UI
import { Popup } from "semantic-ui-react";
// Form
import { Field } from "redux-form";
// Constant
import { Images } from "../../../constants";
// Scss
import styles from "./Radio.module.scss";

// Interface
interface IProps {
  name: string;
  options: Array<IOptions>;
  myValue: string;
  noBackground: boolean | string;
  horizontal: string;
  thinVerticalSpacing: string;
  thinMarginBottom: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}
interface IOptions {
  [index: string]: any;
}

/**
 * @param {IProps} { name, options,noBackground, horizontal, thinVerticalSpacing, thinMarginBottom, onChange, disabled=false }
 * @return {*}  {ReactElement}
 */
const CRG: FC<IProps> = ({
  name,
  options,
  noBackground,
  horizontal,
  thinVerticalSpacing,
  thinMarginBottom,
  onChange,
  disabled = false,
}: IProps): ReactElement => (
  <Fragment>
    <Field
      component={({ input, options }: any) => (
        <div
          className={`${styles.radioGroup} 
								${horizontal ? `${styles.horizontal}` : ""}
								${thinVerticalSpacing ? `${styles.thinVerticalSpacing}` : ""}				
								${thinMarginBottom ? `${styles.thinMarginBottom}` : ""}				
								`}
        >
          {options.map((option: IOptions) => {
            const { tooltipMessage, tooltipImage } = option;
            const checkedClass =
              option.value === input.value ? styles.selectedContainer : "";

            return (
              <div
                key={option.id}
                className={`${styles.container} ${
                  !noBackground
                    ? `${checkedClass}`
                    : `${styles.noPaddingMarginContainer}`
                } ${horizontal ? `${styles.padRight}` : ""} `}
              >
                <div className={styles.radioButtonArea}>
                  <label>
                    <input
                      id={option.id}
                      type="radio"
                      {...input}
                      onChange={(e) => {
                        if (!disabled) {
                          input.onChange(e);
                          if (typeof onChange == "function") onChange(e);
                        }
                      }}
                      value={option.value}
                      checked={option.value === input.value}
                    />
                    <span />
                  </label>
                </div>
                <div className={styles.labelArea}>
                  <label htmlFor={option.id}>
                    {option.label}{" "}
                    {(tooltipMessage || tooltipImage) && (
                      <Popup
                        content={
                          <Fragment>
                            {tooltipImage && (
                              <Fragment>
                                <img
                                  className={styles.tooltipImage}
                                  src={Images[tooltipImage]}
                                />
                              </Fragment>
                            )}
                            {tooltipMessage}
                          </Fragment>
                        }
                        position="top center"
                        trigger={
                          <img
                            src={Images.question}
                            className={styles.tooltip}
                          />
                        }
                      />
                    )}
                  </label>
                  {option.subLabel && (
                    <div className={styles.subLabelStyle}>
                      {option.subLabel}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      name={name}
      options={options}
    />
  </Fragment>
);

// Memo
const ConnectedRadioGroup = memo(CRG);
// Export
export { ConnectedRadioGroup };
