import React, { ReactElement } from "react";
import styled from "styled-components";
import { COLOR_SCHEMES } from "../../../designSystem/colors";

export const Divider: React.FC = (): ReactElement => {
  return (
    <div
      style={{
        flex: 1,
        border: "1px solid #B6D6EC",
        opacity: "0.5",
        marginBottom: 10,
        marginTop: 10,
      }}
    />
  );
};

export default Divider;

export const CustomDivider: React.FC<{ children: any }> = ({
  children,
}): ReactElement => {
  return (
    <DividerContainer>
      <DividerLine />
      {children}
      <DividerLine />
    </DividerContainer>
  );
};

const DividerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Montserrat";
`;

const DividerLine = styled.div`
  border-top: 1px solid ${COLOR_SCHEMES.GREYS[400]};
  flex: 1;
  height: 1px;
  box-sizing: border-box;
`;
