module.exports = {
  person: {
    firstName: "Mario",
    lastName: "Furgiuele",
    accessRole: {
      name: "Super Admin",
    },
  },
  InvoicingPeriodList: [
    {
      id: "Monthly",
      value: "Monthly",
      label: "Monthly",
    },
    {
      id: "Quarterly",
      value: "Quarterly",
      label: "Quarterly",
    },
    {
      id: "Yearly",
      value: "Yearly",
      label: "Yearly",
    },
  ],
  items: [
    {
      value: "1",
      label: "HSA Plan Sponsorship Type 1",
      subLabel: "Fixed Value, No Carry Over",
    },
    {
      value: "2",
      label: "HSA Plan Sponsorship Type 2",
      subLabel: "Fixed Value, Balance Carried Over to Next Annual Season",
    },
    {
      value: "3",
      label: "HSA Plan Sponsorship Type 3",
      subLabel: "Fixed Value Claims Forward",
    },
  ],
  items2: [
    {
      value: "1",
      label: "HSA Plan Sponsorship Type 1",
      subLabel: "Fixed Value, No Carry Over",
    },
    {
      value: "2",
      label: "HSA Plan Sponsorship Type 2",
      subLabel: "Fixed Value, Balance Carried Over to Next Annual Season",
    },
  ],
  textCardList: [
    {
      id: 1,
      label: "1 year",
    },
    {
      id: 2,
      label: "2 years",
    },
    {
      id: 3,
      label: "3 years",
    },
  ],
  classList: [
    {
      id: 1,
      label: "Class One",
      value: "Class 1",
    },
    {
      id: 2,
      label: "Class Two",
      value: "Class 2",
    },
    {
      id: 3,
      label: "Class Three",
      value: "Class 3",
    },
    {
      id: 4,
      label: "Class Four",
      value: "Class 4",
    },
    {
      id: 5,
      label: "Class Five",
      value: "Class 5",
    },
  ],
  inputItems: [
    {
      id: 1,
      name: "1year",
      placeholder: "1 year",
      extrastyle: "small",
    },
    {
      id: 2,
      name: "2years",
      placeholder: "2 years",
      extrastyle: "small-with-margin",
    },
    {
      id: 3,
      name: "3years",
      placeholder: "3 years",
      extrastyle: "small-with-margin",
    },
    {
      id: 4,
      name: "4years",
      placeholder: "4 years",
      extrastyle: "small-with-margin",
    },
    {
      id: 5,
      name: "5years",
      placeholder: "5 years",
      extrastyle: "small-with-margin",
    },
  ],
  transactionReceipts: [
    {
      id: 1,
      label: "30 days",
    },
    {
      id: 2,
      label: "60 days",
    },
    {
      id: 3,
      label: "90 days",
    },
  ],
  actionableSteps: [
    {
      id: "Do Nothing",
      label: "Do Nothing",
      subLabel: "Do Nothing",
    },
    {
      id: "Do Not Reclaim Funds",
      label: "Suspend Account",
      subLabel: "Do Not Reclaim Funds",
    },
    {
      id: "Reclaim Funds",
      label: "Suspend Account",
      subLabel: "Reclaim Funds",
    },
    {
      id: "After Reclaim Funds, Terminate Account",
      label: "Terminate Account",
      subLabel: "",
    },
  ],
  paymentList: [
    {
      id: "Paperless",
      label: "Paperless Billing",
      subLabel: "PDF File",
    },
    {
      id: "Paper",
      label: "Paper Invoice",
      subLabel: "Billable Expense at $19.99/invoice",
    },
  ],
  languageList: [
    {
      id: "English",
      label: "English",
    },
    {
      id: "French",
      label: "French",
      subLabel: "Coming Soon",
      disabled: true,
    },
    {
      id: "Spanish",
      label: "Spanish",
      subLabel: "Coming Soon",
      disabled: true,
    },
  ],
  ClassOne: "Class One",
  ClassTwo: "Class Two",
  ClassThree: "Class Three",
  ClassFour: "Class Four",
  ClassFive: "Class Five",
  ClassOneValue: "Class 1",
  ClassTwoValue: "Class 2",
  ClassThreeValue: "Class 3",
  ClassFourValue: "Class 4",
  ClassFiveValue: "Class 5",
  CompanyIncorporatedList: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  StartOnSameDate: [
    { value: true, label: "Yes" },
    { value: false, label: "No, Year 2 Start Date Will Be Different" },
  ],
  PlanChangeNotificationPeriodList: [
    {
      id: 30,
      label: "30 Days Prior to Plan Year Renewal",
    },
    {
      id: 60,
      label: "60 Days Prior to Plan Year Renewal",
    },
  ],
  DistributionScheduleList: [
    {
      id: "Monthly",
      value: "Monthly",
      label: "Monthly",
    },
    {
      id: "Quarterly",
      value: "Quarterly",
      label: "Quarterly",
    },
    {
      id: "Yearly",
      value: "Yearly",
      label: "Yearly",
    },
  ],
  DayOfInvoicingList: [
    {
      id: 1,
      label: "1st Day of the Month",
    },
    {
      id: 2,
      label: "15th Day of the Month",
    },
  ],
  CentralBillList: [
    {
      id: "Direct",
      label: "Direct",
    },
    {
      id: "Broker",
      label: "Broker",
    },
    {
      id: "TPA",
      label: "TPA",
    },
  ],
  LastDayToSubmitInYearList: [
    {
      id: "30",
      value: "30",
      label: "Yes, allow 30 days post-termination",
    },
    // {
    //   id: "60",
    //   value: "60",
    //   label: "60 Days",
    // },
    // {
    //   id: "90",
    //   value: "90",
    //   label: "90 Days",
    // },
    {
      id: "0",
      value: "0",
      label: "No, revoke access  upon termination",
    },
  ],
  LastDayToSubmitAfterTerminationList: [
    {
      id: "30",
      value: "30",
      label: "Yes, allow 30 days post-plan year",
    },
    // {
    //   id: "60",
    //   value: "60",
    //   label: "60 Days",
    // },
    // {
    //   id: "90",
    //   value: "90",
    //   label: "90 Days",
    // },
    {
      id: "0",
      value: "0",
      label: "No, only allow submissions under the new plan year",
    },
  ],
  AllowDependentsList: [
    {
      id: true,
      value: true,
      label: "Yes",
    },
    {
      id: false,
      value: false,
      label: "No",
    },
  ],
  KnowWhiteLabellingList: [
    {
      id: true,
      value: true,
      label: "Yes",
    },
    {
      id: false,
      value: false,
      label: "No",
    },
  ],
  PreviousBenefits: [
    {
      id: true,
      value: true,
      label: "Yes",
    },
    {
      id: false,
      value: false,
      label: "No",
    },
  ],
  ChargePEPMList: [
    {
      id: true,
      value: true,
      label: "Yes",
    },
    {
      id: false,
      value: false,
      label: "No",
    },
  ],
  PepmFrequencyList: [
    {
      id: "Monthly",
      value: "Monthly",
      label: "No, Invoice Me Monthly",
    },
    {
      id: "Yearly",
      value: "Yearly",
      label: "Yes, Invoice Me Yearly",
    },
  ],
  PlanAdministratorsCanAddEmployeesList: [
    {
      id: true,
      label: "Yes, directly",
    },
    {
      id: false,
      label: "No, through TPA",
    },
  ],
  ApplyThroughBrokerageHouseList: [
    {
      id: true,
      value: true,
      label: "Yes",
    },
    {
      id: false,
      value: false,
      label: "No",
    },
  ],
  OtherPrograms: [
    {
      value: "League",
      label: "League",
    },
    {
      value: "MyHSA",
      label: "MyHSA",
    },
    {
      value: "Olympia",
      label: "Olympia",
    },
    {
      value: 0,
      label: "Other",
    },
  ],
  PlanTypeList: [
    {
      id: "Type 1",
      value: "Type 1",
      label: "Type 1 - No Carry Over",
      tooltipMessage: "Unused funds go back to employer at end of plan year",
    },
    {
      id: "Type 2",
      value: "Type 2",
      label: "Type 2 - Carry Over",
      tooltipMessage:
        "Unused funds are rolled over once for the next plan year",
    },
  ],
  ContributionAmountTypeList: [
    {
      id: "fixed",
      value: "fixed",
      label: "Fixed Amount",
    },
    {
      id: "percentage",
      value: "percentage",
      label: "Percentage of Base Salary (PBS)",
    },
  ],
  PlanTypeList2: [
    {
      value: "Type 1",
      label: "Type 1 - Fixed Value, No Carry Over",
      tooltipMessage: "Unused funds go back to employer at end of plan year",
    },
    {
      value: "Type 2",
      label: "Type 2 - Fixed Value, Carry Over",
      tooltipMessage: "Unused funds are rolled over for next year",
    },
  ],
  PaymentMethodList: [
    {
      value: "Cheque",
      label: "Cheque",
    },
    {
      value: "Wire",
      label: "Wire",
    },
    {
      value: "PAD",
      label: "PAD (Pre-authorized debit)",
    },
    {
      value: "Bill Pay",
      label: "Bill Pay",
    },
  ],
  WaitingPeriodList: [
    {
      value: "0",
      label: "0 month",
    },
    {
      value: "1",
      label: "1 month",
    },
    {
      value: "2",
      label: "2 months",
    },
    {
      value: "3",
      label: "3 months",
    },
    {
      value: "4",
      label: "4 months",
    },
    {
      value: "5",
      label: "5 months",
    },
    {
      value: "6",
      label: "6 months",
    },
    {
      value: "7",
      label: "7 months",
    },
    {
      value: "8",
      label: "8 months",
    },
    {
      value: "9",
      label: "9 months",
    },
    {
      value: "10",
      label: "10 months",
    },
    {
      value: "11",
      label: "11 months",
    },
    {
      value: "12",
      label: "12 months",
    },
  ],
  Countries: [
    {
      value: "CA",
      label: "Canada",
    },
  ],
  GovermentIDOptions: [
    {
      label: "Canadian Passport",
      value: "canpass",
    },
    {
      label: "Canadian Drivers License",
      value: "candrlic",
    },
    {
      label: "International Passport",
      value: "intpass",
    },
    {
      label: "International Drivers License",
      value: "intdrlic",
    },
  ],
  BusinessOperatedInCAList: [
    {
      label: "Yes, Registered",
      value: "Yes, Registered",
    },
    {
      label: "Yes, Via PEO (or equivalent)",
      value: "Yes, Via PEO (or equivalent)",
    },
    {
      label: "No, USA Registered",
      value: "No, USA Registered",
    },
    {
      label: "No, International (Non-USA)",
      value: "No, International (Non-USA)",
    },
  ],
  CompanyTypeList: [
    {
      label: "Partnership",
      value: "Partnership",
    },
    {
      label: "Limited Liability",
      value: "Limited Liability",
    },
    {
      label: "Not For Profit",
      value: "Not For Profit",
    },
    {
      label: "Corporation",
      value: "Corporation",
    },
    {
      label: "Trust",
      value: "Trust",
    },
    {
      label: "Sole Propietor (with CRA RT Number)",
      value: "Sole Propietor (with CRA RT Number)",
    },
  ],
  CorporateNumberTypeList: [
    {
      label: "Federal",
      value: "Federal",
    },
    {
      label: "Provincial",
      value: "Provincial",
    },
  ],
  EmployeeDropdownList: [
    {
      label: "1-5",
      value: "1-5",
    },
    {
      label: "6-50",
      value: "6-50",
    },
    {
      label: "51-500",
      value: "51-500",
    },
    {
      label: "over 500",
      value: "over 500",
    },
  ],
  ExchangeDropdownList: [
    {
      label: "CNQ",
      value: "CNQ",
    },
    {
      label: "ICE",
      value: "ICE",
    },
    {
      label: "Montreal Exchange",
      value: "Montreal Exchange",
    },
    {
      label: "NASDAQ Canada",
      value: "NASDAQ Canada",
    },
    {
      label: "NGX",
      value: "NGX",
    },
    {
      label: "TSX",
      value: "TSX",
    },
  ],
  ProvinceList: [
    { label: "Alberta", value: "Alberta" },
    { label: "British Columbia", value: "British Columbia" },
    { label: "Manitoba", value: "Manitoba" },
    { label: "New Brunswick", value: "New Brunswick" },
    { label: "North West Territories", value: "North West Territories" },
    { label: "Nova Scotia", value: "Nova Scotia" },
    { label: "Nunavut", value: "Nunavut" },
    { label: "Ontario", value: "Ontario" },
    { label: "Prince Edward Island", value: "Prince Edward Island" },
    { label: "Quebec", value: "Quebec" },
    { label: "Saskatchewan", value: "Saskatchewan" },
    { label: "Yukon", value: "Yukon" },
  ],
  SourceDocumentCorporationList: [
    {
      label: "Article of Incorporation",
      value: "Article of Incorporation",
    },
    {
      label: "Corporate Certificate of Corporate Status",
      value: "Corporate Certificate of Corporate Status",
    },
    {
      label: "Notice of Assessment from Government Entity",
      value: "Notice of Assessment from Government Entity",
    },
    {
      label: "GST Return Submission Form",
      value: "GST Return Submission Form",
    },
  ],
  SourceDocumentNonCorporationList: [
    {
      label: "GST Return Submission Form",
      value: "GST Return Submission Form",
    },
    {
      label: "Government Issued Photo ID",
      value: "Government Issued Photo ID",
    },
  ],
  ShipToList: [
    {
      label: "Always Ship direct to plan member",
      value: "Always Ship direct to plan member",
    },
    // {
    //   label: "Always Ship to Plan Administrator (i.e Office)",
    //   value: "Always Ship to Plan Administrator (i.e Office)",
    // },
    // {
    //   label: "Ship 1st batch of cards to office, then ship to plan member",
    //   value: "Ship 1st batch of cards to office",
    // },
  ],
  FATCAList: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  PEPList: [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ],
  BeneficialOwnersList: [
    { value: 1, label: "Yes, the company has multiple Beneficial Owners" },
    {
      value: 2,
      label: "No, the company does not have multiple Beneficial Owners",
    },
  ],
  DirectorsList: [
    { value: 1, label: "Yes, the company has multiple Directors" },
    { value: 2, label: "No, the company does not have multiple Directors" },
  ],
  AuthorizedSignersList: [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ],
  BankAccountTypeList: [
    {
      id: "businessChecking",
      value: "businessChecking",
      label: "Business",
      subLabel: "Checking",
      dropdownLabel: "Business Checking",
    },
    {
      id: "businessSavings",
      value: "businessSavings",
      label: "Business",
      subLabel: "Savings",
      dropdownLabel: "Business Savings",
    },
    {
      id: "personalChecking",
      value: "personalChecking",
      label: "Personal",
      subLabel: "Checking",
      dropdownLabel: "Personal Checking",
    },
    {
      id: "personalSavings",
      value: "personalSavings",
      label: "Personal",
      subLabel: "Savings",
      dropdownLabel: "Personal Savings",
    },
  ],
  BankAccountTypeList2: [
    {
      id: "businessChecking",
      value: "businessChecking",
      label: "Business Checking",
    },
    {
      id: "businessSavings",
      value: "businessSavings",
      label: "Business Savings",
    },
    {
      id: "personalChecking",
      value: "personalChecking",
      label: "Personal Checking",
    },
    {
      id: "personalSavings",
      value: "personalSavings",
      label: "Personal Savings",
    },
  ],
  BankPaymentMethods: [
    {
      value: "pad",
      label: "Pre-Authorized Debit",
      subLabel: "10 business days, no additional fees incurred",
    },
    // {
    //   value: "wire",
    //   label: "Wire Transfer",
    //   subLabel: "3 business days, company incurs banking fees",
    // },
    // {
    //   value: "billPay",
    //   label: "Bill Payment",
    //   subLabel:
    //     "Currently available at Scotiabank, RBC, and BMO: 7-10 business days, no additional fees incurred",
    // },
    // {
    //   value: "cheque",
    //   label: "Cheque",
    //   subLabel: "Usually has a 15 business day posting period",
    // },
    {
      value: "wire",
      label: "Electronic Funds Transfer",
      subLabel: "",
    },
  ],
  ProgramTypeList: [
    {
      label: "HSA",
      value: "hsa",
      id: "hsa",
    },
    {
      label: "WSA",
      value: "wsa",
      id: "wsa",
    },
    {
      label: "HSA + WSA",
      value: "hsa+wsa",
      id: "hsa+wsa",
    },
  ],
  ProgramPaymentTypeList: [
    {
      label: "Prepayment Card Use",
      value: "prepaymentmastercard",
      id: "prepaymentmastercard",
    },
    {
      label: "Postpayment",
      value: "postpayment",
      // subLabel: "Invoice company after claim is approved",
      id: "postpayment",
    },
  ],
  ProrateYear1List: [
    {
      id: true,
      value: true,
      label: "Yes",
    },
    {
      id: false,
      value: false,
      label: "No",
    },
  ],
  purseTypeList: [
    {
      id: "shared",
      value: "shared",
      label: "Consolidated",
    },
    {
      id: "split",
      value: "split",
      label: "Split",
    },
  ],
  ProrateAfterGivenYearList: [
    {
      id: true,
      value: true,
      label: "Yes",
    },
    {
      id: false,
      value: false,
      label: "No",
    },
  ],
  ActionAfterMastercardClaimDeclineList: [
    {
      label:
        "Employee to be notified directly for repayment of funds into their account",
      value: "notifyEmployeeToRepay",
      id: "notifyEmployeeToRepay",
    },
    {
      label:
        "Employer to receive a statement of Other Taxable Allowances and Benefits",
      value: "statementToEmployer",
      id: "statementToEmployer",
    },
  ],
  PostpaymentFundsPullingRateList: [
    {
      label: "On Demand",
      value: "asRequired",
      id: "asRequired",
    },
    {
      label: "Twice a week",
      value: "twiceAWeek",
      id: "twiceAWeek",
    },
    {
      label: "Twice a month",
      value: "twiceAMonth",
      id: "twiceAMonth",
    },
  ],
  PostpaymentFundsFloatOrDepositList: [
    {
      id: true,
      value: true,
      label: "Yes",
    },
    {
      id: false,
      value: false,
      label: "No",
    },
  ],
  DefaultGuid: "00000000-0000-0000-0000-000000000000",
  PreAuthorizedDebitAgreement: "Pre Authorization Debit Agreements",
  ADMINISTRATION_FEE: 7.5,
  PEPM_FEE: 10.0,
  CLAIM_PROCESSING_FEE: 7.5,
  CLAIM_PROCESSING_FEE_POSTPAY: 15.0,
  COMPANY_ONBOARDING_FEES: 0,
  MONTHLY_EMPLOYEE_FEE_TIER_1: 10.0,
  MONTHLY_EMPLOYEE_FEE_TIER_2: 5.0,
  MONTHLY_EMPLOYEE_MINIMUM_FEE: 50.0,
  SETUP_FEE: 0,
  EMPLOYEE_SETUP_FEE: 0,
  CARD_ISSUANCE_FEE: 10.0,
  EMPLOYEE_MONTHLY_FEE: 0.0,
  EARLY_TERMINATION_FEE: 0,
  MASTERCARD_TRANSACTION_IN_CANADA: 0,
  FOREIGN_EXCHANGE_FEE: 3,
  OUT_OF_POCKET_ETF: 2.99,
  EMPLOYEE_OFFBOARD_FEE: 0,
  MINIMUM_BALANCE: 0,
  MAXIMUM_BALANCE: 0,
  PEPM_FREQUENCY: "Monthly",
  steps: [
    {
      indicator: 1,
      title: "Plan",
      step: "FULL-STEP_TWO-COMPANY",
    },
    {
      indicator: 2,
      title: "Corporate",
      step: "FULL-STEP_THREE-COMPANY",
    },
    {
      indicator: 3,
      title: "Contacts",
      step: "FULL-STEP_FOUR-COMPANY",
    },
    {
      indicator: 4,
      title: "Banking",
      step: "FULL-STEP_FIVE-COMPANY",
    },
    {
      indicator: 5,
      title: "Review",
      step: "FULL-STEP_SIX-COMPANY",
    },
  ],
};
