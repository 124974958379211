//COMPANY
export const QUICK__STEP_ONE__COMPANY = "QUICK-STEP_ONE-COMPANY";
export const QUICK__STEP_TWO__COMPANY = "QUICK-STEP_TWO-COMPANY";
export const QUICK__STEP_THREE__COMPANY = "QUICK-STEP_THREE-COMPANY";
export const QUICK__STEP_FOUR__COMPANY = "QUICK-STEP_FOUR-COMPANY";
export const FULL__STEP_ONE__COMPANY = "FULL-STEP_ONE-COMPANY";
export const FULL__STEP_TWO__COMPANY = "FULL-STEP_TWO-COMPANY";
export const FULL__STEP_THREE__COMPANY = "FULL-STEP_THREE-COMPANY";
export const FULL__STEP_FOUR__COMPANY = "FULL-STEP_FOUR-COMPANY";
export const FULL__STEP_FIVE__COMPANY = "FULL-STEP_FIVE-COMPANY";
export const FULL__STEP_SIX__COMPANY = "FULL-STEP_SIX-COMPANY";
export const FULL__STEP_SEVEN__COMPANY = "FULL-STEP_SEVEN-COMPANY";
export const ONBOARDING__STEP_ONE__COMPANY = "ONBOARDING-STEP_ONE-COMPANY";
export const ONBOARDING__STEP_TWO__COMPANY = "ONBOARDING-STEP_TWO-COMPANY";
export const ONBOARDING__STEP_THREE__COMPANY = "ONBOARDING-STEP_THREE-COMPANY";
export const ONBOARDING__STEP_FOUR__COMPANY = "ONBOARDING-STEP_FOUR-COMPANY";
export const ONBOARDING__STEP_FIVE__COMPANY = "ONBOARDING-STEP_FIVE-COMPANY";
export const ONBOARDING__STEP_SIX__COMPANY = "ONBOARDING-STEP_SIX-COMPANY";
export const ONBOARDING__STEP_SEVEN__COMPANY = "ONBOARDING-STEP_SEVEN-COMPANY";
export const COMPLETE__STEP_SEVEN__COMPANY = "COMPLETE-STEP_SEVEN-COMPANY";
//EMPLOYEE
export const QUICK__STEP_ONE__EMPLOYEE = "QUICK-STEP_ONE-EMPLOYEE";
export const QUICK__STEP_TWO__EMPLOYEE = "QUICK-STEP_TWO-EMPLOYEE";
export const FULL__STEP_ONE__EMPLOYEE = "FULL-STEP_ONE-EMPLOYEE";
export const FULL__STEP_TWO__EMPLOYEE = "FULL-STEP_TWO-EMPLOYEE";
export const FULL__STEP_THREE__EMPLOYEE = "FULL-STEP_THREE-EMPLOYEE";
export const FULL__STEP_FOUR__EMPLOYEE = "FULL-STEP_FOUR-EMPLOYEE";
export const ONBOARDING__STEP_ONE__EMPLOYEE = "ONBOARDING-STEP_ONE-EMPLOYEE";
export const ONBOARDING__STEP_TWO__EMPLOYEE = "ONBOARDING-STEP_TWO-EMPLOYEE";
export const ONBOARDING__STEP_THREE__EMPLOYEE =
  "ONBOARDING-STEP_THREE-EMPLOYEE";
export const COMPLETE__STEP_FOUR__EMPLOYEE = "COMPLETE-STEP_FOUR-EMPLOYEE";
// BHA
export const QUICK__STEP_ZERO__BHA = "QUICK_STEP-ZERO-BHA";
export const FULL__STEP_ONE__BHA = "FULL-STEP_ONE-BHA";
export const FULL__STEP_TWO__BHA = "FULL-STEP_TWO-BHA";
export const FULL__STEP_THREE__BHA = "FULL-STEP_THREE-BHA";
export const FULL__STEP_FOUR__BHA = "FULL-STEP_FOUR-BHA";
export const FULL__STEP_FIVE__BHA = "FULL-STEP_FIVE-BHA";
export const FULL__STEP_SIX__BHA = "FULL-STEP_SIX-BHA";
export const QUICK__STEP_ONE__BHA = "QUICK-STEP_ONE-BHA";
export const QUICK__STEP_TWO__BHA = "QUICK-STEP_TWO-BHA";
export const ONBOARDING__STEP_TWO__BHA = "ONBOARDING-STEP_TWO-BHA";
export const ONBOARDING__STEP_THREE__BHA = "ONBOARDING-STEP_THREE-BHA";
export const ONBOARDING__STEP_FOUR__BHA = "ONBOARDING-STEP_FOUR-BHA";
export const ONBOARDING__STEP_FIVE__BHA = "ONBOARDING-STEP_FIVE-BHA";
export const ONBOARDING__STEP_SIX__BHA = "ONBOARDING-STEP_SIX-BHA";
export const COMPLETE__STEP_SEVEN__BHA = "COMPLETE-STEP_SEVEN-BHA";
