export const INDUSTRIES = [
  {
    classificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Arts, Crafts, & Collectables",
  },
  {
    classificationguid: "1bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Baby",
  },
  {
    classificationguid: "1cc7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Beauty and Fragrances",
  },
  {
    classificationguid: "1dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Books and Magazines",
  },
  {
    classificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Business to Business",
  },
  {
    classificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Clothing, Accessories, and Shoes",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Computers, Accessories, and Services",
    classificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
  },
  {
    classificationguid: "21c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Education",
  },
  {
    classificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Electronics and Telecom",
  },
  {
    classificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Entertainment and Media",
  },
  {
    classificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Financial Services and Products",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Food Retail and Service",
    classificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
  },
  {
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Gift and Flowers",
    classificationguid: "26c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "27c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Government",
  },
  {
    classificationname: "Health and Personal Care",
    classificationguid: "28c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Home and Garden",
  },
  {
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Manufacturing",
    classificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "2bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Nonprofit",
  },
  {
    classificationguid: "2cc7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Pets and Animals",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Religion and Spirituality (for Profit)",
    classificationguid: "2dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
  },
  {
    classificationguid: "2ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Retail",
  },
  {
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Services - Other",
    classificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Sports and Outdoors",
    classificationguid: "30c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Toys and Hobbies",
    classificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
  },
  {
    classificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Travel",
  },
  {
    classificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Vehicle Sales",
  },
  {
    classificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    parentclassificationguid: "00000000-0000-0000-0000-000000000000",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Vehicle Service and Accessories",
  },
  {
    classificationguid: "61fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Antiques",
  },
  {
    classificationguid: "62fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Arts and craft supplies",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Art dealers and galleries",
    classificationguid: "63fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "64fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Camera and photographic supplies",
  },
  {
    classificationguid: "65fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Digital art",
  },
  {
    classificationguid: "66fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Memorabilia",
  },
  {
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Music store - instruments and sheet music",
    classificationguid: "67fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Sewing, needlework, and fabrics",
    classificationguid: "68fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "69fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Stamp and coin",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Stationary, printing and writing paper",
    classificationguid: "6afcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationname: "Vintage and collectibles",
    classificationguid: "6bfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    parentclassificationguid: "1bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Children's and baby's clothing",
    classificationguid: "6cfcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "6dfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Furniture",
  },
  {
    classificationguid: "6efcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Safety and health",
  },
  {
    classificationguid: "6ffcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1cc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Bath and body",
  },
  {
    classificationguid: "70fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1cc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Fragrances and perfumes",
  },
  {
    parentclassificationguid: "1cc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Makeup and Cosmetics",
    classificationguid: "71fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "72fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Audiobooks",
  },
  {
    classificationguid: "73fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Bookstore",
  },
  {
    parentclassificationguid: "1dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Magazines",
    classificationguid: "74fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "75fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Printing",
  },
  {
    classificationname: "Publishing",
    classificationguid: "76fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationname: "Rare and used books",
    classificationguid: "77fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "78fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Accounting",
  },
  {
    classificationguid: "79fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Advertising and public relations",
  },
  {
    classificationguid: "7afcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Agriculture - animal production and aquaculture",
  },
  {
    classificationguid: "7bfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Agriculture - fishing, hunting, and trapping",
  },
  {
    classificationguid: "7cfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Agriculture - forestry and logging",
  },
  {
    classificationguid: "7dfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Agriculture - fruit, vegetable, and crop production",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Architectural, engineering, and surveying services",
    classificationguid: "7efcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "7ffcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Career services",
  },
  {
    classificationguid: "80fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Chemical and allied products",
  },
  {
    classificationguid: "81fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Commercial and industrial ods rental",
  },
  {
    classificationguid: "82fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Commercial and industrial ods repair and maintenance",
  },
  {
    classificationguid: "83fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Commercial photography, art and graphics",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Construction - heavy and civil engineering",
    classificationguid: "84fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "85fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Construction - nonresidential building",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Construction - residential building",
    classificationguid: "86fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "87fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Construction - speciality trade contractors",
  },
  {
    classificationguid: "88fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Consulting services",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Consumer ods rental",
    classificationguid: "89fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Consumer ods repair and maintenance",
    classificationguid: "8afcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Educational services",
    classificationguid: "8bfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "8cfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Electronic repair and maintenance",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Industrial and manufacturing supplies",
    classificationguid: "8dfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Mailing lists",
    classificationguid: "8efcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "8ffcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Marketing",
  },
  {
    classificationname: "Multi-level marketing",
    classificationguid: "90fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "91fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Office and commercial furniture",
  },
  {
    classificationguid: "92fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Office supplies and equipment",
  },
  {
    classificationname: "Printing",
    classificationguid: "93fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Publishing",
    classificationguid: "94fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "95fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Quick copy and reproduction services",
  },
  {
    classificationguid: "96fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Shipping and packing",
  },
  {
    classificationguid: "97fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Stenographic and secretarial support services",
  },
  {
    classificationname: "Wholesale - durable ods",
    classificationguid: "98fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "99fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Wholesale - electronic markets and agents and brokers",
  },
  {
    parentclassificationguid: "1ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Wholesale - nondurable ods",
    classificationguid: "9afcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "9bfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Accessories",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Children's and baby's clothing",
    classificationguid: "9cfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "9dfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Fashion jewelry",
  },
  {
    classificationguid: "9efcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Men's clothing",
  },
  {
    classificationname: "Military and civil service uniforms",
    classificationguid: "9ffcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "a0fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Retail - fine jewelry and watches",
  },
  {
    classificationguid: "a1fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Shoes",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Wholesale - precious stones and metals",
    classificationguid: "a2fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Women's clothing",
    classificationguid: "a3fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "1fc7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "a4fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Computer and data processing services",
  },
  {
    classificationguid: "a5fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Desktops, laptops, and notebooks",
  },
  {
    classificationguid: "a6fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Maintenance and repair services",
  },
  {
    classificationname: "Monitors and projectors",
    classificationguid: "a7fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "a8fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Networking",
  },
  {
    classificationguid: "a9fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Online gaming",
  },
  {
    classificationguid: "aafcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Parts and accessories",
  },
  {
    classificationguid: "abfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Peripherals",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Software",
    classificationguid: "acfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "adfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Training services",
  },
  {
    classificationguid: "aefcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Web hosting and design",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "eCommerce services",
    classificationguid: "affcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "20c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "b0fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "21c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Business and secretarial schools",
  },
  {
    parentclassificationguid: "21c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Child care services",
    classificationguid: "b1fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "21c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Colleges and universities",
    classificationguid: "b2fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "b3fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "21c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Dance halls, studios, and school",
  },
  {
    classificationguid: "b4fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "21c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Elementary and secondary schools",
  },
  {
    classificationguid: "b5fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "21c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Vocational and trade schools",
  },
  {
    classificationguid: "b6fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Cameras, camcorders and equipment",
  },
  {
    classificationguid: "b7fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Car audio and electronics",
  },
  {
    classificationguid: "b8fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Cell phones,PDAS, and pagers",
  },
  {
    classificationguid: "b9fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "General electronic accessories",
  },
  {
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Home audio",
    classificationguid: "bafcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Home electronics",
    classificationguid: "bbfcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "bcfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Investigation and security services",
  },
  {
    classificationguid: "bdfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Telecommunication equipment services",
  },
  {
    classificationguid: "befcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Telecommunication service",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Telephone cards",
    classificationguid: "bffcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "22c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "c0fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Adult digital content",
  },
  {
    classificationguid: "c1fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Cable and other subscription programming",
  },
  {
    classificationguid: "c2fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname:
      "Cable, satelite, and other pay TV and radio broadcasting",
  },
  {
    classificationguid: "c3fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Concert tickets",
  },
  {
    classificationname: "Digital content",
    classificationguid: "c4fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "c5fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Entertainers",
  },
  {
    classificationguid: "c6fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Gambling",
  },
  {
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Memorabilia",
    classificationguid: "c7fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "c8fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Motion picture and video",
  },
  {
    classificationname: "Movie store - DVDs, videotapes",
    classificationguid: "c9fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Music",
    classificationguid: "cafcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Music store - CDs, videotapes",
    classificationguid: "cbfcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "ccfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Online gaming",
  },
  {
    classificationguid: "cdfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Slot machines",
  },
  {
    classificationname: "Theater tickets",
    classificationguid: "cefcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "cffcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Toys and games",
  },
  {
    classificationguid: "d0fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "23c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Video games and systems",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Accounting ",
    classificationguid: "d1fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Collection Agency",
    classificationguid: "d2fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "d3fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Commercial Banking",
  },
  {
    classificationguid: "d4fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Commodity contracts brokerage",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Commodity contracts dealing",
    classificationguid: "d5fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "d6fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Consumer lending",
  },
  {
    classificationguid: "d7fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Credit bureaus",
  },
  {
    classificationguid: "d8fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Credit union",
  },
  {
    classificationguid: "d9fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Debt counseling service",
  },
  {
    classificationguid: "dafcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Escrow",
  },
  {
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Financial and investment advice",
    classificationguid: "dbfcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname:
      "Financial transactions processing, reserve, and clearinghouse activities",
    classificationguid: "dcfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Insurance - auto and home",
    classificationguid: "ddfcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "defcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Insurance - life and annuity",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Investment banking and securities dealing",
    classificationguid: "dffcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "e0fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Miscellaneous financial investment activities",
  },
  {
    classificationguid: "e1fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Mortgage brokers and dealers",
  },
  {
    classificationguid: "e2fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Offices of other holding companies",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Online gaming currency",
    classificationguid: "e3fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "e4fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Other activities related to credit intermediation",
  },
  {
    classificationguid: "e5fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Other financial investment activities",
  },
  {
    classificationguid: "e6fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "24c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Other investment pools and funds",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Alcoholic beverage drinking places",
    classificationguid: "e7fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "e8fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Beer, wine,and liquor store",
  },
  {
    classificationguid: "e9fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Breweries",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Catering Services",
    classificationguid: "eafcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Coffee and tea",
    classificationguid: "ebfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "ecfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Distilleries",
  },
  {
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "urmet foods",
    classificationguid: "edfcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationname: "Marijuana dispensaries",
    classificationguid: "eefcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationname: "Pharmacy and drugstore",
    classificationguid: "effcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Restaurant",
    classificationguid: "f0fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Speciality and miscellaneous food store",
    classificationguid: "f1fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "f2fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Supplement store",
  },
  {
    classificationguid: "f3fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Tobacco ",
  },
  {
    classificationguid: "f4fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "25c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Wineries",
  },
  {
    classificationguid: "f5fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "26c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Florist",
  },
  {
    classificationguid: "f6fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "26c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Gift, card, novelty, and souvenir shops",
  },
  {
    classificationguid: "f7fcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "26c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "urmet foods",
  },
  {
    parentclassificationguid: "26c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Nursery plants and flowers",
    classificationguid: "f8fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "26c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Party supplies",
    classificationguid: "f9fcc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "fafcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "27c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Administration and environmental quality programs",
  },
  {
    classificationguid: "fbfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "27c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Administration of economic programs",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname:
      "Administration of housing programs, urban planning, and community development",
    classificationguid: "fcfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "27c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "fdfcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "27c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Administration of human resource programs",
  },
  {
    classificationguid: "fefcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "27c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname:
      "Executive. legislative, and other general government support",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Justice, public order, and safety activities",
    classificationguid: "fffcc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "27c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "00fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "27c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "National security and international affairs",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Space research and technology",
    classificationguid: "01fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "27c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "02fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "28c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Dental care",
  },
  {
    classificationguid: "03fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "28c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Medical care",
  },
  {
    classificationname: "Medical equipment and supplies",
    classificationguid: "04fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "28c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "05fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "28c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Pharmacy and drugstore",
  },
  {
    classificationguid: "06fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "28c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Vision care",
  },
  {
    classificationguid: "07fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "28c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Vitamins and supplements",
  },
  {
    classificationguid: "08fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Antiques",
  },
  {
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Appliances",
    classificationguid: "09fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationname: "Art dealer and galleries",
    classificationguid: "0afdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Construction material ",
    classificationguid: "0bfdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationname: "Drapery, window covering, and upholstery",
    classificationguid: "0cfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Exterminating and disinfecting services",
    classificationguid: "0dfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "0efdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Furniture",
  },
  {
    classificationguid: "0ffdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Glass, paint, and wallpaper",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Hardware and tools",
    classificationguid: "10fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "11fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Home furnishings store",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Lawn and garden equipment and supplies",
    classificationguid: "12fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "13fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "29c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Rugs and carpets",
  },
  {
    classificationguid: "14fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Apparel manufacturing",
  },
  {
    classificationguid: "15fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Beverage and tobacco product manufacturing",
  },
  {
    classificationguid: "16fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Chemical manufacturing ",
  },
  {
    classificationguid: "17fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Computer and electronic product manufacturing",
  },
  {
    classificationguid: "18fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname:
      "Electric equipment, appliance, and component manufacturing",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Fabricated metal products",
    classificationguid: "19fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "1afdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Food manufacturing",
  },
  {
    classificationguid: "1bfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Furniture and related product manufacturing",
  },
  {
    classificationname: "Leather and allied product manufacturing",
    classificationguid: "1cfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "1dfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Machinery manufacturing",
  },
  {
    classificationguid: "1efdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Miscellaneous manufacturing",
  },
  {
    classificationguid: "1ffdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "nonmetal mineral product manufacturing",
  },
  {
    classificationguid: "20fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Paper manufacturing",
  },
  {
    classificationguid: "21fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Petroleum and coal products manufacturing",
  },
  {
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Plastics and rubber products manufacturing",
    classificationguid: "22fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Primary and related support activities",
    classificationguid: "23fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Textile mills",
    classificationguid: "24fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Textile product mills",
    classificationguid: "25fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Transportation equipment manufacturing",
    classificationguid: "26fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "27fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ac7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Wood product manufacturing",
  },
  {
    parentclassificationguid: "2bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Charity",
    classificationguid: "28fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "2bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Educational",
    classificationguid: "29fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "2afdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Other",
  },
  {
    parentclassificationguid: "2bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Personal",
    classificationguid: "2bfdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationname: "Politics",
    classificationguid: "2cfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationname: "Religious",
    classificationguid: "2dfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2bc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    parentclassificationguid: "2cc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Medication and supplements",
    classificationguid: "2efdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "2ffdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2cc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Pet shops, pet food, and supplies",
  },
  {
    classificationguid: "30fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2cc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Specialty and rare pets",
  },
  {
    classificationguid: "31fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2cc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Veterinary services",
  },
  {
    classificationguid: "32fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Member services ",
  },
  {
    classificationguid: "33fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2dc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Services not elsewhere classified",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Department Store",
    classificationguid: "34fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ec7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "35fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Discount store",
  },
  {
    classificationname: "Miscellaneous store retailer",
    classificationguid: "36fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    parentclassificationguid: "2ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Used and seconhand Store",
    classificationguid: "37fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "2ec7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Variety store",
    classificationguid: "38fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "39fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Advertising and public relations",
  },
  {
    classificationguid: "3afdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "All other personal services",
  },
  {
    classificationguid: "3bfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Career services",
  },
  {
    classificationguid: "3cfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Carpentry",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Child care services",
    classificationguid: "3dfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "3efdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Cleaning and maintenance",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Commercial and industrial ods rental ",
    classificationguid: "3ffdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "40fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Commercial photography, art, and graphics",
  },
  {
    classificationguid: "41fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Computer and data processing services",
  },
  {
    classificationguid: "42fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Computer network services",
  },
  {
    classificationguid: "43fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Construction - nonresidential building",
  },
  {
    classificationguid: "44fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Construction - residential building",
  },
  {
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Consulting services",
    classificationguid: "45fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "46fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Consumer ods rental",
  },
  {
    classificationguid: "47fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Counseling services",
  },
  {
    classificationname: "Courier services",
    classificationguid: "48fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Electronic repair and maintenance",
    classificationguid: "49fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "4afdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Entertainment",
  },
  {
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Event and wedding planning",
    classificationguid: "4bfdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationname: "Gambling",
    classificationguid: "4cfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "4dfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "2fc7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Graphic and commercial design",
  },
  {
    classificationguid: "4efdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "30c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Dance halls, studios, and schools",
  },
  {
    classificationguid: "4ffdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "30c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Fan gear and memorabilia",
  },
  {
    classificationguid: "50fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "30c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Firearms, knives, and martial arts weapons",
  },
  {
    classificationguid: "51fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "30c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Other sporting ods",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Swimming pools and spas",
    classificationguid: "52fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "30c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "53fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Arts and crafts",
  },
  {
    classificationguid: "54fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Camera and photographic supplies",
  },
  {
    classificationguid: "55fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Hobby, toy, and game shops",
  },
  {
    classificationguid: "56fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Memorabilia",
  },
  {
    classificationname: "Music store - instruments and sheet music",
    classificationguid: "57fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    parentclassificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Stamp and coin",
    classificationguid: "58fdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "59fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Stationary, printin, and writing paper",
  },
  {
    classificationguid: "5afdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Video games and systems",
  },
  {
    classificationguid: "5bfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "31c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Vintage and collectibles",
  },
  {
    classificationguid: "5cfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Airline",
  },
  {
    classificationguid: "5dfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Auto rental",
  },
  {
    classificationguid: "5efdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Bus line",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Cruises",
    classificationguid: "5ffdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "60fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Lodging and accommodations",
  },
  {
    classificationguid: "61fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Luggage and leather ods",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Recreational services",
    classificationguid: "62fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "63fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Shared services",
  },
  {
    classificationguid: "64fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Sporting and recreation camps",
  },
  {
    classificationguid: "65fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Taxicabs and limousines",
  },
  {
    classificationguid: "66fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "TImeshares",
  },
  {
    classificationguid: "67fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Tours",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Trailer parks and campgrounds",
    classificationguid: "68fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "69fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Transportation services - other",
  },
  {
    classificationguid: "6afdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "32c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Travel agency",
  },
  {
    parentclassificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Auto dealer - new and used",
    classificationguid: "6bfdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationname: "Auto dealer - used only",
    classificationguid: "6cfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "6dfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Aviation",
  },
  {
    classificationname: "Boat dealer",
    classificationguid: "6efdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "6ffdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Mobile home dealer",
  },
  {
    classificationguid: "70fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Motorcycle dealer",
  },
  {
    classificationguid: "71fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Recreational and utility trailer dealer",
  },
  {
    classificationguid: "72fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Recreational vehicle dealer",
  },
  {
    classificationname: "Vintage and collectables",
    classificationguid: "73fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "33c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "74fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Audio and video",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Auto body repair and paint",
    classificationguid: "75fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    classificationname: "Auto rental",
    classificationguid: "76fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    classificationguid: "77fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Auto service",
  },
  {
    classificationname: "Automotive tire supply and service",
    classificationguid: "78fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
  {
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Boat rental and leases",
    classificationguid: "79fdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
  },
  {
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Car wash",
    classificationguid: "7afdc77d-9ec5-e911-aa3e-02118079da7e",
  },
  {
    classificationguid: "7bfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Motorhome and recreational vehicle rental",
  },
  {
    classificationguid: "7cfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Parts, supplies, and accessories",
  },
  {
    classificationguid: "7dfdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Tools and equipment",
  },
  {
    classificationguid: "7efdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
    classificationname: "Towing service",
  },
  {
    classificationname: "Truck and utility trailer rental",
    classificationguid: "7ffdc77d-9ec5-e911-aa3e-02118079da7e",
    parentclassificationguid: "34c7a2e4-9ac5-e911-aa3e-02118079da7e",
    productguid: "05e22eef-2382-e911-8259-0274b7d8c54c",
  },
];
