// React
import { PureComponent, ReactElement, Fragment, ReactNode } from "react";
// Scss
import styles from "./Tooltip.module.scss";

// Type props
type Props = {
  message: string;
  position: number | string;
  render: () => ReactNode;
};

// Type state
type State = {
  displayTooltip: boolean;
};

/**
 * @export
 * @class Tooltip
 * @extends {PureComponent<Props, State>}
 */
export class Tooltip extends PureComponent<Props, State> {
  /**
   * Creates an instance of Tooltip.
   * @param {Props} props
   * @memberof Tooltip
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      displayTooltip: false,
    };
    this.hideTooltip = this.hideTooltip.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
  }

  /**
   * @memberof Tooltip
   */
  hideTooltip(): void {
    this.setState({ displayTooltip: false });
  }

  /**
   * @memberof Tooltip
   */
  showTooltip(): void {
    this.setState({ displayTooltip: true });
  }

  /**
   * @return {*}  {ReactElement}
   * @memberof Tooltip
   */
  render(): ReactElement {
    let { message, position, render } = this.props;
    // let positionStyle = tooltip - `${position}`;
    return (
      <span className={styles.tooltip} onMouseLeave={this.hideTooltip}>
        {this.state.displayTooltip && (
          <div
            className={`${styles.tooltipBubble} ${
              styles[`tooltip-${position}`]
            }`}
          >
            <div className={`${styles.tooltipMessage}`}>
              {render && <Fragment>{render()}</Fragment>}
              {message}
            </div>
          </div>
        )}
        <span className={styles.tooltipTrigger} onMouseOver={this.showTooltip}>
          {this.props.children}
        </span>
      </span>
    );
  }
}

// Export
export default Tooltip;
