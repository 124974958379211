// Custom functions
import { cleanUpCurrency, removePercentDecoration } from "./customFunctions";

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const required = (value: any): string | undefined =>
  value ? undefined : "Required";

/**
 * @param {*} max
 */
export const maxLength = (max: any) => (value: any) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

/**
 * @param {*} min
 */
export const minLength = (min: any) => (value: any) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const number = (value: any): string | undefined =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

/**
 * @desc percentage values
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const minPercentValue0 = (value: any): string | undefined =>
  value && removePercentDecoration(value) < 0
    ? `Cannot be less than 0%`
    : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const maxValue100 = (value: any): string | undefined =>
  value && removePercentDecoration(value) > 100
    ? `Cannot be greater than 100%`
    : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const maxPercentValue15 = (value: any): string | undefined =>
  value && removePercentDecoration(value) > 15
    ? `Cannot be greater than 15%`
    : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const minPercentValue4 = (value: any): string | undefined =>
  value && removePercentDecoration(value) < 4
    ? `Cannot be less than 4%`
    : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const minValue25 = (value: any): string | undefined =>
  value && removePercentDecoration(value) < 25
    ? `Cannot be less than 25%`
    : undefined;

/**
 * @desc currencyvalues
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const minCurrencyValue0 = (value: any): string | undefined =>
  value && cleanUpCurrency(value) < 0 ? `Cannot be less than $0` : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const minCurrencyValue5 = (value: any): string | undefined =>
  value && cleanUpCurrency(value) < 5 ? `Cannot be less than $5` : undefined;

/**
 * @param {*} min
 */
export const minValue = (min: any) => (value: any) =>
  value && value < min ? `Must be at least ${min}` : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const password = (value: any): string | undefined =>
  value && !/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,50})/i.test(value)
    ? "Include atleast one capital letter, one number and one special character"
    : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const email = (value: any): string | undefined =>
  value && !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(value)
    ? "Invalid email address"
    : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const countryNonCA = (value: any): string | undefined =>
  value && typeof value === "string" && value.toLowerCase() === "ca"
    ? "Office cannot be in Canada"
    : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const postalCodeCA = (value: any): string | undefined =>
  value && !/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/i.test(value)
    ? "Invalid CA postal code"
    : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const postalCodeUS = (value: any): string | undefined =>
  value && !/^\d{5}(?:-\d{4})?$/i.test(value)
    ? "Invalid US postal code"
    : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const tooOld = (value: any): string | undefined =>
  value && value > 65 ? "You might be too old for this" : undefined;

/**
 * @param {*} value
 * @return {*}  {(string | undefined)}
 */
export const aol = (value: any): string | undefined =>
  value && /.+@aol\.com/.test(value)
    ? "Really? You still use AOL for your email?"
    : undefined;

/**
 * @param {*} value
 * @param {string} target
 * @return {*}  {string}
 */
export const cannotBeLessThan = (value: any, target: string): string => {
  if (value < target) {
    return `Cannot be less than ${target}`;
  }
  return "";
};

function isNumeric(value) {
  return /^\d+$/.test(value);
}

export const numbersOnly = (value: any): string => {
  return isNumeric(value) ? '' : 'Input must contain numbers only'
};

export function validEmailFormat(input: string): any {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(input).toLowerCase()) ? '' : 'Must be a valid email';
}

/** @type {RegExp} */
export const emailPattern: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Export
export const maxLength3 = maxLength(3);
export const maxLength5 = maxLength(5);
export const maxLength10 = maxLength(10);
export const maxLength12 = maxLength(12);
export const maxLength15 = maxLength(15);
export const minLength3 = minLength(3);
export const minLength5 = minLength(5);
export const minLength7 = minLength(7);
export const minValue18 = minValue(18);
