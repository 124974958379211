import Big from "big.js";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import {
  arrayPush,
  arrayRemoveAll,
  change,
  clearFields,
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { Grid, Icon, Message, Popup } from "semantic-ui-react";
import {
  updateCompany,
  saveEnrolmentCompanyData,
} from "../../../../../actions";
import { Images } from "../../../../../constants";
// import numbersToAlphabets from "../../../../../constants/numbersToAlphabets.json";
import {
  FULL__STEP_THREE__COMPANY,
  FULL__STEP_TWO__COMPANY,
} from "../../../../../sagas/navigation/stepTypes";
import {
  cleanUpCurrency,
  cleanUpPercentage,
  formatNumber,
  renameKeys,
  buildPDVArray,
  restorePercentageValue,
  getClassDetailsInPayloadFormat,
  defaultClassObject,
  formatOldClassDataToNew,
  renameKeysFromNewClass,
  numbersToAlphabets,
} from "../../../../../utilities/customFunctions";
import {
  maxPercentValue15,
  maxValue100,
  minCurrencyValue0,
  minPercentValue0,
  required,
} from "../../../../../utilities/validationRules";
import Button from "../../../../shared/button";
import Checkbox from "../../../../shared/checkbox";
import ConnectedCheckbox from "../../../../shared/checkbox/ConnectedCheckbox";
import DateInput from "../../../../shared/customDateInput";
import { ConnectedInput } from "../../../../shared/input/ConnectedInput";
import { ConnectedTextArea } from "../../../../shared/input/ConnectedTextArea";
import { FormattedInput } from "../../../../shared/input/FormattedInput";
import { RenderDropdownList } from "../../../../shared/input/WidgetInput";
import { RadioGroup } from "../../../../shared/radio";
import { ConnectedRadioGroup } from "../../../../shared/radio/ConnectedRadioGroup";
import CustomSelect from "../../../../shared/select";
import { Stepper } from "../../../../shared/stepper";
import TextCardList from "../../../../shared/textCard";
import styles from "../../../../shared/company-enrolment/Company.module.css";
import {
  ActionAfterMastercardClaimDeclineList,
  AllowDependentsList,
  KnowWhiteLabellingList,
  PreviousBenefits,
  ContributionAmountTypeList,
  InvoicingPeriodList,
  LastDayToSubmitAfterTerminationList,
  LastDayToSubmitInYearList,
  OtherPrograms,
  PlanAdministratorsCanAddEmployeesList,
  PlanTypeList,
  PostpaymentFundsPullingRateList,
  PostpaymentFundsFloatOrDepositList,
  ProgramPaymentTypeList,
  ProgramTypeList,
  ProrateAfterGivenYearList,
  ProrateYear1List,
  ShipToList,
  WaitingPeriodList,
  purseTypeList,
  ChargePEPMList,
  PepmFrequencyList,
  MONTHLY_EMPLOYEE_FEE_TIER_1,
  MONTHLY_EMPLOYEE_FEE_TIER_2,
  MONTHLY_EMPLOYEE_MINIMUM_FEE,
  ADMINISTRATION_FEE,
  CLAIM_PROCESSING_FEE,
  CLAIM_PROCESSING_FEE_POSTPAY,
  SETUP_FEE,
  EMPLOYEE_SETUP_FEE,
  CARD_ISSUANCE_FEE,
  EMPLOYEE_MONTHLY_FEE,
  EARLY_TERMINATION_FEE,
  MASTERCARD_TRANSACTION_IN_CANADA,
  FOREIGN_EXCHANGE_FEE,
  OUT_OF_POCKET_ETF,
  EMPLOYEE_OFFBOARD_FEE,
  MINIMUM_BALANCE,
  MAXIMUM_BALANCE,
  PEPM_FEE,
  PEPM_FREQUENCY,
  COMPANY_ONBOARDING_FEES,
  steps,
  ApplyThroughBrokerageHouseList,
} from "../../../../shared/company-enrolment/sampleData";
// import { steps } from "./../sampleConstants";
import {
  getCompanyPDVInfo,
  addToMetaDataPayloadString,
  resetPayload,
} from "../../../../shared/company-enrolment/company-helpers";
import GradientText from "../../../../shared/GradientText/GradientText";
import { RevealContent, Image, Reveal } from "semantic-ui-react";
import GradientButton from "../../../../auth/login/components/GradientButton";

export class ProgramVariablesCompany extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.bottomRef = React.createRef();
    this.state = {
      //pdv's
      previousProgram: this.props.previousProgram,
      programName: this.props.programName ?? "",
      startDate: this.props.startDate
        ? moment.utc(this.props.startDate).toDate()
        : moment.utc().subtract(1, "years").toDate(),
      endDate: moment.utc(this.props.endDate).toDate(),
      effectiveTerminationDate: moment
        .utc(this.props.effectiveTerminationDate)
        .toDate(),
      startDateYear1:
        moment.utc(this.props.startDateYear1).toDate() ?? moment.utc().toDate(),
      endDateYear1: moment.utc(this.props.endDateYear1).toDate(),
      startDateYear2: moment.utc(this.props.startDateYear2).toDate(),
      startYearOnSameDate: this.props.startYearOnSameDate,
      programTypeId: this.props.programTypeId ?? "hsa",
      purseType: this.props.purseType ?? "split",
      programPaymentTypeId:
        this.props.programPaymentTypeId ?? "prepaymentmastercard",
      invoicingPeriodId: this.props.invoicingPeriodId ?? "Yearly",
      prorateYear1Id: this.props.prorateYear1Id ?? false,
      prorateAfterGivenYearId: this.props.prorateAfterGivenYearId,
      postpaymentFundsPullingRateId:
        this.props.postpaymentFundsPullingRateId ?? "asRequired",
      floatBalanceDeposit:
        this.props.floatBalanceDeposit === true ? true : false,
      applyThroughBrokerageHouseName: this.props.applyThroughBrokerageHouseName,
      applyThroughBrokerageHouse: this.props.applyThroughBrokerageHouseName
        ? true
        : false,
      dependents: this.props.dependents ? true : false,
      chargePEPM: this.props.chargePEPM ? true : false,
      shipTo: this.props.shipTo ?? "Always Ship direct to plan member",
      planAdministratorsCanAddEmployeesId: this.props
        .planAdministratorsCanAddEmployeesId
        ? true
        : false,
      daysToSubmitClaimPreviousYearId:
        this.props.daysToSubmitClaimPreviousYearId ?? "30",
      lastDayToSubmitAfterTerminationId:
        this.props.lastDayToSubmitAfterTerminationId ?? "30",
      internalNotes: this.props.internalNotes,
      payloadString: this.props.payloadString,
      //end of pdv's
      submission: true,
      detailsEnteredlookGood: false,
      pepmFrequency: this.props.pepmFrequency ?? "Monthly",
      companyEnrolment: this.props.companyEnrolment ?? {},
      knowWhiteLabelling: this.props.knowWhiteLabelling,
      advisorCompanyName: this.props.advisorCompanyName,
      advisorFirstName: this.props.advisorFirstName,
      advisorLastName: this.props.advisorLastName,
      advisorEmail: this.props.advisorEmail,
      enrolledByEmail: this.props.enrolledByEmail,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    setTimeout(() => {
      const { selectedCompanyGuid, email, businessLegalName } =
        this.props.companyEnrolment ?? {};
      this.setState({
        selectedCompanyGuid,
        email,
        businessLegalName,
      });

      if (!this.props?.endDate) {
        this.setState({
          endDate: moment
            .utc(this.state.startDate)
            .add(1, "years")
            .subtract(1, "days")
            .toDate(),
        });
      }
      if (!this.props?.effectiveTerminationDate) {
        this.setState({
          effectiveTerminationDate: moment.utc(this.state.endDate).toDate(),
        });
      }
      if (!this.props?.startDateYear2) {
        this.setState({
          startDateYear2: moment
            .utc(this.state.startDateYear1)
            .add(1, "years")
            .toDate(),
        });
      }
      if (!this.props?.endDateYear1) {
        this.setState({
          endDateYear1: moment
            .utc(this.state.startDateYear1)
            .add(1, "years")
            .subtract(1, "days")
            .toDate(),
        });
      }
      if (
        !this.props?.employeeClass ||
        !this.props?.employeeClass?.length > 0
      ) {
        this.props.dispatch(
          arrayPush(
            "FullCompanyEnrolmentPDV",
            "employeeClass",
            defaultClassObject
          )
        );
      }
    });
  }

  switchGeneric = (name, id) => {
    const previousProps = this.state;

    this.setState({ [name]: id }, () => {
      if (
        name == "programPaymentTypeId" &&
        this.state.programPaymentTypeId == "postpayment"
      ) {
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `administrationFee`, 0)
        );
        this.props.dispatch(
          change(
            "FullCompanyEnrolmentPDV",
            `claimProcessingFee`,
            CLAIM_PROCESSING_FEE
          )
        );
        this.setState({ knowWhiteLabelling: false });
        this.setState({ invoicingPeriodId: "Yearly" });
      }
      if (
        name == "programPaymentTypeId" &&
        this.state.programPaymentTypeId == "prepaymentmastercard"
      ) {
        this.props.dispatch(
          change(
            "FullCompanyEnrolmentPDV",
            `administrationFee`,
            ADMINISTRATION_FEE
          )
        );
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `claimProcessingFee`, 0)
        );
      }
      if (name == "chargePEPM" && !this.state.chargePEPM) {
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `pepmMinimumFee`, 0.0)
        );
        this.props.dispatch(change("FullCompanyEnrolmentPDV", `pepmFee`, 0.0));
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `pepmFrequency`, PEPM_FREQUENCY)
        );
      }
      if (name == "chargePEPM" && this.state.chargePEPM) {
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `pepmFee`, PEPM_FEE)
        );
        this.props.dispatch(
          change(
            "FullCompanyEnrolmentPDV",
            `pepmMinimumFee`,
            MONTHLY_EMPLOYEE_MINIMUM_FEE
          )
        );
      }
      if (name === "applyThroughBrokerageHouse" && id === false) {
        this.setState({ applyThroughBrokerageHouseName: "" });
      }
      if (name === "programTypeId" && previousProps.programTypeId !== id) {
        this.props.dispatch(
          arrayRemoveAll("FullCompanyEnrolmentPDV", "employeeClass")
        );
        this.props.dispatch(
          arrayPush(
            "FullCompanyEnrolmentPDV",
            "employeeClass",
            defaultClassObject
          )
        );
      }
    });
  };

  onGenericChange = (id, name) => {
    this.setState({ [name]: id });
  };

  toggleSubmission = () => {
    this.setState({ submission: !this.state.submission });
  };
  toggleDependents = () => {
    this.setState({ dependents: !this.state.dependents });
  };
  togglechargePEPM = () => {
    this.setState({ chargePEPM: !this.state.chargePEPM });
  };

  onChange = (value) => {
    this.setState({ companyHouse: value });
  };

  returnTermBased = (items) => {
    let newItems = [...items];
    newItems.length = this.state.yearId;
    return newItems;
  };

  handleGenenicDateChange = (date, context) => {
    this.setState(
      {
        [context]: date,
      },
      () => {
        if (context === "startDate") {
          let endDate = moment
            .utc(date)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let effectiveTerminationDate = moment.utc(endDate).toDate();
          let startDateYear1 = moment
            .utc(effectiveTerminationDate)
            .add(1, "days")
            .toDate();
          let endDateYear1 = moment
            .utc(startDateYear1)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let startDateYear2 = moment.utc(endDateYear1).add(1, "days").toDate();
          this.setState({
            endDate,
            effectiveTerminationDate,
            startDateYear1,
            endDateYear1,
            startDateYear2,
          });
        }
        if (context === "endDate") {
          let effectiveTerminationDate = moment.utc(date).toDate();
          let startDateYear1 = moment
            .utc(effectiveTerminationDate)
            .add(1, "days")
            .toDate();
          let endDateYear1 = moment
            .utc(startDateYear1)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let startDateYear2 = moment.utc(endDateYear1).add(1, "days").toDate();
          this.setState({
            effectiveTerminationDate,
            startDateYear1,
            endDateYear1,
            startDateYear2,
          });
        }
        if (context === "effectiveTerminationDate") {
          let startDateYear1 = moment.utc(date).add(1, "days").toDate();
          let endDateYear1 = moment
            .utc(startDateYear1)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let startDateYear2 = moment.utc(endDateYear1).add(1, "days").toDate();
          this.setState({ startDateYear1, endDateYear1, startDateYear2 });
        }
        if (context === "startDateYear1") {
          let endDateYear1 = moment
            .utc(date)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let startDateYear2 = moment.utc(endDateYear1).add(1, "days").toDate();
          this.setState({ endDateYear1, startDateYear2 });
        }
        if (context === "endDateYear1") {
          let startDateYear2 = moment.utc(date).add(1, "days").toDate();
          let prorateYear1Id = false;
          if (
            moment
              .utc(this.state["endDateYear1"])
              .diff(moment.utc(this.state["startDateYear1"]), "days", true) +
              1 <
            365
          ) {
            prorateYear1Id = true;
          }
          this.setState({ startDateYear2, prorateYear1Id });
        }
        if (
          (context === "startDate" ||
            context === "endDate" ||
            context === "effectiveTerminationDate" ||
            context === "startDateYear1" ||
            context === "endDateYear1") &&
          this.props.employeeClass.length > 0
        ) {
          this.props.dispatch(
            arrayRemoveAll("FullCompanyEnrolmentPDV", "employeeClass")
          );
          this.props.dispatch(
            arrayPush(
              "FullCompanyEnrolmentPDV",
              "employeeClass",
              defaultClassObject
            )
          );
        }
      }
    );
  };

  onChangeSponsorshipPlan = (value) => {
    this.setState({ sponsorshipPlan: value });
  };

  handleChange = (e) => {
    this.setState({ [e.targe]: e.target.value });
  };

  changeAdministrationFeeType = (id) => {
    if (id === "percentage") {
      this.props.change("optionAFixedPercentagePercentage", "");
    }
    this.setState({ administrationFeeTypeId: id });
  };

  changeStartYearOnSameDate = (id) => {
    this.setState({ startYearOnSameDate: id });
  };

  toggleGeneric = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  changeGeneric = (name, value) => {
    this.setState({ [name]: value });
  };

  determineOverallErrorState = () => {
    if (
      moment.utc(this.state.startDate).isAfter(moment.utc(Date.now())) ||
      moment.utc(this.state.startDate).isAfter(moment.utc(Date.now()), "day") ||
      moment.utc(this.state.endDate).isBefore(this.state.startDate, "day") ||
      moment
        .utc(moment.utc(this.state.effectiveTerminationDate))
        .isBefore(moment.utc(this.state.startDate), "day") ||
      moment
        .utc(this.state.effectiveTerminationDate)
        .isAfter(this.state.endDate, "day") ||
      moment
        .utc(this.state.endDateYear1)
        .isBefore(this.state.startDateYear1, "day") ||
      moment
        .utc(this.state.endDateYear1)
        .isAfter(
          moment.utc(
            moment
              .utc(this.state.startDateYear1)
              .add(1, "years")
              .subtract(1, "days")
          ),
          "day"
        ) ||
      this.state.detailsEnteredlookGood === false
    ) {
      return true;
    }
    return false;
  };

  scrollToBottom = () => {
    if (this.bottomRef.current) {
      this.bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    const { chargePEPM } = this.state;
    return (
      <>
        <div
          className={styles.msgContainer}
          style={{
            width: "600px",
            fontFamily: "Montserrat",
            textAlign: "center",
          }}
        >
          Reminder: Click "
          <a
            onClick={this.scrollToBottom}
            style={{
              cursor: "pointer",
            }}
          >
            Save and Continue
          </a>
          " button to keep your progress!
        </div>
        <div className={styles.contentContainerX}>
          <React.Fragment>
            <div className={styles.description}>
              <Stepper
                steps={steps}
                activeStep={1}
                context={"company-full"}
                businessOperatedName={this.props.businessOperatedName}
              />
            </div>

            <div className={styles.sectionNoMargin}>
              <div className={styles.sectionContent}>
                <div className={styles.subHeaderLabels}>
                  Section 1: Plan Set Up
                </div>
                <div className={styles.standaloneLabel}>
                  Are you transitioning from another benefits provider?
                </div>
                <TextCardList
                  name="previousProgram"
                  selectedId={this.state.previousProgram}
                  items={PreviousBenefits}
                  onChange={(id) => {
                    this.toggleGeneric("previousProgram");
                  }}
                />
                {this.state.previousProgram && (
                  <React.Fragment>
                    <div
                      className={`${styles.checkboxDropdownContainer} ${
                        this.state.previousProgram
                          ? `${styles.checkboxActive}`
                          : ""
                      } ${styles.checkboxActiveFix}`}
                    >
                      <div
                        className={styles.infoTextLabel}
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <strong>
                          We're here to ensure a smooth transition!
                        </strong>
                      </div>
                      <div
                        className={styles.infoTextLabelSmaller}
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Providing this information helps us offer the best
                        advice.
                      </div>
                      <br />

                      <div className={styles.InputContainer}>
                        <div className={styles.inputLabelStylingCopy}>
                          Which benefits provider are you transitioning from?
                        </div>
                        <Field
                          component={ConnectedInput}
                          label=""
                          name="programName"
                          placeholder="Program Name"
                          onChange={(value) =>
                            this.changeGeneric(
                              "programName",
                              value.currentTarget.value
                            )
                          }
                        />

                        <br />
                        <div className={styles.inputContainer}>
                          <DateInput
                            label="Previous Plan Start Date"
                            name="startDate"
                            type="text"
                            extrastyle="medium"
                            error={
                              moment
                                .utc(this.state.startDate)
                                .isAfter(moment.utc(Date.now()), "day")
                                ? "Cannot be greater than today"
                                : ""
                            }
                            selected={this.state.startDate}
                            handleDateChange={(date, name) =>
                              this.handleGenenicDateChange(date, name)
                            }
                          />
                          <div style={{ paddingRight: 20, marginRight: 10 }} />
                          <DateInput
                            label="Previous Plan End Date"
                            name="endDate"
                            type="text"
                            error={
                              moment
                                .utc(this.state.endDate)
                                .isBefore(this.state.startDate, "day")
                                ? "Cannot be before previous plan start date"
                                : ""
                            }
                            extrastyle="medium"
                            selected={this.state.endDate}
                            handleDateChange={(date, name) =>
                              this.handleGenenicDateChange(date, name)
                            }
                          />
                        </div>
                        <DateInput
                          label="When will your plan with the previous provider end?"
                          name="effectiveTerminationDate"
                          type="text"
                          error={
                            moment
                              .utc(
                                moment.utc(this.state.effectiveTerminationDate)
                              )
                              .isBefore(moment.utc(this.state.startDate), "day")
                              ? "Cannot be before previous plan start date"
                              : moment
                                  .utc(this.state.effectiveTerminationDate)
                                  .isAfter(this.state.endDate, "day")
                              ? "Cannot be after previous plan end date"
                              : ""
                          }
                          // extrastyle="medium"
                          selected={this.state.effectiveTerminationDate}
                          handleDateChange={(date, name) =>
                            this.handleGenenicDateChange(date, name)
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                          }}
                        >
                          <DateInput
                            label="When would you like your plan with Aya to start?"
                            name="startDateYear1"
                            type="text"
                            // extrastyle="medium"
                            selected={this.state.startDateYear1}
                            handleDateChange={(date, name) =>
                              this.handleGenenicDateChange(date, name)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
          <div className={styles.section}>
            {/* <Divider /> */}

            <div className={styles.sectionContent}>
              {/* <div className={styles.sectionTitle}>Section 2: Plan Product</div> */}
              <div className={styles.subHeaderLabels}>
                Section 2: Plan Product
              </div>
              <div
                className={styles.headingContainer}
                style={{ marginBottom: "40px" }}
              >
                <div className={styles.infoTextLabel}>
                  Let's get the info we need to create the perfect plan for you!
                </div>
                <br />
                <div className={styles.infoTextLabel}>
                  Time to get started with Aya!
                </div>
              </div>
              <div className={styles.inputLabelContainer}>
                <div className={styles.inputLabel}>Aya Plan Start Date</div>
                <div
                  className={styles.inputLabel}
                  style={{ marginLeft: "50px" }}
                >
                  Aya Plan End Date
                </div>
              </div>
              <div className={styles.inputContainer}>
                <DateInput
                  label=""
                  name="startDateYear1"
                  type="text"
                  extrastyle="medium"
                  selected={this.state.startDateYear1}
                  handleDateChange={(date, name) =>
                    this.handleGenenicDateChange(date, name)
                  }
                />
                <div style={{ paddingRight: 20, marginRight: 10 }} />
                <DateInput
                  label=""
                  name="endDateYear1"
                  error={
                    moment
                      .utc(this.state.endDateYear1)
                      .isBefore(this.state.startDateYear1, "day")
                      ? "Cannot be before year 1 start date"
                      : moment
                          .utc(this.state.endDateYear1)
                          .isAfter(
                            moment.utc(
                              moment
                                .utc(this.state.startDateYear1)
                                .add(1, "years")
                            )
                          )
                      ? "Difference between year 1 start and end date cannot be greater than 1 year"
                      : ""
                  }
                  type="text"
                  extrastyle="medium"
                  selected={this.state.endDateYear1}
                  handleDateChange={(date, name) =>
                    this.handleGenenicDateChange(date, name)
                  }
                />
              </div>
              <div className={styles.msgContainer}>
                <Icon name="info circle" />
                Your plan will be eligible for renewal on an annual basis.
                <br />
                {`Your renewal date will be ${moment
                  .utc(this.state.startDateYear2)
                  .format("Do MMMM, YYYY")}`}
              </div>
            </div>
          </div>
          {/* <div className={styles.section}> */}
          <div className={styles.spacingTopBottom}>
            <div className={styles.standaloneLabelContainer}>
              <div className={styles.standaloneLabel}>
                What type of coverage are you interested in ?
              </div>
            </div>
            <TextCardList
              name="programType"
              selectedId={this.state.programTypeId}
              items={ProgramTypeList}
              onChange={(id) => {
                this.switchGeneric("programTypeId", id);
              }}
            />
            <div>
              {this.state.programTypeId === "hsa" && (
                <div className={styles.standaloneLabel}>
                  <Icon name="info circle" />
                  For more information please visit the link:{" "}
                  <a
                    href="https://drive.google.com/file/d/1lNDVYqYiNqcIfOeAaIuvwZRPvB3r_ARI/view?usp=sharing"
                    target="_blank"
                  >
                    More About HSA
                  </a>
                </div>
              )}
              {this.state.programTypeId === "hsa+wsa" && (
                <div className={styles.standaloneLabel}>
                  <Icon name="info circle" />
                  For more information please visit the link:{" "}
                  <a
                    href="https://drive.google.com/file/d/1mWUbwdgVEUuSnjr1eFcObjPMsvLEqpJ4/view?usp=sharing"
                    target="_blank"
                  >
                    More About HSA+WSA
                  </a>
                </div>
              )}
              {this.state.programTypeId === "wsa" && (
                <div className={styles.standaloneLabel}>
                  <Icon name="info circle" />
                  For more information please visit the link:{" "}
                  <a
                    href="https://drive.google.com/file/d/1ZAsXZvC_j095NfpgvcJhL73L13tPQteD/view?usp=sharing"
                    target="_blank"
                  >
                    More About WSA
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className={styles.spacingTopBottom}>
            <div className={styles.standaloneLabelContainer}>
              <div className={styles.standaloneLabel}>
                What type of product are you interested in?
                <div className={styles.standaloneLabel}>
                  <Icon name="info circle" />
                  For more information, please visit the link:{" "}
                  <a
                    href="https://drive.google.com/file/d/1w9YlSiZI4AC025-FHCC02Lkf9O04LcyV/view"
                    target="_blank"
                  >
                    Prepay & Postpay Breakdown
                  </a>
                </div>
              </div>
            </div>

            <TextCardList
              name="programPaymentType"
              selectedId={this.state.programPaymentTypeId}
              items={ProgramPaymentTypeList}
              onChange={(id) => {
                this.switchGeneric("programPaymentTypeId", id);
              }}
            />

            {this.state.programPaymentTypeId === "postpayment" && (
              <div className={styles.headingContainer}>
                <div
                  className={styles.infoTextLabel}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Invoice company after claim is approved
                </div>
              </div>
            )}
            {this.state.programPaymentTypeId === "prepaymentmastercard" && (
              <React.Fragment>
                <div className={styles.headingContainer}>
                  <div
                    className={styles.infoTextLabel}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    Great choice, Say goodbye to out of pocket expenses!
                  </div>
                  <div
                    className={styles.infoTextLabel}
                    style={{ marginTop: "20px" }}
                  >
                    Did you know we offer White Labelling options!
                  </div>
                  <div
                    className={styles.standaloneLabel}
                    style={{ paddingTop: "0px" }}
                  >
                    This means the card itself and your Aya Portals can be
                    branded to your company!
                  </div>
                  <div
                    className={styles.standaloneLabel}
                    style={{ marginTop: "30px" }}
                  >
                    Are you interested in learning more about this option?
                  </div>
                  <TextCardList
                    name="knowWhiteLabelling"
                    selectedId={this.state.knowWhiteLabelling}
                    items={KnowWhiteLabellingList}
                    onChange={(id) => {
                      this.switchGeneric("knowWhiteLabelling", id);
                    }}
                  />
                  {this.state.knowWhiteLabelling === true && (
                    <React.Fragment>
                      <div className={styles.msgContainer}>
                        Super! We'll be in contact with the options available to
                        you
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.knowWhiteLabelling === false && (
                    <React.Fragment>
                      <div className={styles.msgContainer}>
                        No worries! The Aya brand is one worth showing off!
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>

          <div className={styles.section}>
            <div className={styles.sectionContent}>
              {/* <div className={styles.sectionTitle}>
                Section 3: Plan Allocation
              </div> */}
              <div className={styles.subHeaderLabels}>
                Section 3: Plan Allocation
              </div>
              {this.state.programPaymentTypeId === "prepaymentmastercard" && (
                <React.Fragment>
                  <div className={styles.standaloneLabelContainer}>
                    <div className={styles.standaloneLabel}>
                      How often should employee balances be topped up?
                      <Popup
                        content={
                          <React.Fragment>
                            {
                              "This will also set the frequency you will receive a contribution invoice. Once the funds are received, the amount will be uploaded to the users card!"
                            }
                          </React.Fragment>
                        }
                        position="top center"
                        trigger={
                          <img
                            src={Images.question}
                            className={styles.tooltip}
                          />
                        }
                      />
                    </div>
                  </div>
                  <TextCardList
                    name="invoicingPeriod"
                    selectedId={this.state.invoicingPeriodId}
                    items={InvoicingPeriodList}
                    onChange={(id) => {
                      this.switchGeneric("invoicingPeriodId", id);
                    }}
                  />
                </React.Fragment>
              )}

              {this.state.programPaymentTypeId === "postpayment" && (
                <React.Fragment>
                  <div className={styles.standaloneLabelContainer}>
                    <div className={styles.standaloneLabel}>
                      How often should we process your claim invoices, which are
                      based on usage?
                      <Popup
                        content={
                          <React.Fragment>
                            {
                              "Employees will make claims, and we will invoice based on the following frequencies to ensure funds are available for reimbursement after adjudication."
                            }
                          </React.Fragment>
                        }
                        position="top center"
                        trigger={
                          <img
                            src={Images.question}
                            className={styles.tooltip}
                            width="20px"
                          />
                        }
                      />
                    </div>
                  </div>
                  <TextCardList
                    name="postpaymentFundsPullingRate"
                    selectedId={this.state.postpaymentFundsPullingRateId}
                    items={PostpaymentFundsPullingRateList}
                    onChange={(id) => {
                      this.switchGeneric("postpaymentFundsPullingRateId", id);
                    }}
                  />
                </React.Fragment>
              )}

              {this.state.programPaymentTypeId === "postpayment" && (
                <React.Fragment>
                  <div className={styles.headingContainer}>
                    <div className={styles.msgContainer}>
                      Aya offers instant claim reimbursements using a float,
                      with companies depositing and replenishing funds as
                      needed.
                    </div>
                    <div className={styles.standaloneLabelContainer}>
                      <div className={styles.standaloneLabel}>
                        Interested in setting up a float?
                      </div>
                    </div>
                    <TextCardList
                      name="floatBalanceDeposit"
                      selectedId={this.state.floatBalanceDeposit}
                      items={PostpaymentFundsFloatOrDepositList}
                      onChange={(id) => {
                        this.switchGeneric("floatBalanceDeposit", id);
                      }}
                    />
                  </div>
                </React.Fragment>
              )}

              <div
                className={styles.headingContainer}
                style={{
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                <div className={styles.standaloneLabelContainer}>
                  <div className={styles.standaloneLabel}>
                    Would you like to prorate plan allocation for employees
                    starting mid-plan year?
                    <Popup
                      content={
                        <React.Fragment>
                          <p>
                            {
                              'Answer "Yes": Funds will be prorated from the employee’s enrolment date.'
                            }
                          </p>
                          <p>
                            {
                              'Answer "No": The employee will receive the full allocation regardless of start date.'
                            }
                          </p>
                        </React.Fragment>
                      }
                      position="top center"
                      trigger={
                        <img src={Images.question} className={styles.tooltip} />
                      }
                    />
                  </div>
                </div>
                <TextCardList
                  name="prorateAfterGivenYear"
                  selectedId={this.state.prorateAfterGivenYearId}
                  items={ProrateAfterGivenYearList}
                  onChange={(id) => {
                    this.switchGeneric("prorateAfterGivenYearId", id);
                  }}
                />
                {this.state.prorateAfterGivenYearId === true && (
                  <React.Fragment>
                    <div className={styles.msgContainer}>
                      Funds will be prorated from the employee's enrolment date.
                    </div>
                  </React.Fragment>
                )}
                {this.state.prorateAfterGivenYearId === false && (
                  <React.Fragment>
                    <div className={styles.msgContainer}>
                      The employee will receive the full allocation regardless
                      of start date.
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div className={styles.standaloneLabel}>
            Do you want to allow employees to add dependents?
            <Popup
              content={
                <React.Fragment>
                  <p>
                    Allowing this option incurs no extra cost to the employer,
                    employees can share the allocation and can submit claims for
                    dependents.
                  </p>
                </React.Fragment>
              }
              position="top center"
              trigger={<img src={Images.question} className={styles.tooltip} />}
            />
          </div>
          <TextCardList
            name="dependents"
            selectedId={this.state.dependents}
            items={AllowDependentsList}
            onChange={(id) => {
              this.switchGeneric("dependents", id);
            }}
          />
          {/* </div> */}
          <div className={styles.section}>
            <div className={styles.subHeaderLabels}>
              Section 4: Contributions Classes
            </div>

            <div
              className={styles.msgContainer}
              style={{
                marginBottom: "0px",
              }}
            >
              <p>
                Create classes to provide your Plan Admin a guide, making it
                easy for them to enrol users and allocate funds through the
                portal.
              </p>
              <p>What would you like to name your first class?</p>
            </div>
            <div>
              <FieldArray
                name="employeeClass"
                component={this.renderClasses}
                styles={styles}
                mystate={this.state}
                onGenericChange={this.onGenericChange}
              />
            </div>
            <div
              className={styles.msgContainer}
              style={{
                marginBottom: "0px",
              }}
            >
              <p>
                Who is eligible for Aya benefits? All T4 employees including
                part-time, full time or T4A contractors.
              </p>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.subHeaderLabels}>
              Section 5: Grace Period
            </div>

            <div className={styles.superSubHeader}>
              Post-Termination Grace Period
            </div>

            <div className={styles.standaloneLabel}>
              Does your plan allow terminated employees to submit claims from
              the previous plan year?
            </div>
            <TextCardList
              selectedId={this.state.daysToSubmitClaimPreviousYearId}
              items={LastDayToSubmitInYearList}
              onChange={(id) => {
                this.switchGeneric("daysToSubmitClaimPreviousYearId", id);
              }}
            />

            <div className={styles.superSubHeader}>
              Post-Plan Year Grace Period
            </div>
            <div className={styles.standaloneLabel}>
              When the plan year ends, do you want to allow employees to submit
              claims from the previous plan year?{" "}
            </div>
            <TextCardList
              selectedId={this.state.lastDayToSubmitAfterTerminationId}
              items={LastDayToSubmitAfterTerminationList}
              onChange={(id) => {
                this.switchGeneric("lastDayToSubmitAfterTerminationId", id);
              }}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.sectionContent}>
              <div className={styles.subHeaderLabels}>Section 6: Plan Fees</div>

              <div
                className={styles.msgContainer}
                style={{
                  marginBottom: "50px",
                }}
              >
                Each account includes individual employee and plan admin portals
                for real-time reports, user management, and access to claims
                with built-in adjudication and reimbursement.
              </div>

              {/* {!this.state.isCustomEnrolment && (
                <>
                  <div className={styles.standaloneLabel}>
                    Will your company be applying through a Brokerage House?
                  </div>
                  <TextCardList
                    name="applyThroughBrokerageHouse"
                    selectedId={this.state.applyThroughBrokerageHouse}
                    items={ApplyThroughBrokerageHouseList}
                    onChange={(id) => {
                      this.switchGeneric("applyThroughBrokerageHouse", id);
                    }}
                  />
                  {this.state.applyThroughBrokerageHouse && (
                    <React.Fragment>
                      <div
                        className={styles.headingContainer}
                        style={{
                          marginBottom: "50px",
                          marginTop: "0px",
                        }}
                      >
                        {this.state.programPaymentTypeId !== "postpayment" && (
                          <div
                            className={styles.infoTextLabel}
                            style={{
                              paddingLeft: 0,
                              marginBottom: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            Great! The Admin Fee may vary, and a Broker portal
                            will be provided.
                          </div>
                        )}
                        {this.state.programPaymentTypeId === "postpayment" && (
                          <div
                            className={styles.infoTextLabel}
                            style={{
                              paddingLeft: 0,
                              marginBottom: "20px",
                            }}
                          >
                            Great! The Claim Fee may vary, and a Broker portal
                            will be provided.
                          </div>
                        )}

                        <div className={styles.standaloneLabel}>
                          What is the name of your broker or brokerage firm?
                        </div>
                        <div className={styles.InputContainer}>
                          <Field
                            component={ConnectedInput}
                            label=""
                            name="applyThroughBrokerageHouseName"
                            type="text"
                            placeholder="Enter Brokerage House Name"
                            onChange={(event, newValue) => {
                              this.changeGeneric(
                                "applyThroughBrokerageHouseName",
                                newValue
                              );
                            }}
                            value={this.state.applyThroughBrokerageHouseName}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </>
              )} */}

              <>
                <div
                  className={styles.msgContainer}
                  style={{
                    marginBottom: "50px",
                  }}
                >
                  During onboarding, your appointed Plan Admin and enroled team
                  members will receive personal portals to manage the associated
                  accounts. We also provide portals to your appointed advisor.
                </div>

                <React.Fragment>
                  <div
                    className={styles.headingContainer}
                    style={{
                      marginBottom: "50px",
                      marginTop: "0px",
                    }}
                  >
                    <div
                      className={styles.infoTextLabel}
                      style={{
                        paddingLeft: 0,
                        marginBottom: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      Advisor Details:
                    </div>

                    <div className={styles.standaloneLabel}>
                      Advisor Company Name:
                    </div>
                    <div className={styles.InputContainer}>
                      <Field
                        component={ConnectedInput}
                        label=""
                        name="advisorCompanyName"
                        type="text"
                        placeholder="Company Name"
                        onChange={(event, newValue) => {
                          this.changeGeneric("advisorCompanyName", newValue);
                        }}
                        value={this.state.advisorCompanyName}
                      />
                    </div>

                    <div className={styles.standaloneLabel}>First Name:</div>
                    <div className={styles.InputContainer}>
                      <Field
                        component={ConnectedInput}
                        label=""
                        name="advisorFirstName"
                        type="text"
                        placeholder="First Name"
                        onChange={(event, newValue) => {
                          this.changeGeneric("advisorFirstName", newValue);
                        }}
                        value={this.state.advisorFirstName}
                      />
                    </div>

                    <div className={styles.standaloneLabel}>Last Name:</div>
                    <div className={styles.InputContainer}>
                      <Field
                        component={ConnectedInput}
                        label=""
                        name="advisorLastName"
                        type="text"
                        placeholder="Last Name"
                        onChange={(event, newValue) => {
                          this.changeGeneric("advisorLastName", newValue);
                        }}
                        value={this.state.advisorLastName}
                      />
                    </div>

                    <div className={styles.standaloneLabel}>Email:</div>
                    <div className={styles.InputContainer}>
                      <Field
                        component={ConnectedInput}
                        label=""
                        name="advisorEmail"
                        type="text"
                        placeholder="Email"
                        onChange={(event, newValue) => {
                          this.changeGeneric("advisorEmail", newValue);
                        }}
                        value={this.state.advisorEmail}
                      />
                    </div>
                  </div>
                </React.Fragment>
              </>

              {this.state.programPaymentTypeId !== "postpayment" ? (
                <React.Fragment>
                  <div className={styles.standaloneLabel}>
                    All cards are custom-made and shipped directly to users,
                    with activation instructions sent via email upon enrollment.
                  </div>

                  <ul>
                    <li>
                      <div className={styles.standaloneLabel}>
                        Administration (Administration Fee - On Defined
                        Contribution):{" "}
                        <div className={styles.inputWithSideLabel2}>
                          <Field
                            component={FormattedInput}
                            name="administrationFee"
                            suffix="%"
                            extrastyle="small"
                            validate={[required]}
                            type="text"
                            placeholder="0"
                            decimalScale={2}
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className={styles.standaloneLabel}>
                        Program Fee Per Employee Per Month (PEPM) :{" "}
                        <strong>
                          ${cleanUpCurrency(EMPLOYEE_MONTHLY_FEE)}
                        </strong>
                      </div>
                    </li>
                    <li>
                      <div className={styles.standaloneLabel}>
                        Card issuance fee :{" "}
                        <strong>${cleanUpCurrency(CARD_ISSUANCE_FEE)}</strong>
                        <span className={styles.sideLabel}>
                          For every Card issued
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className={styles.standaloneLabel}>
                        Employee Onboarding Fee :{" "}
                        <strong>${EMPLOYEE_SETUP_FEE}</strong>
                        <span className={styles.sideLabel}>
                          One time charge per employee
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className={styles.standaloneLabel}>
                        Company Onboarding Fee : <strong>${SETUP_FEE}</strong>
                        <span className={styles.sideLabel}>
                          One time charge
                        </span>
                      </div>
                    </li>
                  </ul>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className={styles.standaloneLabel}>
                    Claim Processing Fee:{" "}
                    <div className={styles.inputWithSideLabel2}>
                      <Field
                        component={FormattedInput}
                        name="claimProcessingFee"
                        suffix="%"
                        extrastyle="tiny"
                        validate={[required]}
                        type="text"
                        placeholder="0"
                        decimalScale={2}
                      />
                      <span className={styles.sideLabel}>
                        Per claim submitted and approved (max 15%)
                      </span>
                      <Popup
                        content={
                          <React.Fragment>
                            {
                              "Charges apply per claim for participating employees after submission, adjudication, and approval."
                            }
                          </React.Fragment>
                        }
                        position="top center"
                        trigger={
                          <img
                            src={Images.question}
                            className={styles.tooltip}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.standaloneLabel}>
                    Company Onboarding Fee:{" "}
                    <strong>${COMPANY_ONBOARDING_FEES}</strong>
                  </div>
                  <div className={styles.standaloneLabel}>
                    Employee Onboarding Fee{" "}
                    <strong>${EMPLOYEE_SETUP_FEE}</strong>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <div className={styles.standaloneLabel}>
                      PEPM Program Fee:{" "}
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div>$50 Flat Rate for 1-5 Employees</div>
                      <div>$10 per additional employee</div>
                    </div>
                    <div
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <Popup
                        content={
                          <React.Fragment>
                            {
                              "Per Employee Per Month, Aya issues a separate monthly invoice for PEPM Charges."
                            }
                          </React.Fragment>
                        }
                        position="top center"
                        trigger={
                          <img
                            src={Images.question}
                            className={styles.tooltip}
                          />
                        }
                      />
                    </div>
                  </div>
                  {chargePEPM && (
                    <>
                      <div
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        <div className={styles.superSubHeader}>
                          Did you know Aya offers a 20% Discount?
                        </div>
                        <div className={styles.standaloneLabel}>
                          This discount applies to PEPM Fees, when you pay
                          upfront for the year.
                        </div>
                        <div className={styles.standaloneLabel}>
                          Are you interested in this offer?
                        </div>
                        <TextCardList
                          name="pepmFrequency"
                          selectedId={this.state.pepmFrequency}
                          items={PepmFrequencyList}
                          onChange={(id) => {
                            this.switchGeneric("pepmFrequency", id);
                          }}
                        />
                      </div>
                    </>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>

          <div
            className={styles.msgContainer}
            style={{
              marginTop: "20px",
              padding: "20px",
            }}
          >
            <div
              className={styles.infoTextLabel}
              style={{
                marginBottom: "20px",
              }}
            >
              Found a better deal? Let us know and we'll price match!
            </div>
            <div
              className={styles.infoTextLabelSmaller}
              style={{ fontWeight: "500" }}
            >
              {" "}
              We'll review everything before processing your application and get
              back to you!
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionContent}>
              <div className={styles.standaloneLabel}>
                Before we proceed, if you have any notes, comments, or questions
                about the information above, please add them here.
              </div>
              <div className={styles.borderBox}>
                <Field
                  component={ConnectedTextArea}
                  label=""
                  name="internalNote"
                  // placeholder="Internal Note (note visible to the company contact)"
                />
              </div>
            </div>
          </div>

          <div className={styles.buttonAreaContainer}>
            <GradientButton
              handleClick={() => {
                // e.preventDefault();
                this.updateProgramDefinedVariables(FULL__STEP_THREE__COMPANY);
              }}
              style={{
                marginTop: "20px",
              }}
              label="Save And Continue"
              width="100%"
            ></GradientButton>
          </div>

          {/* Bottom element to scroll to */}
          <div ref={this.bottomRef}></div>
        </div>
        {/* )} */}
      </>
    );
  }
  renderClasses = ({
    styles,
    handleChange,
    fields,
    meta: { error },
    mystate,
    onGenericChange,
  }) => (
    <div>
      {fields.map((employeeClass, index) => {
        let currentField = fields.get(index) || {};
        const {
          contributionAmountType,
          annualProrated,
          annualProratedPlus1,
          annualProratedPlus2,
          annualContribution,
          annualContributionPlus1,
          annualContributionPlus2,
        } = currentField;
        return (
          <div
            key={index}
            className={`${styles.sectionContent} ${styles.employeeClassWrapper}`}
          >
            <div className={``}>
              <div className={styles.inputContainer}>
                <div
                  className={`${styles.checkboxDropdownContainer} ${styles.checkboxActive}`}
                >
                  <div
                    className={styles.sectionTitle}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    Class {numbersToAlphabets(`${index + 1}`)}{" "}
                    <div style={{ alignContent: "center" }}>
                      <Popup
                        style={{ maxWidth: "500px" }}
                        content={
                          <>
                            {" "}
                            <strong>Please note:</strong>
                            <p>
                              <br />
                              According to CRA guidelines, each class must have
                              a consistent allocation amount and be defined
                              using objective criteria. Examples of acceptable
                              criteria for establishing employee group
                              classifications include:
                              <br />
                              <br />
                              <strong>Employment Status:</strong> Full-time,
                              part-time, seasonal, contract, etc.
                              <br />
                              <br />
                              <strong>Job Description/Title:</strong>{" "}
                              Management, Administrative, Owner, Director,
                              Executive, etc.
                              <br />
                              <br />
                              <strong>Length of Service:</strong> 1 year+, 2
                              years+, 3 years+, etc. Geographical Location: ON
                              employees, BC employees etc.
                              <br />
                              <br />
                              All users of each class must have the same
                              allocation.
                            </p>
                          </>
                        }
                        trigger={
                          <Icon
                            name="info circle"
                            size="small"
                            color="grey"
                            style={{ marginLeft: "5px" }}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.InputContainer}>
                    <Field
                      component={ConnectedInput}
                      label="Class Name"
                      name={`${employeeClass}.className`}
                      type="text"
                      max={30}
                      validate={[required]}
                      placeholder="Class Name"
                    />

                    <Field
                      component={ConnectedInput}
                      label="Class Description (maximum of 256 characters)"
                      name={`${employeeClass}.note`}
                      type="text"
                      max={256}
                      validate={[required]}
                      placeholder="Class Description"
                    />

                    <div className={styles.standaloneLabel}>
                      Do you want funds to carry over to the following year, or
                      have a "use it or lose it" option for this class? (Plan
                      Type)
                    </div>
                    <ConnectedRadioGroup
                      name={`${employeeClass}.type`}
                      options={PlanTypeList}
                      thinVerticalSpacing={true}
                      noBackground={true}
                    />
                    <div className={styles.sectionTitle}>
                      Enter the annual allocation for this class.
                    </div>
                    <Grid columns="equal">
                      <Grid.Row
                        verticalAlign="middle"
                        className={styles.gridRowNoPadding}
                      >
                        <Grid.Column>
                          <div className={styles.standaloneLabel}></div>
                        </Grid.Column>
                        {this.state.programTypeId.includes("hsa") && (
                          <React.Fragment>
                            <Grid.Column>
                              <div className={styles.standaloneLabel}>HSA</div>
                            </Grid.Column>
                          </React.Fragment>
                        )}
                        {this.state.programTypeId.includes("wsa") && (
                          <React.Fragment>
                            <Grid.Column>
                              <div className={styles.standaloneLabel}>WSA</div>
                            </Grid.Column>
                          </React.Fragment>
                        )}
                        {this.state.programTypeId === "hsa+wsa" && (
                          <React.Fragment>
                            <Grid.Column>
                              <div className={styles.standaloneLabel}>Flex</div>
                            </Grid.Column>
                          </React.Fragment>
                        )}
                      </Grid.Row>

                      <Grid.Row
                        verticalAlign="middle"
                        className={styles.gridRowNoPadding}
                      >
                        <Grid.Column>
                          <div className={styles.standaloneLabel}>Single</div>
                        </Grid.Column>

                        <React.Fragment>
                          {this.state.programTypeId.includes("hsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[HSA].allocationSingle`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId.includes("wsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[WSA].allocationSingle`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId === "hsa+wsa" && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[Flex].allocationSingle`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                        </React.Fragment>
                      </Grid.Row>

                      <Grid.Row
                        verticalAlign="middle"
                        className={styles.gridRowNoPadding}
                      >
                        <Grid.Column>
                          <div className={styles.standaloneLabel}>
                            Single +1
                            <Popup
                              content={
                                <React.Fragment>
                                  The Single, +1, and +2 fields are optional for
                                  adding extra funds for dependents.
                                  Alternatively, if dependents are allowed,
                                  employees can share their allocation with them
                                  at no additional cost.
                                </React.Fragment>
                              }
                              position="top center"
                              trigger={
                                <img
                                  src={Images.question}
                                  className={styles.tooltip}
                                />
                              }
                            />
                          </div>
                        </Grid.Column>

                        <React.Fragment>
                          {this.state.programTypeId.includes("hsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[HSA].allocationSinglePlusOne`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId.includes("wsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[WSA].allocationSinglePlusOne`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId === "hsa+wsa" && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[Flex].allocationSinglePlusOne`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                        </React.Fragment>
                      </Grid.Row>

                      <Grid.Row
                        verticalAlign="middle"
                        className={styles.gridRowNoPadding}
                      >
                        <Grid.Column>
                          <div className={styles.standaloneLabel}>
                            Single +2
                            <Popup
                              content={
                                <React.Fragment>
                                  The Single, +1, and +2 fields are optional for
                                  adding extra funds for dependents.
                                  Alternatively, if dependents are allowed,
                                  employees can share their allocation with them
                                  at no additional cost.
                                </React.Fragment>
                              }
                              position="top center"
                              trigger={
                                <img
                                  src={Images.question}
                                  className={styles.tooltip}
                                />
                              }
                            />
                          </div>
                        </Grid.Column>

                        <React.Fragment>
                          {this.state.programTypeId.includes("hsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[HSA].allocationSinglePlusTwo`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId.includes("wsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[WSA].allocationSinglePlusTwo`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId === "hsa+wsa" && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[Flex].allocationSinglePlusTwo`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                        </React.Fragment>
                      </Grid.Row>
                    </Grid>
                    <br />
                  </div>
                </div>
              </div>
              <div>
                {_.size(fields) !== 1 && (
                  <div className={styles.removeAdministratorWrapper}>
                    <a
                      href={"#"}
                      onClick={(e) => {
                        e.preventDefault();
                        fields.remove(index);
                      }}
                      className={styles.removeAdministrator}
                    >
                      - Remove Class
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          fields.push(defaultClassObject);
        }}
        className={styles.addAdministrator}
      >
        + Add New Class
      </a>
    </div>
  );

  updateProgramDefinedVariables = async (nextStep) => {
    let {
      setupFee,
      employeeSetupFee,
      cardIssuanceFee,
      employeeMonthlyFee,
      administrationFee,
      masterCardTransactionInCanada,
      foreignExchangeFee,
      outOfPocketEFT,
      otherWSA,
      internalNote,
      employeeClass,
      claimProcessingFee,
      employeeOffboardFee,
      minimumBalance,
      maximumBalance,
    } = this.props;

    let payloadData = this.props.company;

    const {
      programName,
      startDate,
      endDate,
      startDateYear1,
      effectiveTerminationDate,
      endDateYear1,
      startDateYear2,
      programTypeId,
      programPaymentTypeId,
      invoicingPeriodId,
      // prorateYear1Id,
      prorateAfterGivenYearId,
      knowWhiteLabelling,
      dependents,
      chargePEPM,
      postpaymentFundsPullingRateId,
      floatBalanceDeposit,
      daysToSubmitClaimPreviousYearId,
      lastDayToSubmitAfterTerminationId,
      planAdministratorsCanAddEmployeesId,
      shipTo,
      purseType,
      previousProgram,
      pepmFrequency,
      applyThroughBrokerageHouseName,
      advisorCompanyName,
      advisorFirstName,
      advisorLastName,
      advisorEmail,
      isCustomEnrolment,
    } = this.state;

    try {
      const params = {
        method: "put",
        // path: `invites/${payloadData.inviteGuid}`,
        path: `invites`,
        data: {
          ...payloadData,
          dirty: true,
          requestedForBusinessId: "",
          requestedPersonParentId: "",
          inviteState: {
            dirty: true,
            step: nextStep,
            status: "active",
          },
          person: {
            ...payloadData.person,
            dirty: false,
            countryCode: "CA",
            languageCode: "en",
            programDefinedVariables: {},
          },
          // contacts: contacts,
          business: {
            ...payloadData.business,
            dirty: true,
            businessPreferredLanguage: "en",
            institutionType: "planSponsor",
            programDefinedVariables: {
              dirty: true,
              contractTerm: {
                startDateYear1: startDateYear1,
                endDateYear1: endDateYear1,
                startDateYear2: moment.utc(startDateYear2),
                programType: programTypeId,
                purseType: purseType,
                programPaymentType: programPaymentTypeId,
                paymentSchedule: invoicingPeriodId,
                prorateYear1: prorateAfterGivenYearId,
                prorateAfterGivenYear: prorateAfterGivenYearId,
                postpaymentFundsPullingRate: postpaymentFundsPullingRateId,
                // postpaymentFloatOrDeposit: postpaymentFloatOrDepositId,
                actionAfterMastercardClaimDecline: "N/A",
                fitnessWSA: true,
                personalDevelopmentWSA: true,
                mentalHealthWSA: true,
                nutritionWSA: true,
                otherWSA: "",
                chargePEPM: chargePEPM,
                administrationFee:
                  programPaymentTypeId === "postpayment"
                    ? cleanUpPercentage("0%")
                    : cleanUpPercentage(
                        parseFloat(administrationFee) > 0
                          ? administrationFee
                          : ADMINISTRATION_FEE + "%"
                      ),
                claimProcessingFee:
                  programPaymentTypeId === "postpayment"
                    ? cleanUpPercentage(
                        parseFloat(claimProcessingFee) > 0
                          ? claimProcessingFee
                          : CLAIM_PROCESSING_FEE + "%"
                      )
                    : cleanUpPercentage("0%"),
                setupFee: cleanUpCurrency(0),
                employeeSetupFee: cleanUpCurrency(0),
                cardIssuanceFee: cleanUpCurrency(cardIssuanceFee),
                monthlyEmployeeFee: cleanUpCurrency(employeeMonthlyFee),
                mastercardInCanadaTransactionFeePerClaim: cleanUpCurrency(
                  masterCardTransactionInCanada
                ),
                mastercardOutofCanadaTransactionForeignExchangeFee:
                  cleanUpPercentage(foreignExchangeFee),
                oopTransactionEftFee: cleanUpCurrency(outOfPocketEFT),
                ...(programPaymentTypeId === "prepaymentmastercard" && {
                  ChangeFrequency: "asRequired",
                }),
                pepmFee: chargePEPM ? cleanUpCurrency(PEPM_FEE) : 0.0,
                pepmFrequency,
                pepmMinimumFee: chargePEPM
                  ? cleanUpCurrency(MONTHLY_EMPLOYEE_MINIMUM_FEE)
                  : 0.0,
              },
              previousProgram: {
                programName: previousProgram ? programName : "",
                startDate: previousProgram ? startDate : "",
                endDate: previousProgram ? endDate : "",
                effectiveTerminationDate: previousProgram
                  ? effectiveTerminationDate
                  : "",
              },
              dependents: {
                allow: dependents,
              },
              hsaProcessingRules: {
                daysToSubmitClaimPreviousYear: daysToSubmitClaimPreviousYearId,
                daysToSubmitClaimTermination: lastDayToSubmitAfterTerminationId,
              },
              miscellaneous: {
                shipTo: shipTo,
                planAdministratorsCanAddEmployees:
                  planAdministratorsCanAddEmployeesId,
                internalNote: internalNote,
              },
            },
            metaInformation: {
              ...payloadData.business?.metaInformation,
              knowWhiteLabelling: knowWhiteLabelling,
              floatBalanceDeposit: floatBalanceDeposit,
              applyThroughBrokerageHouseName:
                applyThroughBrokerageHouseName ?? "",
              advisorCompanyName: advisorCompanyName ?? "",
              advisorFirstName: advisorFirstName ?? "",
              advisorLastName: advisorLastName ?? "",
              advisorEmail: advisorEmail ?? "",
              classes: [],
            },
          },
          // accessToken: this.props.accessToken,
        },
      };

      let classCount = 0;
      if (
        employeeClass &&
        typeof employeeClass !== null &&
        typeof employeeClass.forEach === "function"
      ) {
        employeeClass.forEach((item, i) => {
          let newClassItem = getClassDetailsInPayloadFormat(
            item,
            params?.data?.person?.email
          );
          params.data.business.metaInformation?.classes.push(newClassItem);

          if (classCount < 5) {
            let keysMap = {
              name: `employeeClass${i + 1}Name`,
              planType: `employeeClass${i + 1}PlanType`,
              contributionAmountType:
                `employeeClass${i + 1}ContributionAmountType` ?? "fixed",

              annualContribution: `employeeClass${i + 1}AnnualContribution`,
              annualContributionPlus1:
                `employeeClass${i + 1}AnnualContributionPlus1` ?? 0.0,
              annualContributionPlus2:
                `employeeClass${i + 1}AnnualContributionPlus2` ?? 0.0,
              annualContributionPercent:
                `employeeClass${i + 1}AnnualContribution` ?? 0.0,
              annualContributionPercentPlus1:
                `employeeClass${i + 1}AnnualContributionPlus1` ?? 0.0,
              annualContributionPercentPlus2:
                `employeeClass${i + 1}AnnualContributionPlus2` ?? 0.0,
            };
            //prepare pdv, rename keys
            let newItem = renameKeysFromNewClass(keysMap, item);

            params.data.business.programDefinedVariables.contractTerm = {
              ...params.data.business.programDefinedVariables.contractTerm,
              ...newItem,
            };
          }

          classCount++;
        });
      }

      //Payload string
      const modifiedPayloadString = addToMetaDataPayloadString(
        this.state.payloadString,
        params.data
      );
      let newParams = resetPayload(params);
      newParams.data.business.metaInformation["payloadString"] =
        modifiedPayloadString;

      // let response = this.props.updateCompany(newParams);
      let response = await this.props.saveEnrolmentCompanyData({
        companyData: modifiedPayloadString,
        enrolledByEmail: this.state.enrolledByEmail,
        guid: this.state.selectedCompanyGuid,
        // businessName: this.state.businessLegalName,
      });
    } catch (ex) {
      alert(
        "Error setting values: Please ensure you have filled up all fields correctly"
      );
      console.log("Error saving: " + ex);
    }
  };
}

const validate = (formValues) => {
  // let { administrationFee, employeeMonthlyFee } = formValues;
  const errors = {};

  return errors;
};

const WrappedProgramVariablesCompany = reduxForm({
  form: "FullCompanyEnrolmentPDV",
  validate,
})(ProgramVariablesCompany);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector("FullCompanyEnrolmentPDV");

  const data = state.companyReducer?.companyEnrolment?.companyData ?? {};
  let payloadString =
    JSON.stringify(state.companyReducer.companyEnrolment?.companyData) ?? "";
  const companyEnrolment = state.companyReducer.companyEnrolment ?? {};

  const enrolledByEmail = state.authReducer?.preAuthDetails?.email ?? "";

  const internalNote =
    _.get(
      data,
      "business.programDefinedVariables.miscellaneous.internalNote"
    ) ?? "";
  const businessOperatedName =
    _.get(data, "business.businessOperatedName") ?? "";

  //get metaData
  //business meta
  let myMetaData = {};
  if (
    _.has(data, "business.metaInformation") &&
    typeof data.business.metaInformation !== "undefined" &&
    typeof data.business.metaInformation.forEach === "function"
  ) {
    data.business.metaInformation.forEach((item) => {
      if (item.metaType === "string") {
        myMetaData[item.metaKey] = item.metaValue;
      } else {
        myMetaData[item.metaKey] = item.metaValue;
      }
    });
  } else if (_.get(data, "business.metaInformation")) {
    for (let item in data.business.metaInformation) {
      if (_.get(data, `business.metaInformation[${item}].metaKey`)) {
        myMetaData[data.business.metaInformation[item].metaKey] =
          data.business.metaInformation[item].metaValue;
      }
      myMetaData[item] = data.business.metaInformation[item];
    }
  }

  //person meta
  if (
    _.has(data, "person.metaInformation") &&
    typeof data.person.metaInformation !== "undefined" &&
    typeof data.person.metaInformation.forEach === "function"
  ) {
    data.person.metaInformation.forEach((item) => {
      if (item.metaType === "string") {
        myMetaData[item.metaKey] = item.metaValue;
      }
    });
  }

  //get programDefinedVariables's
  //business pdv's
  let programDefinedVariables = {};
  if (
    _.get(data, "business.programDefinedVariables") &&
    typeof data.business.programDefinedVariables !== null &&
    typeof data.business.programDefinedVariables.forEach === "function"
  ) {
    data.business.programDefinedVariables.forEach((item) => {
      if (
        item.productVariableDefinition.variableName &&
        item.variableValue.length > 0
      ) {
        programDefinedVariables[item.productVariableDefinition.variableName] =
          item.variableValue;
      }
    });
  } else if (_.get(data, "business.programDefinedVariables")) {
    for (let item in data.business.programDefinedVariables) {
      if (
        _.get(
          data,
          `business.programDefinedVariables[${item}].productVariableDefinition.variableName`
        )
      ) {
        programDefinedVariables[
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableName
        ] =
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableValue;
      }
      programDefinedVariables[item] =
        data.business.programDefinedVariables[item];
    }
  }

  //get employeeClass
  let employeeClass = [];

  employeeClass =
    myMetaData?.classes?.length > 0 ? [...myMetaData.classes] : [];

  let pgType =
    _.get(data, "business.programDefinedVariables.contractTerm.programType") ||
    _.get(programDefinedVariables, "programType");
  let isOldClassStructure = false;

  if (employeeClass.length === 0) {
    let empClass = [];

    if (_.size(programDefinedVariables) > 0) {
      empClass = _.get(programDefinedVariables, "contractTerm")
        ? buildPDVArray(
            programDefinedVariables.contractTerm,
            programDefinedVariables.contractTerm.endDateYear1,
            programDefinedVariables.contractTerm.startDateYear1,
            programDefinedVariables.contractTerm.prorateYear1,
            programDefinedVariables.contractTerm.paymentSchedule
          )
        : buildPDVArray(
            programDefinedVariables,
            programDefinedVariables.endDateYear1,
            programDefinedVariables.startDateYear1,
            programDefinedVariables.prorateYear1,
            programDefinedVariables.paymentSchedule
          );
    } else {
      empClass = buildPDVArray(
        _.get(data, "business.programDefinedVariables.contractTerm"),
        _.get(
          data,
          "business.programDefinedVariables.contractTerm,endDateYear1"
        ),
        _.get(
          data,
          "business.programDefinedVariables.contractTerm,startDateYear1"
        ),
        _.get(
          data,
          "business.programDefinedVariables.contractTerm,prorateYear1"
        ),
        _.get(
          data,
          "business.programDefinedVariables.contractTerm,paymentSchedule"
        )
      );
    }

    empClass.forEach((item) => {
      let newClassObj = formatOldClassDataToNew(item, pgType);
      employeeClass.push(newClassObj);
    });

    isOldClassStructure = true;
  }

  // know white labelling
  let metaKnowWhiteLabelling =
    _.get(data, "business.metaInformation.knowWhiteLabelling") ||
    _.get(myMetaData, "knowWhiteLabelling");
  let knowWhiteLabelling =
    metaKnowWhiteLabelling !== undefined ? metaKnowWhiteLabelling : false;

  //Float confirmation
  let metaFloatBalanceDeposit =
    _.get(data, "business.metaInformation.floatBalanceDeposit") ||
    _.get(myMetaData, "floatBalanceDeposit");
  let floatBalanceDeposit =
    metaFloatBalanceDeposit !== undefined ? metaFloatBalanceDeposit : false;

  let metaApplyThroughBrokerageHouseName =
    _.get(data, "business.metaInformation.applyThroughBrokerageHouseName") ||
    _.get(myMetaData, "applyThroughBrokerageHouseName");
  let applyThroughBrokerageHouseName = metaApplyThroughBrokerageHouseName ?? "";

  let metaAdvisorCompanyName =
    _.get(data, "business.metaInformation.advisorCompanyName") ||
    _.get(myMetaData, "advisorCompanyName");
  let advisorCompanyName = metaAdvisorCompanyName ?? "";

  let metaAdvisorFirstName =
    _.get(data, "business.metaInformation.advisorFirstName") ||
    _.get(myMetaData, "advisorFirstName");
  let advisorFirstName = metaAdvisorFirstName ?? "";

  let metaAdvisorLastName =
    _.get(data, "business.metaInformation.advisorLastName") ||
    _.get(myMetaData, "advisorLastName");
  let advisorLastName = metaAdvisorLastName ?? "";

  let metaAdvisorEmail =
    _.get(data, "business.metaInformation.advisorEmail") ||
    _.get(myMetaData, "advisorEmail");
  let advisorEmail = metaAdvisorEmail ?? "";

  return {
    //pdv's selectors
    otherWSA: selector(state, "otherWSA"),
    administrationFee: selector(state, "administrationFee"),
    claimProcessingFee: selector(state, "claimProcessingFee"),
    pepmFrequency:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.pepmFrequency"
      ) ||
      _.get(programDefinedVariables, "pepmFrequency") ||
      PEPM_FREQUENCY,
    // setupFee: selector(state, "setupFee"),
    companyEnrolment: selector(state, "companyEnrolment"),
    // employeeSetupFee: selector(state, "employeeSetupFee"),
    cardIssuanceFee: selector(state, "cardIssuanceFee"),
    employeeMonthlyFee: selector(state, "employeeMonthlyFee"),
    masterCardTransactionInCanada: selector(
      state,
      "masterCardTransactionInCanada"
    ),
    foreignExchangeFee: selector(state, "foreignExchangeFee"),
    outOfPocketEFT: selector(state, "outOfPocketEFT"),
    internalNote: selector(state, "internalNote"),
    employeeClass: selector(state, "employeeClass"),
    isOldClassStructure: selector(state, "isOldClassStructure"),
    employeeOffboardFee: selector(state, "employeeOffboardFee"),
    minimumBalance: selector(state, "minimumBalance"),
    maximumBalance: selector(state, "maximumBalance"),
    businessOperatedName: selector(state, "businessOperatedName"),
    knowWhiteLabelling: knowWhiteLabelling,
    floatBalanceDeposit: floatBalanceDeposit,
    applyThroughBrokerageHouseName: applyThroughBrokerageHouseName,
    advisorCompanyName: advisorCompanyName,
    advisorFirstName: advisorFirstName,
    advisorLastName: advisorLastName,
    advisorEmail: advisorEmail,
    enrolledByEmail: enrolledByEmail,

    startDate:
      _.get(
        data,
        "business.programDefinedVariables.previousProgram.startDate"
      ) || _.get(programDefinedVariables, "startDate"),
    endDate:
      _.get(data, "business.programDefinedVariables.previousProgram.endDate") ||
      _.get(programDefinedVariables, "endDate"),
    effectiveTerminationDate:
      _.get(
        data,
        "business.programDefinedVariables.previousProgram.effectiveTerminationDate"
      ) || _.get(programDefinedVariables, "effectiveTerminationDate"),
    startDateYear1:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.startDateYear1"
      ) || _.get(programDefinedVariables, "startDateYear1"),
    endDateYear1:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.endDateYear1"
      ) || _.get(programDefinedVariables, "endDateYear1"),
    startDateYear2:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.startDateYear2"
      ) || _.get(programDefinedVariables, "startDateYear2"),
    programTypeId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.programType"
      ) || _.get(programDefinedVariables, "programType"),
    purseType:
      _.get(data, "business.programDefinedVariables.contractTerm.purseType") ||
      _.get(programDefinedVariables, "purseType"),
    programPaymentTypeId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.programPaymentType"
      ) || _.get(programDefinedVariables, "programPaymentType"),
    invoicingPeriodId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.paymentSchedule"
      ) || _.get(programDefinedVariables, "paymentSchedule"),
    prorateYear1Id:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.prorateYear1"
      ) ||
      _.get(programDefinedVariables, "prorateYear1") ||
      false,
    prorateAfterGivenYearId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.prorateAfterGivenYear"
      ) ||
      _.get(programDefinedVariables, "prorateAfterGivenYear") ||
      true,
    postpaymentFundsPullingRateId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.postpaymentFundsPullingRate"
      ) || _.get(programDefinedVariables, "postpaymentFundsPullingRate"),
    chargePEPM:
      _.get(data, "business.programDefinedVariables.contractTerm.chargePEPM") ??
      _.get(programDefinedVariables, "chargePEPM") ??
      true,
    dependents:
      _.get(data, "business.programDefinedVariables.dependents.allow") ||
      _.get(programDefinedVariables, "allow") ||
      true,
    shipTo:
      _.get(data, "business.programDefinedVariables.miscellaneous.shipTo") ||
      _.get(programDefinedVariables, "shipTo"),
    planAdministratorsCanAddEmployeesId:
      _.get(
        data,
        "business.programDefinedVariables.miscellaneous.planAdministratorsCanAddEmployees"
      ) ||
      _.get(programDefinedVariables, "planAdministratorsCanAddEmployees") ||
      false,
    daysToSubmitClaimPreviousYearId:
      _.get(
        data,
        "business.programDefinedVariables.hsaProcessingRules.daysToSubmitClaimPreviousYear"
      ) || _.get(programDefinedVariables, "daysToSubmitClaimPreviousYear"),
    lastDayToSubmitAfterTerminationId:
      _.get(
        data,
        "business.programDefinedVariables.hsaProcessingRules.daysToSubmitClaimTermination"
      ) || _.get(programDefinedVariables, "daysToSubmitClaimTermination"),
    programName:
      _.get(
        data,
        "business.programDefinedVariables.previousProgram.programName"
      ) || _.get(programDefinedVariables, "programName"),
    previousProgram:
      _.get(
        data,
        "business.programDefinedVariables.previousProgram.programName"
      ) || _.get(programDefinedVariables, "programName")
        ? true
        : false,

    // internalNote: selector(state, 'internalNote'),
    company: data,
    // companyAllInfo: companyAllInfo,
    // accessToken,
    payloadString: payloadString,

    initialValues: {
      employeeClass: employeeClass,
      isOldClassStructure: isOldClassStructure,
      programName:
        _.get(
          data,
          "business.programDefinedVariables.previousProgram.programName"
        ) || _.get(programDefinedVariables, "programName"),
      previousProgram:
        _.get(
          data,
          "business.programDefinedVariables.previousProgram.programName"
        ) || _.get(programDefinedVariables, "programName")
          ? true
          : false,
      otherWSA:
        (_.get(
          data,
          "business.programDefinedVariables.contractTerm.otherWSA"
        ) ||
          _.get(programDefinedVariables, "otherWSA")) ??
        "",
      administrationFee:
        (_.get(
          data,
          "business.programDefinedVariables.contractTerm.administrationFee"
        ) || _.get(programDefinedVariables, "administrationFee")) * 100 ||
        ADMINISTRATION_FEE,
      claimProcessingFee:
        (_.get(
          data,
          "business.programDefinedVariables.contractTerm.claimProcessingFee"
        ) || _.get(programDefinedVariables, "claimProcessingFee")) * 100 ||
        CLAIM_PROCESSING_FEE,
      employeeOffboardFee:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.employeeOffboardFee"
        ) ||
        _.get(programDefinedVariables, "employeeOffboardFee") ||
        EMPLOYEE_OFFBOARD_FEE,
      cardIssuanceFee:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.cardIssuanceFee"
        ) ||
        _.get(programDefinedVariables, "cardIssuanceFee") ||
        CARD_ISSUANCE_FEE,
      employeeMonthlyFee:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.monthlyEmployeeFee"
        ) ||
        _.get(programDefinedVariables, "monthlyEmployeeFee") ||
        EMPLOYEE_MONTHLY_FEE,
      masterCardTransactionInCanada:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.mastercardInCanadaTransactionFeePerClaim"
        ) ||
        _.get(
          programDefinedVariables,
          "mastercardInCanadaTransactionFeePerClaim"
        ) ||
        MASTERCARD_TRANSACTION_IN_CANADA,
      foreignExchangeFee:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.mastercardOutofCanadaTransactionForeignExchangeFee"
        ) ||
        _.get(
          programDefinedVariables,
          "mastercardOutofCanadaTransactionForeignExchangeFee"
        ) ||
        FOREIGN_EXCHANGE_FEE,
      outOfPocketEFT:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.oopTransactionEftFee"
        ) ||
        _.get(programDefinedVariables, "oopTransactionEftFee") ||
        OUT_OF_POCKET_ETF,
      minimumBalance:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.minimumBalance"
        ) ||
        _.get(programDefinedVariables, "minimumBalance") ||
        MINIMUM_BALANCE,
      maximumBalance:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.maximumBalance"
        ) ||
        _.get(programDefinedVariables, "maximumBalance") ||
        MAXIMUM_BALANCE,
      internalNote: internalNote,
      businessOperatedName: businessOperatedName,
      payloadString: payloadString ?? "",
      companyEnrolment: companyEnrolment,
      applyThroughBrokerageHouseName: applyThroughBrokerageHouseName,
      advisorCompanyName: advisorCompanyName,
      advisorFirstName: advisorFirstName,
      advisorLastName: advisorLastName,
      advisorEmail: advisorEmail,
      enrolledByEmail: enrolledByEmail,
    },
  };
};

export default connect(mapStateToProps, {
  change,
  updateCompany,
  getCompanyPDVInfo,
  saveEnrolmentCompanyData,
})(WrappedProgramVariablesCompany);
