// React
import { FC } from "react";
// Router
import { NavLink } from "react-router-dom";
// Semantic UI
import { Divider } from "semantic-ui-react";
// Utilities
import { handleLogout } from "../../../utilities/auth";

/**
 * @param {*} props
 * @param {*} styles
 * @return {*}  {ReactElement}
 */
const menuItems: FC<any> = (props: any, styles: any) => {
  /**
   * @param {*} path
   * @return {*}  {boolean}
   */

  const activeBg = (path: any): boolean => {
    return props.location.pathname === path;
  };

  return {
    SA: {
      BROKERAGE: (
        <div className={styles.sectionContent}>
          <NavLink
            to="/brokerage-houses"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Brokerage Houses </div>
            <div className={styles.linkItem}>
              {props.stats.brokerageHouse
                ? parseInt(props.stats.brokerageHouse.value)
                : "..."}{" "}
            </div>
          </NavLink>
          <NavLink
            to="/brokers"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Brokers </div>
            <div className={styles.linkItem}>
              {" "}
              {props.stats.broker
                ? parseInt(props.stats.broker.value)
                : "..."}{" "}
            </div>
          </NavLink>
          <NavLink
            to="/tpa"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Third Party Administrators </div>
            <div className={styles.linkItem}>
              {props.stats.thirdPartyAdministratorPayor
                ? parseInt(props.stats.thirdPartyAdministratorPayor.value)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/tpa-sr"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Service Representatives </div>
            <div className={styles.linkItem}>
              {props.stats.tpaServiceRepresentative
                ? parseInt(props.stats.tpaServiceRepresentative.value)
                : "..."}
            </div>
          </NavLink>

          <Divider />

          <NavLink
            to="/intermediaries"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Intermediary </div>
            <div className={styles.linkItem}>
              {props.stats.claimsCount ? props.stats.claimsCount : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/hsa-adjudication"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Adjudication </div>
            <div className={styles.linkItem}>
              {props.stats.claimsCount ? props.stats.claimsCount : "..."}
            </div>
          </NavLink>
        </div>
      ),
      CLIENTS: (
        <div className={styles.sectionContent}>
          <Divider />
          <NavLink
            to="/companies"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Companies</div>
            <div className={styles.linkItem}>
              {props.stats.companiesCount
                ? parseInt(props.stats.companiesCount)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/self-enroled-companies"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Self Enroled Companies</div>
            <div className={styles.linkItem}>
              {props.stats.selfEnroledCompanies
                ? parseInt(props.stats.selfEnroledCompanies)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/enroled-companies"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Enroled Companies (Others)</div>
            <div className={styles.linkItem}>
              {props.stats.enroledCompanies
                ? parseInt(props.stats.enroledCompanies)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/employees"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Employees</div>
            <div className={styles.linkItem}>
              {props.stats.employeesCount
                ? parseInt(props.stats.employeesCount)
                : "..."}
            </div>
          </NavLink>
        </div>
      ),
      ADDITIONAL: (
        <div className={styles.sectionContent}>
          <NavLink
            to="/users"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>System Users</div>
            <div className={styles.linkItem}>
              {props.stats.usersCount
                ? parseInt(props.stats.usersCount)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/processor"
            activeClassName={activeBg("/processor") ? styles.active : "..."}
          >
            Processor
          </NavLink>
          <NavLink
            to="/processor/report"
            activeClassName={styles.indentedActive}
            className={styles.indented}
          >
            Reports
          </NavLink>
          <NavLink
            to="/sls"
            activeClassName={styles.indentedActive}
            className={styles.indented}
          >
            Finance - SLS
          </NavLink>
        </div>
      ),
    },
    BHAP: {
      BROKERAGE: (
        <div className={styles.sectionContent}>
          <NavLink
            to="/brokers"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Brokers </div>
            <div className={styles.linkItem}>
              {" "}
              {props.stats.brokers ? parseInt(props.stats.brokers) : "..."}{" "}
            </div>
          </NavLink>
        </div>
      ),
      CLIENTS: (
        <div className={styles.sectionContent}>
          <Divider />
          <NavLink
            to="/companies"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Companies</div>
            <div className={styles.linkItem}>
              {props.stats.companiesCount
                ? parseInt(props.stats.companiesCount)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/employees"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Employees</div>
            <div className={styles.linkItem}>
              {props.stats.employeesCount
                ? parseInt(props.stats.employeesCount)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/reports"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Reports</div>
          </NavLink>
        </div>
      ),
    },
    BROKER: {
      BROKERAGE: <div className={styles.sectionContent}></div>,
      CLIENTS: (
        <div className={styles.sectionContent}>
          <Divider />
          <NavLink
            to="/companies"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Companies</div>
            <div className={styles.linkItem}>
              {props.stats.enroledCompanies !== undefined
                ? parseInt(props.stats.enroledCompanies)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/employees"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Employees</div>
            <div className={styles.linkItem}>
              {props.stats.employeesCount !== undefined
                ? parseInt(props.stats.employeesCount)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/reports"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Reports</div>
          </NavLink>
          <NavLink
            to="/employee-enrolment"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Employee Enrolment</div>
          </NavLink>
        </div>
      ),
    },
    TPAP: {
      BROKERAGE: (
        <div className={styles.sectionContent}>
          <NavLink
            to="/tpa-sr"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Service Representatives </div>
            <div className={styles.linkItem}>
              {props.stats.tpaServiceRepresentative
                ? parseInt(props.stats.tpaServiceRepresentative.value)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/hsa-adjudication"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Adjudication </div>
            <div className={styles.linkItem}>
              {props.claimsCount ? props.claimsCount : "..."}
            </div>
          </NavLink>
        </div>
      ),
      CLIENTS: (
        <div className={styles.sectionContent}>
          <Divider />
          <NavLink
            to="/companies"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Companies</div>
            <div className={styles.linkItem}>
              {props.stats.companiesCount
                ? parseInt(props.stats.companiesCount)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/employees"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Employees</div>
            <div className={styles.linkItem}>
              {props.stats.employeesCount
                ? parseInt(props.stats.employeesCount)
                : "..."}
            </div>
          </NavLink>
        </div>
      ),
    },
    TPA_SR: {
      BROKERAGE: (
        <div className={styles.sectionContent}>
          <NavLink
            to="/hsa-adjudication"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Adjudication </div>
            <div className={styles.linkItem}>
              {props.claimsCount ? props.claimsCount : "..."}
            </div>
          </NavLink>
        </div>
      ),
      CLIENTS: (
        <div className={styles.sectionContent}>
          <Divider />
          <NavLink
            to="/companies"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Companies</div>
            <div className={styles.linkItem}>
              {props.stats.companiesCount
                ? parseInt(props.stats.companiesCount)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/employees"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Employees</div>
            <div className={styles.linkItem}>
              {props.stats.employeesCount
                ? parseInt(props.stats.employeesCount)
                : "..."}
            </div>
          </NavLink>
        </div>
      ),
    },
    JA: {
      BROKERAGE: (
        <div className={styles.sectionContent}>
          <NavLink to="#" activeClassName={styles.active}>
            Invoice Payables
          </NavLink>
        </div>
      ),
      CLIENTS: (
        <div className={styles.sectionContent}>
          <Divider />
          <NavLink to="/employees" activeClassName={styles.active}>
            Employees
          </NavLink>
        </div>
      ),
    },
    PSAP: {
      CLIENTS: (
        <div className={styles.sectionContent}>
          <Divider />
          <NavLink
            to="/employees"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Employees</div>
            <div className={styles.linkItem}>
              {props.stats.employeesCount
                ? parseInt(props.stats.employeesCount)
                : "..."}
            </div>
          </NavLink>
        </div>
      ),
    },
    PSC: {
      CLIENTS: (
        <div className={styles.sectionContent}>
          <Divider />
          <NavLink
            to="/employees"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Employees</div>
            <div className={styles.linkItem}>
              {props.stats.employeesCount
                ? parseInt(props.stats.employeesCount)
                : "..."}
            </div>
          </NavLink>
        </div>
      ),
    },
    PCO: {
      BROKERAGE: (
        <>
          <div className={styles.sectionContent}>
            {/* <NavLink
              to="/activity"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}>Activity</div>
            </NavLink> */}
            <NavLink
              to="/plan-info"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}>Plan Info</div>
            </NavLink>
            <NavLink
              to="/list-dependents"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}>Dependents</div>
            </NavLink>
            <NavLink
              to="/bank-info"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}>Bank Info</div>
            </NavLink>
            <NavLink
              to="/settings"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}>Settings</div>
            </NavLink>

            <Divider />

            <div
              className={styles.standAlonelinkContainer}
              onClick={() => handleLogout()}
            >
              <div className={styles.linkItem}>Logout</div>
            </div>
          </div>
        </>
      ),
    },
    DEPENDENT: {},
    FINANCE: {
      BROKERAGE: (
        <>
          <div className={styles.sectionContent}>
            <NavLink
              to="/brokerage-houses"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}> Brokerage Houses </div>
              <div className={styles.linkItem}>
                {props.stats.brokerageHouse
                  ? parseInt(props.stats.brokerageHouse.value)
                  : "..."}{" "}
              </div>
            </NavLink>
            <NavLink
              to="/brokers"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}> Brokers </div>
              <div className={styles.linkItem}>
                {" "}
                {props.stats.broker
                  ? parseInt(props.stats.broker.value)
                  : "..."}{" "}
              </div>
            </NavLink>
            <NavLink
              to="/tpa"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}>
                {" "}
                Third Party Administrators{" "}
              </div>
              <div className={styles.linkItem}>
                {props.stats.thirdPartyAdministratorPayor
                  ? parseInt(props.stats.thirdPartyAdministratorPayor.value)
                  : "..."}
              </div>
            </NavLink>
            <NavLink
              to="/tpa-sr"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}> Service Representatives </div>
              <div className={styles.linkItem}>
                {props.stats.tpaServiceRepresentative
                  ? parseInt(props.stats.tpaServiceRepresentative.value)
                  : "..."}
              </div>
            </NavLink>
          </div>
          <Divider />
          <div className={styles.sectionContent}>
            <NavLink
              to="/invoice"
              activeClassName={activeBg("/invoice") ? styles.active : "..."}
            >
              Invoices
            </NavLink>
            <NavLink
              to="/invoice/audit"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Audit
            </NavLink>

            <NavLink
              to="/payments"
              activeClassName={activeBg("/payments") ? styles.active : "..."}
            >
              Payments
            </NavLink>
            <NavLink
              to="/payments/record"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Record
            </NavLink>

            <NavLink
              to="/ledger"
              activeClassName={activeBg("/ledger") ? styles.active : "..."}
            >
              Ledger
            </NavLink>
            <NavLink
              to="/ledger/collection"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Collection
            </NavLink>
            <NavLink
              to="/ledger/operating"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Operating
            </NavLink>
            <NavLink
              to="/ledger/prefund"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Prefund
            </NavLink>
            <NavLink
              to="/ledger/settlement"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Settlement
            </NavLink>
            <NavLink
              to="/ledger/reimbursement"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Reimbursement
            </NavLink>
            <NavLink
              to="/ledger/return"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Return
            </NavLink>
            <NavLink
              to="/ledger/issuerfee"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Issuer Fees
            </NavLink>
            <NavLink
              to="/ledger/mastercard"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Mastercard
            </NavLink>

            <NavLink
              to="/processor"
              activeClassName={activeBg("/processor") ? styles.active : "..."}
            >
              Processor
            </NavLink>
            <NavLink
              to="/processor/report"
              activeClassName={styles.indentedActive}
              className={styles.indented}
            >
              Reports
            </NavLink>
          </div>
        </>
      ),
      CLIENTS: (
        <>
          <div className={styles.sectionContent}>
            <Divider />
            <NavLink
              to="/companies"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}>Companies</div>
              <div className={styles.linkItem}>
                {props.stats.companiesCount
                  ? parseInt(props.stats.companiesCount)
                  : "..."}
              </div>
            </NavLink>
            <NavLink
              to="/employees"
              activeClassName={styles.active}
              className={styles.linkContainer}
            >
              <div className={styles.linkItem}>Employees</div>
              <div className={styles.linkItem}>
                {props.employeesCount ? parseInt(props.employeesCount) : "..."}
              </div>
            </NavLink>
          </div>
        </>
      ),
    },
    CLAIM_EDITOR: {
      BROKERAGE: (
        <div className={styles.sectionContent}>
          <NavLink
            to="/brokerage-houses"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Brokerage Houses </div>
            <div className={styles.linkItem}>
              {props.stats.brokerageHouse
                ? parseInt(props.stats.brokerageHouse.value)
                : "..."}{" "}
            </div>
          </NavLink>
          <NavLink
            to="/brokers"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Brokers </div>
            <div className={styles.linkItem}>
              {" "}
              {props.stats.broker
                ? parseInt(props.stats.broker.value)
                : "..."}{" "}
            </div>
          </NavLink>
          <NavLink
            to="/tpa"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Third Party Administrators </div>
            <div className={styles.linkItem}>
              {props.stats.thirdPartyAdministratorPayor
                ? parseInt(props.stats.thirdPartyAdministratorPayor.value)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/tpa-sr"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Service Representatives </div>
            <div className={styles.linkItem}>
              {props.stats.tpaServiceRepresentative
                ? parseInt(props.stats.tpaServiceRepresentative.value)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/#"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Claim Editors </div>
            <div className={styles.linkItem}>
              {props.stats.claimEditor
                ? parseInt(props.stats.claimEditor.value)
                : "..."}
            </div>
          </NavLink>
          <Divider />
          <NavLink
            to="/hsa-adjudication"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}> Adjudication </div>
            <div className={styles.linkItem}>
              {props.stats.claimsCount ? props.stats.claimsCount : "..."}
            </div>
          </NavLink>
        </div>
      ),
      CLIENTS: (
        <div className={styles.sectionContent}>
          <Divider />
          <NavLink
            to="/companies"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Companies</div>
            <div className={styles.linkItem}>
              {props.stats.companiesCount
                ? parseInt(props.stats.companiesCount)
                : "..."}
            </div>
          </NavLink>
          <NavLink
            to="/employees"
            activeClassName={styles.active}
            className={styles.linkContainer}
          >
            <div className={styles.linkItem}>Employees</div>
            <div className={styles.linkItem}>
              {props.stats.employeesCount
                ? parseInt(props.stats.employeesCount)
                : "..."}
            </div>
          </NavLink>
        </div>
      ),
    },
  };
};

// Export
export default menuItems;
