import Big from "big.js";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  arrayPush,
  arrayRemoveAll,
  change,
  clearFields,
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { Divider, Grid, Icon, Message, Popup } from "semantic-ui-react";
import {
  clearCompany,
  clearGeneral,
  fetchIndustries,
  updateCompany,
  createNewPlanAdmin,
  // saveEnrolmentCompanyData,
  submitCompanyEnrolmentData,
} from "../../../../../actions";
import { GlobalCountryList, Images } from "../../../../../constants";
// import numbersToAlphabets from "../../../../../constants/numbersToAlphabets.json";
import { FULL__STEP_SEVEN__COMPANY } from "../../../../../sagas/navigation/stepTypes";
import {
  buildPDVArray,
  cleanUpCurrency,
  cleanUpPercentage,
  isEmailValid,
  isEmpty,
  renameKeys,
  restorePercentageValue,
  defaultClassObject,
  getClassDetailsInPayloadFormat,
  formatOldClassDataToNew,
  renameKeysFromNewClass,
  numbersToAlphabets,
} from "../../../../../utilities/customFunctions";
import {
  email,
  maxValue100,
  minCurrencyValue0,
  minPercentValue0,
  postalCodeCA,
  postalCodeUS,
  required,
} from "../../../../../utilities/validationRules";
import Button from "../../../../shared/button";
import Checkbox from "../../../../shared/checkbox";
import ConnectedCheckbox from "../../../../shared/checkbox/ConnectedCheckbox";
import DateInput from "../../../../shared/customDateInput";
import GoogleSuggest from "../../../../shared/googlePlaces";
import Input from "../../../../shared/input";
import { ConnectedInput } from "../../../../shared/input/ConnectedInput";
import { ConnectedTextArea } from "../../../../shared/input/ConnectedTextArea";
import { FormattedInput } from "../../../../shared/input/FormattedInput";
import { RenderDropdownList } from "../../../../shared/input/WidgetInput";
import { RadioGroup } from "../../../../shared/radio";
import { ConnectedRadioGroup } from "../../../../shared/radio/ConnectedRadioGroup";
import CustomSelect from "../../../../shared/select";
import { Stepper } from "../../../../shared/stepper";
import TextCardList from "../../../../shared/textCard";
import {
  AllowDependentsList,
  BankPaymentMethods,
  BusinessOperatedInCAList,
  CompanyTypeList,
  ContributionAmountTypeList,
  CorporateNumberTypeList,
  Countries,
  DefaultGuid,
  EmployeeDropdownList,
  ExchangeDropdownList,
  FATCAList,
  PEPList,
  GovermentIDOptions,
  InvoicingPeriodList,
  LastDayToSubmitAfterTerminationList,
  LastDayToSubmitInYearList,
  OtherPrograms,
  PlanTypeList,
  PostpaymentFundsPullingRateList,
  PostpaymentFundsFloatOrDepositList,
  ProgramPaymentTypeList,
  ProgramTypeList,
  ProrateAfterGivenYearList,
  ProvinceList,
  WaitingPeriodList,
  MONTHLY_EMPLOYEE_MINIMUM_FEE,
  ADMINISTRATION_FEE,
  SETUP_FEE,
  EMPLOYEE_SETUP_FEE,
  CARD_ISSUANCE_FEE,
  EMPLOYEE_MONTHLY_FEE,
  PEPM_FREQUENCY,
  PEPM_FEE,
  PepmFrequencyList,
  steps,
  COMPANY_ONBOARDING_FEES,
  ApplyThroughBrokerageHouseList,
  KnowWhiteLabellingList,
  CLAIM_PROCESSING_FEE,
} from "../../../../shared/company-enrolment/sampleData";
import { getValidationErrors } from "../../../../shared/company-enrolment/company-helpers";
import { ErrorPopupComponent } from "../../../../shared/company-enrolment/errorPopupComponent";
import { INDUSTRIES } from "../../../../shared/company-enrolment/industryConstants";
import GradientButton from "../../../../auth/login/components/GradientButton";

export class ReviewInformation extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);

    this.state = {
      updated: 0,
      submission: false,
      industryClassificationType: this.props.industryClassificationType,
      provinceOfRegisteredOffice: this.props.provinceOfRegisteredOffice,
      dateOfRegistration: moment.utc(this.props.dateOfRegistration).toDate(),
      dateOfBirth: moment.utc(this.props.dateOfBirth).toDate(),
      documentType: this.props.documentType,
      expiryDate: moment.utc(this.props.expiryDate).toDate(),
      provinceOfIssuance: this.props.provinceOfIssuance,
      planAdminDateOfBirth: moment
        .utc(this.props.planAdminDateOfBirth)
        .toDate(),
      planAdminDocumentType: this.props.planAdminDocumentType,
      planAdminExpiryDate: moment.utc(this.props.planAdminExpiryDate).toDate(),
      planAdminProvinceOfIssuance: this.props.planAdminProvinceOfIssuance,

      //pdv's
      startDate: moment.utc(this.props.startDate).toDate(),
      endDate: moment.utc(this.props.endDate).toDate(),
      effectiveTerminationDate: moment
        .utc(this.props.effectiveTerminationDate)
        .toDate(),
      startDateYear1: moment.utc(this.props.startDateYear1).toDate(),
      endDateYear1: moment.utc(this.props.endDateYear1).toDate(),
      startYearOnSameDate: this.props.startYearOnSameDate,
      startDateYear2: moment.utc(this.props.startDateYear2).toDate(),
      programTypeId: this.props.programTypeId,
      purseType: this.props.purseType,
      programPaymentTypeId: this.props.programPaymentTypeId,
      invoicingPeriodId: this.props.invoicingPeriodId,
      prorateYear1Id: this.props.prorateYear1Id,
      prorateAfterGivenYearId: this.props.prorateAfterGivenYearId,
      postpaymentFundsPullingRateId: this.props.postpaymentFundsPullingRateId,
      // postpaymentFloatOrDepositId: this.props.postpaymentFloatOrDepositId,
      chargePEPM: this.props.chargePEPM,
      dependents: this.props.dependents,
      fitnessWSA: this.props.fitnessWSA,
      personalDevelopmentWSA: this.props.personalDevelopmentWSA,
      mentalHealthWSA: this.props.mentalHealthWSA,
      nutritionWSA: this.props.nutritionWSA,
      otherWSA: this.props.otherWSA,
      actionAfterMastercardClaimDeclineId:
        this.props.actionAfterMastercardClaimDeclineId,
      shipTo: this.props.shipTo,
      planAdministratorsCanAddEmployeesId:
        this.props.planAdministratorsCanAddEmployeesId,
      daysToSubmitClaimPreviousYearId:
        this.props.daysToSubmitClaimPreviousYearId,
      lastDayToSubmitAfterTerminationId:
        this.props.lastDayToSubmitAfterTerminationId,
      //end of pdv's

      //Step1
      operatingInCanada: this.props.operatingInCanada,
      businessType: this.props.businessType,
      fatca: this.props.fatca,
      isPEP: this.props.isPEP,
      bhaGuid: this.props.bhaGuid,
      brokerGuid: this.props.brokerGuid,
      tpaGuid: this.props.tpaGuid,
      ayaSalesRepGuid: this.props.ayaSalesRepGuid,
      bhaLabel: this.props.bhaName,
      brokerLabel: this.props.brokerName,
      tpaLabel: this.props.tpaName,
      ayaSalesRepLabel: this.props.ayaSalesRepName,
      //step 2
      planAdministratorSameWithCoperateContact: this.props.planAdminEmail
        ? true
        : false,
      // planSponsorAccountant: _.get(this.props.planSponsorAccountant, "email")
      //   ? true
      //   : false,

      //step 4
      bankPaymentMethod: this.props.bankPaymentMethod,
      selectedBankName: this.props.bankName,
      // selectedBankInList: this.props.selectedBankInList,
      formErrors: false,
      planAdminParams: this.props.planAdminParams,
      planAdminUpdateRequired: true,
      pepmFrequency: this.props.pepmFrequency ?? "Monthly",
      companyEnrolment: this.props.companyEnrolment ?? {},
      floatBalanceDeposit:
        this.props.floatBalanceDeposit === true ? true : false,
      applyThroughBrokerageHouseName: this.props.applyThroughBrokerageHouseName,
      applyThroughBrokerageHouse: this.props.applyThroughBrokerageHouseName
        ? true
        : false,
      knowWhiteLabelling: this.props.knowWhiteLabelling,
      advisorCompanyName: this.props.advisorCompanyName,
      advisorFirstName: this.props.advisorFirstName,
      advisorLastName: this.props.advisorLastName,
      advisorEmail: this.props.advisorEmail,
      enrolledByEmail: this.props.enrolledByEmail,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    setTimeout(() => {
      const { selectedCompanyGuid, email } = this.props.companyEnrolment ?? {};
      this.setState({
        selectedCompanyGuid,
        email,
      });
    });
    if (_.get(this.props, "streetOne") && this.props.streetOne.length > 0) {
      this.setState({
        streetOne: _.get(this.props, "streetOne"),
        streetTwo: _.get(this.props, "streetTwo"),
        city: _.get(this.props, "city"),
        province: _.get(this.props, "province"),
        country: _.get(this.props, "country"),
        postalCode: _.get(this.props, "postalCode"),
      });
    }
    if (
      _.get(this.props, "mailingStreetOne") &&
      this.props.mailingStreetOne.length > 0
    ) {
      this.setState({
        mailingStreetOne: _.get(this.props, "mailingStreetOne"),
        mailingStreetTwo: _.get(this.props, "mailingStreetTwo"),
        mailingCity: _.get(this.props, "mailingCity"),
        mailingProvince: _.get(this.props, "mailingProvince"),
        mailingCountry: _.get(this.props, "mailingCountry"),
        mailingPostalCode: _.get(this.props, "mailingPostalCode"),
      });
    }
    if (
      _.get(this.props, "person1StreetOne") &&
      this.props.person1StreetOne.length > 0
    ) {
      this.setState({
        person1StreetOne: _.get(this.props, "person1StreetOne"),
        person1StreetTwo: _.get(this.props, "person1StreetTwo"),
        person1City: _.get(this.props, "person1City"),
        person1Province: _.get(this.props, "person1Province"),
        person1Country: _.get(this.props, "person1Country"),
        person1PostalCode: _.get(this.props, "person1PostalCode"),
      });
    }
    if (
      _.get(this.props, "planAdminStreetOne") &&
      this.props.planAdminStreetOne.length > 0
    ) {
      this.setState({
        planAdminStreetOne: _.get(this.props, "planAdminStreetOne"),
        planAdminStreetTwo: _.get(this.props, "planAdminStreetTwo"),
        planAdminCity: _.get(this.props, "planAdminCity"),
        planAdminProvince: _.get(this.props, "planAdminProvince"),
        planAdminCountry: _.get(this.props, "planAdminCountry"),
        planAdminPostalCode: _.get(this.props, "planAdminPostalCode"),
      });
    }
    this.setState({
      //Start of PDV's
      detailsEnteredlookGood: false,
      previousProgram: this.props.previousProgram,
      programName: this.props.programName,
      startDate: moment.utc(this.props.startDate).toDate(),
      endDate: moment.utc(this.props.endDate).toDate(),
      effectiveTerminationDate: moment
        .utc(this.props.effectiveTerminationDate)
        .toDate(),
      startDateYear1: moment.utc(this.props.startDateYear1).toDate(),
      endDateYear1: moment.utc(this.props.endDateYear1).toDate(),
      startYearOnSameDate: this.props.startYearOnSameDate,
      startDateYear2: moment.utc(this.props.startDateYear2).toDate(),
      programTypeId: this.props.programTypeId,
      purseType: this.props.purseType,
      programPaymentTypeId: this.props.programPaymentTypeId,
      invoicingPeriodId: this.props.invoicingPeriodId,
      prorateYear1Id: this.props.prorateYear1Id,
      prorateAfterGivenYearId: this.props.prorateAfterGivenYearId,
      postpaymentFundsPullingRateId: this.props.postpaymentFundsPullingRateId,
      // postpaymentFloatOrDepositId: this.props.postpaymentFloatOrDepositId,
      dependents: this.props.dependents,
      chargePEPM: this.props.chargePEPM,
      fitnessWSA: this.props.fitnessWSA,
      personalDevelopmentWSA: this.props.personalDevelopmentWSA,
      mentalHealthWSA: this.props.mentalHealthWSA,
      nutritionWSA: this.props.nutritionWSA,
      otherWSA: this.props.otherWSA,
      actionAfterMastercardClaimDeclineId:
        this.props.actionAfterMastercardClaimDeclineId,
      shipTo: this.props.shipTo,
      planAdministratorsCanAddEmployeesId:
        this.props.planAdministratorsCanAddEmployeesId,
      daysToSubmitClaimPreviousYearId:
        this.props.daysToSubmitClaimPreviousYearId,
      lastDayToSubmitAfterTerminationId:
        this.props.lastDayToSubmitAfterTerminationId,
      //End of PDV's
      industryClassificationList: this.props.industryClassificationList,
      industryList: this.props.industryList,
      estimatedNumberOfEmployees: this.props.estimatedNumberOfEmployees,
      exchange: this.props.exchange,
      businessType: this.props.businessType,
      corporateNumberType: this.props.corporateNumberType,
      provinceOfRegisteredOffice: this.props.provinceOfRegisteredOffice,
      dateOfRegistration: moment.utc(this.props.dateOfRegistration).toDate(),
      sourceDocument: this.props.sourceDocument,
      streetOne: this.props.streetOne,
      streetTwo: this.props.streetTwo,
      city: this.props.city,
      province: this.props.province,
      country: this.props.country,
      postalCode: this.props.postalCode,
      mailingStreetOne: this.props.mailingStreetOne,
      mailingStreetTwo: this.props.mailingStreetTwo,
      mailingCity: this.props.mailingCity,
      mailingProvince: this.props.mailingProvince,
      mailingCountry: this.props.mailingCountry,
      mailingPostalCode: this.props.mailingPostalCode,
      person1StreetOne: this.props.person1StreetOne,
      person1StreetTwo: this.props.person1StreetTwo,
      person1City: this.props.person1City,
      person1Province: this.props.person1Province,
      person1Country: this.props.person1Country,
      person1PostalCode: this.props.person1PostalCode,
      planAdminStreetOne: this.props.planAdminStreetOne,
      planAdminStreetTwo: this.props.planAdminStreetTwo,
      planAdminCity: this.props.planAdminCity,
      planAdminProvince: this.props.planAdminProvince,
      planAdminCountry: this.props.planAdminCountry,
      planAdminPostalCode: this.props.planAdminPostalCode,
      dateOfBirth: moment.utc(this.props.dateOfBirth).toDate(),
      documentType: this.props.documentType,
      expiryDate: moment.utc(this.props.expiryDate).toDate(),
      provinceOfIssuance: this.props.provinceOfIssuance,
      planAdminDateOfBirth: moment
        .utc(this.props.planAdminDateOfBirth)
        .toDate(),
      planAdminDocumentType: this.props.planAdminDocumentType,
      planAdminExpiryDate: moment.utc(this.props.planAdminExpiryDate).toDate(),
      planAdminProvinceOfIssuance: this.props.planAdminProvinceOfIssuance,
      businessIndustry: this.props.businessIndustry,
      industryClassificationType: this.props.industryClassificationType,
    });

    if (this.props.bhaGuid) {
      this.setState({ bhaGuid: this.props.bhaGuid });
      // this.props.fetchBhaBrokers(this.props.bhaGuid);
      // this.props.fetchPcoTpa(this.props.bhaGuid);
    }
    if (this.props.brokerGuid) {
      this.setState({ brokerGuid: this.props.brokerGuid });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.industryClassificationList !==
        prevProps.industryClassificationList ||
      this.props.industryList !== prevProps.industryList
    ) {
      this.setState({
        industryClassificationList: this.props.industryClassificationList,
        industryList: this.props.industryList,
      });
    }
    if (prevProps.bhaGuid !== this.props.bhaGuid) {
      this.setState({ bhaGuid: this.props.bhaGuid });
      // this.props.fetchBhaBrokers(this.props.bhaGuid);
      // this.props.fetchPcoTpa(this.props.bhaGuid);
    }
    if (prevProps.brokerGuid !== this.props.brokerGuid) {
      this.setState({ brokerGuid: this.props.brokerGuid });
    }
    if (
      // prevProps.selectedBankInList !== this.props.selectedBankInList ||
      prevProps.bankPaymentMethod !== this.props.bankPaymentMethod ||
      prevProps.bankName !== this.props.bankName
    ) {
      this.setState({
        // selectedBankInList: this.props.selectedBankInList,
        bankPaymentMethod: this.props.bankPaymentMethod,
        selectedBankName: this.props.bankName,
      });
    }

    // if (
    //   _.has(this.props.newPlanAdmin, "inviteGuid") &&
    //   this.state.planAdminUpdateRequired
    // ) {
    //   this.verifyInformation(FULL__STEP_SEVEN__COMPANY);
    //   this.setState({ planAdminUpdateRequired: false });
    // }
  }

  setAddress = (name, address) => {
    let { address_components, formatted_address } = address;
    let data = {};
    if (address_components) {
      for (let item of address_components) {
        switch (item.types[0]) {
          case "country":
            data.country = item.short_name;
            break;
          case "postal_code":
            data.postalCode = item.long_name;
            break;
          case "administrative_area_level_1":
            data.province = item.long_name;
            break;
          case "political":
            data.city = item.long_name;
            break;
          case "locality":
            !_.has(data, "city")
              ? (data.city = item.long_name)
              : (data.city = `${data.city}`);
            break;
          case "street_number":
            data.streetAddress = item.long_name;
            break;
          case "route":
            data.streetAddress = `${
              data.streetAddress ? `${data.streetAddress}` : ""
            } ${item.long_name}`;
            break;
          default:
            break;
        }
      }
    }

    if (address_components && name === "businessAddress") {
      this.setState({
        businessAddress: formatted_address,
        streetOne: data.streetAddress,
        city: data.city,
        province: data.province,
        country: data.country,
        postalCode: data.postalCode,
      });
    } else if (address_components && name === "mailingAddress") {
      this.setState({
        mailingAddress: formatted_address,
        mailingStreetOne: data.streetAddress,
        mailingCity: data.city,
        mailingProvince: data.province,
        mailingCountry: data.country,
        mailingPostalCode: data.postalCode,
      });
    } else if (address_components && name === "person1ResidentialAddress") {
      this.setState({
        person1ResidentialAddress: formatted_address,
        person1StreetOne: data.streetAddress,
        person1City: data.city,
        person1Province: data.province,
        person1Country: data.country,
        person1PostalCode: data.postalCode,
      });
    } else if (address_components && name === "planAdminResidentialAddress") {
      this.setState({
        planAdminResidentialAddress: formatted_address,
        planAdminStreetOne: data.streetAddress,
        planAdminCity: data.city,
        planAdminProvince: data.province,
        planAdminCountry: data.country,
        planAdminPostalCode: data.postalCode,
      });
    } else if (_.size(name.split(".")[1]) > 0) {
      this.props.dispatch(
        change(
          "CompanyReviewPS",
          `${name.split(".")[0]}.streetOne`,
          data.streetAddress
        )
      );
      this.props.dispatch(
        change("CompanyReviewPS", `${name.split(".")[0]}.city`, data.city)
      );
      this.props.dispatch(
        change(
          "CompanyReviewPS",
          `${name.split(".")[0]}.province`,
          data.province
        )
      );
      this.props.dispatch(
        change("CompanyReviewPS", `${name.split(".")[0]}.country`, data.country)
      );
      this.props.dispatch(
        change(
          "CompanyReviewPS",
          `${name.split(".")[0]}.postalCode`,
          data.postalCode
        )
      );
      this.setState({ updated: this.state.updated + 1 });
    } else {
      this.setState({ [name]: address });
    }
  };

  onChangeCheckBox = () => {
    const {
      businessAddress,
      streetOne,
      streetTwo,
      city,
      province,
      country,
      postalCode,
    } = this.state;
    this.setState({ sameAddress: !this.state.sameAddress }, () => {
      if (this.state.sameAddress) {
        this.setState({
          mailingAddress: businessAddress,
          mailingStreetOne: streetOne,
          mailingStreetTwo: streetTwo,
          mailingCity: city,
          mailingProvince: province,
          mailingPostalCode: postalCode,
          mailingCountry: country,
        });
      } else {
        this.setState({
          mailingAddress: "",
          mailingStreetOne: "",
          mailingStreetTwo: "",
          mailingCity: "",
          mailingProvince: "",
          mailingPostalCode: "",
          mailingCountry: "",
        });
      }
    });
  };

  onChangeGenericCheckBox = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  onChange = (value) => {};

  toggleSubmission = () => {
    this.setState({ submission: !this.state.submission });
  };

  getValidationErrors = () => {
    const errors = getValidationErrors(this.state, this.props);
    return errors;
  };

  handleDateChange = (date, context) => {
    this.setState({
      [context]: date,
    });
  };

  onGenericChange = (id, name) => {
    this.setState({ [name]: id });
  };

  filterIndustries = (industries) => {
    if (industries && industries.length > 0) {
      let desiredIndustries = industries.filter(
        (item) => item.parentClassificationGuid === DefaultGuid
      );
      this.setState({ industryList: desiredIndustries });
      return desiredIndustries;
    } else {
      return [];
    }
  };

  filterClassifications = (industries, newClassificationFilter) => {
    let desiredIndustryClassificationList = [];
    if (this.state.classificationFilter !== "") {
      desiredIndustryClassificationList = industries.filter(
        (item) => item.parentClassificationGuid === newClassificationFilter
      );
      this.setState({
        industryClassificationList: desiredIndustryClassificationList,
      });
      return desiredIndustryClassificationList;
    } else {
      return [];
    }
  };

  changeSelect = (name, value) => {
    this.setState({ [name]: value });
  };

  changeSelectIndustry = (name, value) => {
    let classificationFilter = "";
    this.props.industries.forEach((industry) => {
      if (industry.label === value) {
        classificationFilter = industry.value;
      }
    });
    this.setState(
      {
        [name]: value,
        classificationFilter: classificationFilter,
        industryClassificationType: "",
      },
      () => {
        this.filterClassifications(this.props.industries, classificationFilter);
      }
    );
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleGeneric = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  switchLanguage = (name, id) => {
    if (id === 1) {
      this.setState({ [name]: id });
    }
  };

  onChangeGenericRadio = (value, name) => {
    this.setState({ [name]: value });
  };

  filterDistributionList = (list) => {
    return list.filter((item) => {
      return item.id <= this.state.invoicingPeriodId;
    });
  };

  filterDayOfInvoicing = (list) => {
    if (this.state.distributionScheduleId !== "Monthly") {
      return list.filter((item) => item.id === 1);
    } else {
      return list;
    }
  };

  handleGenenicDateChange = (date, context) => {
    this.setState(
      {
        [context]: date,
      },
      () => {
        if (context === "startDate") {
          let endDate = moment
            .utc(date)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let effectiveTerminationDate = moment.utc(endDate).toDate();
          let startDateYear1 = moment
            .utc(effectiveTerminationDate)
            .add(1, "days")
            .toDate();
          let endDateYear1 = moment
            .utc(startDateYear1)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let startDateYear2 = moment.utc(endDateYear1).add(1, "days").toDate();
          this.setState({
            endDate,
            effectiveTerminationDate,
            startDateYear1,
            endDateYear1,
            startDateYear2,
          });
        }
        if (context === "endDate") {
          let effectiveTerminationDate = moment.utc(date).toDate();
          let startDateYear1 = moment
            .utc(effectiveTerminationDate)
            .add(1, "days")
            .toDate();
          let endDateYear1 = moment
            .utc(startDateYear1)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let startDateYear2 = moment.utc(endDateYear1).add(1, "days").toDate();
          this.setState({
            effectiveTerminationDate,
            startDateYear1,
            endDateYear1,
            startDateYear2,
          });
        }
        if (context === "effectiveTerminationDate") {
          let startDateYear1 = moment.utc(date).add(1, "days").toDate();
          let endDateYear1 = moment
            .utc(startDateYear1)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let startDateYear2 = moment.utc(endDateYear1).add(1, "days").toDate();
          this.setState({ startDateYear1, endDateYear1, startDateYear2 });
        }
        if (context === "startDateYear1") {
          let endDateYear1 = moment
            .utc(date)
            .add(1, "years")
            .subtract(1, "days")
            .toDate();
          let startDateYear2 = moment.utc(endDateYear1).add(1, "days").toDate();
          this.setState({ endDateYear1, startDateYear2 });
        }
        if (context === "endDateYear1") {
          let startDateYear2 = moment.utc(date).add(1, "days").toDate();
          let prorateYear1Id = false;
          if (
            moment
              .utc(this.state["endDateYear1"])
              .diff(moment.utc(this.state["startDateYear1"]), "days", true) +
              1 <
            365
          ) {
            prorateYear1Id = true;
          }
          this.setState({ startDateYear2, prorateYear1Id });
        }
        if (
          (context === "startDate" ||
            context === "endDate" ||
            context === "effectiveTerminationDate" ||
            context === "startDateYear1" ||
            context === "endDateYear1") &&
          this.props.employeeClass.length > 0
        ) {
          this.props.dispatch(
            arrayRemoveAll("CompanyReviewPS", "employeeClass")
          );
          this.props.dispatch(
            arrayPush("CompanyReviewPS", "employeeClass", defaultClassObject)
          );
        }
      }
    );
  };

  onChangeSponsorshipPlan = (value) => {
    this.setState({ sponsorshipPlan: value });
  };

  changeAdministrationFeeType = (id) => {
    if (id === "Fixed") {
      this.props.change("optionAFixedPercentagePercentage", "");
    }
    this.setState({ administrationFeeTypeId: id });
  };

  changeStartYearOnSameDate = (id) => {
    this.setState({ startYearOnSameDate: id });
  };

  switchGeneric = (name, id) => {
    const previousProps = this.state;

    this.setState({ [name]: id }, () => {
      if (
        name == "programPaymentTypeId" &&
        this.state.programPaymentTypeId == "postpayment"
      ) {
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `administrationFee`, 0)
        );
        this.props.dispatch(
          change(
            "FullCompanyEnrolmentPDV",
            `claimProcessingFee`,
            CLAIM_PROCESSING_FEE
          )
        );
        this.props.dispatch(
          arrayRemoveAll("CompanyReviewPS", "authorizedSigners")
        );
        this.setState({ knowWhiteLabelling: false });
        this.setState({ invoicingPeriodId: "Yearly" });
      }
      if (
        name == "programPaymentTypeId" &&
        this.state.programPaymentTypeId == "prepaymentmastercard"
      ) {
        this.props.dispatch(
          change(
            "FullCompanyEnrolmentPDV",
            `administrationFee`,
            ADMINISTRATION_FEE
          )
        );
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `claimProcessingFee`, 0)
        );
        this.props.dispatch(
          arrayPush("CompanyReviewPS", "authorizedSigners", {})
        );
      }
      if (name == "chargePEPM" && !this.state.chargePEPM) {
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `pepmMinimumFee`, 0.0)
        );
        this.props.dispatch(change("FullCompanyEnrolmentPDV", `pepmFee`, 0.0));
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `pepmFrequency`, PEPM_FREQUENCY)
        );
      }
      if (name == "chargePEPM" && this.state.chargePEPM) {
        this.props.dispatch(
          change("FullCompanyEnrolmentPDV", `pepmFee`, PEPM_FEE)
        );
        this.props.dispatch(
          change(
            "FullCompanyEnrolmentPDV",
            `pepmMinimumFee`,
            MONTHLY_EMPLOYEE_MINIMUM_FEE
          )
        );
      }
      if (name === "applyThroughBrokerageHouse" && id === false) {
        this.setState({ applyThroughBrokerageHouseName: "" });
      }
      if (name === "programTypeId" && previousProps.programTypeId !== id) {
        this.props.dispatch(
          arrayRemoveAll("FullCompanyEnrolmentPDV", "employeeClass")
        );
        this.props.dispatch(
          arrayPush(
            "FullCompanyEnrolmentPDV",
            "employeeClass",
            defaultClassObject
          )
        );
      }
    });
  };

  changeGeneric = (name, value) => {
    this.setState({ [name]: value });
  };

  overallErrorState = () => {
    if (
      moment.utc(this.state.startDate).isAfter(moment.utc(Date.now())) ||
      moment.utc(this.state.startDate).isAfter(moment.utc(Date.now()), "day") ||
      moment.utc(this.state.endDate).isBefore(this.state.startDate, "day") ||
      moment
        .utc(moment.utc(this.state.effectiveTerminationDate))
        .isBefore(moment.utc(this.state.startDate), "day") ||
      moment
        .utc(this.state.effectiveTerminationDate)
        .isAfter(this.state.endDate, "day") ||
      moment
        .utc(this.state.endDateYear1)
        .isBefore(this.state.startDateYear1, "day") ||
      moment
        .utc(this.state.endDateYear1)
        .isAfter(
          moment.utc(
            moment
              .utc(this.state.startDateYear1)
              .add(1, "years")
              .subtract(1, "days")
          ),
          "day"
        ) ||
      moment.utc(this.state.dateOfBirth).isAfter(Date.now(), "day")
    ) {
      return true;
    }
    return false;
  };

  changeAddressSelect = (value, label, name) => {
    this.setState({ [name]: value });
  };

  // chosePaymentType = (value) => {
  //   this.setState({ bankPaymentMethod: value }, () => {
  //     if (
  //       this.state.bankPaymentMethod === "pad" &&
  //       this.props.authorizedSigners.length < 1
  //     ) {
  //       this.props.dispatch(
  //         arrayPush("CompanyReviewPS", "authorizedSigners", {})
  //       );
  //     }
  //     if (
  //       this.state.bankPaymentMethod !== "pad" &&
  //       this.props.authorizedSigners.length > 0
  //     ) {
  //       this.props.dispatch(
  //         arrayRemoveAll("CompanyReviewPS", "authorizedSigners")
  //       );
  //     }
  //   });
  // };

  render() {
    const { styles, handleChange, userRole } = this.props;
    const {
      businessType,
      bhaLabel,
      brokerLabel,
      tpaLabel,
      bhaGuid,
      brokerGuid,
      tpaGuid,
      operatingInCanada,
      chargePEPM,
      ayaSalesRepLabel,
    } = this.state;
    return (
      <div className={styles.contentContainerX}>
        <div className={styles.description}>
          <Stepper
            steps={steps}
            activeStep={5}
            context={"company-full"}
            businessOperatedName={this.props.businessOperatedName}
            // stepClickEvent={this.stepClickEvent}
            completed={true}
          />
        </div>
        {/* Plan Set Up */}
        {this.state.previousProgram && (
          <div className={styles.section}>
            <div className={styles.sectionContent}>
              <div className={styles.subHeaderLabels}>Plan Set Up</div>
            </div>
            <div>
              <React.Fragment>
                <div className={styles.InputContainer}>
                  <div className={styles.inputLabelStylingCopy}>
                    Which benefits provider are you transitioning from?
                  </div>
                  <Field
                    component={ConnectedInput}
                    label=""
                    name="programName"
                    type="text"
                    placeholder="Enter Previous Program Name"
                    onChange={(value) =>
                      this.changeGeneric("programName", value)
                    }
                  />
                  <br />
                  <div className={styles.inputContainer}>
                    <DateInput
                      label="Previous Plan Start Date"
                      name="startDate"
                      type="text"
                      extrastyle="medium"
                      error={
                        moment
                          .utc(this.state.startDate)
                          .isAfter(moment.utc(Date.now()), "day")
                          ? "Cannot be greater than today"
                          : ""
                      }
                      selected={this.state.startDate}
                      handleDateChange={(date, name) =>
                        this.handleGenenicDateChange(date, name)
                      }
                    />
                    <div style={{ paddingRight: 20, marginRight: 10 }} />
                    <DateInput
                      label="Previous Plan End Date"
                      name="endDate"
                      type="text"
                      error={
                        moment
                          .utc(this.state.endDate)
                          .isBefore(this.state.startDate, "day")
                          ? "Cannot be before previous plan start date"
                          : ""
                      }
                      extrastyle="medium"
                      selected={this.state.endDate}
                      handleDateChange={(date, name) =>
                        this.handleGenenicDateChange(date, name)
                      }
                    />
                  </div>
                  <DateInput
                    label="When will your plan with the previous provider end?"
                    name="effectiveTerminationDate"
                    type="text"
                    error={
                      moment
                        .utc(moment.utc(this.state.effectiveTerminationDate))
                        .isBefore(moment.utc(this.state.startDate), "day")
                        ? "Cannot be before previous plan start date"
                        : moment
                            .utc(this.state.effectiveTerminationDate)
                            .isAfter(this.state.endDate, "day")
                        ? "Cannot be after previous plan end date"
                        : ""
                    }
                    // extrastyle="medium"
                    selected={this.state.effectiveTerminationDate}
                    handleDateChange={(date, name) =>
                      this.handleGenenicDateChange(date, name)
                    }
                  />
                  <div className={styles.inputLabelStylingCopy}>
                    When would you like your plan with Aya to start?
                  </div>
                  <DateInput
                    label=""
                    name="startDateYear1"
                    type="text"
                    // extrastyle="medium"
                    selected={this.state.startDateYear1}
                    handleDateChange={(date, name) =>
                      this.handleGenenicDateChange(date, name)
                    }
                  />
                </div>

                <div
                  className={styles.msgContainer}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Icon name="info circle" />
                  Your plan will be eligible for renewal on an annual basis.
                  <br />
                  {`Aya Program Renewal will be ${moment
                    .utc(this.state.startDateYear2)
                    .format("Do MMMM, YYYY")}`}
                </div>
              </React.Fragment>
            </div>
          </div>
        )}
        {/* Plan Product */}
        <div className={styles.section}>
          <div className={styles.sectionContent}>
            <div className={styles.sectionTitle}>
              {/* <div className={styles.subHeaderLabels}>
                Section 2: Plan Product
              </div> */}
              <div className={styles.subHeaderLabels}>Plan Product</div>
            </div>
            <div className={styles.inputContainer}>
              <DateInput
                label="Year 1 Start Date"
                name="startDateYear1"
                type="text"
                extrastyle="medium"
                selected={this.state.startDateYear1}
                handleDateChange={(date, name) =>
                  this.handleGenenicDateChange(date, name)
                }
              />
              <div style={{ paddingRight: 20, marginRight: 10 }} />
              <DateInput
                label="Year 1 End Date"
                name="endDateYear1"
                error={
                  moment
                    .utc(this.state.endDateYear1)
                    .isBefore(this.state.startDateYear1, "day")
                    ? "Cannot be before year 1 start date"
                    : moment
                        .utc(this.state.endDateYear1)
                        .isAfter(
                          moment.utc(
                            moment
                              .utc(this.state.startDateYear1)
                              .add(1, "years")
                          )
                        )
                    ? "Difference between year 1 start and end date cannot be greater than 1 year"
                    : ""
                }
                type="text"
                extrastyle="medium"
                selected={this.state.endDateYear1}
                handleDateChange={(date, name) =>
                  this.handleGenenicDateChange(date, name)
                }
              />
            </div>
            <div className={styles.standaloneLabelContainer}>
              <div className={styles.standaloneLabel}>
                What type of coverage are you interested in?
              </div>
            </div>
            <TextCardList
              name="programType"
              selectedId={this.state.programTypeId}
              items={ProgramTypeList}
              onChange={(id) => {
                this.switchGeneric("programTypeId", id);
              }}
            />
            <div className={styles.standaloneLabelContainer}>
              <div className={styles.standaloneLabel}>
                What type of product are you interested in?
              </div>
            </div>
            <TextCardList
              name="programPaymentType"
              selectedId={this.state.programPaymentTypeId}
              items={ProgramPaymentTypeList}
              onChange={(id) => {
                this.switchGeneric("programPaymentTypeId", id);
              }}
            />
            {this.state.programPaymentTypeId === "postpayment" && (
              <div className={styles.headingContainer}>
                <div
                  className={styles.infoTextLabel}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Invoice company after claim is approved
                </div>
              </div>
            )}
            {this.state.programPaymentTypeId === "prepaymentmastercard" && (
              <React.Fragment>
                <div className={styles.headingContainer}>
                  <div
                    className={styles.infoTextLabel}
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    Great choice, Say goodbye to out of pocket expenses!
                  </div>
                  <div
                    className={styles.infoTextLabel}
                    style={{ marginTop: "20px" }}
                  >
                    Did you know we offer White Labelling options!
                  </div>
                  <div
                    className={styles.standaloneLabel}
                    style={{ paddingTop: "0px" }}
                  >
                    This means the card itself and your Aya Portals can be
                    branded to your company!
                  </div>
                  <div
                    className={styles.standaloneLabel}
                    style={{ marginTop: "30px" }}
                  >
                    Are you interested in learning more about this option?
                  </div>
                  <TextCardList
                    name="knowWhiteLabelling"
                    selectedId={this.state.knowWhiteLabelling}
                    items={KnowWhiteLabellingList}
                    onChange={(id) => {
                      this.switchGeneric("knowWhiteLabelling", id);
                    }}
                  />
                  {this.state.knowWhiteLabelling === true && (
                    <React.Fragment>
                      <div className={styles.msgContainer}>
                        Super! We'll be in contact with the options available to
                        you
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.knowWhiteLabelling === false && (
                    <React.Fragment>
                      <div className={styles.msgContainer}>
                        No worries! The Aya brand is one worth showing off!
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        {/* Plan Allocation */}
        <div className={styles.section}>
          <div className={styles.subHeaderLabels}>Plan Allocation</div>
          <div className={styles.sectionContent}>
            {this.state.programPaymentTypeId === "prepaymentmastercard" && (
              <React.Fragment>
                <div className={styles.standaloneLabelContainer}>
                  <div className={styles.standaloneLabel}>
                    How often should employee balances be topped up?
                    <Popup
                      content={
                        <React.Fragment>
                          {
                            "This will also set the frequency you will receive a contribution invoice. Once the funds are received, the amount will be uploaded to the users card!"
                          }
                        </React.Fragment>
                      }
                      position="top center"
                      trigger={
                        <img src={Images.question} className={styles.tooltip} />
                      }
                    />
                  </div>
                </div>
                <TextCardList
                  name="invoicingPeriod"
                  selectedId={this.state.invoicingPeriodId}
                  items={InvoicingPeriodList}
                  onChange={(id) => {
                    this.switchGeneric("invoicingPeriodId", id);
                  }}
                />
              </React.Fragment>
            )}

            {this.state.programPaymentTypeId === "postpayment" && (
              <React.Fragment>
                <div className={styles.standaloneLabelContainer}>
                  <div className={styles.standaloneLabel}>
                    How often should we process your claim invoices, which are
                    based on usage?
                    <Popup
                      content={
                        <React.Fragment>
                          {
                            "Employees will make claims, and we will invoice based on the following frequencies to ensure funds are available for reimbursement after adjudication."
                          }
                        </React.Fragment>
                      }
                      position="top center"
                      trigger={
                        <img
                          src={Images.question}
                          className={styles.tooltip}
                          width="20px"
                        />
                      }
                    />
                  </div>
                </div>
                <TextCardList
                  name="postpaymentFundsPullingRate"
                  selectedId={this.state.postpaymentFundsPullingRateId}
                  items={PostpaymentFundsPullingRateList}
                  onChange={(id) => {
                    this.switchGeneric("postpaymentFundsPullingRateId", id);
                  }}
                />
              </React.Fragment>
            )}

            {this.state.programPaymentTypeId === "postpayment" && (
              <React.Fragment>
                <div className={styles.headingContainer}>
                  <div className={styles.msgContainer}>
                    Aya offers instant claim reimbursements using a float, with
                    companies depositing and replenishing funds as needed.
                  </div>
                  <div className={styles.standaloneLabelContainer}>
                    <div className={styles.standaloneLabel}>
                      Interested in setting up a float?
                    </div>
                  </div>
                  <TextCardList
                    name="postpaymentFloatOrDeposit"
                    selectedId={this.state.floatBalanceDeposit}
                    items={PostpaymentFundsFloatOrDepositList}
                    onChange={(id) => {
                      this.switchGeneric("floatBalanceDeposit", id);
                    }}
                  />
                </div>
              </React.Fragment>
            )}

            <div
              className={styles.headingContainer}
              style={{
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              <div className={styles.standaloneLabelContainer}>
                <div className={styles.standaloneLabel}>
                  Would you like to prorate plan allocation for employees
                  starting mid-plan year?
                  <Popup
                    content={
                      <React.Fragment>
                        <p>
                          {
                            'Answer "Yes": Funds will be prorated from the employee’s enrolment date.'
                          }
                        </p>
                        <p>
                          {
                            'Answer "No": The employee will receive the full allocation regardless of start date.'
                          }
                        </p>
                      </React.Fragment>
                    }
                    position="top center"
                    trigger={
                      <img src={Images.question} className={styles.tooltip} />
                    }
                  />
                </div>
              </div>
              <TextCardList
                name="prorateAfterGivenYear"
                selectedId={this.state.prorateAfterGivenYearId}
                items={ProrateAfterGivenYearList}
                onChange={(id) => {
                  this.switchGeneric("prorateAfterGivenYearId", id);
                }}
              />
              {this.state.prorateAfterGivenYearId === true && (
                <React.Fragment>
                  <div className={styles.msgContainer}>
                    Funds will be prorated from the employee's enrolment date.
                  </div>
                </React.Fragment>
              )}
              {this.state.prorateAfterGivenYearId === false && (
                <React.Fragment>
                  <div className={styles.msgContainer}>
                    The employee will receive the full allocation regardless of
                    start date.
                  </div>
                </React.Fragment>
              )}
            </div>

            <div className={styles.standaloneLabel}>
              Do you want to allow employees to add dependents?
              <Popup
                content={
                  <React.Fragment>
                    <p>
                      Allowing this option incurs no extra cost to the employer,
                      employees can share the allocation and can submit claims
                      for dependents.
                    </p>
                  </React.Fragment>
                }
                position="top center"
                trigger={
                  <img src={Images.question} className={styles.tooltip} />
                }
              />
            </div>
            <TextCardList
              name="dependents"
              selectedId={this.state.dependents}
              items={AllowDependentsList}
              onChange={(id) => {
                this.switchGeneric("dependents", id);
              }}
            />
          </div>
        </div>
        {/* Contribution Classes */}
        <div className={styles.section}>
          {/* <div className={styles.subHeaderLabels}>
            Section 4: Contributions Classes
          </div> */}
          <div className={styles.subHeaderLabels}>Contribution Classes</div>
          <div
            className={styles.msgContainer}
            style={{
              marginBottom: "0px",
            }}
          >
            <p>
              Create up to 5 classes to provide your Plan Admin a guide, making
              it easy for them to enrol users and allocate funds through the
              portal.
            </p>
            <p>What would you like to name your first class?</p>
          </div>
          <div className={styles.sectionContent}>
            <FieldArray
              name="employeeClass"
              component={this.renderClasses}
              styles={styles}
              mystate={this.state}
              onGenericChange={this.onGenericChange}
            />
          </div>
          <div
            className={styles.msgContainer}
            style={{
              marginBottom: "0px",
            }}
          >
            <p>
              Who is eligible for Aya benefits? All T4 employees including
              part-time, full time or T4A contractors.
            </p>
          </div>
        </div>
        {/* Grace Period */}
        <div className={styles.section}>
          <div className={styles.subHeaderLabels}>Grace Period</div>

          <div className={styles.sectionContent}>
            <div className={styles.standaloneLabel}>
              Last day to submit a claim in previous plan year
            </div>
            <TextCardList
              selectedId={this.state.daysToSubmitClaimPreviousYearId}
              items={LastDayToSubmitInYearList}
              onChange={(id) => {
                this.switchGeneric("daysToSubmitClaimPreviousYearId", id);
              }}
            />
            <div className={styles.standaloneLabel}>
              Last day to submit a claim following termination date
            </div>
            <TextCardList
              selectedId={this.state.lastDayToSubmitAfterTerminationId}
              items={LastDayToSubmitAfterTerminationList}
              onChange={(id) => {
                this.switchGeneric("lastDayToSubmitAfterTerminationId", id);
              }}
            />
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionContent}>
            {/* <div className={styles.subHeaderLabels}>Section 6: Plan Fees</div> */}
            <div className={styles.subHeaderLabels}>Plan Fees</div>
            <div
              className={styles.msgContainer}
              style={{
                marginBottom: "50px",
              }}
            >
              Each account includes individual employee and plan admin portals
              for real-time reports, user management, and access to claims with
              built-in adjudication and reimbursement.
            </div>

            {/* {!this.state.isCustomEnrolment && (
              <>
                <div className={styles.standaloneLabel}>
                  Will your company be applying through a Brokerage House?
                </div>
                <TextCardList
                  name="applyThroughBrokerageHouse"
                  selectedId={this.state.applyThroughBrokerageHouse}
                  items={ApplyThroughBrokerageHouseList}
                  onChange={(id) => {
                    this.switchGeneric("applyThroughBrokerageHouse", id);
                  }}
                />

                {this.state.applyThroughBrokerageHouse && (
                  <React.Fragment>
                    <div
                      className={styles.headingContainer}
                      style={{
                        marginBottom: "50px",
                        marginTop: "0px",
                      }}
                    >
                      {this.state.programPaymentTypeId !== "postpayment" && (
                        <div
                          className={styles.infoTextLabel}
                          style={{
                            paddingLeft: 0,
                            marginBottom: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          Great! The Admin Fee may vary, and a Broker portal
                          will be provided.
                        </div>
                      )}
                      {this.state.programPaymentTypeId === "postpayment" && (
                        <div
                          className={styles.infoTextLabel}
                          style={{
                            paddingLeft: 0,
                            marginBottom: "20px",
                          }}
                        >
                          Great! The Claim Fee may vary, and a Broker portal
                          will be provided.
                        </div>
                      )}

                      <div className={styles.standaloneLabel}>
                        What is the name of your broker or brokerage firm?
                      </div>
                      <div className={styles.InputContainer}>
                        <Field
                          component={ConnectedInput}
                          label=""
                          name="applyThroughBrokerageHouseName"
                          type="text"
                          placeholder="Enter Brokerage House Name"
                          onChange={(event, newValue) => {
                            this.changeGeneric(
                              "applyThroughBrokerageHouseName",
                              newValue
                            );
                          }}
                          value={this.state.applyThroughBrokerageHouseName}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </>
            )} */}

            <>
              <div
                className={styles.msgContainer}
                style={{
                  marginBottom: "50px",
                }}
              >
                During onboarding, your appointed Plan Admin and enroled team
                members will receive personal portals to manage the associated
                accounts. We also provide portals to your appointed advisor.
              </div>

              <React.Fragment>
                <div
                  className={styles.headingContainer}
                  style={{
                    marginBottom: "50px",
                    marginTop: "0px",
                  }}
                >
                  <div
                    className={styles.infoTextLabel}
                    style={{
                      paddingLeft: 0,
                      marginBottom: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    Advisor Details:
                  </div>

                  <div className={styles.standaloneLabel}>
                    Advisor Company Name:
                  </div>
                  <div className={styles.InputContainer}>
                    <Field
                      component={ConnectedInput}
                      label=""
                      name="advisorCompanyName"
                      type="text"
                      placeholder="Company Name"
                      onChange={(event, newValue) => {
                        this.changeGeneric("advisorCompanyName", newValue);
                      }}
                      value={this.state.advisorCompanyName}
                    />
                  </div>

                  <div className={styles.standaloneLabel}>First Name:</div>
                  <div className={styles.InputContainer}>
                    <Field
                      component={ConnectedInput}
                      label=""
                      name="advisorFirstName"
                      type="text"
                      placeholder="First Name"
                      onChange={(event, newValue) => {
                        this.changeGeneric("advisorFirstName", newValue);
                      }}
                      value={this.state.advisorFirstName}
                    />
                  </div>

                  <div className={styles.standaloneLabel}>Last Name:</div>
                  <div className={styles.InputContainer}>
                    <Field
                      component={ConnectedInput}
                      label=""
                      name="advisorLastName"
                      type="text"
                      placeholder="Last Name"
                      onChange={(event, newValue) => {
                        this.changeGeneric("advisorLastName", newValue);
                      }}
                      value={this.state.advisorLastName}
                    />
                  </div>

                  <div className={styles.standaloneLabel}>Email:</div>
                  <div className={styles.InputContainer}>
                    <Field
                      component={ConnectedInput}
                      label=""
                      name="advisorEmail"
                      type="text"
                      placeholder="Email"
                      onChange={(event, newValue) => {
                        this.changeGeneric("advisorEmail", newValue);
                      }}
                      value={this.state.advisorEmail}
                    />
                  </div>
                </div>
              </React.Fragment>
            </>

            {this.state.programPaymentTypeId !== "postpayment" ? (
              <React.Fragment>
                <div className={styles.standaloneLabel}>
                  All cards are custom-made and shipped directly to users, with
                  activation instructions sent via email upon enrollment.
                </div>

                <ul>
                  <li>
                    <div className={styles.standaloneLabel}>
                      Administration (Administration Fee - On Defined
                      Contribution):{" "}
                      <div className={styles.inputWithSideLabel2}>
                        <Field
                          component={FormattedInput}
                          name="administrationFee"
                          suffix="%"
                          extrastyle="small"
                          validate={[required]}
                          type="text"
                          placeholder="0"
                          decimalScale={2}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={styles.standaloneLabel}>
                      Program Fee Per Employee Per Month (PEPM) :{" "}
                      <strong>${cleanUpCurrency(EMPLOYEE_MONTHLY_FEE)}</strong>
                    </div>
                  </li>
                  <li>
                    <div className={styles.standaloneLabel}>
                      Card issuance fee :{" "}
                      <strong>${cleanUpCurrency(CARD_ISSUANCE_FEE)}</strong>
                      <span className={styles.sideLabel}>
                        For every Card issued
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className={styles.standaloneLabel}>
                      Employee Onboarding Fee :{" "}
                      <strong>${EMPLOYEE_SETUP_FEE}</strong>
                      <span className={styles.sideLabel}>
                        One time charge per employee
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className={styles.standaloneLabel}>
                      Company Onboarding Fee : <strong>${SETUP_FEE}</strong>
                      <span className={styles.sideLabel}>One time charge</span>
                    </div>
                  </li>
                </ul>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={styles.standaloneLabel}>
                  Claim Processing Fee:{" "}
                  <div className={styles.inputWithSideLabel2}>
                    <Field
                      component={FormattedInput}
                      name="claimProcessingFee"
                      suffix="%"
                      extrastyle="tiny"
                      validate={[required]}
                      type="text"
                      placeholder="0"
                      decimalScale={2}
                    />
                    <span className={styles.sideLabel}>
                      Per claim submitted and approved (max 15%)
                    </span>
                    <Popup
                      content={
                        <React.Fragment>
                          {
                            "Charges apply per claim for participating employees after submission, adjudication, and approval."
                          }
                        </React.Fragment>
                      }
                      position="top center"
                      trigger={
                        <img src={Images.question} className={styles.tooltip} />
                      }
                    />
                  </div>
                </div>
                <div className={styles.standaloneLabel}>
                  Company Onboarding Fee:{" "}
                  <strong>${COMPANY_ONBOARDING_FEES}</strong>
                  <span className={styles.sideLabel}>One time charge</span>
                </div>
                <div className={styles.standaloneLabel}>
                  Employee Onboarding Fee <strong>${EMPLOYEE_SETUP_FEE}</strong>
                  <span className={styles.sideLabel}>
                    One time charge per employee
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <div className={styles.standaloneLabel}>
                    PEPM Program Fee:{" "}
                  </div>
                  <div
                    style={{
                      marginLeft: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div>$50 Flat Rate for 1-5 Employees</div>
                    <div>$10 per additional employee</div>
                  </div>
                  <div
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    <Popup
                      content={
                        <React.Fragment>
                          {
                            "Per Employee Per Month, Aya issues a separate monthly invoice for PEPM Charges."
                          }
                        </React.Fragment>
                      }
                      position="top center"
                      trigger={
                        <img src={Images.question} className={styles.tooltip} />
                      }
                    />
                  </div>
                </div>
                {chargePEPM && (
                  <>
                    <div
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <div className={styles.superSubHeader}>
                        Did you know Aya offers a 20% Discount?
                      </div>
                      <div className={styles.standaloneLabel}>
                        This discount applies to PEPM Fees, when you pay upfront
                        for the year.
                      </div>
                      <div className={styles.standaloneLabel}>
                        Are you interested in this offer?
                      </div>
                      <TextCardList
                        name="pepmFrequency"
                        selectedId={this.state.pepmFrequency}
                        items={PepmFrequencyList}
                        onChange={(id) => {
                          this.switchGeneric("pepmFrequency", id);
                        }}
                      />
                    </div>
                  </>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionContent}>
            <div className={styles.standaloneLabel}>
              Before we proceed, if you have any notes, comments, or questions
              about the information above, please add them here.
            </div>
            <div className={styles.borderBox}>
              <Field
                component={ConnectedTextArea}
                label=""
                name="internalNote"
                // placeholder="Internal Note (note visible to the company contact)"
              />
            </div>
          </div>
        </div>
        {/* Company Information */}
        <div className={styles.section}>
          {/* <div className={styles.subHeaderLabels}>
            Section 7: Company Information
          </div> */}
          <div className={styles.subHeaderLabels}>Company Information</div>
          <div className={styles.section}>
            <Field
              component={ConnectedInput}
              label="Legal Business Name"
              name="businessLegalName"
              type="text"
              disabled={true}
              placeholder="New Company LLC"
              onKeyUp={handleChange}
            />
            <Field
              component={ConnectedInput}
              label="Company Name (Doing Business As)"
              name="businessOperatedName"
              type="text"
              max={23}
              disabled={true}
              placeholder="New Company LLC"
              onKeyUp={handleChange}
            />
            <CustomSelect
              label="Is the Business Operating in Canada?"
              name="operatingInCanada"
              placeholder="Select"
              extrastyle="fullWidth"
              onChange={(value) =>
                this.changeSelect("operatingInCanada", value)
              }
              options={BusinessOperatedInCAList}
              value={operatingInCanada}
            />
            {operatingInCanada === "Yes, Via PEO (or equivalent)" && (
              <React.Fragment>
                <div className={styles.leftIndentContainer}>
                  <Field
                    component={ConnectedInput}
                    label="Name of PEO"
                    name="peoName"
                    type="text"
                    disabled={true}
                    placeholder="Name of PEO"
                  />
                  <Field
                    component={ConnectedInput}
                    label="PEO Contact Name"
                    labelDetail="Optional"
                    name="peoContactName"
                    type="text"
                    disabled={true}
                    placeholder="PEO Contact Name"
                  />
                  <Field
                    component={ConnectedInput}
                    label="PEO Contact Email"
                    labelDetail="Optional"
                    name="peoContactEmail"
                    type="email"
                    disabled={true}
                    placeholder="PEO Contact Email"
                  />
                </div>
              </React.Fragment>
            )}
            <CustomSelect
              label="Company Type"
              name="businessType"
              onChange={(value) => this.changeSelect("businessType", value)}
              options={CompanyTypeList}
              value={businessType}
            />
            {
              //When Business is US registered and a corporation
              operatingInCanada === "No, USA Registered" && (
                <React.Fragment>
                  <div className={styles.leftIndentContainer}>
                    <Field
                      component={ConnectedInput}
                      label="TAX ID Number / EIN"
                      name="taxIdOrEinNumber"
                      type="text"
                      disabled={true}
                      placeholder="0000000000"
                    />
                  </div>
                </React.Fragment>
              )
            }
            {
              //When Business is US registered and a corporation
              operatingInCanada === "No, International (Non-USA)" && (
                <React.Fragment>
                  <div className={styles.leftIndentContainer}>
                    <Field
                      component={ConnectedInput}
                      label="TAX ID Number / TIN"
                      name="taxIdOrTinNumber"
                      type="text"
                      disabled={true}
                      placeholder="0000000000"
                    />
                  </div>
                </React.Fragment>
              )
            }
            {(operatingInCanada === "Yes, Registered" ||
              operatingInCanada === "Yes, Via PEO (or equivalent)") &&
              businessType && (
                // this.state.programPaymentTypeId !== "postpayment" && (
                <React.Fragment>
                  <div className={styles.leftIndentContainer}>
                    <div className={styles.standaloneLabel}>
                      9 Digit CRA Business Registered Tax Number
                    </div>
                    <Field
                      component={ConnectedInput}
                      label=""
                      name="craBusinessNumber"
                      type="text"
                      disabled={true}
                      placeholder="00000000"
                      extrastyle="medium"
                    />
                  </div>
                </React.Fragment>
              )}
            {(operatingInCanada === "Yes, Registered" ||
              operatingInCanada === "Yes, Via PEO (or equivalent)") &&
              businessType &&
              businessType.toLowerCase() === "corporation" &&
              this.state.programPaymentTypeId !== "postpayment" && (
                <React.Fragment>
                  <div className={styles.leftIndentContainer}>
                    <Field
                      component={ConnectedInput}
                      label="Corporate Number"
                      name="corporateNumber"
                      type="text"
                      disabled={true}
                      placeholder="0000000000"
                      tooltipMessage={
                        "As found in your Articles of Incorporation"
                      }
                    />
                    <div className={styles.standaloneLabel}>
                      Place of Corp. Registration
                    </div>
                    <RadioGroup
                      items={CorporateNumberTypeList}
                      value={this.state.corporateNumberType}
                      noBackground={true}
                      name="corporateNumberType"
                      onUpdate={(value) =>
                        this.onChangeGenericRadio(value, "corporateNumberType")
                      }
                    />
                  </div>
                </React.Fragment>
              )}
            {this.state.corporateNumberType === "Provincial" &&
              businessType &&
              businessType.toLowerCase() === "corporation" && (
                <CustomSelect
                  label="Province of Registration"
                  name="provinceOfRegisteredOffice"
                  value={this.state.provinceOfRegisteredOffice}
                  onChange={(value) =>
                    this.changeSelect("provinceOfRegisteredOffice", value)
                  }
                  options={ProvinceList}
                />
              )}
          </div>
          <div className={styles.section}>
            <div className={styles.sectionContent}>
              <div className={styles.standaloneLabel}>
                Business Registered Address
              </div>
              {this.state.streetOne && !this.state.businessAddressEditMode ? (
                <React.Fragment>
                  <br />
                  <Button
                    name="Edit Address"
                    style="primary"
                    onClick={() =>
                      this.toggleGeneric("businessAddressEditMode")
                    }
                  />
                  <br />
                </React.Fragment>
              ) : (
                <GoogleSuggest
                  name="businessAddress"
                  onKeyUp={this.handleChange}
                  setAddress={this.setAddress}
                  labelDetail="P. O. Box Not Accepted"
                />
              )}
              {(this.state.streetOne || this.state.city) && (
                <React.Fragment>
                  <Input
                    label="Address 1"
                    name="streetOne"
                    type="text"
                    placeholder="Address 1"
                    onKeyUp={this.handleChange}
                    myValue={this.state.streetOne}
                  />
                  <Input
                    label="Address 2 (e.g., Apt Number, Suite Number…etc)"
                    name="streetTwo"
                    type="text"
                    placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
                    onKeyUp={this.handleChange}
                    myValue={this.state.streetTwo}
                  />
                  <div className={styles.inputContainer}>
                    <Input
                      label="City"
                      name="city"
                      extrastyle="semi-medium"
                      type="text"
                      placeholder="City"
                      onKeyUp={this.handleChange}
                      myValue={this.state.city}
                    />
                    <Input
                      label="Province/State"
                      name="province"
                      type="text"
                      extrastyle="medium-with-margin"
                      placeholder="State/Province"
                      onKeyUp={this.handleChange}
                      myValue={this.state.province}
                    />
                    <CustomSelect
                      label="Country"
                      name="country"
                      extrastyle="medium-with-margin"
                      onChange={(value, label, name) =>
                        this.changeAddressSelect(value, label, name)
                      }
                      options={GlobalCountryList}
                      value={this.state.country}
                    />
                  </div>
                  <Input
                    label="Postal Code/ZIP"
                    name="postalCode"
                    type="text"
                    error={
                      (this.state.postalCode &&
                        this.state.country === "US" &&
                        postalCodeUS(this.state.postalCode)) ||
                      (this.state.country === "CA" &&
                        postalCodeCA(this.state.postalCode))
                    }
                    extrastyle="semi-medium"
                    placeholder="Zip/Postal Code"
                    onKeyUp={this.handleChange}
                    myValue={this.state.postalCode}
                  />
                </React.Fragment>
              )}
              <div className={styles.standaloneLabel}>Mailing Address</div>
              {this.state.mailingStreetOne &&
              !this.state.mailingAddressEditMode ? (
                <React.Fragment>
                  <br />
                  <Button
                    name="Edit Mailing Address"
                    style="primary"
                    onClick={() => this.toggleGeneric("mailingAddressEditMode")}
                  />
                  <br />
                </React.Fragment>
              ) : (
                <GoogleSuggest
                  name="mailingAddress"
                  labelDetail="P. O. Box Not Accepted"
                  setAddress={this.setAddress}
                  onKeyUp={this.handleChange}
                />
              )}
              {(this.state.mailingStreetOne || this.state.mailingCity) && (
                <React.Fragment>
                  <Input
                    label="Address 1"
                    name="mailingStreetOne"
                    type="text"
                    placeholder="Address 1"
                    onKeyUp={this.handleChange}
                    myValue={
                      this.state.sameAddress
                        ? this.state.streetOne
                        : this.state.mailingStreetOne
                    }
                  />
                  <Input
                    label="Address 2 (e.g., Apt Number, Suite Number…etc)"
                    name="mailingStreetTwo"
                    type="text"
                    placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
                    onKeyUp={this.handleChange}
                    myValue={
                      this.state.sameAddress
                        ? this.state.streetTwo
                        : this.state.mailingStreetTwo
                    }
                  />
                  <div className={styles.inputContainer}>
                    <Input
                      label="City"
                      name="mailingCity"
                      extrastyle="semi-medium"
                      type="text"
                      placeholder="City"
                      onKeyUp={this.handleChange}
                      myValue={
                        this.state.sameAddress
                          ? this.state.city
                          : this.state.mailingCity
                      }
                    />
                    <Input
                      label="Province/State"
                      name="mailingProvince"
                      type="text"
                      extrastyle="medium-with-margin"
                      placeholder="State/Province"
                      onKeyUp={this.handleChange}
                      myValue={
                        this.state.sameAddress
                          ? this.state.province
                          : this.state.mailingProvince
                      }
                    />
                    <CustomSelect
                      label="Country"
                      name="mailingCountry"
                      extrastyle="medium-with-margin"
                      onChange={(value, label, name) =>
                        this.changeAddressSelect(value, label, name)
                      }
                      options={GlobalCountryList}
                      value={
                        this.state.sameAddress
                          ? this.state.country
                          : this.state.mailingCountry
                      }
                    />
                  </div>
                  <Input
                    label="Postal Code/ZIP"
                    name="mailingPostalCode"
                    type="text"
                    extrastyle="semi-medium"
                    placeholder="Zip/Postal Code"
                    onKeyUp={this.handleChange}
                    myValue={
                      this.state.sameAddress
                        ? this.state.postalCode
                        : this.state.mailingPostalCode
                    }
                  />
                </React.Fragment>
              )}
              <br />
              <Field
                component={ConnectedInput}
                label="Company Website"
                name="website"
                labelDetail={"optional"}
                type="text"
                disabled={true}
                extrastyle={"withCheckBox"}
                placeholder="http://www.example.com"
              />
              <br />
              {this.state.programPaymentTypeId !== "postpayment" && (
                <React.Fragment>
                  <CustomSelect
                    label="Business Industry"
                    name="businessIndustry"
                    onChange={(value, label) =>
                      this.changeSelectIndustry("businessIndustry", label)
                    }
                    options={this.state.industryList}
                    value={this.state.businessIndustry}
                  />
                  <CustomSelect
                    label="Industry Classification Type"
                    name="industryClassificationType"
                    onChange={(value, label) =>
                      this.changeSelect("industryClassificationType", label)
                    }
                    options={this.state.industryClassificationList}
                    value={this.state.industryClassificationType}
                  />
                </React.Fragment>
              )}
              <CustomSelect
                label="Number of T4 & T4A Employees"
                name="estimatedNumberOfEmployees"
                onChange={(value) =>
                  this.changeSelect("estimatedNumberOfEmployees", value)
                }
                options={EmployeeDropdownList}
                value={this.state.estimatedNumberOfEmployees}
              />
            </div>
            {this.state.exchange &&
              this.state.programPaymentTypeId !== "postpayment" && (
                <div className={styles.inputContainer}>
                  <div
                    className={`${styles.checkboxDropdownContainer} ${
                      this.state.exchange ? `` : ""
                    }`}
                  >
                    <div className={styles.multiInputContainer}>
                      <Field
                        component={ConnectedInput}
                        label="Stock Symbol"
                        name="stockSymbol"
                        type="text"
                        extrastyle="small"
                        placeholder="Symbol"
                      />
                      <div style={{ paddingRight: 20, marginRight: 10 }} />
                      <CustomSelect
                        selectLabel={"Exchange"}
                        name="exchange"
                        extrastyle="largeWithoutMarginTop"
                        onChange={(value) =>
                          this.changeSelect("exchange", value)
                        }
                        options={ExchangeDropdownList}
                        value={this.state.exchange}
                      />
                    </div>
                  </div>
                </div>
              )}
            <br />
            {/* {this.props.canadaAddresses &&
              this.props.canadaAddresses.length > 0 &&
              this.state.programPaymentTypeId !== "postpayment" && (
                <React.Fragment>
                  <div className={styles.standaloneLabel}>
                    This company has other addresses within Canada
                  </div>
                  <FieldArray
                    name="canadaAddresses"
                    component={this.renderCanadaAddresses}
                    styles={styles}
                    mystate={this.state}
                  />
                </React.Fragment>
              )} */}
            {this.props.otherAddresses &&
              this.props.otherAddresses.length > 0 &&
              this.state.programPaymentTypeId !== "postpayment" && (
                <React.Fragment>
                  <div className={styles.superSubHeader}>
                    This company has other addresses outside of Canada
                  </div>
                  <FieldArray
                    name="otherAddresses"
                    component={this.renderOtherAddresses}
                    styles={styles}
                    mystate={this.state}
                  />
                </React.Fragment>
              )}
          </div>
        </div>
        {/* FATCA CRS Declaration */}
        <React.Fragment>
          {/* <Divider /> */}
          <div className={styles.section}>
            {/* <div className={styles.subHeaderLabels}>
              Section 8: FATCA CRS Declaration
            </div> */}
            <div className={styles.subHeaderLabels}>FATCA CRS Declaration</div>
            <div className={styles.description}>
              Aya Payments, collects, through it's third-party agents
              Know-Your-Clients as mandaled under the Proceeds of Crime, Money
              Laundering and Terrorist Financing Act (PCMLTFA) on individuals
              and on corporate entities. Inaddition , for the specific purposes
              of compliance, Aya Payments will be required to collect
              Declaration of Tax Residence for Entities (RC519E).{" "}
              <a
                href="https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/rc519.html"
                target="_blank"
              >
                {" "}
                Learn More{" "}
              </a>
            </div>
            <div className={styles.sectionContent}>
              <div className={styles.standaloneLabel}>
                Does your business have tax obligations in any other country
                other than Canada?
              </div>
              <RadioGroup
                items={FATCAList}
                value={this.state.fatca}
                noBackground={true}
                name="fatca"
                onUpdate={(value) => this.onChangeGenericRadio(value, "fatca")}
              />
            </div>
          </div>
        </React.Fragment>
        {/* Corporate Contact */}
        <div className={styles.section}>
          {/* <div className={styles.subHeaderLabels}>
            Section {this.state.programPaymentType !== "postpayment" ? 9 : 7}:
            Corporate Contact
          </div> */}
          <div className={styles.subHeaderLabels}>Corporate Contact</div>
          <div className={styles.sectionContent}>
            <div className={styles.inputContainer}>
              <Field
                component={ConnectedInput}
                label="First Name"
                name="firstName"
                type="text"
                disabled={true}
                placeholder="Enter First Name"
                extrastyle="medium"
              />
              <div style={{ paddingRight: 20, marginRight: 10 }} />
              <Field
                component={ConnectedInput}
                label="Last Name"
                name="lastName"
                type="text"
                disabled={true}
                extrastyle="medium"
                placeholder="Enter Last Name"
              />
            </div>
            <Field
              component={ConnectedInput}
              label="Email"
              name="email"
              disabled={true}
              fixed={true}
              type="email"
              placeholder="Enter Email"
            />
          </div>
          <Field
            component={FormattedInput}
            label="Mobile Number"
            name="mobileNumber"
            type="tel"
            labelDetail="optional"
            disabled={true}
            myFormat="+1 (###) ###-####"
            placeholder="Enter Mobile Number"
          />
          <div className={styles.multiInputContainer}>
            <Field
              component={FormattedInput}
              label="Work Number"
              name="workNumber"
              type="tel"
              labelDetail="optional"
              extrastyle="large"
              disabled={true}
              myFormat="+1 (###) ###-####"
              placeholder="Enter Work Number"
            />
            <Field
              component={ConnectedInput}
              label="Extension"
              name="workNumberExt"
              type="text"
              extrastyle="tiny"
              placeholder="0"
            />
          </div>
          {this.state.programPaymentTypeId !== "postpayment" && (
            <React.Fragment>
              <div className={styles.standaloneLabel}>Residential Address</div>
              {this.state.person1StreetOne &&
              !this.state.person1ResidentialAddressEditMode ? (
                <React.Fragment>
                  <br />
                  <Button
                    name="Edit Address"
                    style="primary"
                    onClick={() =>
                      this.toggleGeneric("person1ResidentialAddressEditMode")
                    }
                  />
                  <br />
                </React.Fragment>
              ) : (
                <GoogleSuggest
                  name="person1ResidentialAddress"
                  onKeyUp={this.handleChange}
                  setAddress={this.setAddress}
                  labelDetail="P. O. Box Not Accepted"
                />
              )}
              {(this.state.person1StreetOne || this.state.person1City) && (
                <React.Fragment>
                  <Input
                    label="Address 1"
                    name="person1StreetOne"
                    type="text"
                    placeholder="Address 1"
                    onKeyUp={this.handleChange}
                    myValue={this.state.person1StreetOne}
                  />
                  <Input
                    label="Address 2 (e.g., Apt Number, Suite Number…etc)"
                    name="person1StreetTwo"
                    type="text"
                    placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
                    onKeyUp={this.handleChange}
                    myValue={this.state.person1StreetTwo}
                  />
                  <div className={styles.inputContainer}>
                    <Input
                      label="City"
                      name="person1City"
                      extrastyle="semi-medium"
                      type="text"
                      placeholder="City"
                      onKeyUp={this.handleChange}
                      myValue={this.state.person1City}
                    />
                    <Input
                      label="Province/State"
                      name="person1Province"
                      type="text"
                      extrastyle="medium-with-margin"
                      placeholder="State/Province"
                      onKeyUp={this.handleChange}
                      myValue={this.state.person1Province}
                    />
                    <CustomSelect
                      label="Country"
                      name="person1Country"
                      extrastyle="medium-with-margin"
                      onChange={(value, label, name) =>
                        this.changeAddressSelect(value, label, name)
                      }
                      options={GlobalCountryList}
                      value={this.state.person1Country}
                    />
                  </div>
                  <Input
                    label="Postal Code/ZIP"
                    name="person1PostalCode"
                    type="text"
                    error={
                      (this.state.person1PostalCode &&
                        this.state.person1Country === "US" &&
                        postalCodeUS(this.state.person1PostalCode)) ||
                      (this.state.person1Country === "CA" &&
                        postalCodeCA(this.state.person1PostalCode))
                    }
                    extrastyle="semi-medium"
                    placeholder="Zip/Postal Code"
                    onKeyUp={this.handleChange}
                    myValue={this.state.person1PostalCode}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <DateInput
            label="Date of Birth "
            name="dateOfBirth"
            type="text"
            extrastyle="medium"
            selected={this.state.dateOfBirth}
            handleDateChange={(date, name) =>
              this.handleGenenicDateChange(date, name)
            }
            error={
              moment.utc(this.state.dateOfBirth).isAfter(Date.now(), "day")
                ? "Date cannot be greater than today"
                : moment.utc().diff(this.state.dateOfBirth, "years", true) < 18
                ? "Must be over 18 years of age"
                : ""
            }
          />
          {this.state.programPaymentTypeId !== "postpayment" && (
            <React.Fragment>
              <CustomSelect
                label="Select the Government Photo ID Type "
                name="documentType"
                onChange={(value, label) =>
                  this.changeSelect("documentType", value, label)
                }
                options={GovermentIDOptions}
                value={this.state.documentType}
              />
              <Field
                component={ConnectedInput}
                label="Government Photo ID Number"
                name="documentNumber"
                validate={[required]}
                type="text"
                placeholder="0000000000"
              />
              <DateInput
                label="Government photo ID Expiry Date "
                name="expiryDate"
                type="text"
                extrastyle="medium"
                selected={this.state.expiryDate}
                handleDateChange={(date, name) =>
                  this.handleGenenicDateChange(date, name)
                }
                error={
                  moment
                    .utc(this.state.expiryDate)
                    .isBefore(Date.now(), "day") &&
                  "You cannot use an expired document"
                }
              />
              {(this.state["documentType"] === "canpass" ||
                this.state["documentType"] === "candrlic") && (
                <CustomSelect
                  label="Province of Issuance"
                  name="provinceOfIssuance"
                  onChange={(value) =>
                    this.changeSelect("provinceOfIssuance", value)
                  }
                  options={ProvinceList}
                  value={this.state.provinceOfIssuance}
                />
              )}
            </React.Fragment>
          )}
        </div>

        {/* PEP Declaration */}
        <div className={styles.section}>
          {/* <div className={styles.subHeaderLabels}>
            Section 10: PEP Declaration
          </div> */}
          <div className={styles.subHeaderLabels}>PEP Declaration</div>
          <div className={styles.standaloneLabel}>
            Are you a Politically Exposed Person (PEP), a HIO, or a family
            member or close associate to a PEP or HIO?{" "}
          </div>
          <div className={styles.description}>
            A PEP is an individual who holds or has held office or a senior
            position in or on behalf of a country, government, military,
            judiciary or Mayor of any size Municipality or, is or was a head of
            an international organization (HIO), a leader or president of an
            organization funded by multiple nations or states, regardless of
            their citizenship, residency status or birth place.
          </div>
          <div className={styles.sectionContent}>
            <RadioGroup
              items={PEPList}
              value={this.state.isPEP}
              noBackground={true}
              name="isPEP"
              onUpdate={(value) => this.onChangeGenericRadio(value, "isPEP")}
            />
          </div>
        </div>

        {/* Plan Administrator */}
        {!_.get(this.props.company, "person.additionalRole") && (
          <React.Fragment>
            <div className={styles.section}>
              {/* <div className={styles.subHeaderLabels}>
                Section 11: Plan Administrator
              </div> */}
              <div className={styles.subHeaderLabels}>Plan Administrator</div>
              <div className={styles.sectionContent}>
                <div className={styles.inputContainer}>
                  <Field
                    component={ConnectedInput}
                    label="First Name"
                    name="planAdminFirstName"
                    type="text"
                    disabled={true}
                    placeholder="Enter First Name"
                    extrastyle="medium"
                  />
                  <div style={{ paddingRight: 20, marginRight: 10 }} />
                  <Field
                    component={ConnectedInput}
                    label="Last Name"
                    name="planAdminLastName"
                    type="text"
                    disabled={true}
                    extrastyle="medium"
                    placeholder="Enter Last Name"
                  />
                </div>
                <Field
                  component={ConnectedInput}
                  label="Email"
                  name="planAdminEmail"
                  type="email"
                  disabled={true}
                  fixed={true}
                  placeholder="Enter Email"
                />
                <div className={styles.msgContainer}>
                  <Icon name="info circle" />
                  <span>
                    The Plan Administrator Email Address must be unique and
                    should be used only as the Plan Administrator.
                  </span>
                </div>
                <br />
              </div>
              <Field
                component={FormattedInput}
                label="Plan Administrator Mobile Number"
                name="planAdminMobileNumber"
                type="tel"
                labelDetail="optional"
                myFormat="+1 (###) ###-####"
                placeholder="Enter Mobile Number"
              />
              <div className={styles.multiInputContainer}>
                <Field
                  component={FormattedInput}
                  label="Plan Administrator Work Number"
                  name="planAdminWorkNumber"
                  type="tel"
                  labelDetail="optional"
                  extrastyle="large"
                  myFormat="+1 (###) ###-####"
                  placeholder="Enter Work Number"
                />
                <Field
                  component={ConnectedInput}
                  label="Extension"
                  name="planAdminWorkNumberExt"
                  type="text"
                  extrastyle="tiny"
                  placeholder="0"
                />
              </div>
            </div>
          </React.Fragment>
        )}

        {/* Beneficial Ownership Information */}
        {this.props.planSponsorBeneficiarys &&
          this.props.planSponsorBeneficiarys.length > 0 && (
            <React.Fragment>
              <div className={styles.section}>
                {/* <div className={styles.subHeaderLabels}>
                  Section 12: Beneficial Ownership Information
                </div> */}
                <div className={styles.subHeaderLabels}>
                  Beneficial Ownership Information
                </div>
                <FieldArray
                  name="beneficiaryOwners"
                  component={this.renderBeneficialOwners}
                  styles={styles}
                  mystate={this.state}
                />
              </div>
            </React.Fragment>
          )}

        {/* Director Information */}
        {this.props.planSponsorDirectors &&
          this.props.planSponsorDirectors.length > 0 &&
          this.state.programPaymentTypeId !== "postpayment" && (
            <React.Fragment>
              <div className={styles.section}>
                {/* <div className={styles.subHeaderLabels}>
                  Section{" "}
                  {this.state.programPaymentType !== "postpayment" ? 13 : 12}:
                  Director Information
                </div> */}
                <div className={styles.subHeaderLabels}>
                  Director Information
                </div>
                <FieldArray
                  name="directors"
                  component={this.renderDirectors}
                  styles={styles}
                  mystate={this.state}
                />
              </div>
            </React.Fragment>
          )}

        {/* Invoicing Info */}
        <div className={styles.section}>
          {/* <div className={styles.subHeaderLabels}>
            Section {this.state.programPaymentType !== "postpayment" ? 14 : 12}:
            Invoicing Info
          </div> */}
          <div className={styles.subHeaderLabels}>Invoicing Info</div>
          <div className={styles.standaloneLabel}>
            How do you plan on paying your invoices?
          </div>
          <div className={styles.sectionContent}>
            <RadioGroup
              items={BankPaymentMethods}
              value={this.state.bankPaymentMethod}
              disabled={true}
              name="opt-group"
              // onUpdate={(value) => this.chosePaymentType(value)}
            />
            {this.state.bankPaymentMethod === "pad" && (
              <div className={styles.msgContainer}>
                Funds will be automatically withdrawn from the company's account
                and transferred to us via EFT (Electronic Funds Transfer).
                Please note that there will be a 10-day hold on the funds to
                clear with no additional fees.
              </div>
            )}
            {this.state.bankPaymentMethod === "wire" && (
              <div className={styles.msgContainer}>
                Aya will issue an invoice, and the company must manually
                transfer the funds to pay it.
              </div>
            )}
          </div>

          {this.state.bankPaymentMethod === "pad" && (
            <React.Fragment>
              <div className={styles.section}>
                <div className={styles.superSubHeader}>
                  Pre-Authorized Debit
                </div>
                <div className={styles.sectionContent}>
                  <Popup
                    content={
                      <React.Fragment>
                        <React.Fragment>
                          <img
                            className={styles.tooltipImage}
                            src={Images.institutionNumber}
                          />
                        </React.Fragment>
                        {
                          "you can find this 3 digit number on the bottom of a cheque or on your online banking"
                        }
                      </React.Fragment>
                    }
                    trigger={
                      <div className={styles.standaloneLabel}>
                        Financial Institution Number{" "}
                        <img src={Images.question} className={styles.tooltip} />{" "}
                      </div>
                    }
                  />
                  <Field
                    component={ConnectedInput}
                    label=""
                    name="bankNumber"
                    type="text"
                    max={3}
                    disabled={true}
                    extrastyle="semi-small"
                    placeholder="---"
                  />
                  <Popup
                    content={
                      <React.Fragment>
                        <React.Fragment>
                          <img
                            className={styles.tooltipImage}
                            src={Images.branchNumber}
                          />
                        </React.Fragment>
                        {
                          "you can find this 5 digit number on the bottom of a cheque or on your online banking"
                        }
                      </React.Fragment>
                    }
                    trigger={
                      <div className={styles.standaloneLabel}>
                        Branch Transit Number{" "}
                        <img src={Images.question} className={styles.tooltip} />{" "}
                      </div>
                    }
                  />
                  <Field
                    component={ConnectedInput}
                    label=""
                    name="bankBranchNumber"
                    type="text"
                    max={5}
                    disabled={true}
                    extrastyle="semi-small"
                    placeholder="-----"
                  />
                  <Field
                    component={ConnectedInput}
                    label="Account Number"
                    name="accountNumber"
                    type="text"
                    disabled={true}
                    placeholder="xxxxxxxxxx"
                    tooltipImage={"accountNumber"}
                    tooltipMessage={
                      "you can find this number on the bottom of a cheque or on your online banking"
                    }
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        {/* Authorized Signer */}
        {this.state.programPaymentTypeId === "prepaymentmastercard" && (
          <React.Fragment>
            <div className={styles.section}>
              <div className={styles.section}>
                {/* <div className={styles.subHeaderLabels}>
                  Section 15: Authorized Signer
                </div> */}
                <div className={styles.subHeaderLabels}>Authorized Signer</div>
                <div className={styles.msgContainer}>
                  An authorized signer is legally empowered to manage the
                  company's Aya account, including signing and conducting
                  transactions. Aya, as a registered Money Services Business
                  (MSB) under FINTRAC regulations, requires authorized signers
                  to provide two forms of ID to enroll a business and activate
                  an account.
                </div>
              </div>
              <FieldArray
                name="authorizedSigners"
                component={this.renderAuthorizedSigners}
                styles={styles}
                mystate={this.state}
              />
            </div>
          </React.Fragment>
        )}

        <div className={`${styles.checkboxDropdownContainerDark} `}>
          <Checkbox
            checked={this.state.submission}
            onClick={this.toggleSubmission}
          >
            Everything above looks good
          </Checkbox>
        </div>
        <div className={styles.buttonAreaContainer}>
          <div className={styles.actionLink} style={{ alignItems: "end" }}>
            {this.state.submission &&
              Object.entries(this.getValidationErrors()).length > 0 && (
                <ErrorPopupComponent errors={this.getValidationErrors()} />
              )}
          </div>

          <GradientButton
            disabled={
              this.overallErrorState() ||
              !this.state.submission ||
              this.props.invalid ||
              this.props.submitting ||
              Object.entries(this.getValidationErrors()).length > 0
            }
            handleClick={(e) => {
              this.setState({ planAdminUpdateRequired: false });
              this.verifyInformation(FULL__STEP_SEVEN__COMPANY);
            }}
            style={{
              marginTop: "20px",
            }}
            label="Proceed"
            width="100%"
          ></GradientButton>
        </div>
      </div>
    );
  }

  // renderCanadaAddresses = ({ styles, handleChange, fields }) => (
  //   <div>
  //     <React.Fragment>
  //       {fields.map((canadaAddress, index) => {
  //         let currentField = fields.get(index);
  //         const { streetOne, city, country } = currentField || {};
  //         return (
  //           <div key={index}>
  //             <React.Fragment>
  //               <div className={styles.sectionTitle}>Address {index + 1}</div>
  //               {(streetOne || city) &&
  //               !this.state[`${canadaAddress}.canadaAddressesEditMode`] ? (
  //                 <React.Fragment>
  //                   <br />
  //                   <Button
  //                     name="Edit Address"
  //                     style="primary"
  //                     onClick={() =>
  //                       this.toggleGeneric(
  //                         `${canadaAddress}.canadaAddressesEditMode`
  //                       )
  //                     }
  //                   />
  //                   <br />
  //                 </React.Fragment>
  //               ) : (
  //                 <GoogleSuggest
  //                   name={`${canadaAddress}.canadaAddresses`}
  //                   onKeyUp={this.handleChange}
  //                   setAddress={this.setAddress}
  //                   labelDetail="P. O. Box Not Accepted"
  //                 />
  //               )}
  //               {(streetOne || city) && (
  //                 <React.Fragment>
  //                   <Field
  //                     component={ConnectedInput}
  //                     label="Address 1"
  //                     name={`${canadaAddress}.streetOne`}
  //                     type="text"
  //                     placeholder="Address 1"
  //                     onKeyUp={this.handleChange}
  //                   />
  //                   <Field
  //                     component={ConnectedInput}
  //                     label="Address 2 (e.g., Apt Number, Suite Number…etc)"
  //                     name={`${canadaAddress}.streetTwo`}
  //                     type="text"
  //                     placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
  //                     onKeyUp={this.handleChange}
  //                   />
  //                   <div className={styles.inputContainer}>
  //                     <Field
  //                       component={ConnectedInput}
  //                       label="City"
  //                       name={`${canadaAddress}.city`}
  //                       extrastyle="semi-medium"
  //                       type="text"
  //                       placeholder="City"
  //                       onKeyUp={this.handleChange}
  //                     />
  //                     <Field
  //                       component={ConnectedInput}
  //                       label="Province/State"
  //                       name={`${canadaAddress}.province`}
  //                       type="text"
  //                       extrastyle="medium-with-margin"
  //                       placeholder="State/Province"
  //                       onKeyUp={this.handleChange}
  //                     />
  //                     <Field
  //                       component={RenderDropdownList}
  //                       label="Country"
  //                       name={`${canadaAddress}.country`}
  //                       extrastyle="medium-with-margin"
  //                       data={Countries}
  //                       valueField="value"
  //                       textField="label"
  //                       placeholder="country"
  //                     />
  //                   </div>
  //                   <Field
  //                     component={ConnectedInput}
  //                     label="Postal / Zip Code"
  //                     name={`${canadaAddress}.postalCode`}
  //                     type="text"
  //                     validate={[postalCodeCA]}
  //                     extrastyle="semi-medium"
  //                     placeholder="Zip/Postal Code"
  //                     onKeyUp={this.handleChange}
  //                   />
  //                 </React.Fragment>
  //               )}
  //               {country &&
  //               country !== "CA" &&
  //               _.get(country, "value") !== "CA" ? (
  //                 <div className={styles.error}>
  //                   Your address must be a Canadian address{" "}
  //                   {_.get(country, "value")}
  //                   <br />
  //                   <br />
  //                 </div>
  //               ) : (
  //                 ""
  //               )}
  //               <div className={styles.removeAdministratorWrapper}>
  //                 {/* <img
  //                   onClick={(e) => {
  //                     e.preventDefault();
  //                     fields.remove(index);
  //                   }}
  //                   src={Images.close}
  //                   className={styles.closeIcon}
  //                 /> */}
  //                 <a
  //                   href={"#"}
  //                   onClick={(e) => {
  //                     e.preventDefault();
  //                     fields.remove(index);
  //                   }}
  //                   className={styles.removeAdministrator}
  //                 >
  //                   - Remove this Address
  //                 </a>
  //               </div>
  //               <br />
  //               {/* <Divider /> */}
  //               {/* <br /> */}
  //             </React.Fragment>
  //           </div>
  //         );
  //       })}
  //     </React.Fragment>
  //   </div>
  // );

  renderOtherAddresses = ({ styles, handleChange, fields }) => (
    <div>
      <React.Fragment>
        {fields.map((otherAddress, index) => {
          let currentField = fields.get(index);
          const { streetOne, city, country } = currentField || {};
          return (
            <div key={index}>
              <React.Fragment>
                <div className={styles.sectionTitle}>Address {index + 1}</div>
                {(streetOne || city) &&
                !this.state[`${otherAddress}.otherAddressesEditMode`] ? (
                  <React.Fragment>
                    <br />
                    <Button
                      name="Edit Address"
                      style="primary"
                      onClick={() =>
                        this.toggleGeneric(
                          `${otherAddress}.otherAddressesEditMode`
                        )
                      }
                    />
                    <br />
                  </React.Fragment>
                ) : (
                  <GoogleSuggest
                    name={`${otherAddress}.otherAddresses`}
                    onKeyUp={this.handleChange}
                    setAddress={this.setAddress}
                    labelDetail="P. O. Box Not Accepted"
                  />
                )}
                {(streetOne || city) && (
                  <React.Fragment>
                    <Field
                      component={ConnectedInput}
                      label="Address 1"
                      name={`${otherAddress}.streetOne`}
                      type="text"
                      placeholder="Address 1"
                      onKeyUp={this.handleChange}
                    />
                    <Field
                      component={ConnectedInput}
                      label="Address 2 (e.g., Apt Number, Suite Number…etc)"
                      name={`${otherAddress}.streetTwo`}
                      type="text"
                      placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
                      onKeyUp={this.handleChange}
                    />
                    <div className={styles.inputContainer}>
                      <Field
                        component={ConnectedInput}
                        label="City"
                        name={`${otherAddress}.city`}
                        extrastyle="semi-medium"
                        type="text"
                        placeholder="City"
                        onKeyUp={this.handleChange}
                      />
                      <Field
                        component={ConnectedInput}
                        label="Province/State"
                        name={`${otherAddress}.province`}
                        type="text"
                        extrastyle="medium-with-margin"
                        placeholder="State/Province"
                        onKeyUp={this.handleChange}
                      />
                      <Field
                        component={RenderDropdownList}
                        label="Country"
                        name={`${otherAddress}.country`}
                        extrastyle="medium-with-margin"
                        data={GlobalCountryList}
                        valueField="value"
                        textField="label"
                        placeholder="country"
                      />
                    </div>
                    <Field
                      component={ConnectedInput}
                      label="Postal / Zip Code"
                      name={`${otherAddress}.postalCode`}
                      type="text"
                      extrastyle="semi-medium"
                      placeholder="Zip/Postal Code"
                      onKeyUp={this.handleChange}
                    />
                  </React.Fragment>
                )}
                {country &&
                (country === "CA" || _.get(country, "value") === "CA") ? (
                  <div className={styles.error}>
                    Your Non-Canadian office address must not be a Canadian
                    address
                    <br />
                    <br />
                  </div>
                ) : (
                  ""
                )}
                <div className={styles.removeAdministratorWrapper}>
                  <a
                    href={"#"}
                    onClick={(e) => {
                      e.preventDefault();
                      fields.remove(index);
                    }}
                    className={styles.removeAdministrator}
                  >
                    - Remove this Address
                  </a>
                </div>
                <br />
                {/* <Divider />
                <br /> */}
              </React.Fragment>
            </div>
          );
        })}
      </React.Fragment>
    </div>
  );

  renderDirectors = ({ styles, handleChange, fields, mystate }) => (
    <div>
      {fields.map((director, index) => (
        <div
          key={index}
          className={`${styles.sectionContent} ${styles.directorWrapper}`}
        >
          <div className={`${styles.blueContainer} `}>
            <div className={styles.sectionTitle}>Director {index + 1}</div>
            <div className={styles.inputContainer}>
              <Field
                component={ConnectedInput}
                label="First Name"
                name={`${director}.firstName`}
                type="text"
                disabled={true}
                fixed={true}
                placeholder="Enter First Name"
                extrastyle="medium"
              />
              <div style={{ paddingRight: 20, marginRight: 10 }} />
              <Field
                component={ConnectedInput}
                label="Last Name"
                name={`${director}.lastName`}
                type="text"
                disabled={true}
                fixed={true}
                extrastyle="medium"
                placeholder="Enter Last Name"
              />
            </div>
            <Field
              component={ConnectedInput}
              label="Email"
              name={`${director}.email`}
              type="email"
              validate={[required, email]}
              disabled={true}
              fixed={true}
              placeholder="Enter Email"
            />
            <Field
              component={FormattedInput}
              label="Mobile Number"
              name={`${director}.mobileNumber`}
              type="tel"
              disabled={true}
              fixed={true}
              labelDetail="optional"
              myFormat="+1 (###) ###-####"
              placeholder="Enter Mobile Number"
            />
            <div className={styles.multiInputContainer}>
              <Field
                component={FormattedInput}
                label="Work Number"
                name={`${director}.workNumber`}
                type="tel"
                disabled={true}
                fixed={true}
                extrastyle="large"
                myFormat="+1 (###) ###-####"
                placeholder="Enter Work Number"
              />
              <Field
                component={ConnectedInput}
                label="Extension"
                name={`${director}.workNumberExt`}
                type="text"
                disabled={true}
                fixed={true}
                extrastyle="tiny"
                placeholder="0"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  renderBeneficialOwners = ({ styles, handleChange, fields, mystate }) => (
    <div>
      {fields.map((beneficiaryOwner, index) => (
        <div
          key={index}
          className={`${styles.sectionContent} ${styles.beneficiaryOwnerWrapper}`}
        >
          <div className={`${styles.blueContainer} `}>
            <div className={styles.sectionTitle}>
              Beneficial Owner {index + 1}
            </div>
            <div className={styles.inputContainer}>
              <Field
                component={ConnectedInput}
                label="First Name"
                name={`${beneficiaryOwner}.firstName`}
                type="text"
                disabled={true}
                fixed={true}
                placeholder="Enter First Name"
                extrastyle="medium"
              />
              <div style={{ paddingRight: 20, marginRight: 10 }} />
              <Field
                component={ConnectedInput}
                label="Last Name"
                name={`${beneficiaryOwner}.lastName`}
                type="text"
                disabled={true}
                fixed={true}
                extrastyle="medium"
                placeholder="Enter Last Name"
              />
            </div>
            <Field
              component={ConnectedInput}
              label="Email"
              name={`${beneficiaryOwner}.email`}
              type="email"
              disabled={true}
              fixed={true}
              validate={[required, email]}
              placeholder="Enter Email"
            />
            <Field
              component={FormattedInput}
              label="Mobile Number"
              name={`${beneficiaryOwner}.mobileNumber`}
              type="tel"
              disabled={true}
              fixed={true}
              labelDetail="optional"
              myFormat="+1 (###) ###-####"
              placeholder="Enter Mobile Number"
            />
            <div className={styles.multiInputContainer}>
              <Field
                component={FormattedInput}
                label="Work Number"
                name={`${beneficiaryOwner}.workNumber`}
                type="tel"
                disabled={true}
                fixed={true}
                extrastyle="large"
                myFormat="+1 (###) ###-####"
                placeholder="Enter Work Number"
              />
              <Field
                component={ConnectedInput}
                label="Extension"
                name={`${beneficiaryOwner}.workNumberExt`}
                type="text"
                disabled={true}
                fixed={true}
                extrastyle="tiny"
                placeholder="0"
              />
            </div>
            <Field
              component={FormattedInput}
              name={`${beneficiaryOwner}.amountOfOwnership`}
              type="text"
              placeholder="0%"
              disabled={true}
              fixed={true}
              extrastyle="tiny"
              suffix={"%"}
            />
          </div>
        </div>
      ))}
    </div>
  );

  renderAuthorizedSigners = ({ styles, handleChange, fields, mystate }) => (
    <div>
      {fields.map((authorizedSigner, index) => (
        <div
          key={index}
          className={`${styles.sectionContent} ${styles.directorWrapper}`}
        >
          <div className={`${styles.blueContainer} ${styles.checkboxActive}`}>
            <div className={styles.sectionTitle}>
              Authorized Signer {index + 1}
            </div>
            <div className={styles.inputContainer}>
              <Field
                component={ConnectedInput}
                label="First Name"
                name={`${authorizedSigner}.firstName`}
                type="text"
                validate={[required]}
                placeholder="Enter First Name"
                extrastyle="medium"
              />
              <div style={{ paddingRight: 20, marginRight: 10 }} />
              <Field
                component={ConnectedInput}
                label="Last Name"
                name={`${authorizedSigner}.lastName`}
                type="text"
                validate={[required]}
                extrastyle="medium"
                placeholder="Enter Last Name"
              />
            </div>
            <Field
              component={ConnectedInput}
              label="Email"
              name={`${authorizedSigner}.email`}
              type="email"
              validate={[required, email]}
              placeholder="Enter Email"
            />
            <div className={styles.msgContainer}>
              <Icon name="info circle" />
              <span>
                The Authorized Signer Email Address must be unique and should be
                used only as the Authorized Signer.
              </span>
            </div>
            <br />
            <Field
              component={FormattedInput}
              label="Mobile Number"
              name={`${authorizedSigner}.mobileNumber`}
              type="tel"
              labelDetail="optional"
              myFormat="+1 (###) ###-####"
              placeholder="Enter Mobile Number"
            />
            <div className={styles.multiInputContainer}>
              <Field
                component={FormattedInput}
                label="Work Number"
                name={`${authorizedSigner}.workNumber`}
                type="tel"
                extrastyle="large"
                labelDetail="optional"
                myFormat="+1 (###) ###-####"
                placeholder="Enter Work Number"
              />
              <Field
                component={ConnectedInput}
                label="Extension"
                name={`${authorizedSigner}.workNumberExt`}
                type="text"
                extrastyle="tiny"
                placeholder="0"
              />
            </div>
            <div>
              <div className={styles.removeAdministratorWrapper}>
                <a
                  href={"#"}
                  onClick={(e) => {
                    e.preventDefault();
                    fields.remove(index);
                  }}
                  className={styles.removeAdministrator}
                >
                  - Remove Authorized Signer
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
      {_.size(fields) < 3 && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            fields.push();
          }}
          className={styles.addAdministrator}
        >
          + Add Authorized Signer
        </a>
      )}
    </div>
  );

  renderClasses = ({
    styles,
    handleChange,
    fields,
    meta: { error },
    mystate,
    onGenericChange,
  }) => (
    <div>
      {fields.map((employeeClass, index) => {
        let currentField = fields.get(index) || {};
        const {
          contributionAmountType,
          waitingPeriod,
          waitingPeriodNextMonth,
          annualProrated,
          annualProratedPlus1,
          annualProratedPlus2,
          // quarterlyContribution,
          // quarterlyContributionPlus1,
          // quarterlyContributionPlus2,
        } = currentField;
        return (
          <div
            key={index}
            className={`${styles.sectionContent} ${styles.employeeClassWrapper}`}
          >
            <div className={``}>
              <div className={styles.inputContainer}>
                <div
                  className={`${styles.checkboxDropdownContainer} ${styles.checkboxActive}`}
                >
                  <div
                    className={styles.sectionTitle}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    Class {numbersToAlphabets(`${index + 1}`)}{" "}
                    <div style={{ alignContent: "center" }}>
                      <Popup
                        style={{ maxWidth: "500px" }}
                        content={
                          <>
                            {" "}
                            <strong>Please note:</strong>
                            <p>
                              <br />
                              According to CRA guidelines, each class must have
                              a consistent allocation amount and be defined
                              using objective criteria. Examples of acceptable
                              criteria for establishing employee group
                              classifications include:
                              <br />
                              <br />
                              <strong>Employment Status:</strong> Full-time,
                              part-time, seasonal, contract, etc.
                              <br />
                              <br />
                              <strong>Job Description/Title:</strong>{" "}
                              Management, Administrative, Owner, Director,
                              Executive, etc.
                              <br />
                              <br />
                              <strong>Length of Service:</strong> 1 year+, 2
                              years+, 3 years+, etc. Geographical Location: ON
                              employees, BC employees etc.
                              <br />
                              <br />
                              All users of each class must have the same
                              allocation.
                            </p>
                          </>
                        }
                        trigger={
                          <Icon
                            name="info circle"
                            size="small"
                            color="grey"
                            style={{ marginLeft: "5px" }}
                          />
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.InputContainer}>
                    <Field
                      component={ConnectedInput}
                      label="Class Name"
                      name={`${employeeClass}.className`}
                      type="text"
                      max={30}
                      validate={[required]}
                      placeholder="Class Name"
                    />

                    <Field
                      component={ConnectedInput}
                      label="Class Description (maximum of 256 characters)"
                      name={`${employeeClass}.note`}
                      type="text"
                      max={256}
                      validate={[required]}
                      placeholder="Class Description"
                    />

                    <div className={styles.standaloneLabel}>
                      Do you want funds to carry over to the following year, or
                      have a "use it or lose it" option for this class? (Plan
                      Type)
                    </div>
                    <ConnectedRadioGroup
                      name={`${employeeClass}.type`}
                      options={PlanTypeList}
                      thinVerticalSpacing={true}
                      noBackground={true}
                    />
                    <div className={styles.sectionTitle}>
                      Enter the annual allocation for this class.
                    </div>
                    <Grid columns="equal">
                      <Grid.Row
                        verticalAlign="middle"
                        className={styles.gridRowNoPadding}
                      >
                        <Grid.Column>
                          <div className={styles.standaloneLabel}></div>
                        </Grid.Column>

                        {this.state.programTypeId?.includes("hsa") && (
                          <React.Fragment>
                            <Grid.Column>
                              <div className={styles.standaloneLabel}>HSA</div>
                            </Grid.Column>
                          </React.Fragment>
                        )}
                        {this.state.programTypeId?.includes("wsa") && (
                          <React.Fragment>
                            <Grid.Column>
                              <div className={styles.standaloneLabel}>WSA</div>
                            </Grid.Column>
                          </React.Fragment>
                        )}
                        {this.state.programTypeId === "hsa+wsa" && (
                          <React.Fragment>
                            <Grid.Column>
                              <div className={styles.standaloneLabel}>Flex</div>
                            </Grid.Column>
                          </React.Fragment>
                        )}
                      </Grid.Row>

                      <Grid.Row
                        verticalAlign="middle"
                        className={styles.gridRowNoPadding}
                      >
                        <Grid.Column>
                          <div className={styles.standaloneLabel}>Single</div>
                        </Grid.Column>
                        <React.Fragment>
                          {this.state.programTypeId?.includes("hsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[HSA].allocationSingle`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId?.includes("wsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[WSA].allocationSingle`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId === "hsa+wsa" && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[Flex].allocationSingle`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                        </React.Fragment>
                      </Grid.Row>

                      <Grid.Row
                        verticalAlign="middle"
                        className={styles.gridRowNoPadding}
                      >
                        <Grid.Column>
                          <div className={styles.standaloneLabel}>
                            Single +1
                          </div>
                        </Grid.Column>

                        <React.Fragment>
                          {this.state.programTypeId?.includes("hsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[HSA].allocationSinglePlusOne`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId?.includes("wsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[WSA].allocationSinglePlusOne`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId === "hsa+wsa" && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[Flex].allocationSinglePlusOne`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                        </React.Fragment>
                      </Grid.Row>

                      <Grid.Row
                        verticalAlign="middle"
                        className={styles.gridRowNoPadding}
                      >
                        <Grid.Column>
                          <div className={styles.standaloneLabel}>
                            Single +2
                          </div>
                        </Grid.Column>

                        <React.Fragment>
                          {this.state.programTypeId?.includes("hsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[HSA].allocationSinglePlusTwo`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId?.includes("wsa") && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[WSA].allocationSinglePlusTwo`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                          {this.state.programTypeId === "hsa+wsa" && (
                            <>
                              <div className={styles.standaloneLabelDollar}>
                                ${" "}
                              </div>
                              <Grid.Column>
                                <Field
                                  component={FormattedInput}
                                  name={`${employeeClass}.allocation[Flex].allocationSinglePlusTwo`}
                                  type="text"
                                  placeholder="0.00"
                                  validate={[]}
                                  thousandSeparator={true}
                                  // prefix={"$"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                />
                              </Grid.Column>
                            </>
                          )}
                        </React.Fragment>
                      </Grid.Row>
                    </Grid>
                    <br />
                  </div>
                </div>
              </div>
              <div>
                {_.size(fields) !== 1 && (
                  <div className={styles.removeAdministratorWrapper}>
                    <a
                      href={"#"}
                      onClick={(e) => {
                        e.preventDefault();
                        fields.remove(index);
                      }}
                      className={styles.removeAdministrator}
                    >
                      - Remove Class
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          fields.push(defaultClassObject);
        }}
        className={styles.addAdministrator}
      >
        + Add New Class
      </a>
    </div>
  );

  verifyInformation = async (nextStep) => {
    const {
      businessOperatedName,
      businessLegalName,
      craBusinessNumber,
      stockSymbol,
      firstName,
      lastName,
      mobileNumber,
      workNumber,
      workNumberExt,
      corporateNumber,
      cardIssuanceFee,
      employeeMonthlyFee,
      masterCardTransactionInCanada,
      foreignExchangeFee,
      outOfPocketEFT,
      internalNote,
      employeeClass,
      // END OF PDV
      email,
      bankName,
      bankNumber,
      bankBranchNumber,
      accountNumber,
      documentType,
      documentNumber,
      canadaAddresses,
      otherAddresses,
      peoName,
      peoContactName,
      peoContactEmail,
      taxIdOrEinNumber,
      taxIdOrTinNumber,
      authorizedSigners,
      website,
      administrationFee,
      claimProcessingFee,
    } = this.props;

    const {
      institutionNumber,
      bankPaymentMethod,
      selectedBankName,
      estimatedNumberOfEmployees,
      businessIndustry,
      industryClassificationType,
      corporateNumberType,
      exchange,
      provinceOfRegisteredOffice,
      dateOfRegistration,
      sourceDocument,
      //pdvs
      programName,
      startDate,
      endDate,
      startDateYear1,
      effectiveTerminationDate,
      endDateYear1,
      startDateYear2,
      programTypeId,
      purseType,
      programPaymentTypeId,
      invoicingPeriodId,
      prorateAfterGivenYearId,
      dependents,
      chargePEPM,
      postpaymentFundsPullingRateId,
      // postpaymentFloatOrDepositId,
      actionAfterMastercardClaimDeclineId,
      daysToSubmitClaimPreviousYearId,
      lastDayToSubmitAfterTerminationId,
      planAdministratorsCanAddEmployeesId,
      shipTo,
      //end of pdv's

      person1StreetOne,
      person1StreetTwo,
      person1City,
      person1Province,
      person1Country,
      person1PostalCode,
      streetOne,
      streetTwo,
      city,
      province,
      country,
      postalCode,
      mailingStreetOne,
      mailingStreetTwo,
      mailingCity,
      mailingProvince,
      mailingCountry,
      mailingPostalCode,
      dateOfBirth,
      expiryDate,
      provinceOfIssuance,
      bhaLabel,
      brokerLabel,
      tpaLabel,
      bhaGuid,
      brokerGuid,
      operatingInCanada,
      businessType,
      fatca,
      isPEP,
      ayaSalesRepLabel,
      ayaSalesRepGuid,
      previousProgram,
      pepmFrequency,
      knowWhiteLabelling,
      applyThroughBrokerageHouseName,
      floatBalanceDeposit,
      advisorCompanyName,
      advisorFirstName,
      advisorLastName,
      advisorEmail,
    } = this.state;

    let payloadData = this.props.company;

    //modify contacts
    let { contacts = [] } = payloadData;

    const params = {
      method: "put",
      path: `invites/${payloadData.inviteGuid}`,
      data: {
        ...payloadData,
        dirty: true,
        requestedForBusinessId: "",
        requestedPersonParentId: "",
        inviteState: {
          dirty: true,
          step: nextStep,
          status: "active",
          inviteStatus: "BA00S",
        },
        sendNotification: true,
        inviteStatus: "active",
        person: {
          ...payloadData.person,
          dirty: true,
          personStatus: "PIDV01",
          firstName: firstName,
          lastName: lastName,
          mobileNumber: mobileNumber,
          workNumber: workNumber,
          workNumberExt: workNumberExt,
          faxNumber: "",
          faxNumberExt: "",
          email: email ? email.toLowerCase() : undefined,
          postalCode: "",
          countryCode: "CA",
          languageCode: "en",
          governmentBirthID: "",
          address: {
            dirty: true,
            city: person1City,
            isPrimary: true,
            addressClass: "residential",
            province: person1Province,
            postalCode: person1PostalCode,
            streetOne: person1StreetOne,
            streetTwo: person1StreetTwo,
            country: person1Country,
          },
          mailingAddress: {
            dirty: true,
            city: person1City,
            isPrimary: false,
            addressClass: "mailing",
            province: person1Province,
            postalCode: person1PostalCode,
            streetOne: person1StreetOne,
            streetTwo: person1StreetTwo,
            country: person1Country,
          },
          userRole: {
            dirty: false,
            label: "PlanSponsor Company Contact",
            name: "planSponsorContact",
          },
          verification: {
            dirty: true,
            documentType: documentType,
            documentNumber: documentNumber,
            expiryDate: expiryDate,
            jurisdictionOfInsurance: provinceOfIssuance,
          },
          metaInformation: {
            ...payloadData.person.metaInformation,
            dirty: true,
            dateOfBirth: dateOfBirth,
          },
          programDefinedVariables: {},
        },
        business: {
          ...payloadData.business,
          dirty: true,
          businessStatus: "BA01S-IPCC",
          businessLegalName: businessLegalName,
          businessOperatedName: businessOperatedName,
          businessShortName: businessOperatedName
            ? businessOperatedName.substring(0, 5)
            : "SHORTNAME",
          businessDbaLicNumber: craBusinessNumber,
          isAddressSameAsMailing: true,
          defaultPaymentMethod: bankPaymentMethod,
          addressBook: [
            {
              dirty: true,
              isPrimary: true,
              addressClass: "business",
              city: city,
              province: province,
              postalCode: postalCode,
              streetOne: streetOne,
              streetTwo: streetTwo,
              country: country,
            },
            {
              dirty: true,
              isPrimary: false,
              addressClass: "mailing",
              city: mailingCity,
              province: mailingProvince,
              postalCode: mailingPostalCode,
              streetOne: mailingStreetOne,
              streetTwo: mailingStreetTwo,
              country: mailingCountry,
            },
          ],
          businessPreferredLanguage: "en",
          logo: "",
          country: "CA",
          mailingCountry: "CA",
          programDefinedVariables: {
            dirty: true,
            contractTerm: {
              startDateYear1: startDateYear1, //date
              endDateYear1: endDateYear1, // date
              startDateYear2: moment.utc(startDateYear2), //date
              programType: programTypeId, //hsa, wsa, hsa+wsa
              purseType: purseType,
              programPaymentType: programPaymentTypeId, // postpayment, prepaymentmastercard
              paymentSchedule: invoicingPeriodId, //yearly, quarterly
              prorateYear1: prorateAfterGivenYearId, //true or false
              prorateAfterGivenYear: prorateAfterGivenYearId, //true or false
              postpaymentFundsPullingRate: postpaymentFundsPullingRateId,
              // postpaymentFloatOrDeposit: postpaymentFloatOrDepositId,
              actionAfterMastercardClaimDecline:
                actionAfterMastercardClaimDeclineId, //notifyEmployeeToRepay,statementToEmployer
              fitnessWSA: true, //true or false
              personalDevelopmentWSA: true, //true or false
              mentalHealthWSA: true, //true or false
              nutritionWSA: true, //true or false
              otherWSA: "", //string
              chargePEPM: chargePEPM, //true or false
              administrationFee:
                programPaymentTypeId === "postpayment"
                  ? cleanUpPercentage("0%")
                  : cleanUpPercentage(
                      parseFloat(administrationFee) > 0
                        ? administrationFee
                        : ADMINISTRATION_FEE + "%"
                    ),
              claimProcessingFee:
                programPaymentTypeId === "postpayment"
                  ? cleanUpPercentage(
                      parseFloat(claimProcessingFee) > 0
                        ? claimProcessingFee
                        : CLAIM_PROCESSING_FEE + "%"
                    )
                  : cleanUpPercentage("0%"),
              setupFee: cleanUpCurrency("0"), //dollar
              employeeSetupFee: cleanUpCurrency("0"), //dollar
              cardIssuanceFee: cleanUpCurrency(cardIssuanceFee), //dollar
              monthlyEmployeeFee: cleanUpCurrency(employeeMonthlyFee), //dollar
              mastercardInCanadaTransactionFeePerClaim: cleanUpCurrency(
                masterCardTransactionInCanada
              ), //dollar
              mastercardOutofCanadaTransactionForeignExchangeFee:
                cleanUpPercentage(foreignExchangeFee), //dollar
              oopTransactionEftFee: cleanUpCurrency(outOfPocketEFT), //dollar
              pepmFee: chargePEPM ? cleanUpCurrency(PEPM_FEE) : 0.0,
              pepmFrequency,
              pepmMinimumFee: chargePEPM
                ? cleanUpCurrency(MONTHLY_EMPLOYEE_MINIMUM_FEE)
                : 0.0,
            },
            previousProgram: {
              programName: previousProgram ? programName : "",
              startDate: previousProgram ? startDate : "",
              endDate: previousProgram ? endDate : "",
              effectiveTerminationDate: effectiveTerminationDate, //date
            },
            dependents: {
              allow: dependents, //true or false
            },
            hsaProcessingRules: {
              daysToSubmitClaimPreviousYear: daysToSubmitClaimPreviousYearId, //30,60,90
              daysToSubmitClaimTermination: lastDayToSubmitAfterTerminationId, //30,60,90
            },
            miscellaneous: {
              shipTo: shipTo, //Abitrary String
              planAdministratorsCanAddEmployees:
                planAdministratorsCanAddEmployeesId, //true or false
              internalNote: internalNote, // String
            },
          },
          metaInformation: {
            ...payloadData.business.metaInformation,
            dirty: true,
            companyWebsite: website ?? "n/a",
            newPlanAdminGuid: this.props.newPlanAdmin
              ? this.props.newPlanAdmin.inviteGuid
              : "",
            assocBrokerageHouseName: bhaLabel,
            assocBrokerName: brokerLabel,
            assocThirdPartyAdministratorName: tpaLabel,
            requestedByPersonGuid: brokerGuid,
            ayaSalesRepName: ayaSalesRepLabel ?? "",
            operatingInCanada: operatingInCanada,
            peo: {
              dirty: true,
              peoName: peoName,
              peoContactName: peoContactName,
              peoContactEmail: peoContactEmail,
            },
            taxIdOrEinNumber: taxIdOrEinNumber,
            taxIdOrTinNumber: taxIdOrTinNumber,
            corporateNumber: corporateNumber,
            corporateNumberType: corporateNumberType,
            numberOfEmployees: estimatedNumberOfEmployees,
            businessType: businessType,
            jurisdictionOfInsurance: provinceOfRegisteredOffice,
            dateOfRegistration: dateOfRegistration,
            industry: {
              dirty: true,
              industryType: businessIndustry,
              industryClassificationType: industryClassificationType,
            },
            trading: {
              dirty: true,
              stockSymbol: stockSymbol,
              stockExchangeName: exchange,
            },
            sourceDocument: {
              dirty: true,
              name: sourceDocument,
              number: "",
            },
            fatca: fatca ? "true" : "false",
            isPEP: isPEP ? "true" : "false",
            knowWhiteLabelling: knowWhiteLabelling ? "true" : "false",
            floatBalanceDeposit: floatBalanceDeposit ? "true" : "false",
            applyThroughBrokerageHouseName:
              applyThroughBrokerageHouseName ?? "",
            advisorCompanyName: advisorCompanyName ?? "",
            advisorFirstName: advisorFirstName ?? "",
            advisorLastName: advisorLastName ?? "",
            advisorEmail: advisorEmail ?? "",
            classes: [],
            enrolledByRole: "broker",
          },
          enrolledByBusinessID: this.props.bhaGuid, //brokerageHOuse GUid
          newEnrolledByBusinessID:
            bhaGuid !== this.props.bhaGuid ? bhaGuid : undefined, //only send if bhaGuid is different
          // requestedByPersonGuid: this.props.brokerGuid, //broker
          // newRequestedByPersonGuid:
          //   brokerGuid !== this.props.brokerGuid ? brokerGuid : undefined, //only send if brokerGuid is different
          // requestedForBusinessAssocId: this.props.tpaGuid, //tpa GUid
          // newRequestedForBusinessAssocId:
          //   tpaGuid !== this.props.tpaGuid ? tpaGuid : undefined, //only send if tpaGuid is different
          // requestedForBusinessAssocBusinessType: "planSponsor",
        },
        bankAccountDetails: {
          dirty: true,
          bankName:
            institutionNumber === "0" ? bankName : selectedBankName || "",
          bankBranchNumber: bankBranchNumber.replace(/\s/g, ""),
          bankNumber: bankNumber.replace(/\s/g, ""),
          bankAccountNumber: accountNumber.replace(/\s/g, ""),
          bankAccountType: "business",
        },
        agreementSignatures: [],
        salesPersonGuid: ayaSalesRepGuid ?? "",
        accessToken: "",
      },
    };

    if (canadaAddresses.length > 0) {
      for (const [index, address] of canadaAddresses.entries()) {
        let item = {
          dirty: true,
          isPrimary: false,
          addressClass: `canadaAddresses.${index}`,
          city: address.city,
          province: address.province,
          postalCode: address.postalCode,
          streetOne: address.streetOne,
          streetTwo: address.streetTwo,
          country: address.country,
        };
        params.data.business.addressBook.push(item);
      }
    }
    if (otherAddresses.length > 0) {
      for (const [index, address] of otherAddresses.entries()) {
        let item = {
          dirty: true,
          isPrimary: false,
          addressClass: `otherAddresses.${index}`,
          city: address.city,
          province: address.province,
          postalCode: address.postalCode,
          streetOne: address.streetOne,
          streetTwo: address.streetTwo,
          country: address.country,
        };
        params.data.business.addressBook.push(item);
      }
    }

    let classCount = 0;
    if (
      employeeClass &&
      typeof employeeClass !== null &&
      typeof employeeClass.forEach === "function"
    ) {
      employeeClass.forEach((item, i) => {
        let newClassItem = getClassDetailsInPayloadFormat(
          item,
          params?.data?.person?.email
        );
        params.data.business.metaInformation.classes.push(newClassItem);

        if (classCount < 5) {
          let keysMap = {
            name: `employeeClass${i + 1}Name`,
            planType: `employeeClass${i + 1}PlanType`,
            // waitingPeriod: `employeeClass${i + 1}WaitingPeriod`,
            contributionAmountType:
              `employeeClass${i + 1}ContributionAmountType` ?? "fixed",
            // waitingPeriodNextMonth: `employeeClass${i + 1}WaitingPeriodNextMonth`,
            annualContribution: `employeeClass${i + 1}AnnualContribution`,
            annualContributionPlus1:
              `employeeClass${i + 1}AnnualContributionPlus1` ?? 0.0,
            annualContributionPlus2:
              `employeeClass${i + 1}AnnualContributionPlus2` ?? 0.0,
            annualContributionPercent:
              `employeeClass${i + 1}AnnualContribution` ?? 0.0,
            annualContributionPercentPlus1:
              `employeeClass${i + 1}AnnualContributionPlus1` ?? 0.0,
            annualContributionPercentPlus2:
              `employeeClass${i + 1}AnnualContributionPlus2` ?? 0.0,
          };
          //prepare pdv, rename keys
          let newItem = renameKeysFromNewClass(keysMap, item);
          params.data.business.programDefinedVariables.contractTerm = {
            ...params.data.business.programDefinedVariables.contractTerm,
            ...newItem,
          };
        }

        classCount++;
      });
    }

    //add authorized signers
    let authSigners = [];
    if (authorizedSigners && authorizedSigners.length > 0) {
      for (const authorizedSigner of authorizedSigners) {
        if (authorizedSigner.email) {
          let item = {
            firstName: authorizedSigner.firstName,
            lastName: authorizedSigner.lastName,
            email: authorizedSigner.email
              ? authorizedSigner.email.toLowerCase()
              : undefined,
            userRole: {
              label: "PlanSponsor Company Authorized Signer",
              name: "planSponsorAuthorizedSigner",
            },
            countryCode: "CA",
            languageCode: "en",
            mobileNumber: authorizedSigner.mobileNumber,
            workNumber: authorizedSigner.workNumber,
            workNumberExt: authorizedSigner.workNumberExt,
            dirty: true,
          };
          authSigners.push(item);
        }
      }
    }

    let otherContacts = params.data.contacts.filter(
      (contact) => contact.userRole.name !== "planSponsorAuthorizedSigner"
    );

    if (authorizedSigners && authorizedSigners.length > 0) {
      params.data.contacts = [...otherContacts, ...authSigners];
    }

    try {
      let response = await this.props.submitCompanyEnrolmentData({
        companyData: JSON.stringify(params.data),
        enrolledByEmail: this.state.enrolledByEmail,
        guid: this.state.selectedCompanyGuid,
      });
    } catch (ex) {}
  };
}

const validate = (formValues) => {
  const {
    businessOperatedName,
    businessLegalName,
    businessDbaLicNumber,
    businessShortName,
    businessGenericEmail,
    registeredBusinessAddress,
    registeredMailingAddress,
    firstName,
    lastName,
    email,
    bankName,
    bankNumber,
    bankBranchNumber,
    accountNumber,
  } = formValues;
  const errors = {};

  //company
  if (isEmpty(businessLegalName))
    errors.businessLegalName = "Business Legal Name cannot be blank";
  if (isEmpty(businessOperatedName))
    errors.businessOperatedName = "Business Operated Name cannot be left blank";

  if (isEmpty(registeredBusinessAddress))
    errors.registeredBusinessAddress = "Address cannot be blank";

  //administrator
  if (isEmpty(registeredMailingAddress))
    errors.registeredMailingAddress = "Address cannot be blank";

  if (isEmpty(firstName)) errors.firstName = "You must enter a first name";
  if (isEmpty(lastName)) errors.lastName = "You must enter a last name";

  if (isEmpty(email)) errors.email = "You must enter an administrator email";
  else if (!isEmailValid(email)) errors.email = "Invalid administrator email";

  if (isEmpty(businessGenericEmail))
    errors.businessGenericEmail = "You must enter an administrator email";
  else if (!isEmailValid(businessGenericEmail))
    errors.businessGenericEmail = "Invalid administrator email";

  return errors;
};

let WrappedReviewInformation = reduxForm({
  form: "CompanyReviewPS",
  validate,
  enableReinitialize: true,
})(ReviewInformation);

const mapStateToProps = (state) => {
  const selector = formValueSelector("CompanyReviewPS");
  // const { brokerageHouses, banks } = state.brokerageHouseReducer;
  // const { brokers } = state.brokerReducer;
  const industries = {
    data: INDUSTRIES || [],
  };
  // const {
  //   userClaims: { businessOperatedName: bhaName, userRole, personGuid },
  // } = state.authReducer;
  // let properUserRole = userRoleMapper[userRole];

  //From payload string
  // let data = state.companyReducer.companyAllInfo;
  // let payloadString =
  //   state.companyReducer.companyAllInfo?.business?.metaInformation
  //     ?.payloadString ?? "";
  // if (payloadString !== "") data = JSON.parse(payloadString);
  const data = state.companyReducer?.companyEnrolment?.companyData ?? {};
  let payloadString =
    JSON.stringify(state.companyReducer.companyEnrolment?.companyData) ?? "";
  const companyEnrolment = state.companyReducer.companyEnrolment ?? {};

  const enrolledByEmail = state.authReducer?.preAuthDetails?.email ?? "";

  // const companyAllInfo = state.companyReducer.companyAllInfo;
  const firstName = _.get(data, "person.firstName") ?? "";
  const lastName = _.get(data, "person.lastName") ?? "";
  const email = _.get(data, "person.email") ?? "";
  const mobileNumber = _.get(data, "person.mobileNumber") ?? "";
  const workNumber = _.get(data, "person.workNumber") ?? "";
  const workNumberExt = _.get(data, "person.workNumberExt") ?? "";

  let allIndustries = [];
  if (_.has(industries, "data")) {
    let feed = industries.data;
    feed.forEach((data) => {
      allIndustries.push({
        label: data.classificationname,
        value: data.classificationguid,
        parentClassificationGuid: data.parentclassificationguid,
      });
    });
  }

  //get metaData
  //business meta
  let myMetaData = {};
  if (
    _.has(data, "business.metaInformation") &&
    typeof data.business.metaInformation !== "undefined" &&
    typeof data.business.metaInformation.forEach === "function"
  ) {
    data.business.metaInformation.forEach((item) => {
      if (item.metaType === "string") {
        myMetaData[item.metaKey] = item.metaValue;
      } else {
        myMetaData[item.metaKey] = item.metaValue;
      }
    });
  } else if (_.get(data, "business.metaInformation")) {
    for (let item in data.business.metaInformation) {
      if (_.get(data, `business.metaInformation[${item}].metaKey`)) {
        myMetaData[data.business.metaInformation[item].metaKey] =
          data.business.metaInformation[item].metaValue;
      }
      myMetaData[item] = data.business.metaInformation[item];
    }
  }
  //person meta
  if (
    _.has(data, "person.metaInformation") &&
    typeof data.person.metaInformation !== "undefined" &&
    typeof data.person.metaInformation.forEach === "function"
  ) {
    data.person.metaInformation.forEach((item) => {
      if (item.metaType === "string") {
        myMetaData[item.metaKey] = item.metaValue;
      }
    });
  } else if (_.get(data, "person.metaInformation")) {
    for (let item in data.person.metaInformation) {
      if (_.get(data, `person.metaInformation[${item}].metaKey`)) {
        myMetaData[data.person.metaInformation[item].metaKey] =
          data.person.metaInformation[item].metaValue;
      }
      myMetaData[item] = data.person.metaInformation[item];
    }
  }

  //Get plan admin contact
  const {
    planAdminFirstName,
    planAdminLastName,
    planAdminEmail,
    planAdminMobileNumber,
    planAdminWorkNumber,
    planAdminWorkNumberExt,
    planAdminStreetOne,
    planAdminStreetTwo,
    planAdminCity,
    planAdminProvince,
    planAdminPostalCode,
    planAdminParams,
  } = JSON.parse(myMetaData?.planAdminContact ?? "{}");

  //get programDefinedVariables's
  //business pdv's
  let programDefinedVariables = {};
  if (
    _.get(data, "business.programDefinedVariables") &&
    typeof data.business.programDefinedVariables !== null &&
    typeof data.business.programDefinedVariables.forEach === "function"
  ) {
    data.business.programDefinedVariables.forEach((item) => {
      if (
        item.productVariableDefinition.variableName &&
        item.variableValue.length > 0
      ) {
        programDefinedVariables[item.productVariableDefinition.variableName] =
          item.variableValue;
      }
    });
  } else if (_.get(data, "business.programDefinedVariables")) {
    for (let item in data.business.programDefinedVariables) {
      if (
        _.get(
          data,
          `business.programDefinedVariables[${item}].productVariableDefinition.variableName`
        )
      ) {
        programDefinedVariables[
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableName
        ] =
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableValue;
      }
      programDefinedVariables[item] =
        data.business.programDefinedVariables[item];
    }
  }

  //get employeeClass
  let employeeClass = [];

  employeeClass =
    myMetaData?.classes?.length > 0 ? [...myMetaData?.classes] : [];

  let pgType =
    _.get(data, "business.programDefinedVariables.contractTerm.programType") ||
    _.get(programDefinedVariables, "programType");
  let isOldClassStructure = false;

  if (employeeClass.length === 0) {
    let empClass = [];

    if (_.size(programDefinedVariables) > 0) {
      empClass = _.get(programDefinedVariables, "contractTerm")
        ? buildPDVArray(
            programDefinedVariables.contractTerm,
            programDefinedVariables.contractTerm.endDateYear1,
            programDefinedVariables.contractTerm.startDateYear1,
            programDefinedVariables.contractTerm.prorateYear1,
            programDefinedVariables.contractTerm.paymentSchedule
          )
        : buildPDVArray(
            programDefinedVariables,
            programDefinedVariables.endDateYear1,
            programDefinedVariables.startDateYear1,
            programDefinedVariables.prorateYear1,
            programDefinedVariables.paymentSchedule
          );
    } else {
      empClass = buildPDVArray(
        _.get(data, "business.programDefinedVariables.contractTerm"),
        _.get(
          data,
          "business.programDefinedVariables.contractTerm,endDateYear1"
        ),
        _.get(
          data,
          "business.programDefinedVariables.contractTerm,startDateYear1"
        ),
        _.get(
          data,
          "business.programDefinedVariables.contractTerm,prorateYear1"
        ),
        _.get(
          data,
          "business.programDefinedVariables.contractTerm,paymentSchedule"
        )
      );
    }

    empClass.forEach((item) => {
      let newClassObj = formatOldClassDataToNew(item, pgType);
      employeeClass.push(newClassObj);
    });

    isOldClassStructure = true;
  }

  let industryList = [];
  if (allIndustries.length > 0) {
    industryList = allIndustries.filter(
      (item) => item.parentClassificationGuid === DefaultGuid
    );
  }

  let industryClassificationList = [];
  if (
    _.get(data, "business.metaInformation.industry.industryType") ||
    (_.get(myMetaData, "industryType") && industryList.length > 0)
  ) {
    let classificationFilter = "";
    industryList.forEach((industry) => {
      if (
        industry.label ===
        _.get(data, "business.metaInformation.industry.industryType")
      ) {
        classificationFilter = industry.value;
      } else if (industry.label === _.get(myMetaData, "industryType")) {
        classificationFilter = industry.value;
      }
    });
    industryClassificationList = allIndustries.filter(
      (item) => item.parentClassificationGuid === classificationFilter
    );
  }

  //create plan sponsor accountant
  let planSponsorAccountants = [];
  let planSponsorDirectors = [];
  let planSponsorBeneficiarys = [];
  let planSponsorAuthorizedSigners = [];
  if (_.get(data, "contacts")) {
    data.contacts.forEach((item) => {
      if (item.userRole.name === "planSponsorDirector") {
        planSponsorDirectors.push(item);
      }
      if (item.userRole.name === "planSponsorBeneficiary") {
        item[`amountOfOwnership`] = item.metaInformation.amountOfOwnership;
        planSponsorBeneficiarys.push(item);
      }
      if (item.userRole.name === "planSponsorAuthorizedSigner") {
        planSponsorAuthorizedSigners.push(item);
      }
    });
  }

  // get fatca
  let fatca = false;
  let metaFatca =
    _.get(data, "business.metaInformation.fatca") || _.get(myMetaData, "fatca");
  if (metaFatca) {
    fatca = metaFatca === "true" ? true : false;
  }

  // get pep
  let isPEP = false;
  let metaPEP =
    _.get(data, "business.metaInformation.isPEP") || _.get(myMetaData, "isPEP");
  if (metaPEP) {
    isPEP = metaPEP === "true" ? true : false;
  }

  //get person 1 Address components
  let person1StreetOne;
  let person1StreetTwo;
  let person1City;
  let person1Province;
  let person1PostalCode;
  let person1Country;
  if (
    _.get(data, "person.addressBook") &&
    typeof data.person.addressBook !== "undefined" &&
    typeof data.person.addressBook.forEach === "function"
  ) {
    data.person.addressBook.forEach((item) => {
      if (item.addressClass === "residential") {
        person1StreetOne = item.streetOne;
        person1StreetTwo = item.streetTwo;
        person1City = item.city;
        person1Province = item.province;
        person1PostalCode = item.postalCode;
        person1Country = item.country;
      }
    });
  }

  //get businessAddress
  let streetOne;
  let streetTwo;
  let city;
  let province;
  let postalCode;
  let country;
  let mailingStreetOne;
  let mailingStreetTwo;
  let mailingCity;
  let mailingProvince;
  let mailingPostalCode;
  let mailingCountry;
  let canadaAddresses = [];
  let otherAddresses = [];
  if (
    _.get(data, "business.addressBook") &&
    typeof data.business.addressBook !== "undefined" &&
    typeof data.business.addressBook.forEach === "function"
  ) {
    data.business.addressBook.forEach((item) => {
      if (item.addressClass === "business") {
        streetOne = item.streetOne;
        streetTwo = item.streetTwo;
        city = item.city;
        province = item.province;
        postalCode = item.postalCode;
        country = item.country;
      }
      if (item.addressClass === "mailing") {
        mailingStreetOne = item.streetOne;
        mailingStreetTwo = item.streetTwo;
        mailingCity = item.city;
        mailingProvince = item.province;
        mailingPostalCode = item.postalCode;
        mailingCountry = item.country;
      }
      if (item.addressClass.split(".")[0] === "canadaAddresses") {
        canadaAddresses.push(item);
      }
      if (item.addressClass.split(".")[0] === "otherAddresses") {
        otherAddresses.push(item);
      }
    });
  }

  //get verification for person with current invite
  let personVerification = {};
  if (
    _.get(data, "person.personVerification") &&
    typeof data.person.personVerification !== "undefined" &&
    typeof data.person.personVerification.forEach === "function" &&
    _.size(data.person.personVerification) > 0
  ) {
    personVerification = data.person.personVerification[0];
  }

  let businessRelation = {};
  if (
    _.get(data, "business.businessRelation") &&
    _.size(data, "business.businessRelation") > 0
  ) {
    for (let item of _.get(data, "business.businessRelation")) {
      if (item.hierarchyLevel === "brokerageHouse")
        businessRelation["bhaGuid"] = item.businessParentGUID;
      if (item.hierarchyLevel === "thirdPartyAdministratorPayor")
        businessRelation["tpaGuid"] = item.businessParentGUID;
    }
  }

  // know white labelling
  let metaKnowWhiteLabelling =
    _.get(data, "business.metaInformation.knowWhiteLabelling") ||
    _.get(myMetaData, "knowWhiteLabelling");
  let knowWhiteLabelling =
    metaKnowWhiteLabelling !== undefined ? metaKnowWhiteLabelling : false;

  //Float confirmation
  let metaFloatBalanceDeposit =
    _.get(data, "business.metaInformation.floatBalanceDeposit") ||
    _.get(myMetaData, "floatBalanceDeposit");
  let floatBalanceDeposit =
    metaFloatBalanceDeposit !== undefined ? metaFloatBalanceDeposit : false;

  let metaApplyThroughBrokerageHouseName =
    _.get(data, "business.metaInformation.applyThroughBrokerageHouseName") ||
    _.get(myMetaData, "applyThroughBrokerageHouseName");
  let applyThroughBrokerageHouseName = metaApplyThroughBrokerageHouseName ?? "";

  let metaAdvisorCompanyName =
    _.get(data, "business.metaInformation.advisorCompanyName") ||
    _.get(myMetaData, "advisorCompanyName");
  let advisorCompanyName = metaAdvisorCompanyName ?? "";

  let metaAdvisorFirstName =
    _.get(data, "business.metaInformation.advisorFirstName") ||
    _.get(myMetaData, "advisorFirstName");
  let advisorFirstName = metaAdvisorFirstName ?? "";

  let metaAdvisorLastName =
    _.get(data, "business.metaInformation.advisorLastName") ||
    _.get(myMetaData, "advisorLastName");
  let advisorLastName = metaAdvisorLastName ?? "";

  let metaAdvisorEmail =
    _.get(data, "business.metaInformation.advisorEmail") ||
    _.get(myMetaData, "advisorEmail");
  let advisorEmail = metaAdvisorEmail ?? "";

  return {
    businessOperatedName: selector(state, "businessOperatedName"),
    businessLegalName: selector(state, "businessLegalName"),
    businessTelephoneNumber: selector(state, "businessTelephoneNumber"),
    businessTelephoneNumberExtension: selector(
      state,
      "businessTelephoneNumberExtension"
    ),
    website: selector(state, "website"),
    stockSymbol: selector(state, "stockSymbol"),
    corporateNumber: selector(state, "corporateNumber"),
    craBusinessNumber: selector(state, "craBusinessNumber"),
    peoName: selector(state, "peoName"),
    peoContactName: selector(state, "peoContactName"),
    peoContactEmail: selector(state, "peoContactEmail"),
    taxIdOrEinNumber: selector(state, "taxIdOrEinNumber"),
    taxIdOrTinNumber: selector(state, "taxIdOrTinNumber"),
    firstName: selector(state, "firstName"),
    lastName: selector(state, "lastName"),
    email: selector(state, "email"),
    mobileNumber: selector(state, "mobileNumber"),
    workNumber: selector(state, "workNumber"),
    workNumberExt: selector(state, "workNumberExt"),
    documentNumber: selector(state, "documentNumber"),
    planAdminFirstName: selector(state, "planAdminFirstName"),
    planAdminLastName: selector(state, "planAdminLastName"),
    planAdminEmail: selector(state, "planAdminEmail"),
    planAdminMobileNumber: selector(state, "planAdminMobileNumber"),
    planAdminWorkNumber: selector(state, "planAdminWorkNumber"),
    planAdminWorkNumberExt: selector(state, "planAdminWorkNumberExt"),
    planAdminDocumentNumber: selector(state, "planAdminDocumentNumber"),
    canadaAddresses: selector(state, "canadaAddresses"),
    otherAddresses: selector(state, "otherAddresses"),
    authorizedSigners: selector(state, "authorizedSigners"),
    knowWhiteLabelling: knowWhiteLabelling,
    floatBalanceDeposit: floatBalanceDeposit,
    applyThroughBrokerageHouseName: applyThroughBrokerageHouseName,
    advisorCompanyName: advisorCompanyName,
    advisorFirstName: advisorFirstName,
    advisorLastName: advisorLastName,
    advisorEmail: advisorEmail,
    enrolledByEmail: enrolledByEmail,

    faxNumber: selector(state, "faxNumber"),
    faxNumberExt: selector(state, "faxNumberExt"),
    bankName: selector(state, "bankName"),
    bankBranchNumber: selector(state, "bankBranchNumber"),
    bankNumber: selector(state, "bankNumber"),
    accountNumber: selector(state, "accountNumber"),
    company: data,
    bankPaymentMethod: _.get(data, "business.defaultPaymentMethod"),
    bhaName:
      _.get(data, "business.metaInformation.assocBrokerageHouseName") ||
      _.get(myMetaData, "assocBrokerageHouseName"),
    brokerName:
      _.get(data, "business.metaInformation.assocBrokerName") ||
      _.get(myMetaData, "assocBrokerName"),
    tpaName:
      _.get(
        data,
        "business.metaInformation.assocThirdPartyAdministratorName"
      ) || _.get(myMetaData, "assocThirdPartyAdministratorName"),
    ayaSalesRepName:
      _.get(data, "business.metaInformation.ayaSalesRepName") ||
      _.get(myMetaData, "ayaSalesRepName"),
    bhaGuid:
      _.get(data, "business.newEnrolledByBusinessID") ||
      _.get(data, "business.enrolledByBusinessID") ||
      _.get(businessRelation, "bhaGuid"),
    brokerGuid:
      _.get(data, "business.metaInformation.requestedByPersonGuid") ||
      _.get(myMetaData, "requestedByPersonGuid"),
    tpaGuid:
      _.get(data, "business.requestedForBusinessAssocId") ||
      _.get(businessRelation, "tpaGuid") ||
      _.get(businessRelation, "bhaGuid"),
    ayaSalesRepGuid: _.get(data, "salesPersonGuid"),
    industries: allIndustries,
    industryList: industryList,
    industryClassificationList: industryClassificationList,
    businessIndustry:
      _.get(data, "business.metaInformation.industry.industryType") ||
      _.get(myMetaData, "industryType"),
    industryClassificationType:
      _.get(
        data,
        "business.metaInformation.industry.industryClassificationType"
      ) || _.get(myMetaData, "industryClassificationType"),
    estimatedNumberOfEmployees:
      _.get(data, "business.metaInformation.numberOfEmployees") ||
      _.get(myMetaData, "numberOfEmployees"),
    exchange:
      _.get(data, "business.metaInformation.trading.stockExchangeName") ||
      _.get(myMetaData, "stockExchangeName"),
    operatingInCanada:
      _.get(data, "business.metaInformation.operatingInCanada") ||
      _.get(myMetaData, "operatingInCanada"),
    businessType:
      _.get(data, "business.metaInformation.businessType") ||
      _.get(myMetaData, "businessType"),
    corporateNumberType:
      _.get(data, "business.metaInformation.corporateNumberType") ||
      _.get(myMetaData, "corporateNumberType"),
    provinceOfRegisteredOffice:
      _.get(data, "business.metaInformation.jurisdictionOfInsurance") ||
      _.get(myMetaData, "jurisdictionOfInsurance"),
    dateOfRegistration:
      _.get(data, "business.metaInformation.dateOfRegistration") ||
      _.get(myMetaData, "dateOfRegistration"),
    sourceDocument:
      _.get(data, "business.metaInformation.sourceDocument.name") ||
      _.get(myMetaData, "name"),
    //Business Registered Address
    streetOne: streetOne,
    streetTwo: streetTwo,
    city: city,
    province: province,
    country: country,
    postalCode: postalCode,
    //Business Mailing Address
    mailingStreetOne: mailingStreetOne,
    mailingStreetTwo: mailingStreetTwo,
    mailingCity: mailingCity,
    mailingProvince: mailingProvince,
    mailingCountry: mailingCountry,
    mailingPostalCode: mailingPostalCode,
    //Person Residential Address
    person1StreetOne:
      _.get(data, "person.address.streetOne") || person1StreetOne,
    person1StreetTwo:
      _.get(data, "person.address.streetTwo") || person1StreetTwo,
    person1City: _.get(data, "person.address.city") || person1City,
    person1Province: _.get(data, "person.address.province") || person1Province,
    person1Country: _.get(data, "person.address.country") || person1Country,
    person1PostalCode:
      _.get(data, "person.address.postalCode") || person1PostalCode,
    //New Plan Admin Residential Address
    planAdminStreetOne: planAdminStreetOne,
    planAdminStreetTwo: planAdminStreetTwo,
    planAdminCity: planAdminCity,
    planAdminProvince: planAdminProvince,
    planAdminCountry: "CA",
    planAdminPostalCode: planAdminPostalCode,

    dateOfBirth:
      _.get(data, "person.metaInformation.dateOfBirth") ||
      _.get(myMetaData, "dateOfBirth"),
    documentType:
      _.get(data, "person.verification.documentType") ||
      personVerification.documentType,
    expiryDate:
      _.get(data, "person.verification.expiryDate") ||
      personVerification.expiryDate,
    provinceOfIssuance:
      _.get(data, "person.verification.jurisdictionOfInsurance") ||
      personVerification.jurisdictionOfInsurance,

    //contacts
    // planSponsorAccountant:
    //   planSponsorAccountants.length > 0 ? planSponsorAccountants[0] : "",
    planSponsorDirectors: planSponsorDirectors,
    planSponsorBeneficiarys: planSponsorBeneficiarys,
    planSponsorAuthorizedSigners: planSponsorAuthorizedSigners || [],
    //Addresses and Fatca
    // canadaAddresses: canadaAddresses,
    // otherAddresses: otherAddresses,
    fatca: fatca,
    isPEP: isPEP,
    //pdv's
    //pdv's selectors
    previousProgram: selector(state, "previousProgram"),
    otherWSA: selector(state, "otherWSA"),
    administrationFee: selector(state, "administrationFee"),
    claimProcessingFee: selector(state, "claimProcessingFee"),
    setupFee: selector(state, "setupFee"),
    employeeSetupFee: selector(state, "employeeSetupFee"),
    cardIssuanceFee: selector(state, "cardIssuanceFee"),
    employeeMonthlyFee: selector(state, "employeeMonthlyFee"),
    masterCardTransactionInCanada: selector(
      state,
      "masterCardTransactionInCanada"
    ),
    foreignExchangeFee: selector(state, "foreignExchangeFee"),
    outOfPocketEFT: selector(state, "outOfPocketEFT"),
    internalNote: selector(state, "internalNote"),
    employeeClass: selector(state, "employeeClass"),
    isOldClassStructure: selector(state, "isOldClassStructure"),
    employeeOffboardFee: selector(state, "employeeOffboardFee"),
    minimumBalance: selector(state, "minimumBalance"),
    maximumBalance: selector(state, "maximumBalance"),
    //pdv's state values
    startDate:
      _.get(
        data,
        "business.programDefinedVariables.previousProgram.startDate"
      ) || _.get(programDefinedVariables, "startDate"),
    endDate:
      _.get(data, "business.programDefinedVariables.previousProgram.endDate") ||
      _.get(programDefinedVariables, "endDate"),
    effectiveTerminationDate:
      _.get(
        data,
        "business.programDefinedVariables.previousProgram.effectiveTerminationDate"
      ) || _.get(programDefinedVariables, "effectiveTerminationDate"),
    startDateYear1:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.startDateYear1"
      ) || _.get(programDefinedVariables, "startDateYear1"),
    endDateYear1:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.endDateYear1"
      ) || _.get(programDefinedVariables, "endDateYear1"),
    startDateYear2:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.startDateYear2"
      ) || _.get(programDefinedVariables, "startDateYear2"),
    programTypeId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.programType"
      ) || _.get(programDefinedVariables, "programType"),
    purseType:
      _.get(data, "business.programDefinedVariables.contractTerm.purseType") ||
      _.get(programDefinedVariables, "purseType"),
    programPaymentTypeId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.programPaymentType"
      ) || _.get(programDefinedVariables, "programPaymentType"),
    invoicingPeriodId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.paymentSchedule"
      ) || _.get(programDefinedVariables, "paymentSchedule"),
    prorateYear1Id:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.prorateYear1"
      ) ||
      _.get(programDefinedVariables, "prorateYear1") ||
      false,
    prorateAfterGivenYearId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.prorateAfterGivenYear"
      ) ||
      _.get(programDefinedVariables, "prorateAfterGivenYear") ||
      false,
    postpaymentFundsPullingRateId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.postpaymentFundsPullingRate"
      ) || _.get(programDefinedVariables, "postpaymentFundsPullingRate"),
    // postpaymentFloatOrDepositId:
    //   _.get(
    //     data,
    //     'business.programDefinedVariables.contractTerm.postpaymentFloatOrDeposit'
    //   ) || _.get(programDefinedVariables, 'postpaymentFloatOrDeposit'),
    chargePEPM:
      _.get(data, "business.programDefinedVariables.contractTerm.chargePEPM") ||
      _.get(programDefinedVariables, "chargePEPM") ||
      false,
    dependents:
      _.get(data, "business.programDefinedVariables.dependents.allow") ||
      _.get(programDefinedVariables, "allow") ||
      false,
    fitnessWSA:
      _.get(data, "business.programDefinedVariables.contractTerm.fitnessWSA") ||
      _.get(programDefinedVariables, "fitnessWSA") ||
      false,
    personalDevelopmentWSA:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.personalDevelopmentWSA"
      ) ||
      _.get(programDefinedVariables, "personalDevelopmentWSA") ||
      false,
    mentalHealthWSA:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.mentalHealthWSA"
      ) ||
      _.get(programDefinedVariables, "mentalHealthWSA") ||
      false,
    nutritionWSA:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.nutritionWSA"
      ) ||
      _.get(programDefinedVariables, "nutritionWSA") ||
      false,
    otherWSA:
      _.get(data, "business.programDefinedVariables.contractTerm.otherWSA") ||
      _.get(programDefinedVariables, "otherWSA") ||
      false,
    actionAfterMastercardClaimDeclineId:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.actionAfterMastercardClaimDecline"
      ) || _.get(programDefinedVariables, "actionAfterMastercardClaimDecline"),
    shipTo:
      _.get(data, "business.programDefinedVariables.miscellaneous.shipTo") ||
      _.get(programDefinedVariables, "shipTo"),
    planAdministratorsCanAddEmployeesId:
      _.get(
        data,
        "business.programDefinedVariables.miscellaneous.planAdministratorsCanAddEmployees"
      ) ||
      _.get(programDefinedVariables, "planAdministratorsCanAddEmployees") ||
      false,
    daysToSubmitClaimPreviousYearId:
      _.get(
        data,
        "business.programDefinedVariables.hsaProcessingRules.daysToSubmitClaimPreviousYear"
      ) || _.get(programDefinedVariables, "daysToSubmitClaimPreviousYear"),
    lastDayToSubmitAfterTerminationId:
      _.get(
        data,
        "business.programDefinedVariables.hsaProcessingRules.daysToSubmitClaimTermination"
      ) || _.get(programDefinedVariables, "daysToSubmitClaimTermination"),
    programName:
      _.get(
        data,
        "business.programDefinedVariables.previousProgram.programName"
      ) || _.get(programDefinedVariables, "programName"),
    previousProgram:
      _.get(
        data,
        "business.programDefinedVariables.previousProgram.programName"
      ) || _.get(programDefinedVariables, "programName")
        ? true
        : false,
    //end of pdv's
    bankName: _.get(data, "bankAccountDetails.bankName"),
    planAdminParams: planAdminParams,
    pepmFrequency:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.pepmFrequency"
      ) ||
      _.get(programDefinedVariables, "pepmFrequency") ||
      PEPM_FREQUENCY,
    companyEnrolment: selector(state, "companyEnrolment"),

    initialValues: {
      businessOperatedName: _.get(data, "business.businessOperatedName"),
      businessLegalName: _.get(data, "business.businessLegalName"),
      peoName:
        _.get(data, "business.metaInformation.peo.peoName") ||
        _.get(myMetaData, "peoName"),
      peoContactName:
        _.get(data, "business.metaInformation.peo.peoContactName") ||
        _.get(myMetaData, "peoContactName"),
      peoContactEmail:
        _.get(data, "business.metaInformation.peo.peoContactEmail") ||
        _.get(myMetaData, "peoContactEmail"),
      taxIdOrEinNumber:
        _.get(data, "business.metaInformation.taxIdOrEinNumber") ||
        _.get(myMetaData, "taxIdOrEinNumber"),
      taxIdOrTinNumber:
        _.get(data, "business.metaInformation.taxIdOrTinNumber") ||
        _.get(myMetaData, "taxIdOrTinNumber"),
      website:
        _.get(data, "business.metaInformation.companyWebsite") ||
        _.get(myMetaData, "companyWebsite"),
      stockSymbol:
        _.get(data, "business.metaInformation.trading.stockSymbol") ||
        _.get(myMetaData, "stockSymbol"),
      craBusinessNumber: _.get(data, "business.businessDbaLicNumber"),
      corporateNumber:
        _.get(data, "business.metaInformation.corporateNumber") ||
        _.get(myMetaData, "corporateNumber"),
      //plan contact info
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      mobileNumber: mobileNumber ?? "",
      workNumber: workNumber ?? "",
      workNumberExt: workNumberExt ?? "",
      documentNumber:
        _.get(data, "person.verification.documentNumber") ||
        personVerification.documentNumber,
      //other addresses
      canadaAddresses: canadaAddresses,
      otherAddresses: otherAddresses,
      directors: planSponsorDirectors,
      beneficiaryOwners: planSponsorBeneficiarys,
      authorizedSigners: planSponsorAuthorizedSigners,
      //alternate plan admin
      planAdminFirstName: planAdminFirstName,
      planAdminLastName: planAdminLastName,
      planAdminEmail: planAdminEmail,
      planAdminMobileNumber: planAdminMobileNumber,
      planAdminWorkNumber: planAdminWorkNumber,
      planAdminWorkNumberExt: planAdminWorkNumberExt,

      //pdv's
      employeeClass: employeeClass,
      isOldClassStructure: isOldClassStructure,
      programName:
        _.get(
          data,
          "business.programDefinedVariables.previousProgram.programName"
        ) || _.get(programDefinedVariables, "programName"),
      previousProgram:
        _.get(
          data,
          "business.programDefinedVariables.previousProgram.programName"
        ) || _.get(programDefinedVariables, "programName")
          ? true
          : false,
      otherWSA:
        _.get(data, "business.programDefinedVariables.contractTerm.otherWSA") ==
          "false" || _.get(programDefinedVariables, "otherWSA") == "false"
          ? ""
          : _.get(
              data,
              "business.programDefinedVariables.contractTerm.otherWSA"
            ) || _.get(programDefinedVariables, "otherWSA"),

      administrationFee: restorePercentageValue(
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.administrationFee"
        ) ||
          _.get(programDefinedVariables, "administrationFee") ||
          ADMINISTRATION_FEE
      ),
      claimProcessingFee: restorePercentageValue(
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.claimProcessingFee"
        ) ||
          _.get(programDefinedVariables, "claimProcessingFee") ||
          CLAIM_PROCESSING_FEE
      ),
      setupFee:
        _.get(data, "business.programDefinedVariables.contractTerm.setupFee") ||
        _.get(programDefinedVariables, "setupFee") ||
        0,
      employeeSetupFee:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.employeeSetupFee"
        ) ||
        _.get(programDefinedVariables, "employeeSetupFee") ||
        0,
      cardIssuanceFee:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.cardIssuanceFee"
        ) ||
        _.get(programDefinedVariables, "cardIssuanceFee") ||
        CARD_ISSUANCE_FEE,
      employeeMonthlyFee:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.monthlyEmployeeFee"
        ) ||
        _.get(programDefinedVariables, "monthlyEmployeeFee") ||
        0,
      masterCardTransactionInCanada:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.mastercardInCanadaTransactionFeePerClaim"
        ) ||
        _.get(
          programDefinedVariables,
          "mastercardInCanadaTransactionFeePerClaim"
        ) ||
        0,
      foreignExchangeFee: restorePercentageValue(
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.mastercardOutofCanadaTransactionForeignExchangeFee"
        ) ||
          _.get(
            programDefinedVariables,
            "mastercardOutofCanadaTransactionForeignExchangeFee"
          ) ||
          0
      ),
      outOfPocketEFT:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.oopTransactionEftFee"
        ) ||
        _.get(programDefinedVariables, "oopTransactionEftFee") ||
        0,
      internalNote:
        _.get(
          data,
          "business.programDefinedVariables.miscellaneous.internalNote"
        ) || _.get(programDefinedVariables, "internalNote"),
      employeeOffboardFee:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.employeeOffboardFee"
        ) ||
        _.get(programDefinedVariables, "employeeOffboardFee") ||
        0,
      minimumBalance:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.minimumBalance"
        ) ||
        _.get(programDefinedVariables, "minimumBalance") ||
        0,
      maximumBalance:
        _.get(
          data,
          "business.programDefinedVariables.contractTerm.maximumBalance"
        ) ||
        _.get(programDefinedVariables, "maximumBalance") ||
        0,
      //End of PDV's
      //bank
      bankName: _.get(data, "bankAccountDetails.bankName"),
      bankAddress: _.get(data, "bankAccountDetails.bankBranchStreetOne"),
      accountType: _.get(data, "bankAccountDetails.bankAccountType"),
      bankBranchNumber: _.get(data, "bankAccountDetails.bankBranchNumber"),
      bankNumber: _.get(data, "bankAccountDetails.bankNumber"),
      accountNumber: _.get(data, "bankAccountDetails.bankAccountNumber"),
      companyEnrolment: companyEnrolment,
      applyThroughBrokerageHouseName: applyThroughBrokerageHouseName,
      advisorCompanyName: advisorCompanyName,
      advisorFirstName: advisorFirstName,
      advisorLastName: advisorLastName,
      advisorEmail: advisorEmail,
      enrolledByEmail: enrolledByEmail,

      //end of bank
    },
  };
};

export default connect(mapStateToProps, {
  updateCompany,
  clearGeneral,
  clearCompany,
  fetchIndustries,
  createNewPlanAdmin,
  // saveEnrolmentCompanyData,
  submitCompanyEnrolmentData,
})(WrappedReviewInformation);
