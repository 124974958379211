import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import {
  clearCompany,
  clearGeneral,
  createCompany,
} from "../../../../../actions";
import GradientText from "../../../../shared/GradientText/GradientText";
import Confetti from "../../../../shared/confetti/Confetti";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardCheck,
  faFileCircleCheck,
  faFileCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { COLOR_SCHEMES } from "../../../../../styles/colors/colors";
import Button from "../../../../shared/button";
import history from "../../../../../history";

const styleClasses = {
  imageContainer: {
    background: `linear-gradient(45deg,  
		${COLOR_SCHEMES.PRIMARY_GRADIENT.BLUE}, 
		${COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE_SECONDARY}, 
		${COLOR_SCHEMES.PRIMARY_GRADIENT.PURPLE})`,
    width: "72px",
    height: "72px",
    padding: "10px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

export class EnrolmentSuccess extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      view: "STEP_ONE",
      languageId: 1,
      companyIncorperated: true,
      companyHouse: "",
      selectedFile: "",
      companyEnrolment: this.props.companyEnrolment ?? {},
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const { enrolmentStatus, submitCompanyEnrolmentDataSuccess } =
        this.props.companyEnrolment ?? {};
      this.setState({
        enrolmentStatus,
        submitCompanyEnrolmentDataSuccess,
      });
    });
  }

  render() {
    const { styles } = this.props;
    return (
      <div className={styles.contentContainer2} style={{ marginTop: "25px" }}>
        <div className={styles.mainContentContainer}>
          {/* <div className={styles.successImageContainer}>
            <div style={styleClasses.imageContainer}>
              <FontAwesomeIcon
                icon={faFileCircleCheck}
                style={{ padding: 10, color: `${COLOR_SCHEMES.WHITE}` }}
                size="3x"
              />
            </div>
          </div> */}
          {this.state.enrolmentStatus === "Submitted" && (
            <div>
              <div className={styles.successImageContainer}>
                <div style={styleClasses.imageContainer}>
                  <FontAwesomeIcon
                    icon={faFileCircleCheck}
                    style={{ padding: 10, color: `${COLOR_SCHEMES.WHITE}` }}
                    size="3x"
                  />
                </div>
              </div>
              {this.state.submitCompanyEnrolmentDataSuccess && <Confetti />}
              <div className={styles.successMessage}>
                <GradientText
                  string={"Company Successfully Added For Enrolment Review"}
                  fontSize={"1.5rem"}
                />
              </div>
              <div className={styles.mainInfoSection}>
                <div className={styles.companyLogo}>
                  {this.props.companiesBusinessName
                    ? this.props.companiesBusinessName
                        .substring(0, 2)
                        .toUpperCase()
                    : ""}
                </div>
                <div className={styles.bhInfo}>
                  <div className={styles.bold}>
                    {this.props.companiesBusinessName}
                  </div>
                </div>
                <div className={styles.bhInfo}>
                  <div className={styles.light}>
                    {this.props.usersFirstName} {this.props.usersLastName}
                  </div>
                  <div className={styles.light}>
                    {this.props.usersEmail
                      ? `${this.props.usersEmail.substring(0, 20)} ${
                          _.size(this.props.usersEmail) > 21 ? "..." : ""
                        }`
                      : ""}
                  </div>
                </div>
              </div>
              <div
                className={styles.descriptionSucccess}
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                Thank you {this.props.usersFirstName} {this.props.usersLastName}{" "}
                for submitting your enrolment request!
              </div>
              <br />
              <div className={styles.descriptionSucccess}>
                Your application is currently undergoing review by our team.
                Rest assured, we are carefully considering all aspects. We'll
                keep you informed via email once a decision has been made. We
                appreciate your patience and understanding during this process.
                <br />
                <GradientText
                  string={"Thank you for choosing AyaCare!"}
                  fontSize={"20px"}
                  extraStyles={{ marginTop: "1rem" }}
                />
              </div>
            </div>
          )}

          {this.state.enrolmentStatus === "Approved" && (
            <div>
              <div className={styles.successImageContainer}>
                <div style={styleClasses.imageContainer}>
                  <FontAwesomeIcon
                    icon={faClipboardCheck}
                    style={{ padding: 10, color: `${COLOR_SCHEMES.WHITE}` }}
                    size="3x"
                  />
                </div>
              </div>
              <div className={styles.successMessage}>
                <GradientText
                  string={"Company Successfully Approved For Enrolment"}
                  fontSize={"1.5rem"}
                />
              </div>
              <div className={styles.mainInfoSection}>
                <div className={styles.companyLogo}>
                  {this.props.companiesBusinessName
                    ? this.props.companiesBusinessName
                        .substring(0, 2)
                        .toUpperCase()
                    : ""}
                </div>
                <div className={styles.bhInfo}>
                  <div className={styles.bold}>
                    {this.props.companiesBusinessName}
                  </div>
                </div>
                <div className={styles.bhInfo}>
                  <div className={styles.light}>
                    {this.props.usersFirstName} {this.props.usersLastName}
                  </div>
                  <div className={styles.light}>
                    {this.props.usersEmail
                      ? `${this.props.usersEmail.substring(0, 20)} ${
                          _.size(this.props.usersEmail) > 21 ? "..." : ""
                        }`
                      : ""}
                  </div>
                </div>
              </div>
              <div
                className={styles.descriptionSucccess}
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                Thank you {this.props.usersFirstName} {this.props.usersLastName}{" "}
                for enroling your company!
              </div>
              <br />
              <div className={styles.descriptionSucccess}>
                <p>
                  Congratulations! Your application has been approved! You can
                  expect to receive further instructions via email regarding the
                  next steps. You can log in to the{" "}
                  <a href={"https://app.ayacare.com"} target="__blank">
                    <GradientText
                      string={"AyaCare Portal"}
                      fontSize={"16px"}
                      cursor="pointer"
                    />
                  </a>{" "}
                  to begin the onboarding process.
                </p>
                <p>
                  In case you haven't received any communication yet, please
                  don't hesitate to reach out to us at{" "}
                  <a href="mailto:support@ayacare.com?Subject=Hello%20again">
                    support@ayacare.com
                  </a>
                </p>
                <GradientText
                  string={"Thank you for choosing AyaCare!"}
                  fontSize={"20px"}
                  extraStyles={{ marginTop: "1rem" }}
                />
              </div>
            </div>
          )}

          {this.state.enrolmentStatus === "Declined" && (
            <div>
              <div className={styles.successImageContainer}>
                <div style={styleClasses.imageContainer}>
                  <FontAwesomeIcon
                    icon={faFileCircleXmark}
                    style={{ padding: 10, color: `${COLOR_SCHEMES.WHITE}` }}
                    size="3x"
                  />
                </div>
              </div>
              <div className={styles.successMessage}>
                <GradientText
                  string={"Company Enrolment Declined"}
                  fontSize={"1.5rem"}
                />
              </div>
              <div className={styles.mainInfoSection}>
                <div className={styles.companyLogo}>
                  {this.props.companiesBusinessName
                    ? this.props.companiesBusinessName
                        .substring(0, 2)
                        .toUpperCase()
                    : ""}
                </div>
                <div className={styles.bhInfo}>
                  <div className={styles.bold}>
                    {this.props.companiesBusinessName}
                  </div>
                </div>
                <div className={styles.bhInfo}>
                  <div className={styles.light}>
                    {this.props.usersFirstName} {this.props.usersLastName}
                  </div>
                  <div className={styles.light}>
                    {this.props.usersEmail
                      ? `${this.props.usersEmail.substring(0, 20)} ${
                          _.size(this.props.usersEmail) > 21 ? "..." : ""
                        }`
                      : ""}
                  </div>
                </div>
              </div>
              <div
                className={styles.descriptionSucccess}
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                Thank you {this.props.usersFirstName} {this.props.usersLastName}{" "}
                for your interest.
              </div>
              <br />
              <div className={styles.descriptionSucccess}>
                After careful consideration, we regret to inform you that your
                application has not been approved at this time.
                <br />
                We genuinely appreciate your interest in partnering with us and
                encourage you to consider reapplying in the future. Should you
                have any questions or require further clarification, please
                don't hesitate to reach out at{" "}
                <a href="mailto:support@ayacare.com?Subject=Hello%20again">
                  support@ayacare.com
                </a>
                <br />
                <GradientText
                  string={"Thank you for choosing AyaCare!"}
                  fontSize={"20px"}
                  extraStyles={{ marginTop: "1rem" }}
                />
              </div>
            </div>
          )}

          <div className={styles.actionButton} style={{ marginTop: "2rem" }}>
            <Button
              name="Go to Dashboard"
              style="primary"
              onClick={() => {
                this.props.clearGeneral();
                this.props.clearCompany();
                history.push("/dashboard");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector("EnrolmentSuccess");
  const data = state.companyReducer?.companyEnrolment?.companyData ?? {};
  let payloadString =
    JSON.stringify(state.companyReducer.companyEnrolment?.companyData) ?? "";
  const companyEnrolment = state.companyReducer.companyEnrolment ?? {};
  let { person = {}, business = {} } = data;

  return {
    company: data,
    // accessToken,
    usersFirstName: person.firstName,
    usersLastName: person.lastName,
    usersEmail: person.email,
    usersID: person.personGuid ? person.personGuid.split("-")[0] : "no ID",
    companiesBusinessName: business.businessOperatedName,
    companyEnrolment,
  };
};

export default connect(mapStateToProps, {
  createCompany,
  clearCompany,
  clearGeneral,
})(EnrolmentSuccess);
