import React from "react";
import { positions, useAlert } from "react-alert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import {
  clearCompany,
  clearGeneral,
  createCompany,
  fetchBrokerageHouses,
  fetchPcoTpa,
  setLoading,
  updateCompany,
  setCompanyEnrolmentDetails,
} from "../../../../actions";
import styles from "../../../shared/company-enrolment/Company.module.css";
import AddBankAccount from "./enrolmentPages/AddBankAccount";
import Contacts from "./enrolmentPages/Contacts";
import Corporate from "./enrolmentPages/Corporate";
import CorporateFull from "./enrolmentPages/CorporateFull";
import EnrolmentSuccess from "./enrolmentPages/EnrolmentSuccess";
import ProgramVariablesCompany from "./enrolmentPages/ProgramVariablesCompany";
import ReviewInformation from "./enrolmentPages/ReviewInformation";
import GradientText from "../../../shared/GradientText/GradientText";
import Button from "../../../shared/button";

const EnrolmentPageMapper = (props) => {
  const alert = useAlert();

  React.useEffect(() => {
    if (props.createEnrolmentCompanyDataFailed) {
      alert.show(
        {
          rawMessage:
            "Failed to create company. Please try again or contact support. " +
            props.errorMessage,
        },
        {
          position: positions.TOP_CENTER,
          type: "error",
          timeout: 5000,
          onClose: () => {
            props.setLoading({
              error: false,
              errorMessage: null,
              success: false,
              successMessage: null,
            });
            props.setCompanyEnrolmentDetails({
              createEnrolmentCompanyDataFailed: false,
            });
          },
        }
      );
    }
  }, [props.createEnrolmentCompanyDataFailed]);

  React.useEffect(() => {
    if (
      props.saveCompanyEnrolmentDataFailed ||
      props.submitCompanyEnrolmentDataFailed
    ) {
      alert.show(
        {
          rawMessage:
            "Failed to save company details. Please try again or contact support. " +
            props.errorMessage,
        },
        {
          position: positions.TOP_CENTER,
          type: "error",
          timeout: 5000,
          onClose: () => {
            props.setLoading({
              error: false,
              errorMessage: null,
              success: false,
              successMessage: null,
            });
            props.setCompanyEnrolmentDetails({
              saveCompanyEnrolmentDataFailed: false,
              submitCompanyEnrolmentDataFailed: false,
            });
          },
        }
      );
    }
  }, [
    props.saveCompanyEnrolmentDataFailed,
    props.submitCompanyEnrolmentDataFailed,
  ]);

  const updateView = (view) => {
    props.setCompanyEnrolmentDetails({ view });
  };

  const handleBack = () => {
    props.clearGeneral();
    props.history.push("/companies");
  };

  const stepOneView = () => (
    <Corporate updateView={(view) => updateView(view)} styles={styles} />
  );
  const stepTwoView = () => (
    <ProgramVariablesCompany
      updateView={(view) => updateView(view)}
      styles={styles}
    />
  );
  const stepThreeView = () => (
    <CorporateFull updateView={(view) => updateView(view)} styles={styles} />
  );
  const stepFourView = () => (
    <Contacts updateView={(view) => updateView(view)} styles={styles} />
  );
  const stepFiveView = () => (
    <AddBankAccount updateView={(view) => updateView(view)} styles={styles} />
  );
  const stepSixView = () => (
    <ReviewInformation
      updateView={(view) => updateView(view)}
      styles={styles}
    />
  );
  const stepSevenView = () => (
    <EnrolmentSuccess updateView={(view) => updateView(view)} styles={styles} />
  );

  let view = props.view;
  let currentView;

  switch (view) {
    case "STEP_ONE":
      currentView = stepOneView();
      break;
    case "STEP_TWO":
      currentView = stepTwoView();
      break;
    case "STEP_THREE":
      currentView = stepThreeView();
      break;
    case "STEP_FOUR":
      currentView = stepFourView();
      break;
    case "STEP_FIVE":
      currentView = stepFiveView();
      break;
    case "STEP_SIX":
      currentView = stepSixView();
      break;
    case "STEP_SEVEN":
      currentView = stepSevenView();
      break;
    default:
      currentView = stepOneView();
  }

  const handleClose = () => {
    props.clearGeneral();
    props.history.push("/companies");
  };

  return (
    <div className={styles.container}>
      <div className={styles.subHeader}>
        {/* <div className={styles.leftSubHeader}> */}
        {/* <Button content="Back" onClick={handleBack} basic icon="arrow left" /> */}
        {/* </div> */}
        {props.view !== "STEP_SEVEN" && (
          <div className={styles.centerSubHeader}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <GradientText
                string={props.largeHeader}
                fontSize={"1.8rem"}
                extraStyles={{ fontWeight: "bold" }}
              />
              <Icon
                name="close"
                size="large"
                onClick={handleClose}
                style={{ cursor: "pointer", marginLeft: "auto" }}
              />
            </div>
            <span className={styles.smallHeader}>{props.largeSubHeader}</span>
          </div>
        )}
      </div>
      {currentView}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { view, largeHeader, largeSubHeader, errorMessage } =
    state.generalReducer;
  const { person, business } =
    state.companyReducer.companyEnrolment.companyData ?? {};
  const { companyEnrolment, userClaims } = state.companyReducer;

  return {
    view: view || "STEP_ONE",
    largeHeader:
      !view || view === "STEP_ONE" ? "Add New Company" : "Company Details",
    largeSubHeader,
    business,
    companyEnrolment,
    userClaims,
    createEnrolmentCompanyDataFailed:
      companyEnrolment.createEnrolmentCompanyDataFailed,
    errorMessage: errorMessage ?? "",
    saveCompanyEnrolmentDataFailed:
      companyEnrolment.saveCompanyEnrolmentDataFailed,
    submitCompanyEnrolmentDataFailed:
      companyEnrolment.submitCompanyEnrolmentDataFailed,
  };
};

export default connect(mapStateToProps, {
  clearCompany,
  clearGeneral,
  createCompany,
  fetchBrokerageHouses,
  fetchPcoTpa,
  updateCompany,
  setLoading,
  setCompanyEnrolmentDetails,
})(withRouter(EnrolmentPageMapper));
