// React
import { PureComponent, ReactElement } from "react";
// Datepicker
import DatePicker from "react-datepicker";
// Constant
import { Images } from "../../../constants";
// Scss & css
import "react-datepicker/dist/react-datepicker.css";
import styles from "./CustomDate.module.scss";

// Interface
interface IProps {
  name: string;
  label: string;
  type: string;
  placeholder: string;
  labelDetail: string;
  secondaryLink: string;
  secondaryLinkOnClick: () => void;
  extrastyle: string;
  alternateSource: string;
  alternateSourceLabel: string;
  secondaryType: string;
  error: string;
  selected: Date;
  disabled: boolean;
  handleDateChange: (...args: any) => void;
  showTimeInput: boolean;
  coreInputStylesContainer: string;
  coreInputStyles: string;
  maxDate?: any;
  minDate?: any;
}

/**
 * @export
 * @class DateInput
 * @extends {PureComponent<IProps>}
 */
export class DateInput extends PureComponent<IProps> {
  /**
   * @return {*}
   * @memberof DateInput
   */
  render(): ReactElement {
    let {
      name,
      label,
      type,
      placeholder,
      labelDetail,
      secondaryLink,
      secondaryLinkOnClick,
      extrastyle,
      alternateSource,
      alternateSourceLabel,
      secondaryType,
      error,
      selected,
      disabled,
      handleDateChange,
      showTimeInput,
      coreInputStylesContainer,
      coreInputStyles,
      maxDate,
      minDate
    }: IProps = this.props;

    const leftIconReferenceStyles: string = "leftIconReferenceStyles";
    if (Object.prototype.toString.call(selected) === "[object Date]") {
      // it is a date
      if (isNaN(selected.getTime())) {
        selected = new Date();
      } else {
        new Date(selected);
      }
    } else {
      selected = new Date();
    }

    return (
      <div
        className={`
					${styles.inputContainer} 
					${styles[extrastyle]} 
					${styles[coreInputStylesContainer]}
				`}
      >
        {label && (
          <div className={styles.labelContainer}>
            <div className={styles.label}>
              {label}
              <span className={styles.labelDetail}>{labelDetail}</span>
            </div>
            <div
              className={styles.secondaryLink}
              onClick={secondaryLinkOnClick}
            >
              {secondaryLink}
            </div>
          </div>
        )}
        <div className={styles.inputWrapper}>
          <span className={styles.leftIcon}>
            <img height={"20px"} width={"20px"} src={Images.calendar} />
          </span>
          <DatePicker
            {...{
              className: `
                ${disabled ? `${styles.inputFieldDisabled}` : `${styles.inputField}`} 
                    ${styles[leftIconReferenceStyles]} 
                    ${styles[coreInputStyles]} 
                    ${disabled ? `${styles.notEditableStyles}` : ""} 
              `,
              selected,
              onChange:(date: any) => handleDateChange(date, name),
              dateFormat:"d MMMM, yyyy",
              disabled,
              showMonthDropdown: true,
              showYearDropdown: true,
              showTimeInput: showTimeInput || false,
              ...(maxDate && {maxDate}),
              ...(minDate && {minDate: new Date(minDate)}),
            }}
          />
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  }
}

// Export
export default DateInput;
