import _, { set } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  arrayPush,
  arrayRemoveAll,
  clearFields,
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
} from "redux-form";
import {
  // createNewPlanAdmin,
  // fetchIndustries,
  updateCompany,
  saveEnrolmentCompanyData,
} from "../../../../../actions";
import { GlobalCountryList } from "../../../../../constants";
import {
  COMPLETE__STEP_SEVEN__COMPANY,
  FULL__STEP_FIVE__COMPANY,
  FULL__STEP_FOUR__COMPANY,
} from "../../../../../sagas/navigation/stepTypes";
import {
  isEmailValid,
  isEmpty,
} from "../../../../../utilities/customFunctions";
import {
  email,
  maxValue100,
  minValue25,
  postalCodeCA,
  postalCodeUS,
  required,
} from "../../../../../utilities/validationRules";
import Button from "../../../../shared/button";
import Checkbox from "../../../../shared/checkbox";
import DateInput from "../../../../shared/customDateInput";
import Divider from "../../../../shared/divider";
import GoogleSuggest from "../../../../shared/googlePlaces";
import Input from "../../../../shared/input";
import { ConnectedInput } from "../../../../shared/input/ConnectedInput";
import { FormattedInput } from "../../../../shared/input/FormattedInput";
import { RadioGroup } from "../../../../shared/radio";
import CustomSelect from "../../../../shared/select";
import { Stepper } from "../../../../shared/stepper";
import {
  BeneficialOwnersList,
  DirectorsList,
  GovermentIDOptions,
  ProvinceList,
  PEPList,
  steps,
} from "../../../../shared/company-enrolment/sampleData";
// import { steps } from "./sampleConstants";
import {
  addToMetaDataPayloadString,
  resetPayload,
} from "../../../../shared/company-enrolment/company-helpers";
import { Icon, Message } from "semantic-ui-react";
import GradientButton from "../../../../auth/login/components/GradientButton";

export class Contacts extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.bottomRef = React.createRef();
    this.state = {
      directorState: this.props.directorState,
      directors: this.props.directors,
      beneficiaryOwnersState: this.props.beneficiaryOwnersState,
      beneficiaryOwners: this.props.beneficiaryOwners,
      beneficiaryOwnersWhoAreDirectors:
        this.props.beneficiaryOwnersWhoAreDirectors,
      expiryDate:
        moment.utc(this.props.expiryDate).toDate() ??
        new Date(
          new Date().setFullYear(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
        ),
      planAdminExpiryDate: new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ),
      planAdministratorSameWithCoperateContact:
        // _.get(this.props.newPlanAdmin, 'person.email') ||
        this.props.planAdminContactSeparate ? true : false,
      planSponsorAccountant: _.get(this.props.planSponsorAccountant, "email")
        ? true
        : false,
      streetOne: this.props.streetOne,
      streetTwo: this.props.streetTwo,
      city: this.props.city,
      province: this.props.province,
      country: this.props.country,
      postalCode: this.props.postalCode,
      dateOfBirth: this.props.dateOfBirth
        ? moment(this.props.dateOfBirth).toDate()
        : new Date(
            new Date().setFullYear(
              new Date().getFullYear() - 18,
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
      documentType: this.props.documentType ?? "",
      documentNumber: this.props.documentNumber ?? "",
      provinceOfIssuance: this.props.provinceOfIssuance ?? "",

      planAdminFirstName: this.props.planAdminFirstName ?? "",
      planAdminLastName: this.props.planAdminLastName ?? "",
      planAdminEmail: this.props.planAdminEmail ?? "",
      planAdminMobileNumber: this.props.planAdminMobileNumber ?? "",
      planAdminWorkNumber: this.props.planAdminWorkNumber ?? "",
      planAdminWorkNumberExt: this.props.planAdminWorkNumberExt ?? "",
      payloadString: this.props.payloadString,
      isPEP: this.props.isPEP,
      companyEnrolment: this.props.companyEnrolment ?? {},
      enrolledByEmail: this.props.enrolledByEmail,
    };
    setTimeout(() => {
      this.updateStateDynamicProps();
    });
  }

  updateStateDynamicProps = () => {
    this.state.beneficiaryOwnersWhoAreDirectors.forEach((index) => {
      this.setState({
        [`beneficiaryOwnerIsDirector${index}`]: true,
      });
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    setTimeout(() => {
      const { selectedCompanyGuid, email } = this.props.companyEnrolment ?? {};
      this.setState({
        selectedCompanyGuid,
        email,
      });
    });
  }

  componentDidUpdate(prevProps) {}

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setAddress = (name, address) => {
    let { address_components, formatted_address } = address;
    let data = {};
    if (address_components) {
      for (let item of address_components) {
        switch (item.types[0]) {
          case "country":
            data.country = item.short_name;
            break;
          case "postal_code":
            data.postalCode = item.long_name;
            break;
          case "administrative_area_level_1":
            data.province = item.long_name;
            break;
          case "political":
            data.city = item.long_name;
            break;
          case "locality":
            !_.has(data, "city")
              ? (data.city = item.long_name)
              : (data.city = `${data.city}`);
            break;
          case "street_number":
            data.streetAddress = item.long_name;
            break;
          case "route":
            data.streetAddress = `${
              data.streetAddress ? `${data.streetAddress}` : ""
            } ${item.long_name}`;
            break;
          default:
            break;
        }
      }
    }

    if (address_components && name === "residentialAddress") {
      this.setState({
        residentialAddress: formatted_address,
        streetOne: data.streetAddress,
        city: data.city,
        province: data.province,
        country: data.country,
        postalCode: data.postalCode,
      });
    } else if (address_components && name === "planAdminResidentialAddress") {
      this.setState({
        planAdminAddress: formatted_address,
        planAdminStreetOne: data.streetAddress,
        planAdminCity: data.city,
        planAdminProvince: data.province,
        planAdminCountry: data.country,
        planAdminPostalCode: data.postalCode,
      });
    } else {
      this.setState({ [name]: address });
    }
  };

  handleGenenicDateChange = (date, context) => {
    this.setState({
      [context]: date,
    });
  };

  changeSelect = (name, value, label) => {
    this.setState({ [name]: value, [`${name}Label`]: label });
  };

  toggleGeneric = (name) => {
    this.setState({ [name]: !this.state[name] }, () => {
      if (!this.state.planAdministratorSameWithCoperateContact) {
        this.props.dispatch(
          clearFields("Contacts", false, false, [
            "planAdminFirstName",
            "planAdminLastName",
            "planAdminEmail",
            "planAdminWorkNumber",
            "planAdminWorkNumberExt",
          ])
        );
      }
    });
  };

  onChangeGenericRadio = (value, name) => {
    this.setState({ [name]: value }, () => {
      if (name === "beneficiaryOwnersState") {
        if (
          this.state.beneficiaryOwnersState === 1 &&
          this.props.beneficiaryOwners.length < 1
        ) {
          this.props.dispatch(arrayRemoveAll("Contacts", "beneficiaryOwners"));
          this.props.dispatch(arrayPush("Contacts", "beneficiaryOwners", {}));
        }
        if (this.state.beneficiaryOwnersState === 2) {
          this.props.dispatch(arrayRemoveAll("Contacts", "beneficiaryOwners"));
        }
      }
      if (name === "directorState") {
        if (this.state.directorState === 1 && this.props.directors.length < 1) {
          this.props.dispatch(arrayRemoveAll("Contacts", "directors"));
          this.props.dispatch(arrayPush("Contacts", "directors", {}));
        }
        if (this.state.directorState === 2) {
          this.props.dispatch(arrayRemoveAll("Contacts", "directors"));
        }
      }
    });
  };

  changeAddressSelect = (value, label, name) => {
    this.setState({ [name]: value });
  };

  scrollToBottom = () => {
    if (this.bottomRef.current) {
      this.bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    const { styles } = this.props;
    return (
      <>
        <div
          className={styles.msgContainer}
          style={{
            width: "600px",
            fontFamily: "Montserrat",
            textAlign: "center",
          }}
        >
          Reminder: Click "
          <a
            onClick={this.scrollToBottom}
            style={{
              cursor: "pointer",
            }}
          >
            Save and Continue
          </a>
          " button to keep your progress!
        </div>
        <div className={styles.contentContainerX}>
          <div className={styles.description}>
            <Stepper
              steps={steps}
              activeStep={3}
              context={"company-full"}
              businessOperatedName={this.props.businessOperatedName}
            />
          </div>
          <div className={styles.section}>
            <div className={styles.subHeaderLabels}>
              Section {this.state.programPaymentType !== "postpayment" ? 9 : 7}:
              Corporate Contact
            </div>
            <div className={styles.msgContainer}>
              The Corporate Contact is authorized to open and manage the
              company's Aya account, including signing documents and conducting
              financial transactions.
            </div>
            <div className={styles.sectionContent}>
              <div className={styles.inputContainer}>
                <Field
                  component={ConnectedInput}
                  label="First Name"
                  name="firstName"
                  type="text"
                  disabled={true}
                  placeholder="Enter First Name"
                  extrastyle="medium"
                />
                <div style={{ paddingRight: 20, marginRight: 10 }} />
                <Field
                  component={ConnectedInput}
                  label="Last Name"
                  name="lastName"
                  type="text"
                  disabled={true}
                  extrastyle="medium"
                  placeholder="Enter Last Name"
                />
              </div>
              <Field
                component={ConnectedInput}
                label="Email"
                name="email"
                validate={[email]}
                disabled={true}
                fixed={true}
                type="email"
                placeholder="Enter Email"
              />
            </div>
            <Field
              component={FormattedInput}
              label="Mobile Number"
              name="mobileNumber"
              type="tel"
              labelDetail="optional"
              disabled={true}
              myFormat="+1 (###) ###-####"
              placeholder="Enter Mobile Number"
            />
            <div className={styles.multiInputContainer}>
              <Field
                component={FormattedInput}
                label="Work Number"
                name="workNumber"
                type="tel"
                labelDetail="optional"
                extrastyle="large"
                disabled={true}
                myFormat="+1 (###) ###-####"
                placeholder="Enter Work Number"
              />
              <Field
                component={ConnectedInput}
                label="Extension"
                name="workNumberExt"
                type="text"
                extrastyle="tiny"
                placeholder="0"
              />
            </div>
            {this.props.programPaymentType !== "postpayment" && (
              <React.Fragment>
                <div className={styles.standaloneLabel}>
                  Residential Address
                </div>
                <GoogleSuggest
                  name="residentialAddress"
                  setAddress={this.setAddress}
                  labelDetail="P. O. Box Not Accepted"
                />
                {(this.state.streetOne || this.state.city) && (
                  <React.Fragment>
                    <Input
                      label="Address 1"
                      name="streetOne"
                      type="text"
                      placeholder="Address 1"
                      onKeyUp={this.handleChange}
                      myValue={this.state.streetOne}
                    />
                    <Input
                      label="Address 2 (e.g., Apt Number, Suite Number…etc)"
                      name="streetTwo"
                      type="text"
                      placeholder="Address 2 (e.g., Apt Number, Suite Number…etc)"
                      onKeyUp={this.handleChange}
                      myValue={this.state.streetTwo}
                    />
                    <div className={styles.inputContainer}>
                      <Input
                        label="City"
                        name="city"
                        extrastyle="semi-medium"
                        type="text"
                        placeholder="City"
                        onKeyUp={this.handleChange}
                        myValue={this.state.city}
                      />
                      <Input
                        label="Province/State"
                        name="province"
                        type="text"
                        extrastyle="medium-with-margin"
                        placeholder="State/Province"
                        onKeyUp={this.handleChange}
                        myValue={this.state.province}
                      />
                      <CustomSelect
                        label="Country"
                        name="country"
                        extrastyle="medium-with-margin"
                        onChange={(value, label, name) =>
                          this.changeAddressSelect(value, label, name)
                        }
                        options={GlobalCountryList}
                        value={this.state.country}
                      />
                    </div>
                    <Input
                      label="Postal Code/ZIP"
                      name="postalCode"
                      type="text"
                      error={
                        (this.state.postalCode &&
                          this.state.country === "US" &&
                          postalCodeUS(this.state.postalCode)) ||
                        (this.state.country === "CA" &&
                          postalCodeCA(this.state.postalCode))
                      }
                      extrastyle="semi-medium"
                      placeholder="Zip/Postal Code"
                      onKeyUp={this.handleChange}
                      myValue={this.state.postalCode}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <DateInput
              label="Date of Birth "
              name="dateOfBirth"
              type="text"
              extrastyle="medium"
              selected={this.state.dateOfBirth}
              handleDateChange={(date, name) =>
                this.handleGenenicDateChange(date, name)
              }
              error={
                moment.utc(this.state.dateOfBirth).isAfter(Date.now(), "day")
                  ? "Date cannot be greater than today"
                  : moment.utc().diff(this.state.dateOfBirth, "years", true) <
                    18
                  ? "Must be over 18 years of age"
                  : ""
              }
            />
            {this.props.programPaymentType !== "postpayment" && (
              <React.Fragment>
                <CustomSelect
                  label="Select the Government Photo ID Type "
                  name="documentType"
                  onChange={(value, label) =>
                    this.changeSelect("documentType", value, label)
                  }
                  value={this.state.documentType}
                  options={GovermentIDOptions}
                />
                <Field
                  component={ConnectedInput}
                  label="Government Photo ID Number "
                  name="documentNumber"
                  type="text"
                  placeholder="0000000000"
                />
                <DateInput
                  label="Government photo ID Expiry Date "
                  name="expiryDate"
                  type="text"
                  extrastyle="medium"
                  selected={this.state.expiryDate}
                  handleDateChange={(date, name) =>
                    this.handleGenenicDateChange(date, name)
                  }
                  error={
                    moment
                      .utc(this.state.expiryDate)
                      .isBefore(Date.now(), "day") &&
                    "You cannot use an expired document"
                  }
                />
                {(this.state["documentType"] === "canpass" ||
                  this.state["documentType"] === "candrlic") && (
                  <CustomSelect
                    label="Province of Issuance"
                    name="provinceOfIssuance"
                    onChange={(value) =>
                      this.changeSelect("provinceOfIssuance", value)
                    }
                    value={this.state.provinceOfIssuance}
                    options={ProvinceList}
                  />
                )}
              </React.Fragment>
            )}
          </div>
          {/* {this.props.programPaymentType !== "postpayment" && (
          <div className={styles.section}>
            <div className={styles.subHeaderLabels}>
              Section 10: Authorized Signer
            </div>
            <div className={styles.msgContainer}>
              An authorized signer is legally empowered to manage the company's
              Aya account, including signing and conducting transactions. Aya,
              as a registered Money Services Business (MSB) under FINTRAC
              regulations, requires authorized signers to provide two forms of
              ID to enroll a business and activate an account.
            </div>
          </div>
        )} */}
          <div className={styles.section}>
            {/* <Divider /> */}
            <div className={styles.subHeaderLabels}>
              Section 10: PEP Declaration
            </div>
            {/* <div className={styles.sectionTitle}>PEP Declaration</div> */}
            <div className={styles.standaloneLabel}>
              Are you a Politically Exposed Person (PEP), a Head of an
              International Organization (HIO), or a family member or close
              associate of one?{" "}
            </div>
            <div className={styles.description}>
              A PEP is someone who holds or has held a senior position in a
              government, military, judiciary, or as Mayor of any municipality.
              An HIO is a leader of an international organization funded by
              multiple nations, regardless of citizenship, residency, or
              birthplace.
            </div>
            <div className={styles.sectionContent}>
              <RadioGroup
                items={PEPList}
                value={this.state.isPEP}
                noBackground={true}
                name="isPEP"
                onUpdate={(value) => this.onChangeGenericRadio(value, "isPEP")}
              />
            </div>
          </div>

          {/* <Divider /> */}

          <div className={styles.section}>
            {/* <div className={styles.sectionTitle}>Plan Administrator</div> */}
            <div className={styles.subHeaderLabels}>
              Section 11: Plan Administrator
            </div>
            <div className={styles.msgContainer}>
              A Plan Administrator will receive access to the Plan Admin Portal
              for managing your Aya account, including user management,
              reporting, and invoicing. The Plan Admin can enrol, terminate, and
              allocate funds to users as needed.
            </div>
            <div className={styles.sectionContent}>
              <div className={styles.inputContainer}>
                <Checkbox
                  checked={this.state.planAdministratorSameWithCoperateContact}
                  onClick={() =>
                    this.toggleGeneric(
                      "planAdministratorSameWithCoperateContact"
                    )
                  }
                  darkBox={"darkBox"}
                  label={
                    "The Plan Administrator different from the Corporate Contact/Authorized Signer"
                  }
                />
              </div>
              {this.state.planAdministratorSameWithCoperateContact && (
                <React.Fragment>
                  <div className={styles.inputContainer}>
                    <Field
                      component={ConnectedInput}
                      label="First Name"
                      name="planAdminFirstName"
                      type="text"
                      validate={
                        this.state.planAdministratorSameWithCoperateContact && [
                          required,
                        ]
                      }
                      placeholder="Enter First Name"
                      extrastyle="medium"
                    />
                    <div style={{ paddingRight: 20, marginRight: 10 }} />
                    <Field
                      component={ConnectedInput}
                      label="Last Name"
                      name="planAdminLastName"
                      type="text"
                      validate={
                        this.state.planAdministratorSameWithCoperateContact && [
                          required,
                        ]
                      }
                      extrastyle="medium"
                      placeholder="Enter Last Name"
                    />
                  </div>
                  <div>
                    <Field
                      component={ConnectedInput}
                      label="Email"
                      name="planAdminEmail"
                      validate={
                        this.state.planAdministratorSameWithCoperateContact && [
                          required,
                          email,
                        ]
                      }
                      type="email"
                      placeholder="Enter Email"
                    />
                    <div className={styles.msgContainer}>
                      <Icon name="info circle" />
                      <span>
                        The Plan Administrator Email Address must be unique and
                        should be used only as the Plan Administrator.
                      </span>
                    </div>
                  </div>
                  <br />
                  <Field
                    component={FormattedInput}
                    label="Plan Administrator Mobile Number"
                    name="planAdminMobileNumber"
                    type="tel"
                    labelDetail="optional"
                    myFormat="+1 (###) ###-####"
                    placeholder="Enter Mobile Number"
                  />
                  <div className={styles.multiInputContainer}>
                    <Field
                      component={FormattedInput}
                      label="Plan Administrator Work Number"
                      name="planAdminWorkNumber"
                      type="tel"
                      labelDetail="optional"
                      extrastyle="large"
                      myFormat="+1 (###) ###-####"
                      placeholder="Enter Work Number"
                    />
                    <Field
                      component={ConnectedInput}
                      label="Extension"
                      name="planAdminWorkNumberExt"
                      type="text"
                      extrastyle="tiny"
                      placeholder="0"
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          {this.props.businessType &&
            this.props.businessType.toLowerCase() !== "corporation" && (
              <React.Fragment>
                {/* <Divider /> */}
                <div className={styles.subHeaderLabels}>
                  Section
                  {this.state.programPaymentType !== "postpayment" ? 13 : 12}:
                  Beneficial Ownership Information
                </div>
                <div className={styles.section}>
                  {/* <div className={styles.sectionTitle}>
                  Beneficial Ownership Information
                </div> */}
                  <div className={styles.description}>
                    Does the company have beneficiary owners (not listed above)
                    that own more than 25% of the company
                  </div>
                  <RadioGroup
                    items={BeneficialOwnersList}
                    value={this.state.beneficiaryOwnersState}
                    noBackground={true}
                    name="beneficiaryOwnersState"
                    onUpdate={(value) =>
                      this.onChangeGenericRadio(value, "beneficiaryOwnersState")
                    }
                  />
                  {this.state.beneficiaryOwnersState === 1 && (
                    <FieldArray
                      name="beneficiaryOwners"
                      component={this.renderBeneficialOwners}
                      styles={styles}
                      mystate={this.state}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          {this.props.businessType &&
            this.props.businessType.toLowerCase() === "corporation" &&
            this.props.programPaymentType !== "postpayment" && (
              <React.Fragment>
                <Divider />
                <div className={styles.section}>
                  {/* <div className={styles.sectionTitle}>Director Information</div> */}
                  <div className={styles.subHeaderLabels}>
                    Section{" "}
                    {this.state.programPaymentType === "postpayment" ? 11 : 12}:
                    Director Information
                  </div>
                  <div className={styles.description}>
                    Does the company have Directors ? Directors are individuals
                    listed in the articles of Incorporation that may or may not
                    be Beneficial Owners
                  </div>
                  <RadioGroup
                    items={DirectorsList}
                    value={this.state.directorState}
                    noBackground={true}
                    name="directorState"
                    onUpdate={(value) =>
                      this.onChangeGenericRadio(value, "directorState")
                    }
                  />
                  {this.state.directorState === 1 && (
                    <FieldArray
                      name="directors"
                      component={this.renderDirectors}
                      styles={styles}
                      mystate={this.state}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          <div className={styles.buttonAreaContainer}>
            <GradientButton
              handleClick={() => {
                // e.preventDefault();
                this.updateCompany(FULL__STEP_FIVE__COMPANY);
              }}
              style={{
                marginTop: "20px",
              }}
              label="Save And Continue"
              width="100%"
            ></GradientButton>
          </div>

          {/* Bottom element to scroll to */}
          <div ref={this.bottomRef}></div>
        </div>
      </>
    );
  }

  renderDirectors = ({ styles, handleChange, fields, mystate }) => (
    <div>
      {fields.map((director, index) => (
        <div
          key={index}
          className={`${styles.sectionContent} ${styles.directorWrapper}`}
        >
          <div className={`${styles.blueContainer} ${styles.checkboxActive}`}>
            <div className={styles.sectionTitle}>Director {index + 1}</div>
            <div className={styles.inputContainer}>
              <Field
                component={ConnectedInput}
                label="First Name"
                name={`${director}.firstName`}
                type="text"
                validate={[required]}
                placeholder="Enter First Name"
                extrastyle="medium"
              />
              <div style={{ paddingRight: 20, marginRight: 10 }} />
              <Field
                component={ConnectedInput}
                label="Last Name"
                name={`${director}.lastName`}
                type="text"
                validate={[required]}
                extrastyle="medium"
                placeholder="Enter Last Name"
              />
            </div>
            <Field
              component={ConnectedInput}
              label="Email"
              name={`${director}.email`}
              type="email"
              validate={[required, email]}
              placeholder="Enter Email"
            />
            <Field
              component={FormattedInput}
              label="Mobile Number"
              name={`${director}.mobileNumber`}
              type="tel"
              labelDetail="optional"
              myFormat="+1 (###) ###-####"
              placeholder="Enter Mobile Number"
            />
            <div className={styles.multiInputContainer}>
              <Field
                component={FormattedInput}
                label="Work Number"
                name={`${director}.workNumber`}
                type="tel"
                labelDetail="optional"
                extrastyle="large"
                myFormat="+1 (###) ###-####"
                placeholder="Enter Work Number"
              />
              <Field
                component={ConnectedInput}
                label="Extension"
                name={`${director}.workNumberExt`}
                type="text"
                extrastyle="tiny"
                placeholder="0"
              />
            </div>
            <div>
              <div className={styles.removeAdministratorWrapper}>
                <a
                  href={"#"}
                  onClick={(e) => {
                    e.preventDefault();
                    if (_.size(fields) === 1) {
                      this.setState({ directorState: 2 });
                    }
                    fields.remove(index);
                  }}
                  className={styles.removeAdministrator}
                >
                  - Remove Director
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          fields.push();
        }}
        className={styles.addAdministrator}
      >
        + Add New Director
      </a>
    </div>
  );

  renderBeneficialOwners = ({ styles, handleChange, fields, mystate }) => (
    <div>
      {fields.map((beneficiaryOwner, index) => (
        <div
          key={index}
          className={`${styles.sectionContent} ${styles.beneficiaryOwnerWrapper}`}
        >
          <div className={`${styles.blueContainer} ${styles.checkboxActive}`}>
            <div className={styles.sectionTitle}>
              Beneficial Owner {index + 1}
            </div>
            <div className={styles.inputContainer}>
              <Field
                component={ConnectedInput}
                label="First Name"
                name={`${beneficiaryOwner}.firstName`}
                type="text"
                validate={[required]}
                placeholder="Enter First Name"
                extrastyle="medium"
              />
              <div style={{ paddingRight: 20, marginRight: 10 }} />
              <Field
                component={ConnectedInput}
                label="Last Name"
                name={`${beneficiaryOwner}.lastName`}
                type="text"
                validate={[required]}
                extrastyle="medium"
                placeholder="Enter Last Name"
              />
            </div>
            <Field
              component={ConnectedInput}
              label="Email"
              name={`${beneficiaryOwner}.email`}
              type="email"
              validate={[required, email]}
              placeholder="Enter Email"
            />
            <Field
              component={FormattedInput}
              label="Mobile Number"
              name={`${beneficiaryOwner}.mobileNumber`}
              type="tel"
              labelDetail="optional"
              myFormat="+1 (###) ###-####"
              placeholder="Enter Mobile Number"
            />
            <div className={styles.multiInputContainer}>
              <Field
                component={FormattedInput}
                label="Work Number"
                name={`${beneficiaryOwner}.workNumber`}
                type="tel"
                labelDetail="optional"
                extrastyle="large"
                myFormat="+1 (###) ###-####"
                placeholder="Enter Work Number"
              />
              <Field
                component={ConnectedInput}
                label="Extension"
                name={`${beneficiaryOwner}.workNumberExt`}
                type="text"
                extrastyle="tiny"
                placeholder="0"
              />
            </div>
            <Field
              component={FormattedInput}
              name={`${beneficiaryOwner}.amountOfOwnership`}
              type="text"
              placeholder="0%"
              validate={[required, maxValue100, minValue25]}
              extrastyle="tiny"
              suffix={"%"}
            />
            <Checkbox
              checked={mystate[`beneficiaryOwnerIsDirector${index}`]}
              onClick={() =>
                this.toggleGeneric(`beneficiaryOwnerIsDirector${index}`)
              }
              label={"This beneficiary owner is also a Company Director"}
            />
            <div>
              <div className={styles.removeAdministratorWrapper}>
                <a
                  href={"#"}
                  onClick={(e) => {
                    e.preventDefault();
                    if (_.size(fields) === 1) {
                      this.setState({ beneficiaryOwnersState: 2 });
                    }
                    fields.remove(index);
                  }}
                  className={styles.removeAdministrator}
                >
                  - Remove Beneficial Owner
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
      {_.size(fields) < 4 && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            fields.push();
          }}
          className={styles.addAdministrator}
        >
          + Add New Beneficial Owner
        </a>
      )}
    </div>
  );

  updateCompany = async (nextStep) => {
    const {
      firstName,
      lastName,
      mobileNumber,
      workNumber,
      workNumberExt,
      documentNumber,
      // accountantFirstName,
      // accountantLastName,
      // accountantEmail,
      // accountantMobileNumber,
      // accountantWorkNumber,
      // accountantWorkNumberExt,
      directors,
      beneficiaryOwners,
    } = this.props;

    const {
      planAdminFirstName,
      planAdminLastName,
      planAdminEmail,
      planAdminMobileNumber,
      planAdminWorkNumber,
      planAdminWorkNumberExt,
      planAdminDocumentNumber,
    } = this.props;

    const {
      residentialAddress,
      streetOne,
      streetTwo,
      city,
      province,
      postalCode,
      country,
      dateOfBirth,
      documentType,
      expiryDate,
      provinceOfIssuance,
      isPEP,
    } = this.state;

    const { businessType } = this.state;

    let payloadData = this.props.company;
    // if (!payloadData.inviteGuid) {
    //   payloadData = this.props.companyAllInfo;
    // }

    let { contacts = [] } = payloadData;
    //Remove directors, beneficiaries and sponsors from contacts
    if (
      contacts &&
      typeof contacts !== null &&
      typeof contacts.forEach === "function"
    ) {
      var i = contacts.length;
      while (i--) {
        if (
          contacts[i].userRole.name === "planSponsorDirector" ||
          contacts[i].userRole.name === "planSponsorBeneficiary" ||
          contacts[i].userRole.name === "planSponsorAccountant"
        ) {
          contacts.splice(i, 1);
        }
      }
    }

    const params = {
      method: "put",
      path: `invites/${payloadData.inviteGuid}`,
      data: {
        ...payloadData,
        dirty: true,
        inviteState: {
          dirty: true,
          step: nextStep,
          status: "active",
        },
        sendNotification: false,
        person: {
          ...payloadData.person,
          dirty: true,
          firstName: firstName,
          lastName: lastName,
          mobileNumber: mobileNumber,
          workNumber: workNumber,
          workNumberExt: workNumberExt,
          countryCode: "CA",
          languageCode: "en",
          isAddressSame: true,
          address: {
            dirty: true,
            isPrimary: true,
            addressClass: "residential",
            city: city,
            province: province,
            postalCode: postalCode,
            streetOne: streetOne,
            streetTwo: streetTwo,
            country: country,
          },
          mailingAddress: {
            dirty: true,
            isPrimary: false,
            addressClass: "mailing",
            city: city,
            province: province,
            postalCode: postalCode,
            streetOne: streetOne,
            streetTwo: streetTwo,
            country: country,
          },
          verification: {
            dirty: true,
            documentType: documentType,
            documentNumber: documentNumber,
            expiryDate: expiryDate,
            jurisdictionOfInsurance: provinceOfIssuance,
          },
          metaInformation: {
            ...payloadData.person?.metaInformation,
            dirty: true,
            dateOfBirth: dateOfBirth,
            familyStatus: "n/a",
          },
        },
        business: {
          dirty: true,
          ...payloadData.business,
          metaInformation: {
            ...payloadData.business.metaInformation,
            dirty: true,
            // billingContactSameWith:
            //   this.state.billingContactSameWith === "true" ? "true" : "false",
            isPEP: isPEP ? "true" : "false",
          },
        },
        contacts: contacts || [],
        accessToken: this.props.accessToken,
      },
    };

    if (!this.state.planAdministratorSameWithCoperateContact) {
      params.data.person.additionalRole = ["planSponsorAdministratorPrimary"];
    } else {
      delete params.data.person.additionalRole;
    }

    //add plan admin
    const {
      planAdminAddress,
      planAdminStreetOne,
      planAdminStreetTwo,
      planAdminCity,
      planAdminProvince,
      planAdminCountry,
      planAdminPostalCode,
      planAdminDateOfBirth,
      planAdminDocumentType,
      planAdminExpiryDate,
      planAdminProvinceOfIssuance,
    } = this.state;
    let planAdminContact = {
      planAdminFirstName: this.state.planAdministratorSameWithCoperateContact
        ? planAdminFirstName
        : "",
      planAdminLastName: this.state.planAdministratorSameWithCoperateContact
        ? planAdminLastName
        : "",
      planAdminEmail: this.state.planAdministratorSameWithCoperateContact
        ? planAdminEmail?.toLowerCase()
        : "",
      planAdminMobileNumber: this.state.planAdministratorSameWithCoperateContact
        ? planAdminMobileNumber
        : "",
      planAdminWorkNumber: this.state.planAdministratorSameWithCoperateContact
        ? planAdminWorkNumber
        : "",
      planAdminWorkNumberExt: this.state
        .planAdministratorSameWithCoperateContact
        ? planAdminWorkNumberExt
        : "",
      planAdminAddress,
      planAdminStreetOne,
      planAdminStreetTwo,
      planAdminCity,
      planAdminProvince,
      planAdminCountry,
      planAdminPostalCode,
      planAdminDateOfBirth,
      planAdminDocumentType,
      planAdminExpiryDate,
      planAdminProvinceOfIssuance,
      planAdminParams: this.createNewPlanAdmin(),
    };
    params.data.person.metaInformation["planAdminContact"] =
      JSON.stringify(planAdminContact);

    if (
      this.props.businessType.toLowerCase() === "corporation" &&
      this.state.directorState === 1 &&
      directors.length > 0
    ) {
      for (const director of directors) {
        let item = {
          dirty: true,
          personStatus: "PIDV01",
          firstName: director.firstName,
          lastName: director.lastName,
          email: director.email ? director.email.toLowerCase() : undefined,
          userRole: {
            label: "PlanSponsor Company Director",
            name: "planSponsorDirector",
          },
          countryCode: "CA",
          languageCode: "en",
          mobileNumber: director.mobileNumber,
          workNumber: director.workNumber,
          workNumberExt: director.workNumberExt,
          dirty: true,
        };
        params.data.contacts.push(item);
      }
    }

    if (
      this.props.businessType.toLowerCase() !== "corporation" &&
      this.state.beneficiaryOwnersState === 1 &&
      beneficiaryOwners.length > 0
    ) {
      for (const [index, beneficiary] of beneficiaryOwners.entries()) {
        let item = {
          dirty: true,
          personStatus: "PIDV01",
          firstName: beneficiary.firstName,
          lastName: beneficiary.lastName,
          email: beneficiary.email
            ? beneficiary.email.toLowerCase()
            : undefined,
          userRole: {
            label: "PlanSponsor Company Beneficiary Contact",
            name: "planSponsorBeneficiary",
          },
          countryCode: "CA",
          languageCode: "en",
          mobileNumber: beneficiary.mobileNumber,
          workNumber: beneficiary.workNumber,
          workNumberExt: beneficiary.workNumberExt,
          metaInformation: {
            dirty: true,
            amountOfOwnership: beneficiary.amountOfOwnership,
            isDirector: this.state[`beneficiaryOwnerIsDirector${index}`],
          },
        };
        params.data.contacts.push(item);
      }
    }

    //Payload string
    const modifiedPayloadString = addToMetaDataPayloadString(
      this.state.payloadString,
      params.data
    );
    let newParams = resetPayload(params);
    newParams.data.business.metaInformation["payloadString"] =
      modifiedPayloadString;

    try {
      // let response = this.props.updateCompany(newParams);
      let response = await this.props.saveEnrolmentCompanyData({
        companyData: modifiedPayloadString,
        enrolledByEmail: this.state.enrolledByEmail,
        guid: this.state.selectedCompanyGuid,
      });
    } catch (ex) {}
  };

  createNewPlanAdmin = () => {
    const {
      planAdminFirstName,
      planAdminLastName,
      planAdminEmail,
      planAdminMobileNumber,
      planAdminWorkNumber,
      planAdminWorkNumberExt,
      planAdminDocumentNumber,
    } = this.props;

    const {
      planAdminAddress,
      planAdminStreetOne,
      planAdminStreetTwo,
      planAdminCity,
      planAdminProvince,
      planAdminCountry,
      planAdminPostalCode,
      planAdminDateOfBirth,
      planAdminDocumentType,
      planAdminExpiryDate,
      planAdminProvinceOfIssuance,
    } = this.state;

    const { businessType } = this.state;

    let payloadData = this.props.company;

    const params = {
      method: "post",
      path: `invites`,
      data: {
        dirty: true,
        inviteState: {
          dirty: true,
          step: COMPLETE__STEP_SEVEN__COMPANY,
          status: "active",
        },
        sendNotification: false,
        requestedForBusinessId: payloadData.business
          ? payloadData.business.businessGUID
          : "",
        requestedPersonParentId: "",
        person: {
          dirty: true,
          personStatus: "PIDV12",
          firstName: planAdminFirstName,
          lastName: planAdminLastName,
          mobileNumber: planAdminMobileNumber,
          workNumber: planAdminWorkNumber,
          workNumberExt: planAdminWorkNumberExt,
          email: planAdminEmail ? planAdminEmail.toLowerCase() : undefined,
          countryCode: "CA",
          languageCode: "en",
          isAddressSame: true,
          address: {
            dirty: true,
            isPrimary: true,
            addressClass: "residential",
            city: planAdminCity,
            province: planAdminProvince,
            postalCode: planAdminPostalCode,
            streetOne: planAdminStreetOne,
            streetTwo: planAdminStreetTwo,
            country: planAdminCountry,
          },
          mailingAddress: {
            dirty: true,
            isPrimary: false,
            addressClass: "mailing",
            city: planAdminCity,
            province: planAdminProvince,
            postalCode: planAdminPostalCode,
            streetOne: planAdminStreetOne,
            streetTwo: planAdminStreetTwo,
            country: planAdminCountry,
          },
          verification: {
            dirty: true,
            documentType: planAdminDocumentType,
            documentNumber: planAdminDocumentNumber,
            expiryDate: planAdminExpiryDate,
            jurisdictionOfInsurance: planAdminProvinceOfIssuance,
          },
          metaInformation: {
            dirty: true,
            dateOfBirth: planAdminDateOfBirth,
            familyStatus: "n/a",
          },
          userRole: {
            dirty: true,
            label: "planSponsorAdministratorPrimary",
            name: "planSponsorAdministratorPrimary",
          },
        },
        accessToken: this.props.accessToken,
      },
    };

    return params;
  };
}

const validate = (formValues) => {
  const { firstName, lastName, email, documentNumber } = formValues;
  const errors = {};

  if (isEmpty(firstName)) errors.firstName = "FirstName cannot be left blank";
  if (isEmpty(lastName)) errors.lastName = "LastName cannot be left blank";
  if (isEmpty(email)) errors.email = "Email cannot be left blank";
  else if (!isEmailValid(email)) errors.email = "Invalid email";
  if (isEmpty(documentNumber))
    errors.documentNumber = "DocumentNumber cannot be left blank";

  return errors;
};

const WrappedContacts = reduxForm({
  form: "Contacts",
  validate,
  enableReinitialize: true,
})(Contacts);

const mapStateToProps = (state) => {
  const selector = formValueSelector("Contacts");
  // const { industries, newPlanAdmin } = state.companyReducer;

  // const companyAllInfo = state.companyReducer.companyAllInfo;
  const { accessToken } = state.authReducer;

  //From payload string
  // let data = state.companyReducer.companyAllInfo;
  // let payloadString =
  //   state.companyReducer.companyAllInfo?.business?.metaInformation
  //     ?.payloadString ?? "";
  // if (payloadString !== "") data = JSON.parse(payloadString);
  const data = state.companyReducer?.companyEnrolment?.companyData ?? {};
  let payloadString =
    JSON.stringify(state.companyReducer.companyEnrolment?.companyData) ?? "";
  const companyEnrolment = state.companyReducer.companyEnrolment ?? {};

  const enrolledByEmail = state.authReducer?.preAuthDetails?.email ?? "";

  const businessOperatedName =
    _.get(data, "business.businessOperatedName") ?? "";

  //retrieve meta data
  let myMetaData = {};
  if (payloadString !== "") {
    myMetaData = {
      ...data.business.metaInformation,
      ...data.person.metaInformation,
    };
  } else {
    if (
      _.has(data, "business.metaInformation") &&
      typeof data.business.metaInformation !== "undefined" &&
      typeof data.business.metaInformation.forEach === "function"
    ) {
      data.business.metaInformation.forEach((item) => {
        if (item.metaType === "string") {
          myMetaData[item.metaKey] = item.metaValue;
        }
      });
    }
    if (
      _.has(data, "person.metaInformation") &&
      typeof data.person.metaInformation !== "undefined" &&
      typeof data.person.metaInformation.forEach === "function"
    ) {
      data.person.metaInformation.forEach((item) => {
        if (item.metaType === "string") {
          myMetaData[item.metaKey] = item.metaValue;
        }
      });
    }
  }

  const businessType =
    _.get(data, "business.metaInformation.businessType") ?? "";

  // get pep
  let isPEP = false;
  let metaPEP =
    _.get(data, "business.metaInformation.isPEP") || _.get(myMetaData, "isPEP");
  if (metaPEP) {
    isPEP = metaPEP === "true" ? true : false;
  }

  const {
    planAdminFirstName,
    planAdminLastName,
    planAdminEmail,
    planAdminMobileNumber,
    planAdminWorkNumber,
    planAdminWorkNumberExt,
  } = JSON.parse(myMetaData?.planAdminContact ?? "{}");

  let directors = [];
  let beneficiaryOwners = [];
  let beneficiaryOwnersWhoAreDirectors = [];
  let index = 0;
  if (
    data.contacts &&
    typeof data.contacts !== null &&
    typeof data.contacts.forEach === "function"
  ) {
    for (let item of data.contacts) {
      if (_.get(item, "userRole.name") === "planSponsorDirector") {
        directors.push({
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          mobileNumber: item.mobileNumber,
          workNumber: item.workNumber,
          workNumberExt: item.workNumberExt,
        });
      }
      if (_.get(item, "userRole.name") === "planSponsorBeneficiary") {
        beneficiaryOwners.push({
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          mobileNumber: item.mobileNumber,
          workNumber: item.workNumber,
          workNumberExt: item.workNumberExt,
          amountOfOwnership: item.metaInformation?.amountOfOwnership ?? "",
          ["beneficiaryOwnerIsDirector" + index]:
            item.metaInformation?.isDirector ?? false,
          beneficiaryOwnerIsDirector: item.metaInformation?.isDirector ?? false,
        });
        if (item.metaInformation?.isDirector) {
          beneficiaryOwnersWhoAreDirectors.push(index);
        }
        index++;
      }
    }
  }
  let directorState = directors.length > 0 ? 1 : 2;
  let beneficiaryOwnersState = beneficiaryOwners.length > 0 ? 1 : 2;

  const firstName = _.get(data, "person.firstName") ?? "";
  const lastName = _.get(data, "person.lastName") ?? "";
  const email = _.get(data, "person.email") ?? "";
  const mobileNumber = _.get(data, "person.mobileNumber") ?? "";
  const workNumber = _.get(data, "person.workNumber") ?? "";
  const workNumberExt = _.get(data, "person.workNumberExt") ?? "";

  let personVerification = _.get(data, "person.verification");
  let expiryDate = personVerification?.expiryDate;
  let documentType = personVerification?.documentType;
  let documentNumber = personVerification?.documentNumber;
  let jurisdictionOfInsurance = personVerification?.jurisdictionOfInsurance;

  let address = _.get(data, "person.address");
  let streetOne = address?.streetOne;
  let streetTwo = address?.streetTwo;
  let city = address?.city;
  let province = address?.province;
  let country = address?.country;
  let postalCode = address?.postalCode;

  //get programDefinedVariables's
  //business pdv's
  let programDefinedVariables = {};
  if (
    _.get(data, "business.programDefinedVariables") &&
    typeof data.business.programDefinedVariables !== null &&
    typeof data.business.programDefinedVariables.forEach === "function"
  ) {
    data.business.programDefinedVariables.forEach((item) => {
      if (
        item.productVariableDefinition.variableName &&
        item.variableValue.length > 0
      ) {
        programDefinedVariables[item.productVariableDefinition.variableName] =
          item.variableValue;
      }
    });
  } else if (_.get(data, "business.programDefinedVariables")) {
    for (let item in data.business.programDefinedVariables) {
      if (
        _.get(
          data,
          `business.programDefinedVariables[${item}].productVariableDefinition.variableName`
        )
      ) {
        programDefinedVariables[
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableName
        ] =
          data.business.programDefinedVariables[
            item
          ].productVariableDefinition.variableValue;
      }
      programDefinedVariables[item] =
        data.business.programDefinedVariables[item];
    }
  }

  return {
    firstName: selector(state, "firstName"),
    lastName: selector(state, "lastName"),
    email: selector(state, "email"),
    mobileNumber: selector(state, "mobileNumber"),
    workNumber: selector(state, "workNumber"),
    workNumberExt: selector(state, "workNumberExt"),
    documentNumber: selector(state, "documentNumber"),
    planAdminFirstName: selector(state, "planAdminFirstName"),
    planAdminLastName: selector(state, "planAdminLastName"),
    planAdminEmail: selector(state, "planAdminEmail"),
    planAdminMobileNumber: selector(state, "planAdminMobileNumber"),
    planAdminWorkNumber: selector(state, "planAdminWorkNumber"),
    planAdminWorkNumberExt: selector(state, "planAdminWorkNumberExt"),
    planAdminDocumentNumber: selector(state, "planAdminDocumentNumber"),
    beneficiaryOwners: selector(state, "beneficiaryOwners"),
    directors: selector(state, "directors"),
    directorState: directorState,
    beneficiaryOwnersState: beneficiaryOwnersState,
    beneficiaryOwnersWhoAreDirectors: beneficiaryOwnersWhoAreDirectors,
    company: data,
    accessToken,
    businessType:
      _.get(data, "business.metaInformation.businessType") ||
      _.get(myMetaData, "businessType"),
    programPaymentType:
      _.get(
        data,
        "business.programDefinedVariables.contractTerm.programPaymentType"
      ) || _.get(programDefinedVariables, "programPaymentType"),
    dateOfBirth: _.get(myMetaData, "dateOfBirth") ?? "",
    expiryDate: expiryDate ?? "",
    documentType: documentType ?? "",
    provinceOfIssuance: jurisdictionOfInsurance ?? "",
    documentNumber: selector(state, "documentNumber"),
    planAdminContactSeparate:
      planAdminFirstName ||
      planAdminLastName ||
      planAdminEmail ||
      planAdminMobileNumber ||
      planAdminWorkNumber ||
      planAdminWorkNumberExt
        ? true
        : false,

    companyEnrolment: selector(state, "companyEnrolment"),
    enrolledByEmail: enrolledByEmail,

    //Person address
    //Business Registered Address
    streetOne: streetOne,
    streetTwo: streetTwo,
    city: city,
    province: province,
    country: country,
    postalCode: postalCode,
    businessOperatedName: selector(state, "businessOperatedName"),
    payloadString: payloadString,
    isPEP: isPEP,
    businessType: businessType,

    initialValues: {
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      mobileNumber: mobileNumber ?? "",
      workNumber: workNumber ?? "",
      workNumberExt: workNumberExt ?? "",
      beneficiaryOwners: beneficiaryOwners,
      beneficiaryOwnersState: beneficiaryOwnersState,
      beneficiaryOwnersWhoAreDirectors: beneficiaryOwnersWhoAreDirectors,
      directors: directors,
      directorState: directorState,
      dateOfBirth: _.get(myMetaData, "dateOfBirth") ?? "",
      expiryDate: expiryDate ?? "",
      documentType: documentType ?? "",
      documentNumber: documentNumber ?? "",
      provinceOfIssuance: jurisdictionOfInsurance ?? "",

      planAdminFirstName: planAdminFirstName ?? "",
      planAdminLastName: planAdminLastName ?? "",
      planAdminEmail: planAdminEmail ?? "",
      planAdminMobileNumber: planAdminMobileNumber ?? "",
      planAdminWorkNumber: planAdminWorkNumber ?? "",
      planAdminWorkNumberExt: planAdminWorkNumberExt ?? "",
      planAdminContactSeparate:
        planAdminFirstName ||
        planAdminLastName ||
        planAdminEmail ||
        planAdminMobileNumber ||
        planAdminWorkNumber ||
        planAdminWorkNumberExt
          ? true
          : false,
      businessOperatedName: businessOperatedName,
      payloadString: payloadString ?? "",
      companyEnrolment: companyEnrolment,
      enrolledByEmail: enrolledByEmail,
    },
  };
};

export default connect(mapStateToProps, {
  updateCompany,
  saveEnrolmentCompanyData,
})(WrappedContacts);
