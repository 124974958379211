import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const GradientLine: React.FC<{
  absolute: boolean;
}> = ({ absolute = true }) => {
  const [whiteLabel] = useSelector((state: any) => [
    state.authReducer.preAuthDetails.whiteLabel,
  ]);
  const primary = whiteLabel?.primarycolor ?? "#606ac1";
  const secondary = whiteLabel?.secondarycolor ?? "#e300b1";
  return (
    <StyledGradientLine
      primary={primary}
      secondary={secondary}
      absolute={absolute}
    />
  );
};

export default GradientLine;

const StyledGradientLine = styled.div`
  height: 3px;
  color: white;
  background: linear-gradient(
    to right,
    ${({ primary }) => primary},
    ${({ secondary }) => secondary} 50% 0
  );
  position: ${(props) => (props.absolute ? "absolute" : "static")};
  width: 100%;
`;
