// React
import { PureComponent, ReactElement } from "react";
// React number format
import NumberFormat from "react-number-format";
// Constant
import { Images } from "../../../constants";
// Components
import Checkbox from "../checkbox";
import { Tooltip } from "../tooltip";
// Scss
import styles from "./Input.module.scss";

// Props
type Props = {
  disabled: boolean;
  fixed: boolean;
  coreInputStylesContainer: string;
  coreInputStyles: string;
  useAlternateValue: boolean;
  label: string;
  input: any;
  type: string;
  placeholder: string;
  labelDetail: string;
  secondaryLink: string;
  secondaryLinkOnClick: () => void;
  meta: { touched: any; error: any; warning: any };
  min: string | number;
  max: string | number;
  extrastyle: string;
  leftIcon: string;
  alternateSource: boolean | string;
  alternateSourceLabel: string;
  secondaryType: string;
  myValue: string;
  tooltip: string | boolean;
  tooltipMessage: string;
  tooltipImage: any;
  dataTestid: string;
  suffix: string | number;
  prefix: string | number;
  myFormat: string | number;
  prestine: any;
  thousandSeparator: string;
  decimalSeparator: string;
  fixedDecimalScale: string;
  decimalScale: string;
  extraInputStyle: string;
};

// State
type State = {
  notEditable: boolean;
  useAlternateValue: boolean;
  fixed: boolean;
  myValue: string;
};

/**
 * @export
 * @class FormattedInput
 * @extends {PureComponent<Props, State>}
 */
export class FormattedInput extends PureComponent<Props, State> {
  /**
   * Creates an instance of FormattedInput.
   * @param {Props} props
   * @memberof FormattedInput
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      notEditable: props.disabled || false,
      useAlternateValue: false,
      fixed: props.fixed || false,
      myValue: this.props.myValue,
    };
  }

  /**
   * @memberof FormattedInput
   */
  onChangeCheckBox = (): void => {
    this.setState((prevState) => {
      return { ...prevState, useAlternateValue: !this.state.useAlternateValue };
    });
  };

  /**
   * @return {*}  {ReactElement}
   * @memberof FormattedInput
   */
  render(): ReactElement {
    const {
      label,
      input,
      type,
      placeholder,
      labelDetail,
      secondaryLink,
      secondaryLinkOnClick,
      meta,
      min,
      max,
      extrastyle,
      leftIcon,
      alternateSource,
      alternateSourceLabel,
      secondaryType,
      myValue,
      suffix,
      prefix,
      myFormat,
      prestine,
      thousandSeparator,
      decimalSeparator,
      fixedDecimalScale,
      decimalScale,
      tooltip,
      tooltipMessage,
      tooltipImage,
      extraInputStyle,
    } = this.props;

    const notEditableStyles: string = this.state.notEditable
      ? "notEditableStyles"
      : "";
    const leftIconReferenceStyles: string = leftIcon
      ? "leftIconReferenceStyles"
      : "";

    return (
      <div
        className={`${styles.inputContainer} ${styles[extrastyle]} ${
          styles[this.props.coreInputStylesContainer]
        }`}
      >
        {label && (
          <div className={styles.labelContainer}>
            <div className={styles.label}>
              {label} <span className={styles.labelDetail}>{labelDetail}</span>
              {(tooltipMessage || tooltipImage) && (
                <Tooltip
                  message={tooltipMessage}
                  render={() => (
                    <img
                      className={styles.tooltipImage}
                      src={Images[tooltipImage]}
                    />
                  )}
                  position={"top"}
                >
                  <img src={Images.question} className={styles.tooltip} />
                </Tooltip>
              )}
            </div>
            <div
              className={styles.secondaryLink}
              onClick={secondaryLinkOnClick}
            >
              {secondaryLink}
            </div>
          </div>
        )}
        <div className={styles.inputWrapper}>
          {leftIcon && (
            <span className={styles.leftIcon}>
              <img height={"20px"} width={"20px"} src={Images[leftIcon]} />
            </span>
          )}
          <NumberFormat
            suffix={suffix}
            prefix={prefix}
            format={myFormat}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            autoCapitalize={"false"}
            className={`${styles.inputField} ${styles[extraInputStyle]} ${
              styles[notEditableStyles]
            } ${styles[leftIconReferenceStyles]} ${
              styles[leftIconReferenceStyles]
            } ${styles[this.props.coreInputStyles]}`}
            type={type}
            {...input}
            min={min}
            max={max}
            value={
              this.props.useAlternateValue
                ? this.props.myValue
                : myValue || input.value
            }
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            disabled={this.state.notEditable}
            placeholder={placeholder}
            allowNegative={false}
          />
          {this.state.notEditable && !this.state.fixed && (
            <span
              className={styles.rightText}
              onClick={() => this.setState({ notEditable: false })}
            >
              Edit
            </span>
          )}
        </div>

        {alternateSource && alternateSourceLabel && (
          <Checkbox
            checked={this.state.useAlternateValue}
            label={alternateSourceLabel}
            darkBox={"darkBox"}
            onClick={this.onChangeCheckBox}
          />
        )}

        {typeof meta !== "undefined" &&
          meta.touched &&
          ((meta.error && <span className={styles.error}>{meta.error}</span>) ||
            (meta.warning && (
              <span className={styles.warn}>{meta.warning}</span>
            )))}
      </div>
    );
  }
}
