// React
import { PureComponent, ReactElement } from "react";
// Components
import { Tooltip } from "../tooltip";
// Constant
import { Images } from "../../../constants/Images";
// Utilities
import { formatPhone } from "../../../utilities/customFunctions";
// Scss
import styles from "./Input.module.scss";

// Props
type Props = {
  disabled: boolean;
  fixed: boolean;
  type: string;
  leftIcon: string;
  tooltipMessage: string;
  tooltipImage: string;
  coreInputStylesContainer: string;
  extrastyle: string;
  label: string;
  secondaryLinkOnClick: () => void;
  secondaryLink: string;
  coreInputStyles: string;
  name: string;
  placeholder: string;
  onBlur: () => void;
  secondaryType: string;
  onKeyUp: () => void;
  myValue: string;
  error: string;
  testid: string;
};
// State
type State = {
  type: string;
  icon: string;
  notEditable: boolean;
  fixed: boolean;
};

/**
 * @export
 * @class Input
 * @extends {PureComponent}
 */
export class Input extends PureComponent<Props, State> {
  /**
   * Creates an instance of Input.
   * @param {Props} props
   * @memberof Input
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      type: "",
      icon: "closedEyeIcon",
      notEditable: props.disabled || false,
      fixed: props.fixed || false,
    };
  }

  /**
   * @memberof Input
   */
  changePasswordType(): void {
    let newState: object;
    if (this.state.type === "password") {
      newState = {
        icon: "openEyeIcon",
        type: "text",
      };
    } else {
      newState = {
        icon: "closedEyeIcon",
        type: "password",
      };
    }
    this.setState(newState);
  }

  /**
   * @return {*}  {ReactElement}
   * @memberof Input
   */
  render(): ReactElement {
    let props: Props = this.props;
    let { leftIcon, tooltipMessage, tooltipImage, testid }: Props = this.props;
    const notEditableStyles: string = this.state.notEditable
      ? "notEditableStyles"
      : "";
    const leftIconReferenceStyles: string = leftIcon
      ? "leftIconReferenceStyles"
      : "";
    return (
      <div
        className={`${styles.inputContainer} ${styles[props.extrastyle]} ${
          styles[props.coreInputStylesContainer]
        }`}
      >
        <div className={styles.labelContainer}>
          <div className={styles.label}>
            {props.label}
            {(tooltipMessage || tooltipImage) && (
              <Tooltip
                message={tooltipMessage}
                render={() => (
                  <img
                    className={styles.tooltipImage}
                    src={Images[tooltipImage]}
                  />
                )}
                position={"top"}
              >
                <img src={Images.question} className={styles.tooltip} />
              </Tooltip>
            )}
          </div>
          <div
            className={styles.secondaryLink}
            onClick={props.secondaryLinkOnClick}
          >
            {props.secondaryLink}
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div className={styles.inputWrapper}>
            {leftIcon && (
              <span className={styles.leftIcon}>
                <img height={"20px"} width={"20px"} src={Images[leftIcon]} />
              </span>
            )}
            <input
              className={`${styles.inputField} ${
                styles[leftIconReferenceStyles]
              } ${styles[props.coreInputStyles]}`}
              type={this.state.type || props.type}
              name={props.name}
              placeholder={props.placeholder}
              // onKeyUp={props.onKeyUp}
              onBlur={props.onBlur}
              onChange={props.onKeyUp}
              value={
                props.secondaryType === "phone"
                  ? formatPhone(props.myValue)
                  : props.myValue
              }
              disabled={this.state.notEditable}
              data-testid={testid}
            />
            {props.type === "password" && (
              <img
                className={styles.imageStyles}
                src={Images[this.state.icon]}
                onClick={() => this.changePasswordType()}
              />
            )}
            {this.state.notEditable && !this.state.fixed && (
              <span
                className={styles.rightText}
                onClick={() => this.setState({ notEditable: false })}
              >
                Edit
              </span>
            )}
          </div>
        </div>
        {props.error && <span className={styles.error}>{props.error}</span>}
      </div>
    );
  }
}

// Export
export default Input;
